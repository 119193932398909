import { ReactElement } from "react";
import classNames from "classnames";
import styles from "./Tag.module.scss";

export type TagVariant = "success" | "error" | "info" | "warning";
type TagProps = {
  children: string | ReactElement;
  variant?: TagVariant;
  className?: string;
};
export function Tag({ children, variant = "success", className }: TagProps) {
  return (
    <div className={classNames(styles.tag, className)} data-variant={variant}>
      {children}
    </div>
  );
}
