import {
  GatewayAssujetti,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { useAssujetisAvancement } from "@src/assujettis/useAssujettiAvancement";
import {
  activitesFromEntreprise,
  isIas,
  isIobsp,
} from "@src/helper/entreprise.helper";
import { useAssujetti } from "@src/store/store.assujetti";

export const useEntrepriseAvancement = () => {
  const { assujettisBySocietes } = useAssujetti();
  const { getAssujetisAvancement } = useAssujetisAvancement();

  return {
    getEntrepriseAvancement: (
      entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
    ): number => {
      const assujettis = assujettisBySocietes[entreprise.siren] || [];
      const activites = activitesFromEntreprise(entreprise);
      const formToKeep = [
        activites.includes(GatewayInfoSocieteOrias.Categorie.COA)
          ? [entreprise.avancement.rcProCoa]
          : [],
        activites.includes(GatewayInfoSocieteOrias.Categorie.MIA)
          ? [entreprise.avancement.rcProMia]
          : [],
        activites.includes(GatewayInfoSocieteOrias.Categorie.COBSP)
          ? [entreprise.avancement.rcProCobsp]
          : [],
        activites.includes(GatewayInfoSocieteOrias.Categorie.MIOBSP)
          ? [entreprise.avancement.rcProMiobsp]
          : [],
        isIas(activites)
          ? [
              entreprise.declarationActiviteIASOptionnelle
                ? 100
                : entreprise.avancement.declarationActiviteIas,
              entreprise.avancement.garantieFinanciereIas,
            ]
          : [],
        isIobsp(activites)
          ? [
              entreprise.declarationActiviteIOBSPOptionnelle
                ? 100
                : entreprise.avancement.declarationActiviteIobsp,
              entreprise.avancement.garantieFinanciereIobsp,
            ]
          : [],
        assujettis.length === 0
          ? [0]
          : assujettis.map((a) => getAssujetisAvancement(a)),
      ]
        .flat()
        .map((a) => a ?? 0);

      return Math.floor(
        formToKeep.reduce<number>((acc, curr) => acc + curr, 0) /
          formToKeep.length
      );
    },
    getEntrepriseAvancementForAssujettiAxe: (
      entreprise: GatewayEntreprise.EntrepriseAControllerResponseType,
      axe: keyof GatewayAssujetti.Avancement
    ) => {
      const assujettis = assujettisBySocietes[entreprise.siren] || [];
      const axes = assujettis.map(
        (assujetti) => assujetti.avancement[axe]
      ) as number[];
      return Math.round(
        axes.reduce<number>((acc, curr) => acc + (curr ?? 0), 0) / axes.length
      );
    },
  };
};
