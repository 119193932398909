import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AdhesionEntreprisePage } from "@src/adhesion/Steps/AdhesionEntreprise/AdhesionEntreprisePage";
import { AdhesionInfoPerso } from "@src/adhesion/Steps/AdhesionInfoPerso/AdhesionInfoPerso";
import { AdhesionIdentifiants } from "@src/adhesion/Steps/AdhesionIdentifiants/AdhesionIdentifiants";
import { AdhesionConfirm } from "@src/adhesion/Steps/AdhesionConfirm/AdhesionConfirm";
import { AdhesionCreationSuccess } from "@src/adhesion/Steps/AdhesionCreationSuccess/AdhesionCreationSuccess";
import { AdhesionTypeEntreprise } from "@src/adhesion/Steps/AdhesionTypeEntreprise/AdhesionTypeEntreprise";
import { AdhesionModeImport } from "@src/adhesion/Steps/AdhesionModeImport/AdhesionModeImport";
import { useSession } from "@src/session/UserSessionContext";
import { UIParcoursFullPage } from "@src/parcours/UIParcoursFullPage";
import { useMe } from "@src/store/store.me";
import { GatewayEntreprise } from "@conformite/gateway";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { useMemo } from "react";
import { Parcours } from "../parcours/Parcours";
import { AdhesionData } from "./AdhesionData/adhesionData";
import { AdhesionSocieteList } from "./Steps/AdhesionSocieteList/AdhesionSocieteList";
import { AdhesionSirenPage } from "./Steps/AdhesionSiren/AdhesionSirenPage";
import { AdhesionEntrepriseAdressePage } from "./Steps/AdhesionEntreprise/AdhesionEntrepriseAdressePage";
import { AdhesionInfoActivitePage } from "./Steps/AdhesionInfoActivite/AdhesionInfoActivitePage";
import { AdhesionSousCategoriesActivitePage } from "./Steps/AdhesionSousCategories/AdhesionSousCategoriesActivitePage";
import { AdhesionOrganisationProfessionnellePage } from "./Steps/AdhesionOrganisationProfessionnelle/AdhesionOrganisationProfessionnellePage";
import { AdhesionInformationsComplementairesPage } from "./Steps/AdhesionInformationsComplementaires/AdhesionInformationsComplementairesPage";
import { AdhesionPaiementPage } from "./Steps/AdhesionPaiement/AdhesionPaiementPage";
import { persistCacheOnAPI } from "./cacheAdhesion.utils";
import {
  AdhesionStepEnum,
  ConnectedAdhesionStepEnum,
  adhesionConnectedSteps,
  adhesionSteps,
} from "./ParcoursAdhesion.definition";
import { useParcoursAdhesionCacheDefaults } from "./useParcoursAdhesionCacheDefaults";
import { AdhesionCouponPage } from "./Steps/AdhesionPaiement/AdhesionCouponPage";
import { AdhesionInit } from "./Steps/AdhesionInit/AdhesionInit";
import { AdhesionImportDashboard } from "./Steps/AdhesionImportDashboard/AdhesionImportDashboard";

function extractStepFromAdhesionPathName(
  pathName: string
): AdhesionStepEnum | undefined {
  if (!pathName.startsWith("/adhesion/")) return undefined;
  const step = pathName.split("/adhesion/")[1];
  if (adhesionSteps.includes(step as AdhesionStepEnum)) {
    return step as AdhesionStepEnum;
  }
  return undefined;
}

function useShouldRedirectOnUnauthorizedStepAccess(
  isConnected: boolean
): boolean {
  const { pathname } = useLocation();
  const wishedStep = extractStepFromAdhesionPathName(pathname);

  if (
    !isConnected &&
    adhesionConnectedSteps.includes(wishedStep as ConnectedAdhesionStepEnum)
  ) {
    return true;
  }
  return false;
}

function useShouldRedirectOnAdhesionAlreadyCompleted(
  isConnected: boolean
): boolean {
  const { me } = useMe();

  if (!isConnected || !me) return false;
  if (
    me.entreprisePrincipale &&
    me.entreprisePrincipale.etat !==
      GatewayEntreprise.EtatEntreprise.ADHESION_EN_COURS &&
    me.entreprisePrincipale.etat !==
      GatewayEntreprise.EtatEntreprise.NON_ADHERENT
  ) {
    return true;
  }
  return false;
}
function useGetFirstStep(isConnected: boolean): AdhesionStepEnum {
  const { me } = useMe();

  return useMemo(() => {
    if (!isConnected) {
      return AdhesionStepEnum.SIREN;
    }
    const entreprisePrincpale = me?.entreprisePrincipale;
    if (
      !entreprisePrincpale ||
      entreprisePrincpale?.etat ===
        GatewayEntreprise.EtatEntreprise.NON_ADHERENT
    ) {
      return AdhesionStepEnum.SIREN;
    }
    return AdhesionStepEnum.CREATION_SUCCESS;
  }, [isConnected, me?.entreprisePrincipale]);
}

export function ParcoursAdhesion() {
  const session = useSession();
  const navigate = useNavigate();
  const { me } = useMe();
  const [defaultDataIsReady, defaultData, defaultHistory] =
    useParcoursAdhesionCacheDefaults(session.isConnected);

  const parcoursIsReady = session.isConnected ? !!me : true;
  const shouldRedirectToLogin = useShouldRedirectOnUnauthorizedStepAccess(
    session.isConnected
  );
  const shouldRedirectToHome = useShouldRedirectOnAdhesionAlreadyCompleted(
    session.isConnected
  );
  const firstStep = useGetFirstStep(session.isConnected);

  if (!parcoursIsReady || !defaultDataIsReady) return <div />;

  if (shouldRedirectToHome) return <Navigate to="/" replace />;
  if (shouldRedirectToLogin) return <Navigate to="/login" replace />;

  return (
    <div>
      <Parcours<AdhesionStepEnum, AdhesionData>
        withRouter
        afterChangeStep={(nextData, history) => {
          if (session.isConnected) {
            persistCacheOnAPI(nextData, history).catch((err) => {
              console.error(err);
              displayErrorToast("Votre Parcours n'a pas pu être sauvegardé");
            });
          }
        }}
        handleQuit={
          session.isConnected
            ? async (data, history) => {
                try {
                  await persistCacheOnAPI(data, history);
                  try {
                    await session.logout();
                  } catch (err) {
                    console.error(err);
                    displayErrorToast("Erreur lors de la déconnexion");
                  }
                } catch (err) {
                  console.error(err);
                  displayErrorToast(
                    "Votre Parcours n'a pas pu être sauvegardé"
                  );
                }
              }
            : undefined
        }
        UIParcoursComponent={UIParcoursFullPage}
        rootPath="/adhesion"
        config={{
          [AdhesionStepEnum.SIREN]: {
            protected: false,
            component: AdhesionSirenPage,
            progress: 10,
          },
          [AdhesionStepEnum.ENTREPRISE]: {
            protected: false,
            component: AdhesionEntreprisePage,
            progress: 10,
          },
          [AdhesionStepEnum.ENTREPRISE_ADRESSE]: {
            protected: false,
            component: AdhesionEntrepriseAdressePage,
            progress: 20,
          },
          [AdhesionStepEnum.INFORMATIONS_PERSONNELLES]: {
            protected: false,
            component: AdhesionInfoPerso,
            progress: 30,
          },
          [AdhesionStepEnum.IDENTIFIANTS]: {
            protected: false,
            component: AdhesionIdentifiants,
            progress: 40,
          },
          [AdhesionStepEnum.CONFIRM]: {
            protected: false,
            component: AdhesionConfirm,
            progress: 50,
          },
          [AdhesionStepEnum.INIT]: {
            protected: false,
            component: AdhesionInit,
            progress: 50,
          },
          [AdhesionStepEnum.CREATION_SUCCESS]: {
            protected: true,
            centeredContent: true,
            component: AdhesionCreationSuccess,
            progress: 60,
          },
          [AdhesionStepEnum.TYPE_ENTREPRISE]: {
            protected: true,
            component: AdhesionTypeEntreprise,
            progress: 70,
          },
          [AdhesionStepEnum.INFO_ACTIVITE]: {
            protected: true,
            component: AdhesionInfoActivitePage,
            progress: 70,
          },
          [AdhesionStepEnum.SOUS_CATEGORIES]: {
            protected: true,
            component: AdhesionSousCategoriesActivitePage,
            progress: 70,
          },
          [AdhesionStepEnum.ORGANISATION_PROFESSIONNELLE]: {
            protected: true,
            component: AdhesionOrganisationProfessionnellePage,
            progress: 70,
          },
          [AdhesionStepEnum.INFORMATIONS_COMPLEMENTAIRES]: {
            protected: true,
            component: AdhesionInformationsComplementairesPage,
            progress: 70,
          },
          [AdhesionStepEnum.PAIEMENT_ADHESION]: {
            protected: true,
            component: AdhesionPaiementPage,
            progress: 90,
          },
          [AdhesionStepEnum.CODE_REDUCTION]: {
            protected: true,
            component: AdhesionCouponPage,
            progress: 90,
          },
          [AdhesionStepEnum.MODE_IMPORT]: {
            protected: true,
            component: AdhesionModeImport,
            progress: 80,
          },
          [AdhesionStepEnum.IMPORT_DASHBOARD]: {
            protected: true,
            component: AdhesionImportDashboard,
            progress: 85,
          },
          [AdhesionStepEnum.SOCIETES_RATTACHEES]: {
            protected: true,
            component: AdhesionSocieteList,
            progress: 90,
          },
        }}
        initialData={defaultData}
        defaultHistory={defaultHistory}
        firstStep={firstStep}
        errorHandlers={{
          notFound: () => navigate("/404", { replace: true }),
          notAuthorized: () => navigate("/login", { replace: true }),
        }}
      />
    </div>
  );
}
