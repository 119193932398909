/* eslint-disable consistent-return */
import { GatewayContact } from "@conformite/gateway";
import {
  Button,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayAssujettiApi } from "@src/api/assujetti.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { ReactComponent as AddIcon } from "@src/assets/icons/plus-circle.svg";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { AssujettiList } from "@src/components/Assujetti/AssujettiList";
import { ComplianceProgressBar } from "@src/components/ComplianceProgressBar/ComplianceProgressBar";
import { useDialog } from "@src/components/Dialog/useDialog";
import { Dropdown } from "@src/components/DropDown/Dropdown";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import { SidePane } from "@src/components/SidePane/SidePane";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { Assujetti } from "@src/store/store.definition";
import { useMe } from "@src/store/store.me";
import { useCallback, useState } from "react";
import { AssujettiDataItemError } from "../AssujettiData";
import { AssujettiPaneContent } from "../AssujettiPaneContent/AssujettiPaneContent";
import { useAssujettiPane } from "../AssujettiPaneContent/useAssujettiPane";
import { ExportAssujettiButton } from "../ImportExport/Export/ExportAssujettiButton";
import { AssujettiImportDashboard } from "../ImportExport/Import/AssujettiImportDashboard";
import { AssujettiImportEdit } from "../ImportExport/Import/AssujettiImportEdit";
import { ImportAssujettiDialog } from "../ImportExport/Import/ImportAssujettiDialog";
import {
  ImportAssujettiItem,
  useImportAssujetti,
} from "../ImportExport/Import/useImportAssujetti";
import { useAssujetisAvancement } from "../useAssujettiAvancement";
import styles from "./AssujettiMainList.module.scss";
import { useAssujettiFilter } from "./useAssujettiFilter";

type AssujettiMainListProps = {
  millesime: number;
  assujettis?: Assujetti[];
  setEntry: (entry: Assujetti) => void;
  removeEntries: (ids: string[], millesime: number) => void;
  setEntries: (assujettis: Assujetti[], clean: boolean) => void;
};

type PaneState = {
  open: boolean;
  assujettiToEdit?: AssujettiDataItemError;
  indexToEdit?: number;
};

const initialData = { errors: [], success: [] };
export function AssujettiMainList({
  assujettis,
  setEntry,
  removeEntries,
  setEntries,
  millesime,
}: AssujettiMainListProps) {
  const { me } = useMe();
  const props = useAssujettiFilter(assujettis);
  const [assujettiPaneState, setAssujettiPaneState] = useAssujettiPane();
  const { openDialog } = useDialog();
  const [data, setData] = useState<{
    errors: AssujettiDataItemError[];
    success: ImportAssujettiItem[];
  }>(initialData);
  const [currentPage, setCurrentPage] = useState<"import" | "list">("list");
  const [paneState, setPaneState] = useState<PaneState>({
    open: false,
  });
  const { hasAllowedRole } = useIsAllowed();
  const { getAssujetisAvancement } = useAssujetisAvancement();
  const [isImporting, setIsImporting] = useState(false);
  const [clean, setClean] = useState(false);

  const AssujettiProgress = useCallback(
    ({ assujetti }: { assujetti: Assujetti }) => (
      <ComplianceProgressBar progress={getAssujetisAvancement(assujetti)} />
    ),
    [getAssujetisAvancement]
  );

  const { parseAssujettisFile, importAssujettis, generateErrorsFile } =
    useImportAssujetti();

  if (!me) return <div />;

  const handleImportAssujettis = (
    file: File | null | undefined,
    keepAllreadyExistingData = false
  ) => {
    if (!file)
      return displayErrorToast(
        "Une erreur est survenue lors de l'import du fichier"
      );
    parseAssujettisFile(file, ({ assujettisInError, assujettisToAdd }) => {
      try {
        setCurrentPage("import");
        setData((prev) => ({
          errors: assujettisInError,
          success: keepAllreadyExistingData
            ? [...prev.success, ...assujettisToAdd]
            : assujettisToAdd,
        }));
      } catch (err) {
        console.error(err);
        displayErrorToast("Une erreur inconnue est survenue");
      }
    });
  };
  if (currentPage === "import")
    return (
      <>
        <AssujettiImportDashboard
          success={data.success}
          errors={data.errors}
          importButton={
            <FileInputButton
              onChange={(e) =>
                handleImportAssujettis(e.target.files?.item(0), true)
              }
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              Importer le fichier corrigé
            </FileInputButton>
          }
          onExport={() => {
            generateErrorsFile(data.errors).catch(
              handleGatewayError({
                onUnhandled: () => displayErrorToast("Une erreur est survenue"),
              })
            );
          }}
          onUpdateError={(error) =>
            setPaneState({
              open: true,
              assujettiToEdit: data.errors.find((e) => e.index === error.index),
              indexToEdit: data.errors.findIndex(
                (e) => e.index === error.index
              ),
            })
          }
        />

        <div className={styles.buttonContainer}>
          <Button
            variant="outline"
            onClick={() => {
              setData(initialData);
              setCurrentPage("list");
              setIsImporting(false);
            }}
          >
            Annuler
          </Button>
          <Button
            onClick={() => {
              importAssujettis({
                assujettis: data.success.map(({ data: d }) => d),
                clean,
                setIsImporting,
                callback: () => {
                  GatewayAssujettiApi.list(millesime)
                    .then((list) => setEntries(list, clean))
                    .catch((e) => {
                      console.error(e);
                      displayErrorToast(
                        "Une erreur est survenue lors de la mise à jour des assujettis"
                      );
                    })
                    .finally(() => {
                      setData(initialData);
                      setCurrentPage("list");
                      setIsImporting(false);
                    });
                  //
                },
              }).catch(console.error);
            }}
            icon={isImporting ? <Loader /> : undefined}
            disabled={isImporting || data.success.length === 0}
          >
            Valider
          </Button>
        </div>

        <SidePane
          isOpen={paneState.open}
          setIsOpen={(nextIsOpen) => {
            setPaneState((prevState) => ({ ...prevState, open: nextIsOpen }));
          }}
        >
          {(closePane) => {
            if (!paneState.assujettiToEdit) return null;
            return (
              <AssujettiImportEdit
                key={`${JSON.stringify(paneState.assujettiToEdit)}`}
                rowToEdit={paneState.assujettiToEdit}
                onSubmit={(editedAssujetti: ImportAssujettiItem) => {
                  setCurrentPage("import");
                  setData((prev) => ({
                    success: [...prev.success, editedAssujetti],
                    errors: prev.errors.filter(
                      (e) => e.index !== editedAssujetti.index
                    ),
                  }));
                  setPaneState({ open: false });
                  closePane(true);
                }}
              />
            );
          }}
        </SidePane>
      </>
    );

  return (
    <>
      <AssujettiList
        {...props}
        removeEntries={removeEntries}
        AssujetiConformite={AssujettiProgress}
        HeaderActions={
          hasAllowedRole(
            GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
            GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE
          ) && (
            <div className={styles.actionButtons}>
              {me.entreprisePrincipale.millesimeActuel === millesime && (
                <Button
                  onClick={() =>
                    setAssujettiPaneState((prevState) => ({
                      assujetti: null,
                      initialPage: "information-personne",
                      isOpen: true,
                      paneSession:
                        prevState.assujetti === null &&
                        prevState.initialPage === "information-personne"
                          ? prevState.paneSession
                          : assujettiPaneState.paneSession + 1,
                    }))
                  }
                  variant="outline"
                  icon={<AddIcon />}
                >
                  Ajouter une personne
                </Button>
              )}
              <Dropdown content="Actions" placement="bottom-end">
                {({ close }) => (
                  <div>
                    {millesime === me.entreprisePrincipale.millesimeActuel && (
                      <Button
                        variant="text"
                        onClick={() => {
                          close();
                          openDialog(({ onClose, dialogRef }) =>
                            ImportAssujettiDialog({
                              onClose,
                              dialogRef,
                              onFileChange: handleImportAssujettis,
                              setClean,
                            })
                          )
                            .catch(console.error)
                            .finally(close);
                        }}
                      >
                        Importer les personnes assujetties
                      </Button>
                    )}
                    <ExportAssujettiButton onExport={close} />
                  </div>
                )}
              </Dropdown>
            </div>
          )
        }
        onRowClick={(assujetti) => {
          setAssujettiPaneState({
            assujetti,
            initialPage: "detail-conformite",
            isOpen: true,
            paneSession: assujettiPaneState.paneSession + 1,
          });
        }}
      />
      <SidePane
        isOpen={assujettiPaneState.isOpen}
        setIsOpen={(nextIsOpen) =>
          setAssujettiPaneState((prevSate) => ({
            ...prevSate,
            isOpen: nextIsOpen,
          }))
        }
      >
        {(closePane) => (
          <AssujettiPaneContent
            readonly={me.entreprisePrincipale.millesimeActuel !== millesime}
            key={assujettiPaneState.paneSession}
            millesime={millesime}
            setEntry={(assujetti) => {
              setEntry(assujetti);
              setAssujettiPaneState((prevState) => ({
                ...prevState,
                assujetti,
              }));
            }}
            closePane={closePane}
            assujetti={assujettiPaneState.assujetti}
            initialPage={assujettiPaneState.initialPage}
          />
        )}
      </SidePane>
    </>
  );
}
