import {
  GatewayAssujetti,
  GatewayCapacitePro,
  GatewayImportExportAssujetti,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  displayErrorToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayImportExportAssujettiAPI } from "@src/api/importExportAssujetti.api";
import {
  AssujettiDataItemError,
  RowAssujettiDataItem,
} from "@src/assujettis/AssujettiData";
import {
  addAssujettiDataValidationToSheet,
  colorErrorsAssujettisInSheet,
  downloadSheetFromWorkbook,
  importWorkBookFromFile,
  insertDataInSheet,
  TEMPLATE_ASSUJETTIS_FIRST_ROW,
} from "@src/helper/excel/excel.export.helper";
import { ExtractDataExcelSuccessResponse } from "@src/helper/excel/excel.import.helper";
import { useEntreprises } from "@src/store/store.entreprise";
import { useMe } from "@src/store/store.me";
import { format } from "date-fns";
import { Cell, CellRichTextValue, Row, Workbook, Worksheet } from "exceljs";
import { chain, has } from "lodash";
import { useState } from "react";
import {
  assujettiExcelExportTemplate,
  assujettiExcelTemplate,
} from "../AssujettiExcelTemplate";
import { transformAssujettiInErrorApiRowsToRows } from "../Export/ExportAssujettiExcelMapping";
import {
  assujettiRoleByExcelLabelUppercase,
  categorieIASByExcelLabelUppercase,
  categorieIOBSPByExcelLabelUppercase,
  gradeDiplomeByExcelLabelUppercase,
  niveauIasByExcelLabelUppercase,
  niveauIobspByExcelLabelUppercase,
  statutProByExcelLabelUppercase,
  typeJustificatifIASByExcelLabelUppercase,
  typeJustificatifIOBSPByExcelLabelUppercase,
} from "../ImportExport.definition";

const columnKeyMapping = {
  SIREN: "SIREN",
  NOM: "NOM",
  PRENOM: "PRENOM",
  FONCTION: "FONCTION",
  MAIL: "MAIL",
  DATE_VERIFICATION: "DATE VERIFICATION",
  HONORABLE: "HONORABLE",
  DATE_SAISIE_IAS: "IAS : DATE SAISIE",
  SOUMIS_FORMATION_IAS: "IAS : SOUMIS FORMATION",
  INTITULE_THEME_FORMATION_1_IAS: "IAS : INTITULE THEME FORMATION 1",
  NB_HEURES_FORMATION_1_IAS: `IAS : NB HEURES 
FORMATION 1`,
  INTITULE_THEME_FORMATION_2_IAS: "IAS : INTITULE THEME FORMATION 2",
  NB_HEURES_FORMATION_2_IAS: "IAS : NB HEURES FORMATION 2",
  DATE_PRISE_FONCTION_IAS: "IAS : DATE PRISE FONCTION",
  SOUMIS_A_LA_CONDITION_IAS: "IAS : SOUMIS A LA CONDITION",
  NIVEAU_IAS: "IAS : NIVEAU",
  STATUT_PROFESSIONNELLE_IAS: "IAS : STATUT PROFESSIONNELLE",
  CATEGORIE_IAS: "IAS : CATEGORIE",
  NOM_DIPLOME_IAS: "IAS : NOM DIPLÔME",
  GRADE_DIPLOME_IAS: "IAS : GRADE DIPLÔME",
  JUSTIFICATIF_IAS: "IAS : JUSTIFICATIF",
  DATE_SAISIE_IOBSP: "IOBSP : DATE SAISIE",
  SOUMIS_FORMATION_IOBSP: "IOBSP : SOUMIS FORMATION",
  HEURES_FORMATION_CREDIT_CONSOMMATION:
    "IOBSP : HEURES FORMATION CREDIT CONSOMMATION",
  HEURES_FORMATION_CREDIT_IMMOBILIER:
    "IOBSP : HEURES FORMATION CREDIT IMMOBILIER",
  HEURES_FORMATION_PRET_VIAGER_HYPOTECAIRE:
    "IOBSP : HEURES FORMATION PRET VIAGER HYPOTECAIRE",
  HEURES_FORMATION_REGROUPEMENT_CREDITS:
    "IOBSP : HEURES FORMATION REGROUPEMENT CREDITS",
  HEURES_FORMATION_CREDIT_SERVICE_PAIEMENT:
    "IOBSP : HEURES FORMATION CREDIT SERVICE PAIEMENT",
  INTITULE_THEME_FORMATION_1_IOBSP: "IOBSP : INTITULE THEME FORMATION 1",
  NB_HEURES_FORMATION_1_IOBSP: "IOBSP : NB HEURES FORMATION 1",
  INTITULE_THEME_FORMATION_2_IOBSP: "IOBSP : INTITULE THEME FORMATION 2",
  NB_HEURES_FORMATION_2_IOBSP: "IOBSP : NB HEURES FORMATION 2",
  DATE_PRISE_FONCTION_IOBSP: "IOBSP : DATE PRISE FONCTION",
  SOUMIS_A_LA_CONDITION_IOBSP: "IOBSP : SOUMIS A LA CONDITION",
  NIVEAU_IOBSP: "IOBSP : NIVEAU",
  STATUT_PRO_IOBSP: "IOBSP : STATUT PRO",
  CATEGORIE_IOBSP: "IOBSP : CATEGORIE",
  NOM_DIPLOME_IOBSP: "IOBSP : NOM DIPLÔME",
  GRADE_DIPLOME_IOBSP: "IOBSP : GRADE DIPLÔME",
  JUSTIFICATIF_IOBSP: "IOBSP : JUSTIFICATIF",
} as const;
type ColumnKey = keyof typeof columnKeyMapping;
const columnsToKeys: Record<string, ColumnKey> = Object.entries(
  columnKeyMapping
).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value.replace(/[^a-zA-Z0-9]/g, "")]: key, // on enlève les caractères spéciaux de l'entête
  }),
  {}
) as Record<string, ColumnKey>;
const isRichText = (cell: Cell["value"]): cell is CellRichTextValue =>
  has(cell, "richText");
const getCellValueFromRow = (row: Row, key: ColumnKey): string | undefined => {
  let cell: Cell | undefined;
  row.eachCell((cellRow) => {
    if (row.worksheet.getColumn(cellRow.col).key === key) {
      cell = cellRow;
    }
  });
  const value = cell?.value;
  if (isRichText(value)) {
    const richTextValue = value.richText
      .map(({ text }) => text)
      .join("")
      .trim();
    return richTextValue === "" ? undefined : richTextValue;
  }
  const stringValue = value?.toString().trim();
  return stringValue === "" ? undefined : stringValue;
};

const getNumberCellValueFromRow = (
  row: Row,
  key: ColumnKey
): number | undefined => {
  const value = getCellValueFromRow(row, key);
  if (!Number.isNaN(Number(value))) return Number(value);
  return undefined;
};

const isOui = (value: string | undefined): boolean | undefined => {
  if (value === undefined) return undefined;
  return value?.toUpperCase() === "OUI";
};

function getCellValueEnumFromRow<T>(
  row: Row,
  key: ColumnKey,
  uppercaseLabelToEnum: Record<string, T>
): T | undefined {
  const value = getCellValueFromRow(row, key);
  if (value === undefined) return undefined;
  return uppercaseLabelToEnum[value.toUpperCase().trim()];
}

const addKeyToColumns = (worksheet: Worksheet) =>
  worksheet.columns.forEach((column, columnNumber) => {
    const headerValue = worksheet
      .getRow(3)
      .getCell(columnNumber + 1)
      .value?.toString()
      .replace(/[^a-zA-Z0-9]/g, "")
      .trim();
    if (!headerValue) return;
    const key = columnsToKeys[headerValue];
    column.key = key;
  });

const getImportAssujettiRowSchema = (sirens: string[]) =>
  zod.object({
    assujetti: zod
      .object({
        email: zod.string().email("E-mail invalide").optional(),
        name: zod.string({ required_error: "Nom non renseigné" }),
        surname: zod.string({ required_error: "Prénom non renseigné" }),
        sirenCompany: zod
          .string({ required_error: `SIREN non renseigné` })
          .refine(
            (v) => /^\d{9}$/.test(v) && sirens.includes(v),
            "SIREN invalide"
          ),
        role: zod.nativeEnum(GatewayAssujetti.RoleAssujetti, {
          required_error: "Rôle non renseigné",
        }),
      })
      .optional(),

    honorabilite: zod
      .object({
        lastVerifiedAt: zod.string().optional(),
        isHonourable: zod.boolean().optional(),
      })
      .optional(),

    formationContinueIAS: zod
      .object({
        dateDernierVerification: zod
          .string({
            required_error: "Date de dernière vérification IAS non renseignée",
          })
          .optional(),
        formations: zod
          .array(
            zod.object({
              nombreHeures: zod
                .number({ required_error: "Nombre d'heures non renseigné" })
                .optional(),
              theme: zod
                .string({ required_error: "Thème non renseigné" })
                .optional(),
            })
          )
          .optional(),
      })
      .optional(),

    formationContinueIOBSP: zod
      .object({
        dateDernierVerification: zod
          .string({
            required_error:
              "Date de dernière vérification IOBSP non renseignée",
          })
          .optional(),
        formations: zod
          .array(
            zod.object({
              nombreHeures: zod
                .number({ required_error: "Nombre d'heures non renseigné" })
                .optional(),
              theme: zod
                .string({ required_error: "Thème non renseigné" })
                .optional(),
            })
          )
          .optional(),
        heuresFormationCreditConsommation: zod
          .number({
            required_error:
              "Heures de formation crédit consommation non renseignées",
          })
          .optional(),
        heuresFormationCreditImmobilier: zod
          .number({
            required_error:
              "Heures de formation crédit immobilier non renseignées",
          })
          .optional(),
        heuresFormationCreditPretViagerHypotecaire: zod
          .number({
            required_error:
              "Heures de formation crédit prêt viager hypothécaire non renseignées",
          })
          .optional(),
        heuresFormationCreditRegroupementCredits: zod
          .number({
            required_error:
              "Heures de formation crédit regroupement de crédits non renseignées",
          })
          .optional(),
        heuresFormationCreditServicePaiement: zod
          .number({
            required_error:
              "Heures de formation crédit service de paiement non renseignées",
          })
          .optional(),
      })
      .optional(),

    capaciteProIAS: zod
      .object({
        datePriseFonction: zod
          .string({
            required_error: "Date de prise de fonction IAS non renseignée",
          })
          .optional(),
        conditionCapaciteProfessionnelle: zod
          .boolean({
            required_error:
              "Condition de capacité professionnelle IAS non renseignée",
          })
          .optional(),
        categorie: zod
          .nativeEnum(GatewayCapacitePro.CategorieIAS, {
            required_error: "Catégorie IAS non renseignée",
          })
          .optional(),
        gradeDiplome: zod
          .nativeEnum(GatewayCapacitePro.GradeDiplome, {
            required_error: "Grade du diplôme IAS non renseigné",
          })
          .optional(),
        niveau: zod
          .nativeEnum(GatewayCapacitePro.CapaciteProNiveauIAS, {
            required_error: "Niveau IAS non renseigné",
          })
          .optional(),
        nomDiplome: zod
          .string({ required_error: "Nom du diplôme IAS non renseigné" })
          .optional(),
        statusProfessionel: zod
          .nativeEnum(GatewayCapacitePro.StatusProfessionel, {
            required_error: "Statut professionnel IAS non renseigné",
          })
          .optional(),
        typeJustificatif: zod
          .nativeEnum(GatewayCapacitePro.TypeJustificatifIAS, {
            required_error: "Type de justificatif IAS non renseigné",
          })
          .optional(),
      })
      .optional(),

    capaciteProIOBSP: zod
      .object({
        datePriseFonction: zod
          .string({
            required_error: "Date de prise de fonction IOBSP non renseignée",
          })
          .optional(),
        conditionCapaciteProfessionnelle: zod
          .boolean({
            required_error:
              "Condition de capacité professionnelle IOBSP non renseignée",
          })
          .optional(),
        categorie: zod
          .nativeEnum(GatewayCapacitePro.CategorieIOBSP, {
            required_error: "Catégorie IOBSP non renseignée",
          })
          .optional(),
        gradeDiplome: zod
          .nativeEnum(GatewayCapacitePro.GradeDiplome, {
            required_error: "Grade du diplôme IOBSP non renseigné",
          })
          .optional(),
        niveau: zod
          .nativeEnum(GatewayCapacitePro.CapaciteProNiveauIOBSP, {
            required_error: "Niveau IOBSP non renseigné",
          })
          .optional(),
        nomDiplome: zod
          .string({ required_error: "Nom du diplôme IOBSP non renseigné" })
          .optional(),
        statusProfessionel: zod
          .nativeEnum(GatewayCapacitePro.StatusProfessionel, {
            required_error: "Statut professionnel IOBSP non renseigné",
          })
          .optional(),
        typeJustificatif: zod
          .nativeEnum(GatewayCapacitePro.TypeJustificatifIOBSP, {
            required_error: "Type de justificatif IOBSP non renseigné",
          })
          .optional(),
      })
      .optional(),
  });

export type ImportRowAssujettisSchema = zod.infer<
  ReturnType<typeof getImportAssujettiRowSchema>
>;

export const isValidAssujetti = (
  assujetti: RowAssujettiDataItem,
  sirens: string[]
): zod.SafeParseReturnType<
  ImportRowAssujettisSchema,
  ImportRowAssujettisSchema
> => {
  return getImportAssujettiRowSchema(sirens).safeParse(assujetti);
};

export type ImportAssujettiItem = {
  index: number;
  data: RowAssujettiDataItem;
};

export type ImportAssujettisReturn = {
  assujettisToAdd: ImportAssujettiItem[];
  assujettisInError: AssujettiDataItemError[];
};

const validateAndUpdateState = (
  rows: RowAssujettiDataItem[],
  addData: (data: ImportAssujettisReturn) => void,
  sirens: string[]
) => {
  const { assujettisToAdd, assujettisInError } = chain(rows)
    .filter((row) => {
      // la ligne contient des données
      return Object.values(row).some((key) => {
        return Object.values(key).some((val) => {
          return Array.isArray(val) ? val.length > 0 : val;
        });
      });
    })
    .map((row, index) => ({
      data: row,
      index: index + TEMPLATE_ASSUJETTIS_FIRST_ROW,
    }))
    .reduce<ImportAssujettisReturn>(
      (acc, curr) => {
        const isValid = isValidAssujetti(curr.data, sirens);
        if (
          curr.data.assujetti?.sirenCompany &&
          !sirens.includes(curr.data.assujetti.sirenCompany)
        ) {
          acc.assujettisInError.push({
            ...curr,
            errors: [
              { description: "SIREN inconnu", key: "assujetti.sirenCompany" },
            ],
          });
          return acc;
        }

        if (!isValid.success)
          acc.assujettisInError.push({
            ...curr,
            errors: isValid.error.errors.map((e) => ({
              description: e.message,
              key: e.path[1].toString(),
            })),
          });
        else acc.assujettisToAdd.push(curr);
        return acc;
      },
      { assujettisToAdd: [], assujettisInError: [] }
    )
    .value();

  addData({
    assujettisToAdd,
    assujettisInError,
  });
};

const rowToAssujetti = (row: Row): Omit<RowAssujettiDataItem, "index"> => {
  return {
    assujetti: {
      email: getCellValueFromRow(row, "MAIL"),
      name: getCellValueFromRow(row, "NOM"),
      surname: getCellValueFromRow(row, "PRENOM"),
      sirenCompany: getCellValueFromRow(row, "SIREN")
        ?.replaceAll(" ", "")
        .padStart(9, "0"),
      role: getCellValueEnumFromRow(
        row,
        "FONCTION",
        assujettiRoleByExcelLabelUppercase
      ),
    },
    honorabilite: {
      lastVerifiedAt: getCellValueFromRow(row, "DATE_VERIFICATION"),
      isHonourable: isOui(getCellValueFromRow(row, "HONORABLE")),
    },
    formationContinueIAS: {
      soumisFormation: isOui(getCellValueFromRow(row, "SOUMIS_FORMATION_IAS")),
      dateDernierVerification: getCellValueFromRow(row, "DATE_SAISIE_IAS"),
      formations: [
        {
          nombreHeures: getNumberCellValueFromRow(
            row,
            "NB_HEURES_FORMATION_1_IAS"
          ),
          theme: getCellValueFromRow(row, "INTITULE_THEME_FORMATION_1_IAS"),
        },
        {
          nombreHeures: getNumberCellValueFromRow(
            row,
            "NB_HEURES_FORMATION_2_IAS"
          ),
          theme: getCellValueFromRow(row, "INTITULE_THEME_FORMATION_2_IAS"),
        },
      ].filter((formation) => formation.nombreHeures || formation.theme),
    },
    formationContinueIOBSP: {
      soumisFormation: isOui(
        getCellValueFromRow(row, "SOUMIS_FORMATION_IOBSP")
      ),
      dateDernierVerification: getCellValueFromRow(row, "DATE_SAISIE_IOBSP"),
      formations: [
        {
          nombreHeures: getNumberCellValueFromRow(
            row,
            "NB_HEURES_FORMATION_1_IOBSP"
          ),
          theme: getCellValueFromRow(row, "INTITULE_THEME_FORMATION_1_IOBSP"),
        },
        {
          nombreHeures: getNumberCellValueFromRow(
            row,
            "NB_HEURES_FORMATION_2_IOBSP"
          ),
          theme: getCellValueFromRow(row, "INTITULE_THEME_FORMATION_2_IOBSP"),
        },
      ].filter((formation) => formation.nombreHeures || formation.theme),
      heuresFormationCreditConsommation: getNumberCellValueFromRow(
        row,
        "HEURES_FORMATION_CREDIT_CONSOMMATION"
      ),
      heuresFormationCreditImmobilier: getNumberCellValueFromRow(
        row,
        "HEURES_FORMATION_CREDIT_IMMOBILIER"
      ),
      heuresFormationCreditPretViagerHypotecaire: getNumberCellValueFromRow(
        row,
        "HEURES_FORMATION_PRET_VIAGER_HYPOTECAIRE"
      ),
      heuresFormationCreditRegroupementCredits: getNumberCellValueFromRow(
        row,
        "HEURES_FORMATION_REGROUPEMENT_CREDITS"
      ),
      heuresFormationCreditServicePaiement: getNumberCellValueFromRow(
        row,
        "HEURES_FORMATION_CREDIT_SERVICE_PAIEMENT"
      ),
    },
    capaciteProIAS: {
      datePriseFonction: getCellValueFromRow(row, "DATE_PRISE_FONCTION_IAS"),
      conditionCapaciteProfessionnelle: isOui(
        getCellValueFromRow(row, "SOUMIS_A_LA_CONDITION_IAS")
      ),
      categorie: getCellValueEnumFromRow(
        row,
        "CATEGORIE_IAS",
        categorieIASByExcelLabelUppercase
      ),
      gradeDiplome: getCellValueEnumFromRow(
        row,
        "GRADE_DIPLOME_IAS",
        gradeDiplomeByExcelLabelUppercase
      ),
      niveau: getCellValueEnumFromRow(
        row,
        "NIVEAU_IAS",
        niveauIasByExcelLabelUppercase
      ),
      nomDiplome: getCellValueFromRow(row, "NOM_DIPLOME_IAS"),
      statusProfessionel: getCellValueEnumFromRow(
        row,
        "STATUT_PROFESSIONNELLE_IAS",
        statutProByExcelLabelUppercase
      ),
      typeJustificatif: getCellValueEnumFromRow(
        row,
        "JUSTIFICATIF_IAS",
        typeJustificatifIASByExcelLabelUppercase
      ),
    },
    capaciteProIOBSP: {
      datePriseFonction: getCellValueFromRow(row, "DATE_PRISE_FONCTION_IOBSP"),
      conditionCapaciteProfessionnelle: isOui(
        getCellValueFromRow(row, "SOUMIS_A_LA_CONDITION_IOBSP")
      ),
      categorie: getCellValueEnumFromRow(
        row,
        "CATEGORIE_IOBSP",
        categorieIOBSPByExcelLabelUppercase
      ),
      gradeDiplome: getCellValueEnumFromRow(
        row,
        "GRADE_DIPLOME_IOBSP",
        gradeDiplomeByExcelLabelUppercase
      ),
      niveau: getCellValueEnumFromRow(
        row,
        "NIVEAU_IOBSP",
        niveauIobspByExcelLabelUppercase
      ),
      nomDiplome: getCellValueFromRow(row, "NOM_DIPLOME_IOBSP"),
      statusProfessionel: getCellValueEnumFromRow(
        row,
        "STATUT_PRO_IOBSP",
        statutProByExcelLabelUppercase
      ),
      typeJustificatif: getCellValueEnumFromRow(
        row,
        "JUSTIFICATIF_IOBSP",
        typeJustificatifIOBSPByExcelLabelUppercase
      ),
    },
  };
};

type ImportAssujettisParams = {
  assujettis: RowAssujettiDataItem[];
  clean: boolean;
  setIsImporting?: React.Dispatch<React.SetStateAction<boolean>>;
  callback: () => void;
};

const importBatch = async (
  lines: ExtractDataExcelSuccessResponse<GatewayImportExportAssujetti.AssujettiRowImport>[],
  clean: boolean
) => {
  const responses = await GatewayImportExportAssujettiAPI.importAssujetti(
    lines,
    clean
  );

  return responses;
};

const transformAssujettiForSave = (
  assujettis: RowAssujettiDataItem[],
  millesime: number
): ExtractDataExcelSuccessResponse<GatewayImportExportAssujetti.AssujettiRowImport>[] =>
  assujettis.map((assujetti, index) => ({
    index,
    data: {
      assujetti: {
        sirenCompany:
          assujetti.assujetti?.sirenCompany?.replaceAll(" ", "") ?? "",
        email: assujetti.assujetti?.email ?? null,
        name: assujetti.assujetti?.name ?? "",
        surname: assujetti.assujetti?.surname ?? "",
        millesime: assujetti.assujetti?.millesime ?? millesime,
        role:
          assujetti.assujetti?.role ?? GatewayAssujetti.RoleAssujetti.ASSOCIE,
      },
      honorabilite: {
        lastVerifiedAt: assujetti.honorabilite?.lastVerifiedAt
          ? new Date(assujetti.honorabilite.lastVerifiedAt)
          : null,
        isHonourable: assujetti.honorabilite?.isHonourable ?? null,
      },
      formationContinueIAS: {
        soumisFormationContinue:
          assujetti.formationContinueIAS?.soumisFormation ?? null,
        dateDernierVerification: assujetti.formationContinueIAS
          ?.dateDernierVerification
          ? new Date(assujetti.formationContinueIAS.dateDernierVerification)
          : null,
        formations:
          assujetti.formationContinueIAS?.formations?.map((formation) => ({
            nombreHeures: formation.nombreHeures ?? null,
            theme: formation.theme ?? null,
          })) ?? [],
      },
      formationContinueIOBSP: {
        soumisFormationContinue:
          assujetti.formationContinueIOBSP?.soumisFormation ?? null,
        dateDernierVerification: assujetti.formationContinueIOBSP
          ?.dateDernierVerification
          ? new Date(assujetti.formationContinueIOBSP.dateDernierVerification)
          : null,
        formations:
          assujetti.formationContinueIOBSP?.formations?.map((formation) => ({
            nombreHeures: formation.nombreHeures ?? null,
            theme: formation.theme ?? null,
          })) ?? [],
        heuresFormationCreditConsommation:
          assujetti.formationContinueIOBSP?.heuresFormationCreditConsommation ??
          null,
        heuresFormationCreditImmobilier:
          assujetti.formationContinueIOBSP?.heuresFormationCreditImmobilier ??
          null,
        heuresFormationCreditPretViagerHypotecaire:
          assujetti.formationContinueIOBSP
            ?.heuresFormationCreditPretViagerHypotecaire ?? null,
        heuresFormationCreditRegroupementCredits:
          assujetti.formationContinueIOBSP
            ?.heuresFormationCreditRegroupementCredits ?? null,
        heuresFormationCreditServicePaiement:
          assujetti.formationContinueIOBSP
            ?.heuresFormationCreditServicePaiement ?? null,
      },
      capaciteProIAS: {
        datePriseFonction: assujetti.capaciteProIAS?.datePriseFonction
          ? new Date(assujetti.capaciteProIAS.datePriseFonction)
          : null,
        conditionCapaciteProfessionnelle:
          assujetti.capaciteProIAS?.conditionCapaciteProfessionnelle ?? null,
        categorie: assujetti.capaciteProIAS?.categorie ?? null,
        gradeDiplome: assujetti.capaciteProIAS?.gradeDiplome ?? null,
        niveau: assujetti.capaciteProIAS?.niveau ?? null,
        nomDiplome: assujetti.capaciteProIAS?.nomDiplome ?? null,
        statusProfessionel:
          assujetti.capaciteProIAS?.statusProfessionel ?? null,
        typeJustificatif: assujetti.capaciteProIAS?.typeJustificatif ?? null,
      },
      capaciteProIOBSP: {
        datePriseFonction: assujetti.capaciteProIOBSP?.datePriseFonction
          ? new Date(assujetti.capaciteProIOBSP.datePriseFonction)
          : null,
        conditionCapaciteProfessionnelle:
          assujetti.capaciteProIOBSP?.conditionCapaciteProfessionnelle ?? null,
        categorie: assujetti.capaciteProIOBSP?.categorie ?? null,
        gradeDiplome: assujetti.capaciteProIOBSP?.gradeDiplome ?? null,
        niveau: assujetti.capaciteProIOBSP?.niveau ?? null,
        nomDiplome: assujetti.capaciteProIOBSP?.nomDiplome ?? null,
        statusProfessionel:
          assujetti.capaciteProIOBSP?.statusProfessionel ?? null,
        typeJustificatif: assujetti.capaciteProIOBSP?.typeJustificatif ?? null,
      },
    },
  }));

export const useImportAssujetti = () => {
  const [status, setStatus] = useState<"loading" | "loaded" | "init">("init");
  const { me } = useMe();
  const { entreprisesBySiren } = useEntreprises();

  const sortByIndex = (
    a: AssujettiDataItemError,
    b: AssujettiDataItemError
  ) => {
    return a.index - b.index;
  };

  const generateErrorsFile = async (
    assujettisErrors: AssujettiDataItemError[]
  ) => {
    const assujettisExport = assujettisErrors.sort(sortByIndex);

    const workbook = await importWorkBookFromFile(
      "modele_personnes_assujetties.xlsx"
    );
    const sheet = workbook.worksheets[0];
    if (!assujettisExport) return;
    insertDataInSheet(
      assujettisExport.map(transformAssujettiInErrorApiRowsToRows),
      sheet,
      assujettiExcelTemplate,
      assujettiExcelExportTemplate
    );
    colorErrorsAssujettisInSheet(sheet, assujettisExport);

    addAssujettiDataValidationToSheet(sheet, assujettisExport.length);
    await downloadSheetFromWorkbook(
      `export_entreprise_error_${format(new Date(), "dd-MM-yyyy_HH:mm")}.xlsx`,
      workbook
    );
  };

  /**
   *
   * @param siren the siren required for all companies
   * @returns
   */
  const parseAssujettisFile = (
    file: File,
    updateAssujettisRecords: (data: ImportAssujettisReturn) => void,
    siren?: string
  ): void => {
    setStatus("loading");
    if (!file) return;
    file
      .arrayBuffer()
      .then(async (buffer) => {
        const workbook = new Workbook();
        const workbookBuffer = await workbook.xlsx.load(buffer);
        const worksheet = workbookBuffer.getWorksheet(1);
        if (!worksheet) throw new Error("no worksheet");
        addKeyToColumns(worksheet);
        const extractedAssujettis: RowAssujettiDataItem[] = [];
        const virginSheet = workbookBuffer.getWorksheet(1);
        if (!virginSheet) throw new Error("no worksheet");
        virginSheet.eachRow((row, rowNumber) => {
          if (rowNumber <= 3) return;
          extractedAssujettis.push(rowToAssujetti(row));
        });
        const entreprisesSirens = Object.keys(entreprisesBySiren ?? {});
        validateAndUpdateState(
          extractedAssujettis,
          updateAssujettisRecords,
          siren ? [siren] : entreprisesSirens
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setStatus("loaded"));
  };

  return {
    status,
    validateAndUpdateState,
    parseAssujettisFile,
    generateErrorsFile,
    schema: getImportAssujettiRowSchema(Object.keys(entreprisesBySiren ?? {})),
    importAssujettis: async ({
      assujettis,
      clean = false,
      setIsImporting,
      callback,
    }: ImportAssujettisParams) => {
      setIsImporting?.(true);

      if (!me) return;
      try {
        await importBatch(
          transformAssujettiForSave(
            assujettis,
            me?.entreprisePrincipale.millesimeActuel
          ),
          clean
        );
        displaySucessToast(`Les personnes assujetties ont été importées.`, {
          hideAfter: 5000,
        });
        callback();
      } catch {
        displayErrorToast(
          `Une erreur est survenue lors de l'import des personnes assujetties.`
        );
      } finally {
        setIsImporting?.(false);
      }
    },
  };
};
