import { useRef } from "react";
import { Header } from "@src/components/Header/Header";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  UIInputError,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import useDoubleAuth from "./useDoubleAuth";
import styles from "./DoubleAuth.module.scss";
import { DoubleAuthInput } from "./DoubleAuthInput";

export function DoubleAuth() {
  const { formConfig, handleSubmit, error } = useDoubleAuth();
  const code1Ref = useRef<HTMLInputElement>(null);
  const code2Ref = useRef<HTMLInputElement>(null);
  const code3Ref = useRef<HTMLInputElement>(null);
  const code4Ref = useRef<HTMLInputElement>(null);
  const code5Ref = useRef<HTMLInputElement>(null);
  const code6Ref = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <div>
      <Header />
      <MaxWidthContent>
        <Form
          formConfig={formConfig}
          onSubmit={handleSubmit}
          className={styles.form}
        >
          <span>Code provisoire</span>
          <div className={styles.numbers}>
            <DoubleAuthInput
              formConfig={formConfig}
              inputRef={code1Ref}
              name="code1"
              nextRef={code2Ref}
              previousRef={undefined}
              buttonRef={buttonRef}
            />

            <DoubleAuthInput
              formConfig={formConfig}
              inputRef={code2Ref}
              name="code2"
              nextRef={code3Ref}
              previousRef={code1Ref}
              buttonRef={buttonRef}
            />

            <DoubleAuthInput
              formConfig={formConfig}
              inputRef={code3Ref}
              name="code3"
              nextRef={code4Ref}
              previousRef={code2Ref}
              buttonRef={buttonRef}
            />

            <DoubleAuthInput
              formConfig={formConfig}
              inputRef={code4Ref}
              name="code4"
              nextRef={code5Ref}
              previousRef={code3Ref}
              buttonRef={buttonRef}
            />

            <DoubleAuthInput
              formConfig={formConfig}
              inputRef={code5Ref}
              name="code5"
              nextRef={code6Ref}
              previousRef={code4Ref}
              buttonRef={buttonRef}
            />

            <DoubleAuthInput
              formConfig={formConfig}
              inputRef={code6Ref}
              name="code6"
              nextRef={undefined}
              previousRef={code5Ref}
              buttonRef={buttonRef}
            />
          </div>
          {error && (
            <UIInputError
              errorMessage={error}
              className="input-container__error"
            />
          )}
          <Button
            type="submit"
            disabled={formConfig.formState.isSubmitting}
            className={styles.button}
            ref={buttonRef}
          >
            Envoyer
          </Button>
        </Form>
      </MaxWidthContent>
    </div>
  );
}
