import {
  Button,
  displayErrorToast,
  displayInfoToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayImportExportAssujettiAPI } from "@src/api/importExportAssujetti.api";
import { useMillesime } from "@src/store/store.millesime";
import { format } from "date-fns";

import {
  addAssujettiDataValidationToSheet,
  downloadSheetFromWorkbook,
  importWorkBookFromFile,
  insertDataInSheet,
} from "@src/helper/excel/excel.export.helper";
import {
  assujettiExcelExportTemplate,
  assujettiExcelTemplate,
} from "../AssujettiExcelTemplate";
import { transformAPIRowsForToRows } from "./ExportAssujettiExcelMapping";

type ExportAssujettisButtonProps = {
  onExport?: () => void;
};

export function ExportAssujettiButton({
  onExport,
}: ExportAssujettisButtonProps) {
  const { selected: millesime } = useMillesime();

  if (!millesime) return null;

  const exportAssujettis = async () => {
    try {
      onExport?.();
      displayInfoToast("Export des personnes assujetties en cours");
      const workbook = await importWorkBookFromFile(
        "modele_personnes_assujetties.xlsx"
      );
      const sheet = workbook.worksheets[0];
      const exportData = await GatewayImportExportAssujettiAPI.exportAssujetti(
        millesime
      );
      insertDataInSheet(
        exportData.map(transformAPIRowsForToRows),
        sheet,
        assujettiExcelTemplate,
        assujettiExcelExportTemplate
      );
      addAssujettiDataValidationToSheet(sheet, exportData.length);

      await downloadSheetFromWorkbook(
        `export_p_assujetties_${format(new Date(), "dd-MM-yyyy_HH:mm")}.xlsx`,
        workbook
      );
      displaySucessToast("Export des personnes assujetties terminé");
    } catch (e) {
      console.error(e);
      displayErrorToast(
        "Une erreur est survenue lors de l'export des personnes assujetties"
      );
    }
  };

  return (
    <Button
      variant="text"
      onClick={() => {
        exportAssujettis().finally(() => undefined);
      }}
    >
      Exporter la liste des personnes assujetties
    </Button>
  );
}
