import { StepComponentProps } from "@src/parcours/Parcours.types";
import { AdhesionData } from "./AdhesionData/adhesionData";

export const ID_FORM_ADHESION = "form-adhesion";

export enum ConnectedAdhesionStepEnum {
  CREATION_SUCCESS = "creation-success",
  INFO_ACTIVITE = "informations-activite",
  SOUS_CATEGORIES = "sous-categories",
  ORGANISATION_PROFESSIONNELLE = "organisation-professionnelle",
  INFORMATIONS_COMPLEMENTAIRES = "informations-complementaires",
  TYPE_ENTREPRISE = "type-entreprise",
  MODE_IMPORT = "mode-import",
  IMPORT_DASHBOARD = "import-dashboard",
  SOCIETES_RATTACHEES = "societes-rattachees",
  PAIEMENT_ADHESION = "paiement-adhesion",
  CODE_REDUCTION = "code-reduction",
}
export const adhesionConnectedSteps = Object.values(ConnectedAdhesionStepEnum);

export enum PublicAdhesionStepEnum {
  SIREN = "siren",
  ENTREPRISE = "entreprise",
  ENTREPRISE_ADRESSE = "entreprise-adresse",
  INFORMATIONS_PERSONNELLES = "informations-personnelles",
  IDENTIFIANTS = "identifiants",
  CONFIRM = "confirm",
  INIT = "init",
}
export const adhesionPublicSteps = Object.values(PublicAdhesionStepEnum);
export const adhesionSteps = [
  ...adhesionConnectedSteps,
  ...adhesionPublicSteps,
];

export type AdhesionStepEnum =
  | ConnectedAdhesionStepEnum
  | PublicAdhesionStepEnum;
export const AdhesionStepEnum = {
  ...ConnectedAdhesionStepEnum,
  ...PublicAdhesionStepEnum,
};

export type ParcoursAdhesionStepComponentProps = StepComponentProps<
  AdhesionStepEnum,
  AdhesionData
>;
