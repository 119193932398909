import {
  Button,
  Card,
  displayErrorToast,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { ReactComponent as PlusCircle } from "@src/assets/icons/plus-circle.svg";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import { Link } from "@src/components/Link/Link";
import { useMe } from "@src/store/store.me";
import style from "./ConformiteImportAssujettis.module.scss";

type ConformiteImportAssujettisProps = {
  onOpenAddAssujetti: () => void;
  onImportAssujettis: (file: File) => void;
};

export function ConformiteImportAssujettis({
  onImportAssujettis,
  onOpenAddAssujetti,
}: ConformiteImportAssujettisProps) {
  const { me } = useMe();
  const isImporting = false;

  if (!me) return <div />;
  return (
    <FormRow>
      <FormField className={style.card}>
        <Card>
          <FormRow>
            <FormField>
              <h3>Importer les personnes en masse</h3>
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <p>
                Renseignez les informations des personnes assujetties dans un
                fichier excel à partir d&apos;un modèle téléchargeable.
              </p>
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <Link to="/modele_personnes_assujetties.xlsx" target="_blank">
                <Button icon={<Download />} variant="text">
                  Télécharger le fichier modèle
                </Button>
              </Link>
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <FileInputButton
                disabled={isImporting}
                icon={isImporting ? <Loader /> : undefined}
                onChange={(e) => {
                  const file = e.target.files?.item(0);
                  if (!file) {
                    displayErrorToast("Erreur lors de l'import du fichier");
                    return;
                  }
                  onImportAssujettis(file);
                }}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              >
                Importer le fichier complété
              </FileInputButton>
            </FormField>
          </FormRow>
        </Card>
      </FormField>
      <FormField className={style.card}>
        <Card>
          <FormRow>
            <FormField>
              <h3>Ou renseigner les personnes individuellement</h3>
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <p>
                Saisissez directement dans l&apos;outil les personnes
                assujetties une par une.
              </p>
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <Button
                variant="outline"
                onClick={onOpenAddAssujetti}
                icon={<PlusCircle />}
              >
                Ajouter une personne
              </Button>
            </FormField>
          </FormRow>
        </Card>
      </FormField>
    </FormRow>
  );
}
