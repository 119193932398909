import { COGNITO_USER_GROUP } from '../infrastructure/cognito.definition';

export interface SignupRequestType {
  email: string;
  password: string;
}

export type SignupResponseType = string;
export const signupRequestUrl = '/authentication/signup';

export interface ResendCodeRequestType {
  email: string;
}
export type ResendCodeResponseType = string;
export const resendCodeRequestUrl = '/authentication/resendCode';

export interface SigninRequestType {
  email: string;
  password: string;
}
export interface SigninResponseType {
  accessToken: string;
  refreshToken: string;
  idToken: string;
  expiresIn: number;
  hasDoubleAuth?: boolean;
}
export const signinRequestUrl = '/authentication/signin';

export type RevokeRequestType = {
  refreshToken: string;
};
export type RevokeResponseType = string;

export const revokeRequestUrl = '/authentication/revoke';

export type RefreshRequestType = {
  refreshToken: string;
  accessToken: string;
};
export type RefreshResponseType = {
  accessToken: string;
  expiresIn: number;
  idToken: string;
};

export const refreshRequestUrl = '/authentication/refresh';

export type ForgotPasswordRequestType = {
  email: string;
};
export type ForgotPasswordResponseType = string;
export const forgotPasswordRequestUrl = '/authentication/forgotPassword';

export type ConfirmForgotPasswordRequestType = {
  email: string;
  password: string;
  code: string;
};

export type ConfirmForgotPasswordResponseType = string;
export const configForgotPasswordRequestUrl =
  '/authentication/confirmForgotPassword';

export type Role = COGNITO_USER_GROUP;
export const Role = COGNITO_USER_GROUP;

export type ConfirmRequestType = {
  email: string;
  code: string;
};

export const confirmUrl = '/authentication/confirm';

export const changePasswordUrl = '/authentication/changePassword';
export interface ChangePasswordType {
  previousPassword: string;
  proposedPassword: string;
  accessToken: string;
}
export const newPasswordUrl = '/authentication/newPassword';
export interface NewPasswordType {
  userId: string;
  session: string;
  password: string;
}

export interface SendDoubleAuthCodeType {
  accessToken: string;
  code: string;
}

export const sendDoubleAuthCodeUrl = '/authentication/sendDoubleAuthCode';

export type SendDoubleAuthCodeResponseType = {
  value: boolean;
  remainingAttempts: number;
};
export interface DeleteUserByAdminSystemType {
  email: string;
}
