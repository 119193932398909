import { CmsHeader } from "../CmsHeader";
import styles from "./LeSaviezVous.module.scss";
import { LeSaviezVousList } from "./LeSaviezVousList";
import { useLeSaviezVous } from "./useLeSaviezVous";

export function LeSaviezVous() {
  const { data } = useLeSaviezVous({
    pageSize: 5,
    page: 1,
  });

  return (
    <div>
      <CmsHeader title="Le saviez vous" to="/le-saviez-vous" />
      <div className={styles.leSaviezVousList}>
        <LeSaviezVousList leSaviezVous={data} />
      </div>
    </div>
  );
}
