import {
  GatewayAvancement,
  GatewayGarantieFinanciere,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputButtonGroup,
  InputCheckbox,
  InputNumber,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormGrid,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { chain } from "lodash";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { GatewayGarantieFinanciereApi } from "@src/api/conformite/garantieFinanciere.api";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { useOnce } from "@src/hooks/useOnce";
import { millesimeHasEveryDay } from "@src/conformite/conformite.helper";
import { useMe } from "@src/store/store.me";
import { useEffect } from "react";
import styles from "../ConformiteGarantieFinanciere.module.scss";
import {
  ConformiteGarantieFinanciereEditFiles,
  FormWithFiles,
} from "./ConformiteGarantieFinanciereEditFiles";
import {
  ConformiteGarantieFinanciereEditFilesNonDiffusable,
  FormWithFileNonDiffusable,
} from "./ConformiteGarantieFinanciereEditFilesNonDiffusable";

const buttonGroupOptionsBoolean = [
  {
    label: "Oui",
    value: true,
  },
  {
    label: "Non",
    value: false,
  },
];

const schema = (millesime: number) =>
  zod.object({
    millesime: zod.number(),
    files: zod
      .array(
        zod.object({
          pathname: zod.string().optional(),
          filename: zod.string().optional(),
          startDate: zod.date(),
          endDate: zod.date(),
          file: zod.instanceof(File).optional(),
          isValid: zod.boolean(),
        })
      )
      .min(1)
      .refine((files) => millesimeHasEveryDay(millesime, files), {
        message: "Vos attestations doivent couvrir l'année entière",
        path: ["0.startDate"],
      }),
    nonDiffusable: zod
      .object({
        fileNonDiffusable: zod.nullable(
          zod.object({
            pathname: zod.string().optional(),
            filename: zod.string().optional(),
            isValid: zod.boolean(),
            file: zod.instanceof(File).optional(),
          })
        ),
        noCommunication: zod.boolean().nullable(),
      })
      .refine(
        (data) =>
          !data.noCommunication ||
          (data.noCommunication && data.fileNonDiffusable),
        {
          message:
            "Vous devez fournir une attestation de la part de votre assureur",
          path: ["noCommunication"],
        }
      ),
    infoGarantie: zod
      .object({
        primesEncaisseesDehorsMandat: zod.number(),
        primesEncaisseesMontant: zod.number(),
        indemniteSinistreDehorsMandat: zod.number(),
        indemniteSinistreMontant: zod.number(),
        montantGarantie: zod.number(),
        montantFondRoulement: zod.number(),
      })
      .superRefine((data, ctx) => {
        const garantieFinanciereSimulated =
          (data.primesEncaisseesDehorsMandat +
            data.indemniteSinistreDehorsMandat +
            data.montantFondRoulement) /
          6;
        const garantieFinanciereSimulatedRounded =
          Math.round(garantieFinanciereSimulated * 100) / 100;

        if (garantieFinanciereSimulatedRounded <= 115_000) {
          if (data.montantGarantie < 115_000) {
            ctx.addIssue({
              code: zod.ZodIssueCode.custom,
              message:
                "Le montant de la garantie financière doit être supérieur ou égal à 115 000 €",
              path: ["montantGarantie"],
            });
          }
        } else if (data.montantGarantie < garantieFinanciereSimulatedRounded) {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: `Le montant de la garantie financière doit être supérieur ou égal à ${garantieFinanciereSimulatedRounded} €`,
            path: ["montantGarantie"],
          });
        }
      }),
    encaissementFonds: zod.boolean(),
    couvertParMandat: zod.boolean(),
  });
export type ConformiteGarantieFinanciereFormValuesIas = zod.infer<
  ReturnType<typeof schema>
>;
type ConformiteGarantieFinanciereEditFormIasProps = {
  formConfig: ReturnType<
    typeof useConformiteGarantieFinanciereIasEdit
  >["formConfig"];
  onSubmit?: ReturnType<
    typeof useConformiteGarantieFinanciereIasEdit
  >["onSubmit"];
  garantieFinanciere: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas | null;
  millesime: number;
  disabled?: boolean;
  siren: string;
};
const getFileNonDiffusableWithoutFile = (
  file: {
    isValid: boolean;
    pathname?: string | undefined;
    filename?: string | undefined;
    file?: File | undefined;
  } | null
): GatewayGarantieFinanciere.ConformiteGarantieFinanciereFileNonDiffusable | null => {
  if (!file) return null;
  const { file: fileData, ...rest } = file;
  return rest;
};
export const useConformiteGarantieFinanciereIasEdit = (
  siren: string,
  millesime: number,
  garantieFinanciere: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas | null,
  stopEditing: (
    value:
      | GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas
      | undefined,
    avancement: GatewayAvancement.AvancementSociete
  ) => void
) => {
  const formConfig = useFormConfig({
    schema: schema(millesime),
    defaultValues: {
      ...garantieFinanciere,
      millesime,
      nonDiffusable: {
        noCommunication: garantieFinanciere?.noCommunication ?? null,
        fileNonDiffusable: garantieFinanciere?.fileNonDiffusable,
      },
      files:
        garantieFinanciere?.files?.map((file) => ({
          ...file,
          startDate: file?.startDate
            ? new Date(file.startDate as unknown as string)
            : undefined,
          endDate: file?.endDate
            ? new Date(file.endDate as unknown as string)
            : undefined,
        })) ?? [],
      infoGarantie: {
        primesEncaisseesDehorsMandat:
          garantieFinanciere?.primesEncaisseesDehorsMandat,
        primesEncaisseesMontant: garantieFinanciere?.primesEncaisseesMontant,
        indemniteSinistreDehorsMandat:
          garantieFinanciere?.indemniteSinistreDehorsMandat,
        indemniteSinistreMontant: garantieFinanciere?.indemniteSinistreMontant,
        montantGarantie: garantieFinanciere?.montantGarantie,
        montantFondRoulement: garantieFinanciere?.montantFondRoulement,
      },
    } as ConformiteGarantieFinanciereFormValuesIas,
  });
  const onSubmit = async ({
    files,
    nonDiffusable,
    ...data
  }: ConformiteGarantieFinanciereFormValuesIas) => {
    const filesToSend = chain(files)
      .filter((file) => !!file?.file)
      .map((file) => file?.file)
      .value() as File[];
    const filesWithoutBlob = files.map((fileData) => {
      if (!fileData?.file) return fileData;
      const { file, ...rest } = fileData;
      return rest;
    });
    const payload: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas = {
      ...data,
      millesime: data.millesime,
      encaissementFonds: data.encaissementFonds ?? null,
      montantFondRoulement: data.infoGarantie.montantFondRoulement ?? null,
      primesEncaisseesMontant:
        data.infoGarantie.primesEncaisseesMontant ?? null,
      indemniteSinistreMontant:
        data.infoGarantie.indemniteSinistreMontant ?? null,
      primesEncaisseesDehorsMandat:
        data.infoGarantie.primesEncaisseesDehorsMandat ?? null,
      indemniteSinistreDehorsMandat:
        data.infoGarantie.indemniteSinistreDehorsMandat ?? null,
      montantGarantie: data.infoGarantie.montantGarantie ?? null,
      couvertParMandat: data.couvertParMandat ?? null,
      noCommunication: nonDiffusable.noCommunication,
      files: filesWithoutBlob,
      fileNonDiffusable: getFileNonDiffusableWithoutFile(
        nonDiffusable.fileNonDiffusable
      ),
    };
    try {
      const avancement =
        await GatewayGarantieFinanciereApi.saveGarantieFinanciereIas(
          siren,
          millesime,
          payload,
          filesToSend.concat(
            nonDiffusable.fileNonDiffusable?.file
              ? [nonDiffusable.fileNonDiffusable?.file]
              : []
          )
        );
      stopEditing(payload, avancement);
    } catch (err) {
      handleGatewayError({
        onUnhandled: () => {
          displayErrorToast(
            "Une erreur est survenue lors de la récupération du formulaire"
          );
        },
      })(err);
    }
  };
  return {
    formConfig,
    onSubmit,
  };
};
export function ConformiteGarantieFinanciereEditFormIas({
  formConfig,
  onSubmit = () => Promise.resolve(),
  disabled,
  millesime,
  garantieFinanciere,
  siren,
}: ConformiteGarantieFinanciereEditFormIasProps) {
  const { me } = useMe();

  const [
    encaissementFonds,
    indemniteSinistreDehorsMandat,
    primesEncaisseesDehorsMandat,
    noCommunication,
    montantFondRoulement,
  ] = formConfig.watch([
    "encaissementFonds",
    "infoGarantie.indemniteSinistreDehorsMandat",
    "infoGarantie.primesEncaisseesDehorsMandat",
    "nonDiffusable.noCommunication",
    "infoGarantie.montantFondRoulement",
  ]);
  const montantCalculeGarantieFinanciere = Math.max(
    ((indemniteSinistreDehorsMandat || 0) +
      (primesEncaisseesDehorsMandat || 0) +
      (montantFondRoulement || 0)) /
      6,
    115_000
  ).toFixed(2);
  useOnce(() => {
    if (!disabled && garantieFinanciere !== null) {
      formConfig.trigger().catch(console.error);
    }
  });

  const primesEncaissees = formConfig.watch([
    "infoGarantie.primesEncaisseesDehorsMandat",
    "infoGarantie.primesEncaisseesMontant",
    "infoGarantie.indemniteSinistreDehorsMandat",
    "infoGarantie.indemniteSinistreMontant",
  ]);

  useEffect(() => {
    formConfig.trigger("infoGarantie.montantGarantie").catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primesEncaissees, formConfig.trigger]);

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit}>
      <fieldset disabled={disabled} className={styles.fieldset}>
        <FormGrid disableHorizontalGutter>
          <FormRow>
            <FormField>
              <InputButtonGroup<
                ConformiteGarantieFinanciereFormValuesIas,
                false,
                boolean
              >
                label="Encaissez-vous des fonds ?"
                name="encaissementFonds"
                variant="outline-pop"
                options={buttonGroupOptionsBoolean}
                disabled={disabled}
              />
            </FormField>
          </FormRow>
          {encaissementFonds && (
            <>
              <FormRow>
                <FormField>
                  <InputNumber<ConformiteGarantieFinanciereFormValuesIas>
                    name="infoGarantie.montantGarantie"
                    label="Quel est le montant à date de votre garantie financière ?"
                  />
                </FormField>
              </FormRow>
              <h4 className={styles.subtitle}>
                Attestation de couverture sur l&apos;année {millesime}
              </h4>
              {me?.entreprisePrincipale.millesimeActuel === 2024 && (
                <InfoCard className={styles.cardInformationsPJ}>
                  Dans le cadre du renouvellement 2024, si vous ne disposez pas
                  encore de votre attestation pour l&apos;année 2024/2025, nous
                  vous invitons à renseigner et enregistrer les éléments dont
                  vous disposez à ce jour. Vous pourrez compléter cet axe à
                  réception de votre attestation.
                </InfoCard>
              )}
              <ConformiteGarantieFinanciereEditFiles
                form={formConfig as unknown as FormWithFiles}
                disabled={disabled}
                siren={siren}
              />
              <FormRow className={styles.firstField}>
                <FormField>
                  <InputButtonGroup<
                    ConformiteGarantieFinanciereFormValuesIas,
                    false,
                    boolean
                  >
                    label="Tous les produits sont-ils bien couverts par la garantie financière ?"
                    name="couvertParMandat"
                    variant="outline-pop"
                    options={buttonGroupOptionsBoolean}
                    disabled={disabled}
                  />
                </FormField>
              </FormRow>
              {!noCommunication && (
                <>
                  <h4 className={styles.subtitle}>
                    Primes encaissées en {millesime - 1}
                  </h4>
                  <FormRow>
                    <FormField size="half">
                      <InputNumber<ConformiteGarantieFinanciereFormValuesIas>
                        name="infoGarantie.primesEncaisseesDehorsMandat"
                        label="En dehors de tout mandat exprès de l’assureur (en €)"
                      />
                    </FormField>
                    <FormField size="half">
                      <InputNumber<ConformiteGarantieFinanciereFormValuesIas>
                        name="infoGarantie.primesEncaisseesMontant"
                        label="Dans le cadre d’un mandat exprès de l’assureur (en €)"
                      />
                    </FormField>
                  </FormRow>
                  <h4 className={styles.subtitle}>
                    Indemnités sinistres encaissées en {millesime - 1}
                  </h4>
                  <FormRow>
                    <FormField size="half">
                      <InputNumber<ConformiteGarantieFinanciereFormValuesIas>
                        name="infoGarantie.indemniteSinistreDehorsMandat"
                        label="En dehors de tout mandat exprès de l’assureur (en €)"
                      />
                    </FormField>
                    <FormField size="half">
                      <InputNumber<ConformiteGarantieFinanciereFormValuesIas>
                        name="infoGarantie.indemniteSinistreMontant"
                        label="Dans le cadre d’un mandat exprès de l’assureur (en €)"
                      />
                    </FormField>
                  </FormRow>
                </>
              )}
              <FormRow>
                <FormField>
                  <InputCheckbox<ConformiteGarantieFinanciereFormValuesIas>
                    name="nonDiffusable.noCommunication"
                    label="Ne souhaite pas communiquer sur les primes et indemnités encaissées"
                  />
                </FormField>
              </FormRow>
              {noCommunication && (
                <>
                  {!disabled && (
                    <InfoCard
                      type="warning"
                      className={styles.noCommunicationWarning}
                    >
                      <p>
                        Attention : Si vous ne souhaitez pas communiquer sur les
                        primes et indemnités encaissées, vous devez fournir une
                        attestation de la part de votre assureur, garantissant
                        que la société est suffisamment assurée. La validation
                        de cette pièce nécessitant un traitement manuel, le
                        délai de réception de l’attestation de conformité s’en
                        trouvera allongé.
                      </p>
                      <p>
                        Votre formulaire ne sera donc pas validé tant que votre
                        pièce justificative n’aura pas été vérifiée
                        manuellement.
                      </p>
                    </InfoCard>
                  )}
                  <ConformiteGarantieFinanciereEditFilesNonDiffusable
                    form={formConfig as unknown as FormWithFileNonDiffusable}
                    disabled={disabled}
                    siren={siren}
                    millesime={millesime}
                  />
                </>
              )}
              {!noCommunication && (
                <div className={styles.calculatedContainer}>
                  <p className={styles.calculatedLabel}>
                    Montant calculé de la garantie financière :
                  </p>
                  <p className={styles.calculatedValue}>
                    {montantCalculeGarantieFinanciere} €
                  </p>
                </div>
              )}
              <FormRow>
                <FormField>
                  <InputNumber<ConformiteGarantieFinanciereFormValuesIas>
                    name="infoGarantie.montantFondRoulement"
                    label="Montant du fond de roulement (en €)"
                  />
                </FormField>
              </FormRow>
            </>
          )}
        </FormGrid>
      </fieldset>
    </Form>
  );
}
