import { AdresseType } from '../../common/entreprise/adresse.definition';
import { ActiviteBancaire } from '../../info-societe-orias/controller/info-societe-orias.definition';
import {
  Categorie,
  CategorieRepresentation,
  InfoSocieteOriasType,
  OrganisationProfessionnelle,
} from '../../info-societe-orias/service/info-societe-orias.definition';
import { InfoSocietePappersType } from '../../info-societe-pappers/service/info-societe-pappers.definition';

export enum EntrepriseStatus {
  INEXISTANTE = 'INEXISTANTE',
  NON_ADHERENT = 'NON_ADHERENT',
  ADHESION_EN_COURS = 'ADHESION_EN_COURS',
  PAIEMENT_EN_ATTENTE = 'PAIEMENT_EN_ATTENTE',
  ADHERENT = 'ADHERENT',
  RADIE = 'RADIE',
  REMEDIATION = 'REMEDIATION',
  A_RENOUVELER = 'A_RENOUVELER',
  INVITATION = 'INVITATION',
}

export enum TypeAdhesionInactive {
  EN_COURS = 'EN_COURS',
}
export enum TypeAdhesionActive {
  SOCIETE_INDIVIDUELLE = 'SOCIETE_INDIVIDUELLE',
  GROUPE = 'GROUPE',
  GROUPEMENT_MANDATAIRES = 'GROUPEMENT_MANDATAIRES',
}
export const TypeAdhesion = {
  ...TypeAdhesionInactive,
  ...TypeAdhesionActive,
} as const;
export type TypeAdhesion = (typeof TypeAdhesion)[keyof typeof TypeAdhesion];

export enum TypeSouscripteur {
  PERSONNE_PHYSIQUE = 'PERSONNE_PHYSIQUE',
  PERSONNE_MORALE = 'PERSONNE_MORALE',
}

export enum TrancheEffectif {
  AUCUN = 'AUCUN',
  DE_1_A_2 = 'DE_1_A_2',
  DE_3_A_5 = 'DE_3_A_5',
  DE_6_A_9 = 'DE_6_A_9',
  DE_10_A_19 = 'DE_10_A_19',
  DE_20_A_49 = 'DE_20_A_49',
  DE_50_A_99 = 'DE_50_A_99',
  DE_100_A_199 = 'DE_100_A_199',
  DE_200_A_249 = 'DE_200_A_249',
  DE_250_A_499 = 'DE_250_A_499',
  DE_500_A_999 = 'DE_500_A_999',
  DE_1000_A_1999 = 'DE_1000_A_1999',
  DE_2000_A_4999 = 'DE_2000_A_4999',
  DE_5000_A_9999 = 'DE_5000_A_9999',
  PLUS_DE_10000 = 'PLUS_DE_10000',
}

export enum FormeJuridique {
  ORGANISME_DE_PLACEMENT_COLLECTIF_EN_VALEURS_MOBILIERES_SANS_PERSONNALITE_MORALE = 'ORGANISME_DE_PLACEMENT_COLLECTIF_EN_VALEURS_MOBILIERES_SANS_PERSONNALITE_MORALE',
  ENTREPRENEUR_INDIVIDUEL = 'ENTREPRENEUR_INDIVIDUEL',
  INDIVISION = 'INDIVISION',
  SOCIETE_CREEE_DE_FAIT = 'SOCIETE_CREEE_DE_FAIT',
  SOCIETE_EN_PARTICIPATION = 'SOCIETE_EN_PARTICIPATION',
  FIDUCIE = 'FIDUCIE',
  PAROISSE_HORS_ZONE_CONCORDATAIRE = 'PAROISSE_HORS_ZONE_CONCORDATAIRE',
  ASSUJETTI_UNIQUE_A_LA_TVA = 'ASSUJETTI_UNIQUE_A_LA_TVA',
  AUTRE_GROUPEMENT_DE_DROIT_PRIVE_NON_DOTE_DE_LA_PERSONNALITE_MORALE = 'AUTRE_GROUPEMENT_DE_DROIT_PRIVE_NON_DOTE_DE_LA_PERSONNALITE_MORALE',
  PERSONNE_MORALE_DE_DROIT_ETRANGER_IMMATRICULEE_AU_RCS = 'PERSONNE_MORALE_DE_DROIT_ETRANGER_IMMATRICULEE_AU_RCS',
  PERSONNE_MORALE_DE_DROIT_ETRANGERE_NON_IMMATRICULEE_AU_RCS = 'PERSONNE_MORALE_DE_DROIT_ETRANGERE_NON_IMMATRICULEE_AU_RCS',
  ETABLISSEMENT_PUBLIC_OU_REGIE_A_CARACTERE_INDUSTRIEL_OU_COMMERCIAL = 'ETABLISSEMENT_PUBLIC_OU_REGIE_A_CARACTERE_INDUSTRIEL_OU_COMMERCIAL',
  SOCIETE_COOPERATIVE_COMMERCIALE_PARTICULIERE = 'SOCIETE_COOPERATIVE_COMMERCIALE_PARTICULIERE',
  SOCIETE_EN_NOM_COLLECTIF = 'SOCIETE_EN_NOM_COLLECTIF',
  SOCIETE_EN_COMMANDITE = 'SOCIETE_EN_COMMANDITE',
  SOCIETE_A_RESPONSABILITE_LIMITEE = 'SOCIETE_A_RESPONSABILITE_LIMITEE',
  SOCIETE_ANONYME_A_CONSEIL_D_ADMINISTRATION = 'SOCIETE_ANONYME_A_CONSEIL_D_ADMINISTRATION',
  SOCIETE_ANONYME_A_DIRECTOIRE = 'SOCIETE_ANONYME_A_DIRECTOIRE',
  SOCIETE_PAR_ACTIONS_SIMPLIFIEE = 'SOCIETE_PAR_ACTIONS_SIMPLIFIEE',
  SOCIETE_EUROPEENNE = 'SOCIETE_EUROPEENNE',
  CAISSE_D_EPARGNE_ET_DE_PREVOYANCE = 'CAISSE_D_EPARGNE_ET_DE_PREVOYANCE',
  GROUPEMENT_D_INTERET_ECONOMIQUE = 'GROUPEMENT_D_INTERET_ECONOMIQUE',
  SOCIETE_COOPERATIVE_AGRICOLE = 'SOCIETE_COOPERATIVE_AGRICOLE',
  SOCIETE_D_ASSURANCE_MUTUELLE = 'SOCIETE_D_ASSURANCE_MUTUELLE',
  SOCIETE_CIVILE = 'SOCIETE_CIVILE',
  AUTRE_PERSONNE_MORALE_DE_DROIT_PRIVE_INSCRITE_AU_REGISTRE_DU_COMMERCE_ET_DES_SOCIETES = 'AUTRE_PERSONNE_MORALE_DE_DROIT_PRIVE_INSCRITE_AU_REGISTRE_DU_COMMERCE_ET_DES_SOCIETES',
  ADMINISTRATION_DE_L_ETAT = 'ADMINISTRATION_DE_L_ETAT',
  COLLECTIVITE_TERRITORIALE = 'COLLECTIVITE_TERRITORIALE',
  ETABLISSEMENT_PUBLIC_ADMINISTRATIF = 'ETABLISSEMENT_PUBLIC_ADMINISTRATIF',
  AUTRE_PERSONNE_MORALE_DE_DROIT_PUBLIC_ADMINISTRATIF = 'AUTRE_PERSONNE_MORALE_DE_DROIT_PUBLIC_ADMINISTRATIF',
  ORGANISME_GERANT_UN_REGIME_DE_PROTECTION_SOCIALE_A_ADHESION_OBLIGATOIRE = 'ORGANISME_GERANT_UN_REGIME_DE_PROTECTION_SOCIALE_A_ADHESION_OBLIGATOIRE',
  ORGANISME_MUTUALISTE = 'ORGANISME_MUTUALISTE',
  COMITE_D_ENTREPRISE = 'COMITE_D_ENTREPRISE',
  ORGANISME_PROFESSIONNEL = 'ORGANISME_PROFESSIONNEL',
  ORGANISME_DE_RETRAITE_A_ADHESION_NON_OBLIGATOIRE = 'ORGANISME_DE_RETRAITE_A_ADHESION_NON_OBLIGATOIRE',
  SYNDICAT_DE_PROPRIETAIRES = 'SYNDICAT_DE_PROPRIETAIRES',
  ASSOCIATION_LOI_1901_OU_ASSIMILE = 'ASSOCIATION_LOI_1901_OU_ASSIMILE',
  FONDATION = 'FONDATION',
  AUTRE_PERSONNE_MORALE_DE_DROIT_PRIVE = 'AUTRE_PERSONNE_MORALE_DE_DROIT_PRIVE',
}

export type UpdateEntrepriseAControllerType = {
  hubspotId: string;
  raisonSociale: string;
  numeroOrias?: string;
  adresse: AdresseType;
  formeJuridique: FormeJuridique;
  trancheEffectif: TrancheEffectif;
  chiffreAffaire: number;
  categoriesActivites: Categorie[];
  categoriesActivitesAccessoires: Categorie[];
  categorieRepresentation: CategorieRepresentation;
  organisationProfessionnelle: OrganisationProfessionnelle[];
  sousCategorieCOBSP: ActiviteBancaire[];
  sousCategoriesIOBSP: ActiviteBancaire[];
  numeroDerniereFacture?: string;
  dateDerniereFacture?: Date;
  millesimeActuel: number;
  renouvellementOffert?: false;
  consentementEDI?: boolean;
};

export type UpdateEntrepriseAControllerWithInfosType =
  UpdateEntrepriseAControllerType & {
    infoOrias?: InfoSocieteOriasType;
    infoPappers?: InfoSocietePappersType;
  };

export type UpdateEntreprisePrincipaleType = {
  hubspotId: string;
  raisonSociale: string;
  trancheEffectif: TrancheEffectif;
  formeJuridique: FormeJuridique;
  typeSouscripteur: TypeSouscripteur;
  adresse: AdresseType;
  renouvellementOffert?: boolean;
};

export type UpdateEntreprisePrincipaleWithInfosType =
  UpdateEntreprisePrincipaleType & {
    infoOrias?: InfoSocieteOriasType;
    infoPappers?: InfoSocietePappersType;
  };

export enum EntreprisePipelineControleQuinquennal {
  CONTROLE_QUINQUENNAL = 'CONTROLE_QUINQUENNAL',
  CONTROLE_QUINQUENNAL_DELEGUE = 'CONTROLE_QUINQUENNAL_DELEGUE',
}

export enum AAtteintLeTarif {
  NON = 'Non',
  MONO = 'Mono',
  MULTI = 'Multi',
}

export type EntreprisePrincipaleStatus =
  | {
      id: string;
      status: Exclude<EntrepriseStatus, EntrepriseStatus.INEXISTANTE>;
    }
  | {
      status: EntrepriseStatus.INEXISTANTE;
    };
