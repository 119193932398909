import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import {
  Form,
  InputCheckbox,
  InputNumber,
  InputSelect,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { forwardRef, useImperativeHandle } from "react";

const schema = zod.object({
  chiffreAffairesEnEurosNMoins1: zod
    .number({
      errorMap: () => ({
        message: "Veuillez renseigner votre chiffre d'affaires",
      }),
    })
    .min(0, { message: "Veuillez renseigner votre chiffre d'affaires" }),
  categorieRepresentation: zod.nativeEnum(
    GatewayInfoSocieteOrias.CategorieRepresentation,
    {
      errorMap: () => ({
        message: "Veuillez sélectionner votre catégorie de représentation",
      }),
    }
  ),
  consentementEDI: zod.boolean(),
});
export type EntrepriseInformationsComplementairesFormValues = zod.infer<
  typeof schema
>;

export const categorieRepresentationRecord: Record<
  GatewayInfoSocieteOrias.CategorieRepresentation,
  string
> = {
  [GatewayInfoSocieteOrias.CategorieRepresentation.COURTIERS_MOINS_20_SALARIES]:
    "Courtier de moins de 20 salariés",
  [GatewayInfoSocieteOrias.CategorieRepresentation.COURTIERS_PLUS_20_SALARIES]:
    "Courtier de plus de 20 salariés",
  [GatewayInfoSocieteOrias.CategorieRepresentation.COURTIER_GROSSISTE]:
    "Courtier grossiste",
  [GatewayInfoSocieteOrias.CategorieRepresentation.COURTIER_AFFINITAIRE]:
    "Courtier affinitaire",
  [GatewayInfoSocieteOrias.CategorieRepresentation.COMPARATEUR]: "Comparateur",
  [GatewayInfoSocieteOrias.CategorieRepresentation
    .MANDATAIRE_INTERMEDIAIRE_ASSURANCE]:
    "Mandataire d'intermédiaire en assurance",
  [GatewayInfoSocieteOrias.CategorieRepresentation
    .COURTIER_OPERATION_DE_BANQUE_ET_SERVICES_DE_PAIEMENT]:
    "Courtier en opérations de banque et en services de paiement",
  [GatewayInfoSocieteOrias.CategorieRepresentation
    .MANDATAIRE_INTERMEDIAIRE_OPERATION_DE_BANQUE_ET_SERVICE_DE_PAIEMENT]:
    "Mandataire d'intermédiaire en opérations de banque et en services de paiement",
};

const categorieRepresentationOptions = recordToSelectOptions(
  categorieRepresentationRecord
);

type EntrepriseInformationsComplementairesProps = {
  formId?: string;
  onSubmit: (response: EntrepriseInformationsComplementairesFormValues) => void;
  defaultValues: Partial<EntrepriseInformationsComplementairesFormValues>;
  isCOA: boolean;
};

export type EntrepriseInformationsComplementairesFormHandle = {
  getFormConfig: () => UseFormReturn<EntrepriseInformationsComplementairesFormValues>;
};

export const EntrepriseInformationsComplementairesForm = forwardRef<
  EntrepriseInformationsComplementairesFormHandle,
  EntrepriseInformationsComplementairesProps
>(({ defaultValues, onSubmit, formId, isCOA }, ref) => {
  const formConfig = useFormConfig<
    typeof schema,
    EntrepriseInformationsComplementairesFormValues
  >({ schema, defaultValues });

  useImperativeHandle(ref, () => ({
    getFormConfig() {
      return formConfig;
    },
  }));

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      <FormRow>
        <FormField>
          <InputSelect<
            EntrepriseInformationsComplementairesFormValues,
            GatewayInfoSocieteOrias.CategorieRepresentation
          >
            placeholder="Sélectionner..."
            options={categorieRepresentationOptions}
            name="categorieRepresentation"
            label="Catégorie de représentation"
            required
          />
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <InputNumber<EntrepriseInformationsComplementairesFormValues>
            name="chiffreAffairesEnEurosNMoins1"
            label="Chiffre d'affaires n-1 net de rétrocession pour les activités concernées en €"
            min={0}
            required
          />
        </FormField>
      </FormRow>
      {isCOA && (
        <FormRow>
          <FormField>
            <InputCheckbox<EntrepriseInformationsComplementairesFormValues>
              name="consentementEDI"
              label="Je souhaite partager mes informations avec la plateforme EDI Conformité"
            />
          </FormField>
        </FormRow>
      )}
    </Form>
  );
});
