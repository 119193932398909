export function ControleQuinquennalExplainAxeRcProCoa() {
  return (
    <div>
      <p>
        Les documents sollicités ci-dessous permettront de vérifier la
        couverture de l&apos;activité de COA par une garantie RC Professionnelle
        conforme aux exigences légales (activité couverte, territoire et période
        de couverture, montants de de garantie et de franchise).
      </p>
      <br />
      <ul>
        <li>
          Pièce(s) à fournir mentionnant la franchise et la territorialité de la
          garantie RC Professionnelle souscrite :
        </li>
        <ul>
          <li>Conditions particulières</li>
          <li>Et Conditions générales</li>
          <li>
            Et/ou tout autre document tel que le tableau de garantie ou le
            certificat d&apos;adhésion
          </li>
        </ul>
      </ul>
      <ul>
        <li>
          En cas de couverture de mandataires par votre garantie RC
          Professionnelle :
        </li>
        <ul>
          <li>La liste nominative de vos mandataires couverts</li>
        </ul>
      </ul>
    </div>
  );
}
