import {
  GatewayAdresse,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { FieldErrors } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { useFormConfig } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { useEffect, useState } from "react";

interface UseEditEntrepriseProps {
  entreprise: AdhesionDataEntreprisesItem | undefined;
  onSubmit: (data: AdhesionDataEntreprisesItem) => void;
}

const ERROR_MESSAGE = "Vous devez choisir au moins une sous-catégorie";

export const useEntrepriseImportEdit = ({
  entreprise,
  onSubmit,
}: UseEditEntrepriseProps) => {
  const schema = zod
    .object({
      siren: zod
        .string({ required_error: `SIREN non renseigné` })
        .refine((v) => /^\d{9}$/.test(v), "SIREN invalide"),
      raisonSociale: zod.string(),
      trancheEffectif: zod.nativeEnum(GatewayEntreprise.TrancheEffectif, {
        required_error: "Tranche d'effectif invalide",
      }),
      typeVoie: zod.nativeEnum(GatewayAdresse.TypeVoie, {
        required_error: "Type de voie invalide",
      }),
      libelleVoie: zod.string(),
      codePostal: zod
        .string({ required_error: "Code postal non renseigné" })
        .refine((v) => /^\d{5}$/.test(v), "Code postal invalide"),
      ville: zod.string(),
      categories: zod
        .array(
          zod.nativeEnum(GatewayInfoSocieteOrias.Categorie, {
            required_error: "Catégorie ORIAS invalide",
          })
        )
        .min(1, "1 Catégorie ORIAS au minimum"),
      organisationsProfessionnelles: zod
        .array(
          zod.nativeEnum(GatewayInfoSocieteOrias.OrganisationProfessionnelle, {
            required_error: "Organisation professionnelle invalide",
          })
        )
        .min(1, "1 organisation professionnelle minimum"),
      chiffreAffaire: zod.number({
        required_error: "Chiffre d'affaire invalide",
      }),
      categorieRepresentation: zod.nativeEnum(
        GatewayInfoSocieteOrias.CategorieRepresentation,
        { required_error: "Catégorie de représentation invalide" }
      ),
      pays: zod.nativeEnum(GatewayAdresse.Pays, {
        required_error: "Pays invalide",
      }),
      formeJuridique: zod.nativeEnum(GatewayEntreprise.FormeJuridique, {
        required_error: "Forme juridique invalide",
      }),
      sousCategorieCobsp: zod
        .array(zod.nativeEnum(GatewayInfoSocieteOrias.ActiviteBancaire))
        .optional(),
      sousCategorieMiobsp: zod
        .array(zod.nativeEnum(GatewayInfoSocieteOrias.ActiviteBancaire))
        .optional(),
    })
    .refine(
      (data) =>
        entreprise?.categoriesOrias?.includes(
          GatewayInfoSocieteOrias.Categorie.COBSP
        )
          ? (data?.sousCategorieCobsp?.length ?? 0) > 0
          : true,
      {
        message: ERROR_MESSAGE,
        path: ["sousCategorieCobsp"],
      }
    )
    .refine(
      (data) =>
        entreprise?.categoriesOrias?.includes(
          GatewayInfoSocieteOrias.Categorie.MIOBSP
        )
          ? (data?.sousCategorieMiobsp?.length ?? 0) > 0
          : true,
      {
        message: ERROR_MESSAGE,
        path: ["sousCategorieMiobsp"],
      }
    );

  type EditEntrepriseSchema = zod.infer<typeof schema>;

  const [initialErrors, setInitialErrors] =
    useState<FieldErrors<EditEntrepriseSchema> | null>(null);

  const formConfig = useFormConfig<typeof schema, EditEntrepriseSchema>({
    schema,
    defaultValues: {
      ...entreprise,
      categories: entreprise?.categoriesOrias,
      organisationsProfessionnelles: entreprise?.organisationPro,
    },
  });

  useEffect(() => {
    formConfig.trigger().catch((err) => console.error(err));
  }, [formConfig]);

  useEffect(() => {
    if (formConfig?.formState?.errors && initialErrors === null) {
      setInitialErrors({ ...formConfig.formState.errors });
    }
  }, [formConfig?.formState?.errors, initialErrors]);

  const handleSubmit = (data: EditEntrepriseSchema) => {
    const formatedData: AdhesionDataEntreprisesItem = {
      ...data,
      categoriesOrias: data.categories,
      organisationPro: data.organisationsProfessionnelles,
    };
    onSubmit(formatedData);
  };

  return {
    formConfig,
    handleSubmit,
    initialErrors,
    schema,
  };
};
