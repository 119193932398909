import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  ID_FORM_RENOUVELLEMENT,
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";
import { useCallback } from "react";
import {
  RenouvellementChiffreAffaireForm,
  RenouvellementChiffreAffaireFormValues,
} from "./RenouvellementChiffreAffaireForm";

export function RenouvellementChiffreAffairePage({
  data,
  setData,
  changeStep,
}: ParcoursRenouvellementStepComponentProps) {
  const entreprise =
    data.entreprisesAControler[data.resume.entreprisePrincipale.siren];

  const handleSubmit = useCallback(
    (values: RenouvellementChiffreAffaireFormValues) => {
      setData({
        ...data,
        entreprisesAControler: {
          ...data.entreprisesAControler,
          [entreprise.siren]: {
            ...entreprise,
            chiffreAffaire: values.chiffreAffaire,
            consentementEDI: values.consentementEDI,
          },
        },
      });
      changeStep(RenouvellementStepEnum.PAIEMENT);
    },
    [setData, data, entreprise, changeStep]
  );

  return (
    <ParcoursFullPageTemplate
      title="Chiffre d'affaires"
      actions={
        <ParcoursAction type="submit" formId={ID_FORM_RENOUVELLEMENT}>
          Suivant
        </ParcoursAction>
      }
    >
      <RenouvellementChiffreAffaireForm
        formId={ID_FORM_RENOUVELLEMENT}
        defaultValues={{
          chiffreAffaire: entreprise.chiffreAffaire as number,
          consentementEDI: entreprise.consentementEDI,
        }}
        onSubmit={handleSubmit}
      />
    </ParcoursFullPageTemplate>
  );
}
