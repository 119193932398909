import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  InputSelect,
  useFormConfig,
  Form,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { categorieRepresentationRecord } from "@src/societes/form/EntrepriseInformationsComplementairesForm";

const schema = zod.object({
  categorieRepresentation: zod.nativeEnum(
    GatewayInfoSocieteOrias.CategorieRepresentation,
    {
      errorMap: () => ({
        message: "Veuillez sélectionner votre catégorie de représentation",
      }),
    }
  ),
});
export type RenouvellementEntrepriseInformationsComplementairesFormValues =
  zod.infer<typeof schema>;

export const categorieRepresentationOptions = recordToSelectOptions(
  categorieRepresentationRecord
);

type RenouvellementEntrepriseInformationsComplementairesProps = {
  formId?: string;
  onSubmit: (
    response: RenouvellementEntrepriseInformationsComplementairesFormValues
  ) => void;
  defaultValues: RenouvellementEntrepriseInformationsComplementairesFormValues;
};

export function RenouvellementEntrepriseInformationsComplementairesForm({
  formId,
  onSubmit,
  defaultValues,
}: RenouvellementEntrepriseInformationsComplementairesProps) {
  const formConfig = useFormConfig<
    typeof schema,
    RenouvellementEntrepriseInformationsComplementairesFormValues
  >({ schema, defaultValues });

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      <FormRow>
        <FormField>
          <InputSelect<
            RenouvellementEntrepriseInformationsComplementairesFormValues,
            GatewayInfoSocieteOrias.CategorieRepresentation
          >
            placeholder="Sélectionner..."
            options={categorieRepresentationOptions}
            name="categorieRepresentation"
            label="Catégorie de représentation"
            required
          />
        </FormField>
      </FormRow>
    </Form>
  );
}
