import {
  GatewayAvancement,
  GatewayConformite,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { isIas, isIobsp } from "@src/helper/entreprise.helper";
import { difference } from "lodash";

export const categorieToAvancementKey: Record<
  GatewayInfoSocieteOrias.Categorie,
  keyof GatewayAvancement.AvancementSociete
> = {
  COA: "rcProCoa",
  MIA: "rcProMia",
  COBSP: "rcProCobsp",
  MIOBSP: "rcProMiobsp",
};

export const getAxesConformiteByActivites = (
  categories: GatewayInfoSocieteOrias.Categorie[]
) => {
  const hasAccessToIas = isIas(categories);
  const hasAccessToIobsp = isIobsp(categories);
  const hasAccessByAxe: Record<GatewayConformite.AxeConformite, boolean> = {
    [GatewayConformite.AxeConformite.HONORABILITE]: true,
    [GatewayConformite.AxeConformite.FORMATION_CONTINUE_IAS]: hasAccessToIas,
    [GatewayConformite.AxeConformite.FORMATION_CONTINUE_IOBSP]:
      hasAccessToIobsp,
    [GatewayConformite.AxeConformite.CAPACITE_PRO_IAS]: hasAccessToIas,
    [GatewayConformite.AxeConformite.CAPACITE_PRO_IOBSP]: hasAccessToIobsp,
    [GatewayConformite.AxeConformite.RC_PRO_COA]: categories.some(
      (activite) => activite === GatewayInfoSocieteOrias.Categorie.COA
    ),
    [GatewayConformite.AxeConformite.RC_PRO_MIA]: categories.some(
      (activite) => activite === GatewayInfoSocieteOrias.Categorie.MIA
    ),
    [GatewayConformite.AxeConformite.RC_PRO_COBSP]: categories.some(
      (activite) => activite === GatewayInfoSocieteOrias.Categorie.COBSP
    ),
    [GatewayConformite.AxeConformite.RC_PRO_MIOBSP]: categories.some(
      (activite) => activite === GatewayInfoSocieteOrias.Categorie.MIOBSP
    ),
    [GatewayConformite.AxeConformite.GARANTIE_FINANCIERE_IAS]: hasAccessToIas,
    [GatewayConformite.AxeConformite.GARANTIE_FINANCIERE_IOBSP]:
      hasAccessToIobsp,
    [GatewayConformite.AxeConformite.DECLARATION_ACTIVITE_IAS]: hasAccessToIas,
    [GatewayConformite.AxeConformite.DECLARATION_ACTIVITE_IOBSP]:
      hasAccessToIobsp,
  };

  const entries = Object.entries(hasAccessByAxe);
  return entries
    .filter(([_, hasAccess]) => hasAccess)
    .map(([axeName]) => axeName) as GatewayConformite.AxeConformite[];
};

export const getAxesConformiteEntrepriseByActivites = (
  categories: GatewayInfoSocieteOrias.Categorie[]
) => {
  const axesAssujettis = [
    GatewayConformite.AxeConformite.HONORABILITE,
    GatewayConformite.AxeConformite.CAPACITE_PRO_IAS,
    GatewayConformite.AxeConformite.CAPACITE_PRO_IOBSP,
    GatewayConformite.AxeConformite.FORMATION_CONTINUE_IAS,
    GatewayConformite.AxeConformite.FORMATION_CONTINUE_IOBSP,
  ];

  const axesByActivites = getAxesConformiteByActivites(categories);
  return difference(axesByActivites, axesAssujettis);
};
