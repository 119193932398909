import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import styles from "./DoubleAuth.module.scss";

type DoubleAuthListCode =
  | "code1"
  | "code2"
  | "code3"
  | "code4"
  | "code5"
  | "code6";
interface DoubleAuthInputProps {
  formConfig: UseFormReturn<
    {
      code1: number | null;
      code2: number | null;
      code3: number | null;
      code4: number | null;
      code5: number | null;
      code6: number | null;
    },
    unknown,
    undefined
  >;
  previousRef?: React.RefObject<HTMLInputElement>;
  nextRef?: React.RefObject<HTMLInputElement>;
  inputRef: React.RefObject<HTMLInputElement>;
  buttonRef: React.RefObject<HTMLButtonElement>;
  name: DoubleAuthListCode;
}

export function DoubleAuthInput({
  formConfig,
  previousRef,
  nextRef,
  inputRef,
  buttonRef,
  name,
}: DoubleAuthInputProps) {
  return (
    <div className={styles.number}>
      <input
        type="number"
        className={styles.number}
        name={name}
        value={formConfig.watch(name)?.toString() || ""}
        onPaste={(e) => {
          const text = e.clipboardData.getData("text").split(" ").join("");
          if (text.match(/[0-9]{6}/) && text.length === 6) {
            text.split("").map((v, i) => {
              const key = `code${i + 1}`;
              return formConfig.setValue(
                key as DoubleAuthListCode,
                parseInt(v, 10)
              );
            });
            buttonRef.current?.click();
          }
        }}
        onKeyDown={(e) => {
          if (e.key.match(/[0-9]/)) {
            const value = parseInt(e.key, 10);
            formConfig.setValue(name, value);
            if (nextRef && nextRef.current) nextRef.current?.focus();
            else buttonRef.current?.click();
          } else if (e.code === "Backspace") {
            formConfig.setValue(name, null);
            if (previousRef && previousRef.current)
              previousRef.current?.focus();
          } else if (e.code === "Enter") {
            buttonRef.current?.click();
          }
        }}
        ref={inputRef}
      />
    </div>
  );
}
