import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import {
  Button,
  FormField,
  FormRow,
  displayErrorToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactNode, useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import {
  FieldValues,
  UseFormReturn,
} from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import {
  GatewayAdhesion,
  GatewayEntreprise,
  GatewayPaiement,
} from "@conformite/gateway";
import { useNavigate } from "react-router-dom";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import {
  changeCategoriesEntreprise,
  storeEntrepriseUpdatedRequest,
} from "@src/payment/utils/createEntreprises";
import { ID_FORM_ADHESION } from "@src/adhesion/ParcoursAdhesion.definition";
import { AdhesionVirementPage } from "@src/adhesion/Steps/AdhesionPaiement/AdhesionVirementPage";
import { useSocieteStripePaiement } from "./useSocieteStripePaiement";
import styles from "./SocietePaiementStripeForm.module.scss";

type SocietePaiementStripeFormProps<TValue extends FieldValues> = {
  formContent: ReactNode;
  formConfig: UseFormReturn<TValue, unknown, undefined>;
  modePrelevementSelectionne: GatewayPaiement.PaymentMethod;
  siren: string;
  millesime: number;
  entreprise: GatewayEntreprise.UpdateEntrepriseAControllerRequestType;
  paiementIntention: GatewayAdhesion.CreateIntentionPaimentResponseType;
};

export function SocietePaiementStripeForm<TValue extends FieldValues>({
  formContent,
  formConfig,
  modePrelevementSelectionne,
  siren,
  millesime,
  entreprise,
  paiementIntention,
}: SocietePaiementStripeFormProps<TValue>) {
  const { onSubmit: submitStripe } = useSocieteStripePaiement(
    siren,
    entreprise,
    paiementIntention
  );
  const navigate = useNavigate();
  const [isPaiementSubmitting, setIsPaiementSubmitting] = useState(false);

  const handleSubmit = async (values: TValue) => {
    setIsPaiementSubmitting(true);
    if (modePrelevementSelectionne === GatewayPaiement.PaymentMethod.TRANSFER) {
      try {
        const returnUrl = `/societes/${siren}/informations?paiement=success&hasReachedLimit=${
          (paiementIntention.hasReachedLimit ||
            GatewayEntreprise.AAtteintLeTarif.NON) as string
        }`;
        changeCategoriesEntreprise(
          siren,
          millesime,
          entreprise,
          paiementIntention
        )
          .then(() => {
            setIsPaiementSubmitting(false);
            storeEntrepriseUpdatedRequest(entreprise);

            displaySucessToast("La société a bien été mise à jour");
            navigate(returnUrl);
          })
          .catch(() => {
            setIsPaiementSubmitting(false);
            displayErrorToast(
              "Une erreur est survenue lors de la mise à jour",
              {
                canal: "societe-information",
              }
            );
          });
      } catch (error) {
        handleGatewayError({
          onUnhandled: () => {
            console.error(error);
            displayErrorToast(
              "Une erreur est survenue lors de la création des entreprises"
            );
          },
        })(error);
      }
    } else {
      try {
        await submitStripe();
        setIsPaiementSubmitting(false);
      } catch (error) {
        handleGatewayError({
          onUnhandled: () => {
            console.error(error);
            displayErrorToast(
              "Une erreur est survenue lors du paiement de la cotisation"
            );
          },
        })(error);
      }
    }

    return values;
  };

  return (
    <Form formConfig={formConfig} onSubmit={handleSubmit} id={ID_FORM_ADHESION}>
      {formContent}
      {modePrelevementSelectionne ===
        GatewayPaiement.PaymentMethod.TRANSFER && <AdhesionVirementPage />}
      {modePrelevementSelectionne !==
        GatewayPaiement.PaymentMethod.TRANSFER && (
        <FormRow>
          <FormField>
            <PaymentElement id="payment-element" />
          </FormField>
        </FormRow>
      )}
      <Button
        disabled={isPaiementSubmitting}
        className={styles.button}
        type="submit"
        icon={isPaiementSubmitting ? <Loader /> : undefined}
      >
        Valider
      </Button>
    </Form>
  );
}
