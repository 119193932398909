/* eslint-disable react-hooks/exhaustive-deps */
import {
  GatewayAdresse,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import {
  ImportSocietesDoublon,
  ImportSocietesError,
  ImportSocietesItem,
} from "@src/adhesion/Steps/AdhesionModeImport/useImportSocietes";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import { SidePane } from "@src/components/SidePane/SidePane";
import { TitleWithNumber } from "@src/components/TitleWithNumber/TitleWithNumber";
import {
  addSocieteDataValidationToSheet,
  colorErrorsSocietesInSheet,
  downloadSheetFromWorkbook,
  importWorkBookFromFile,
  insertDataInSheet,
} from "@src/helper/excel/excel.export.helper";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { isCategorieAccessoire } from "@src/payment/utils/createIntentionPaiement";
import {
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";
import {
  entrepriseExcelExportTemplate,
  entrepriseExcelTemplate,
} from "@src/societes/export/EntrepriseExcelTemplate";
import { transformEntrepriseInErrorApiRowsToRows } from "@src/societes/export/EntrepriseExportMapping";
import { EntrepriseImportDashboard } from "@src/societes/import/EntrepriseImportDashboard";
import { EntrepriseImportEdit } from "@src/societes/import/EntrepriseImportEdit";
import { format } from "date-fns";
import { partition } from "lodash";
import { useState } from "react";
import { RenouvellementEntrepriseAControllerData } from "../../RenouvellementData";
import { useImportRenouvellement } from "../introduction/useImportRenouvellement";

export function transformAdhesionDataEntreprisesItemToRenouvellementEntrepriseAController(
  societe: AdhesionDataEntreprisesItem
) {
  const [categorieAccessoire, categorieNonAccessoire] = partition(
    societe.categoriesOrias || [],
    (categorie) =>
      isCategorieAccessoire(categorie, {
        coaAccessoire: !!societe.coaAccessoire,
        miaAccessoire: !!societe.miaAccessoire,
        cobspAccessoire: !!societe.cobspAccessoire,
        miobspAccessoire: !!societe.miobspAccessoire,
      })
  );
  return {
    siren: societe.siren as string,
    raisonSociale: societe.raisonSociale as string,
    adresse: {
      codePostal: societe.codePostal as string,
      libelleVoie: societe.libelleVoie as string,
      numeroVoie: societe.numeroVoie as string,
      pays: societe.pays as GatewayAdresse.Pays,
      typeVoie: societe.typeVoie as GatewayAdresse.TypeVoie,
      ville: societe.ville as string,
      complementAdresse: societe.complementAdresse,
    },
    categorieRepresentation:
      societe.categorieRepresentation as GatewayInfoSocieteOrias.CategorieRepresentation,
    categoriesActivites: categorieNonAccessoire,
    categoriesActivitesAccessoires: categorieAccessoire,
    formeJuridique: societe.formeJuridique as GatewayEntreprise.FormeJuridique,
    numeroOrias: societe.numeroOrias,
    organisationProfessionnelle:
      societe.organisationPro as GatewayInfoSocieteOrias.OrganisationProfessionnelle[],
    sousCategorieCOBSP: societe.sousCategorieCobsp ?? [],
    sousCategoriesIOBSP: societe.sousCategorieMiobsp ?? [],
    trancheEffectif:
      societe.trancheEffectif as GatewayEntreprise.TrancheEffectif,
    chiffreAffaire: societe.chiffreAffaire,
    consentementEDI: societe.consentementEDI ?? false,
  };
}

export function transformRenouvellementEntrepriseAControllerToAdhesionDataEntreprisesItem(
  entrepriseAControllerData?: RenouvellementEntrepriseAControllerData
): AdhesionDataEntreprisesItem | undefined {
  if (!entrepriseAControllerData) return undefined;
  return {
    categorieRepresentation: entrepriseAControllerData.categorieRepresentation,
    categoriesOrias: [
      ...entrepriseAControllerData.categoriesActivites,
      ...entrepriseAControllerData.categoriesActivitesAccessoires,
    ],
    coaAccessoire:
      entrepriseAControllerData.categoriesActivitesAccessoires.includes(
        GatewayInfoSocieteOrias.Categorie.COA
      ),
    miaAccessoire:
      entrepriseAControllerData.categoriesActivitesAccessoires.includes(
        GatewayInfoSocieteOrias.Categorie.MIA
      ),
    cobspAccessoire:
      entrepriseAControllerData.categoriesActivitesAccessoires.includes(
        GatewayInfoSocieteOrias.Categorie.COBSP
      ),
    miobspAccessoire:
      entrepriseAControllerData.categoriesActivitesAccessoires.includes(
        GatewayInfoSocieteOrias.Categorie.MIOBSP
      ),
    chiffreAffaire: entrepriseAControllerData.chiffreAffaire,
    codePostal: entrepriseAControllerData.adresse.codePostal,
    complementAdresse: entrepriseAControllerData.adresse.complementAdresse,
    libelleVoie: entrepriseAControllerData.adresse.libelleVoie,
    numeroVoie: entrepriseAControllerData.adresse.numeroVoie,
    pays: entrepriseAControllerData.adresse.pays,
    typeVoie: entrepriseAControllerData.adresse.typeVoie,
    ville: entrepriseAControllerData.adresse.ville,
    formeJuridique: entrepriseAControllerData.formeJuridique,
    numeroOrias: entrepriseAControllerData.numeroOrias,
    organisationPro: entrepriseAControllerData.organisationProfessionnelle,
    raisonSociale: entrepriseAControllerData.raisonSociale,
    siren: entrepriseAControllerData.siren,
    sousCategorieCobsp: entrepriseAControllerData.sousCategorieCOBSP,
    sousCategorieMiobsp: entrepriseAControllerData.sousCategoriesIOBSP,
    trancheEffectif: entrepriseAControllerData.trancheEffectif,
  };
}

export function transformImportSocietesDataToRenouvellementData(
  entreprise: ImportSocietesItem[]
): Record<string, RenouvellementEntrepriseAControllerData> {
  return entreprise.reduce((acc, { societe }) => {
    acc[societe.siren as string] =
      transformAdhesionDataEntreprisesItemToRenouvellementEntrepriseAController(
        societe
      );
    return acc;
  }, {} as Record<string, RenouvellementEntrepriseAControllerData>);
}

type PaneState = {
  open: boolean;
  entrepriseToEdit?: ImportSocietesError | ImportSocietesDoublon;
};

const sortByIndex = (a: ImportSocietesItem, b: ImportSocietesItem) => {
  return a.index - b.index;
};

export function RenouvellementMajSocietesListImport({
  data,
  addData,
  changeStep,
}: ParcoursRenouvellementStepComponentProps) {
  const [paneState, setPaneState] = useState<PaneState>({
    open: false,
  });

  const { handleImportSocietes } = useImportRenouvellement(
    data,
    addData,
    changeStep
  );

  const handleExport = async () => {
    const societesExport = [
      ...data.societesDoublons,
      ...data.societesToAdd,
      ...data.societesInError,
    ].sort(sortByIndex);

    const workbook = await importWorkBookFromFile("modele_societe_error.xlsx");
    const sheet = workbook.worksheets[0];
    if (!societesExport) return;
    insertDataInSheet(
      societesExport.map(transformEntrepriseInErrorApiRowsToRows),
      sheet,
      entrepriseExcelTemplate,
      entrepriseExcelExportTemplate
    );
    colorErrorsSocietesInSheet(sheet, societesExport as ImportSocietesError[]);

    addSocieteDataValidationToSheet(sheet, societesExport.length);
    await downloadSheetFromWorkbook(
      `export_entreprise_error_${format(new Date(), "dd-MM-yyyy_HH:mm")}.xlsx`,
      workbook
    );
  };

  const societesAreValid =
    Object.values(data.entreprisesAControler)?.length > 0 ||
    data.societesInError?.find((s) => s.errors.length === 0);

  return (
    <>
      <ParcoursFullPageTemplate
        title={<TitleWithNumber title="Import en masse" />}
        actions={
          <ParcoursAction
            onClick={() => {
              if (societesAreValid) {
                changeStep(RenouvellementStepEnum.LISTE_SOCIETES);
              }
            }}
          >
            Suivant
          </ParcoursAction>
        }
        size="large"
      >
        <EntrepriseImportDashboard
          doublons={data.societesDoublons.map((e) => ({
            description: e.description,
            index: e.index,
            raisonSociale: e.societe.raisonSociale,
            siren: e.societe.siren,
          }))}
          errors={data.societesInError.map((e) => ({
            errors: e.errors,
            index: e.index,
            raisonSociale: e.societe.raisonSociale,
            siren: e.societe.siren,
          }))}
          success={Object.values(data.entreprisesAControler)}
          onExport={() => {
            handleExport().catch(
              handleGatewayError({
                onUnhandled: () => displayErrorToast("Une erreur est survenue"),
              })
            );
          }}
          onUpdateError={(error) =>
            setPaneState({
              open: true,
              entrepriseToEdit: data.societesInError.find(
                (e) => e.index === error.index
              ),
            })
          }
          importButton={
            <FileInputButton
              onChange={handleImportSocietes}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              Importer le fichier corrigé
            </FileInputButton>
          }
        />
      </ParcoursFullPageTemplate>

      <SidePane
        isOpen={paneState.open}
        setIsOpen={(nextIsOpen) => {
          setPaneState((prevState) => ({ ...prevState, open: nextIsOpen }));
        }}
      >
        {(closePane) => (
          <EntrepriseImportEdit
            key={`${JSON.stringify(paneState.entrepriseToEdit)}${
              data.entreprisesUpdateCount
            }`}
            entrepriseToEdit={paneState.entrepriseToEdit?.societe}
            onSubmit={(editedEntreprise: AdhesionDataEntreprisesItem) => {
              if (!editedEntreprise.siren) return;
              addData({
                societesInError: data.societesInError?.filter(
                  (s) => s.societe.siren !== editedEntreprise.siren
                ),
                entreprisesAControler: {
                  ...data.entreprisesAControler,
                  [editedEntreprise.siren]:
                    transformAdhesionDataEntreprisesItemToRenouvellementEntrepriseAController(
                      editedEntreprise
                    ),
                },
                // societesToAdd: [
                //   ...data.societesToAdd,
                //   transformAdhesionDataEntreprisesItemToRenouvellementEntrepriseAController(
                //     editedEntreprise
                //   ),
                // ],
              });
              setPaneState({ open: false });
              closePane(true);
            }}
          />
        )}
      </SidePane>
    </>
  );
}
