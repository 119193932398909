import {
  GatewayAssujetti,
  GatewayCapacitePro,
  GatewayFormationContinue,
  GatewayHonorabilite,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import styles from "./ControleQuinquennalAssujettiSidePane.module.scss";
import {
  ControleQuinquennalAssujettiSidePaneContentHonorabilite,
  useHonorablite,
} from "./form/ControleQuinquennalAssujettiHonorabilite";
import { AssujettiFormType } from "./ControleQuinquennalAxeAssujettis";
import {
  ControleQuinquennalAssujettiSidePaneContentFormationContinueIas,
  useFormationContinueIas,
} from "./form/ControleQuinquennalAssujettiFormationContinueIas";
import {
  ControleQuinquennalAssujettiSidePaneContentFormationContinueIobsp,
  useFormationContinueIobsp,
} from "./form/ControleQuinquennalAssujettiFormationContinueIobsp";
import {
  ControleQuinquennalAssujettiSidePaneContentCapaciteProIas,
  useCapaciteProIas,
} from "./form/ControleQuinquennalAssujettiCapaciteProIas";
import {
  ControleQuinquennalAssujettiSidePaneContentCapaciteProIobsp,
  useCapaciteProIobsp,
} from "./form/ControleQuinquennalAssujettiCapaciteProIobsp";

type ControleQuinquennalAssujettiSidePaneContentProps = {
  assujetti: GatewayAssujetti.AssujettiWithAvancement;
  honorabilite: GatewayHonorabilite.Honorabilite | null | undefined;
  formationContinueIas:
    | GatewayFormationContinue.FormationContinueIas
    | null
    | undefined;
  formationContinueIobsp:
    | GatewayFormationContinue.FormationContinueIobsp
    | null
    | undefined;
  capaciteProIas:
    | GatewayCapacitePro.CapaciteProfessionnelleIAS
    | null
    | undefined;
  capaciteProIobsp:
    | GatewayCapacitePro.CapaciteProfessionnelleIOBSP
    | null
    | undefined;
  isMonoActivite: boolean;
  categories: GatewayInfoSocieteOrias.Categorie[];
};
export function ControleQuinquennalAssujettiSidePaneContent({
  assujetti,
  honorabilite,
  formationContinueIas,
  formationContinueIobsp,
  capaciteProIas,
  capaciteProIobsp,
  isMonoActivite,
  categories,
}: ControleQuinquennalAssujettiSidePaneContentProps) {
  return (
    <div className={styles.forms}>
      {honorabilite !== undefined && (
        <ControleQuinquennalAssujettiSidePaneContentHonorabilite
          honorabilite={honorabilite}
          assujetti={assujetti}
        />
      )}
      {formationContinueIas !== undefined && (
        <ControleQuinquennalAssujettiSidePaneContentFormationContinueIas
          assujetti={assujetti}
          formationContinue={formationContinueIas}
          categories={categories}
        />
      )}
      {formationContinueIobsp !== undefined && (
        <ControleQuinquennalAssujettiSidePaneContentFormationContinueIobsp
          assujetti={assujetti}
          formationContinue={formationContinueIobsp}
          categories={categories}
        />
      )}
      {capaciteProIas !== undefined && (
        <ControleQuinquennalAssujettiSidePaneContentCapaciteProIas
          assujetti={assujetti}
          capacitePro={capaciteProIas}
          isMonoActivite={isMonoActivite}
        />
      )}
      {capaciteProIobsp !== undefined && (
        <ControleQuinquennalAssujettiSidePaneContentCapaciteProIobsp
          assujetti={assujetti}
          capacitePro={capaciteProIobsp}
          isMonoActivite={isMonoActivite}
        />
      )}
    </div>
  );
}

type ControleQuinquennalAssujettiSidePaneProps = {
  assujetti?: GatewayAssujetti.AssujettiWithAvancement;
  axes: AssujettiFormType[];
  isMonoActivite: boolean;
  categories: GatewayInfoSocieteOrias.Categorie[];
};

export function ControleQuinquennalAssujettiSidePane({
  assujetti,
  axes,
  isMonoActivite,
  categories,
}: ControleQuinquennalAssujettiSidePaneProps) {
  const { honorabilite, hasLoadedHonorabilite } = useHonorablite(
    assujetti,
    axes.includes("honorabilite")
  );

  const { formationContinueIas, hasLoadedFormationContinueIas } =
    useFormationContinueIas(assujetti, axes.includes("formationContinueIas"));
  const { formationContinueIobsp, hasLoadedFormationContinueIobsp } =
    useFormationContinueIobsp(
      assujetti,
      axes.includes("formationContinueIobsp")
    );

  const { capaciteProIas, hasLoadedCapaciteProIas } = useCapaciteProIas(
    assujetti,
    axes.includes("capaciteProfessionnelleIas")
  );
  const { capaciteProIobsp, hasLoadedCapaciteProIobsp } = useCapaciteProIobsp(
    assujetti,
    axes.includes("capaciteProfessionnelleIobsp")
  );

  if (
    !assujetti ||
    !hasLoadedHonorabilite ||
    !hasLoadedFormationContinueIas ||
    !hasLoadedFormationContinueIobsp ||
    !hasLoadedCapaciteProIas ||
    !hasLoadedCapaciteProIobsp
  )
    return null;
  return (
    <ControleQuinquennalAssujettiSidePaneContent
      assujetti={assujetti}
      honorabilite={honorabilite}
      formationContinueIas={formationContinueIas}
      formationContinueIobsp={formationContinueIobsp}
      capaciteProIas={capaciteProIas}
      capaciteProIobsp={capaciteProIobsp}
      isMonoActivite={isMonoActivite}
      categories={categories}
    />
  );
}
