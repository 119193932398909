import { GatewayCapacitePro } from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { isBeforeToday } from "@src/helper/date.helper";
import { isNil } from "lodash";

export const labelsByFormCapaciteProNiveauIAS: Record<
  GatewayCapacitePro.CapaciteProNiveauIAS,
  string
> = {
  NIVEAU_1: "Niveau 1",
  NIVEAU_2: "Niveau 2",
  NIVEAU_3: "Niveau 3",
};

export const labelsByCategorieIAS: Record<
  GatewayCapacitePro.CategorieIAS,
  string
> = {
  DIRIGEANT: "Dirigeant",
  SALARIE_ANIMER_RESEAU_PRODUCTION:
    "Salarié responsable d'un bureau de production",
  SALARIE_AVEC_RESPONSABLE_NIVEAU_1:
    "Salarié de bureau avec responsable niveau 1",
  SALARIE_SANS_RESPONSABLE_NIVEAU_1:
    "Salarié de bureau sans responsable niveau 1",
  SALARIE_DEBOUT: "Salarié debout",
  SALARIE_RESPONSABLE_RESEAU_PRODUCTION:
    "Salarié en charge d'animer un réseau de production",
};

export const typeJustificatifAvailableByCategorieIAS: Record<
  GatewayCapacitePro.CategorieIAS,
  GatewayCapacitePro.TypeJustificatifIAS[]
> = {
  DIRIGEANT: [
    GatewayCapacitePro.TypeJustificatifIAS.GRADE_MASTER,
    GatewayCapacitePro.TypeJustificatifIAS.DIPLOME_ETRANGER_MASTER,
    GatewayCapacitePro.TypeJustificatifIAS.ECOLE_COMMERCE,
    GatewayCapacitePro.TypeJustificatifIAS.STAGE_PRO_NIVEAU_1,
    GatewayCapacitePro.TypeJustificatifIAS.STAGE_PRO_NIVEAU_2,
    GatewayCapacitePro.TypeJustificatifIAS.FORMATION_NIVEAU_3,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_1_AN_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_2_ANS_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_2_ANS_NON_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_4_ANS_NON_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.BTS_313,
    GatewayCapacitePro.TypeJustificatifIAS.LICENCE_313,
    GatewayCapacitePro.TypeJustificatifIAS.CQP_313,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_6_MOIS,
  ],
  SALARIE_ANIMER_RESEAU_PRODUCTION: [
    GatewayCapacitePro.TypeJustificatifIAS.GRADE_MASTER,
    GatewayCapacitePro.TypeJustificatifIAS.DIPLOME_ETRANGER_MASTER,
    GatewayCapacitePro.TypeJustificatifIAS.ECOLE_COMMERCE,
    GatewayCapacitePro.TypeJustificatifIAS.STAGE_PRO_NIVEAU_1,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_2_ANS_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_4_ANS_NON_CADRE,
  ],
  SALARIE_AVEC_RESPONSABLE_NIVEAU_1: [
    GatewayCapacitePro.TypeJustificatifIAS.GRADE_MASTER,
    GatewayCapacitePro.TypeJustificatifIAS.DIPLOME_ETRANGER_MASTER,
    GatewayCapacitePro.TypeJustificatifIAS.ECOLE_COMMERCE,
    GatewayCapacitePro.TypeJustificatifIAS.BTS_313,
    GatewayCapacitePro.TypeJustificatifIAS.LICENCE_313,
    GatewayCapacitePro.TypeJustificatifIAS.CQP_313,
    GatewayCapacitePro.TypeJustificatifIAS.STAGE_PRO_NIVEAU_1,
    GatewayCapacitePro.TypeJustificatifIAS.STAGE_PRO_NIVEAU_2,
    GatewayCapacitePro.TypeJustificatifIAS.FORMATION_NIVEAU_3,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_1_AN_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_2_ANS_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_2_ANS_NON_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_4_ANS_NON_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_6_MOIS,
  ],
  SALARIE_SANS_RESPONSABLE_NIVEAU_1: [
    GatewayCapacitePro.TypeJustificatifIAS.GRADE_MASTER,
    GatewayCapacitePro.TypeJustificatifIAS.DIPLOME_ETRANGER_MASTER,
    GatewayCapacitePro.TypeJustificatifIAS.ECOLE_COMMERCE,
    GatewayCapacitePro.TypeJustificatifIAS.BTS_313,
    GatewayCapacitePro.TypeJustificatifIAS.LICENCE_313,
    GatewayCapacitePro.TypeJustificatifIAS.CQP_313,
    GatewayCapacitePro.TypeJustificatifIAS.STAGE_PRO_NIVEAU_1,
    GatewayCapacitePro.TypeJustificatifIAS.STAGE_PRO_NIVEAU_2,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_1_AN_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_2_ANS_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_2_ANS_NON_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_4_ANS_NON_CADRE,
  ],
  SALARIE_DEBOUT: [
    GatewayCapacitePro.TypeJustificatifIAS.GRADE_MASTER,
    GatewayCapacitePro.TypeJustificatifIAS.DIPLOME_ETRANGER_MASTER,
    GatewayCapacitePro.TypeJustificatifIAS.ECOLE_COMMERCE,
    GatewayCapacitePro.TypeJustificatifIAS.BTS_313,
    GatewayCapacitePro.TypeJustificatifIAS.LICENCE_313,
    GatewayCapacitePro.TypeJustificatifIAS.CQP_313,
    GatewayCapacitePro.TypeJustificatifIAS.STAGE_PRO_NIVEAU_1,
    GatewayCapacitePro.TypeJustificatifIAS.STAGE_PRO_NIVEAU_2,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_1_AN_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_2_ANS_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_2_ANS_NON_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_4_ANS_NON_CADRE,
  ],
  SALARIE_RESPONSABLE_RESEAU_PRODUCTION: [
    GatewayCapacitePro.TypeJustificatifIAS.GRADE_MASTER,
    GatewayCapacitePro.TypeJustificatifIAS.DIPLOME_ETRANGER_MASTER,
    GatewayCapacitePro.TypeJustificatifIAS.ECOLE_COMMERCE,
    GatewayCapacitePro.TypeJustificatifIAS.STAGE_PRO_NIVEAU_1,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_2_ANS_CADRE,
    GatewayCapacitePro.TypeJustificatifIAS.EXP_PRO_4_ANS_NON_CADRE,
  ],
};

export const labelsByTypeJustificatifIAS: Record<
  GatewayCapacitePro.TypeJustificatifIAS,
  string
> = {
  GRADE_MASTER: "Master ou Niveau 7",
  DIPLOME_ETRANGER_MASTER: "Diplôme étranger de niveau Master",
  ECOLE_COMMERCE: "Ecole de commerce",
  LICENCE_313: "Licence 313",
  BTS_313: "BTS 313",
  CQP_313: "CQP 313",
  EXP_PRO_1_AN_CADRE: "Exp pro 1 an cadre",
  EXP_PRO_2_ANS_CADRE: "Exp pro 2 ans cadre",
  EXP_PRO_2_ANS_NON_CADRE: "Exp pro 2 ans non cadre",
  EXP_PRO_4_ANS_NON_CADRE: "Exp pro 4 ans non cadre",
  EXP_PRO_6_MOIS: "Exp pro 6 mois",
  STAGE_PRO_NIVEAU_1: "Stage professionnel de 150h de Niv. 1",
  STAGE_PRO_NIVEAU_2: "Stage professionnel de 150h de Niv. 2",
  FORMATION_NIVEAU_3: "Formation niv. 3",
};

export function getTypeJustificatifOptionsIAS(
  selectedCategorie: GatewayCapacitePro.CategorieIAS | null | undefined
) {
  if (!selectedCategorie) return [];
  return recordToSelectOptions(
    typeJustificatifAvailableByCategorieIAS[selectedCategorie].reduce(
      (record, typeJustificatif) => {
        record[typeJustificatif] =
          labelsByTypeJustificatifIAS[typeJustificatif];
        return record;
      },
      {} as Record<GatewayCapacitePro.TypeJustificatifIAS, string>
    )
  );
}

export const capaciteProIASSchema = zod.object({
  datePriseFonction: zod
    .date()
    .refine(
      (v) => isBeforeToday(v),
      " La date doit être inférieure à aujourd'hui"
    ),
  capacite: zod
    .object({
      conditionCapaciteProfessionnelle: zod.boolean(),
      niveau: zod.nativeEnum(GatewayCapacitePro.CapaciteProNiveauIAS).nullish(),
      statutProfessionel: zod
        .nativeEnum(GatewayCapacitePro.StatusProfessionel)
        .nullish(),
      categorie: zod.nativeEnum(GatewayCapacitePro.CategorieIAS).nullish(),
      gradeDiplome: zod.nativeEnum(GatewayCapacitePro.GradeDiplome).nullish(),
      nomDiplome: zod.string().nullish(),
      typeJustificatif: zod
        .nativeEnum(GatewayCapacitePro.TypeJustificatifIAS)
        .nullish(),
    })
    .superRefine((data, ctx) => {
      if (data.conditionCapaciteProfessionnelle) {
        if (isNil(data.niveau))
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["niveau"],
            message: "Champ requis",
          });

        if (isNil(data.statutProfessionel))
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["statutProfessionel"],
            message: "Champ requis",
          });

        if (isNil(data.categorie))
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["categorie"],
            message: "Champ requis",
          });

        if (isNil(data.gradeDiplome))
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["gradeDiplome"],
            message: "Champ requis",
          });

        if (isNil(data.nomDiplome))
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["nomDiplome"],
            message: "Champ requis",
          });

        if (isNil(data.typeJustificatif))
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            path: ["typeJustificatif"],
            message: "Champ requis",
          });
      }
    }),
});
