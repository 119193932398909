import {
  GatewayConformite,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import {
  activitesFromEntreprise,
  isIas,
  isIobsp,
} from "@src/helper/entreprise.helper";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { useControleQuinquennal } from "@src/store/store.controleQuinquennal";
import { useEntreprises } from "@src/store/store.entreprise";
import { useMe } from "@src/store/store.me";
import { chain } from "lodash";
import { useMemo } from "react";
import {
  Sidebar,
  SidebarDelimiter,
  SidebarLink,
  SidebarSubtitle,
} from "../SideBar/SideBar";

const activitesOrder: Record<GatewayInfoSocieteOrias.Categorie, number> = {
  [GatewayInfoSocieteOrias.Categorie.COA]: 1,
  [GatewayInfoSocieteOrias.Categorie.MIA]: 2,
  [GatewayInfoSocieteOrias.Categorie.COBSP]: 3,
  [GatewayInfoSocieteOrias.Categorie.MIOBSP]: 4,
};
const axeRCProByCategorie: Record<
  GatewayInfoSocieteOrias.Categorie,
  GatewayConformite.AxeConformite
> = {
  [GatewayInfoSocieteOrias.Categorie.COA]:
    GatewayConformite.AxeConformite.RC_PRO_COA,
  [GatewayInfoSocieteOrias.Categorie.COBSP]:
    GatewayConformite.AxeConformite.RC_PRO_COBSP,
  [GatewayInfoSocieteOrias.Categorie.MIA]:
    GatewayConformite.AxeConformite.RC_PRO_MIA,
  [GatewayInfoSocieteOrias.Categorie.MIOBSP]:
    GatewayConformite.AxeConformite.RC_PRO_MIOBSP,
};
export function MainPageSidebar() {
  const { hasAllowedAxeConformite } = useIsAllowed();
  const { me } = useMe();
  const { entreprisesBySiren } = useEntreprises();
  const { hasControlesQuinquennaux } = useControleQuinquennal();
  const activites = useMemo(
    () =>
      chain(entreprisesBySiren)
        .flatMap<GatewayInfoSocieteOrias.Categorie | undefined>((entreprise) =>
          activitesFromEntreprise(entreprise)
        )
        .filter((activite) => activite !== undefined)
        .uniq()
        .sort((activite1, activite2) => {
          if (!activite1 || !activite2) return 0;
          return activitesOrder[activite1] - activitesOrder[activite2];
        })
        .value() as GatewayInfoSocieteOrias.Categorie[],
    [entreprisesBySiren]
  );
  const conformitesRcPro = activites
    .filter((activite) => {
      return hasAllowedAxeConformite(axeRCProByCategorie[activite]);
    })
    .map((activite) => (
      <SidebarLink
        key={activite}
        to={`/conformite/rc-pro/${activite.toLowerCase()}`}
      >
        <>RC Pro {activite}</>
      </SidebarLink>
    ))
    .sort();

  const getSocieteLabel = () => {
    if (!me) return "Société(s)";
    if (
      me.entreprisePrincipale.typeAdhesion ===
      GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE
    ) {
      return "Société";
    }
    return "Sociétés";
  };

  return (
    <Sidebar>
      <SidebarLink to="/" precise>
        Accueil
      </SidebarLink>
      <SidebarLink to="/societes">{getSocieteLabel()}</SidebarLink>
      <SidebarLink to="/personnes-assujetties">
        Personnes assujetties
      </SidebarLink>
      {hasControlesQuinquennaux && (
        <SidebarLink to="/controle-quinquennal">
          Contrôle quinquennal
        </SidebarLink>
      )}
      <SidebarDelimiter />
      <SidebarSubtitle>Axes de conformité</SidebarSubtitle>
      {hasAllowedAxeConformite(
        GatewayConformite.AxeConformite.HONORABILITE
      ) && (
        <SidebarLink to="/conformite/honorabilite">Honorabilité</SidebarLink>
      )}
      {isIas(activites) &&
        hasAllowedAxeConformite(
          GatewayConformite.AxeConformite.CAPACITE_PRO_IAS
        ) && (
          <SidebarLink to="/conformite/capacite-professionnelle/ias">
            Capacité professionnelle IAS
          </SidebarLink>
        )}
      {isIobsp(activites) &&
        hasAllowedAxeConformite(
          GatewayConformite.AxeConformite.CAPACITE_PRO_IOBSP
        ) && (
          <SidebarLink to="/conformite/capacite-professionnelle/iobsp">
            Capacité professionnelle IOBSP
          </SidebarLink>
        )}
      {isIas(activites) &&
        hasAllowedAxeConformite(
          GatewayConformite.AxeConformite.FORMATION_CONTINUE_IAS
        ) && (
          <SidebarLink to="/conformite/formation-continue/ias">
            Formation continue IAS
          </SidebarLink>
        )}
      {isIobsp(activites) &&
        hasAllowedAxeConformite(
          GatewayConformite.AxeConformite.FORMATION_CONTINUE_IOBSP
        ) && (
          <SidebarLink to="/conformite/formation-continue/iobsp">
            Formation continue IOBSP
          </SidebarLink>
        )}
      {conformitesRcPro}
      {isIas(activites) &&
        hasAllowedAxeConformite(
          GatewayConformite.AxeConformite.GARANTIE_FINANCIERE_IAS
        ) && (
          <SidebarLink to="/conformite/garantie-financiere/ias">
            Garantie financière IAS
          </SidebarLink>
        )}
      {isIobsp(activites) &&
        hasAllowedAxeConformite(
          GatewayConformite.AxeConformite.GARANTIE_FINANCIERE_IOBSP
        ) && (
          <SidebarLink to="/conformite/garantie-financiere/iobsp">
            Garantie financière IOBSP
          </SidebarLink>
        )}
      {isIas(activites) &&
        hasAllowedAxeConformite(
          GatewayConformite.AxeConformite.DECLARATION_ACTIVITE_IAS
        ) && (
          <SidebarLink to="/conformite/declaration-activite/ias">
            Déclaration activité IAS
          </SidebarLink>
        )}
      {isIobsp(activites) &&
        hasAllowedAxeConformite(
          GatewayConformite.AxeConformite.DECLARATION_ACTIVITE_IOBSP
        ) && (
          <SidebarLink to="/conformite/declaration-activite/iobsp">
            Déclaration activité IOBSP
          </SidebarLink>
        )}
    </Sidebar>
  );
}
