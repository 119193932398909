import { GatewayCapacitePro } from "@conformite/gateway";
import {
  Button,
  FormAction,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayCapaciteProApi } from "@src/api/conformite/capacitePro.api";
import { ReactComponent as ChevronLeftIcon } from "@src/assets/icons/chevron-left.svg";
import { ReactComponent as SaveIcon } from "@src/assets/icons/save.svg";
import { isMono } from "@src/helper/entreprise.helper";
import {
  AssujettiParcoursConformiteEnum,
  ConformiteStepComponentProps,
  useChangeStepConformite,
} from "../../../AssujettiParcoursConformite";
import {
  ComponentWithFormData,
  WithFormData,
} from "../../../Components/ComponentWithFormData";
import { FormParcoursConformite } from "../../../Components/FormParcoursConformite";
import styles from "./ConformiteFormCapaciteProIAS.module.scss";
import {
  ConformiteFormCapaciteProIASContent,
  useCapaciteProIAS,
} from "./ConformiteFormCapaciteProIASContent";

export function ConformiteFormCapaciteProIASWithData({
  data,
  formData,
  setFormData,
  changeStep,
  closePane,
  entreprise,
  readonly,
}: WithFormData<
  ConformiteStepComponentProps,
  GatewayCapacitePro.CapaciteProfessionnelleIAS
>) {
  const isMonoActivite = entreprise
    ? isMono(entreprise.categoriesActivites)
    : false;
  const { goNext, goPrev } = useChangeStepConformite(
    AssujettiParcoursConformiteEnum.CAPACITE_PRO_IAS,
    changeStep,
    closePane,
    entreprise
  );
  const {
    formConfig,
    typeJustificatifOptions,
    conditionCapaciteProfessionnelleValue,
    onSubmit,
  } = useCapaciteProIAS(data, formData, isMonoActivite, setFormData);
  return (
    <FormParcoursConformite
      validateOnMount={formData !== null}
      readonly={readonly}
      renderActions={(submitAnyway) => (
        <FormAction className={styles.actions}>
          <Button
            variant="text"
            onClick={() => {
              submitAnyway().finally(goPrev);
            }}
            type="button"
            iconVariant="left"
            icon={<ChevronLeftIcon />}
            disabled={formConfig.formState.isSubmitting}
          >
            Précédent
          </Button>
          <div className={styles.actionsRight}>
            <Button
              variant="text"
              onClick={() => {
                submitAnyway().finally(closePane);
              }}
              type="button"
              iconVariant="left"
              icon={<SaveIcon />}
              disabled={formConfig.formState.isSubmitting}
            >
              {readonly ? "Fermer" : "Sauvegarder et fermer"}
            </Button>
            <Button
              type="button"
              onClick={() => {
                submitAnyway().finally(goNext);
              }}
              disabled={formConfig.formState.isSubmitting}
            >
              Suivant
            </Button>
          </div>
        </FormAction>
      )}
      formConfig={formConfig}
      onSubmit={onSubmit}
    >
      <ConformiteFormCapaciteProIASContent
        conditionCapaciteProfessionnelleValue={
          conditionCapaciteProfessionnelleValue
        }
        typeJustificatifOptions={typeJustificatifOptions}
        formConfig={formConfig}
        data={data}
        isMonoActivite={isMonoActivite}
      />
    </FormParcoursConformite>
  );
}

export function ConformiteFormCapaciteProIAS({
  data,
  ...otherProps
}: ConformiteStepComponentProps) {
  return (
    <ComponentWithFormData
      FormComponent={ConformiteFormCapaciteProIASWithData}
      formProps={{ data, ...otherProps }}
      fetchData={async () =>
        GatewayCapaciteProApi.getIAS(
          data.assujetti.id,
          data.assujetti.millesime
        )
      }
    />
  );
}
