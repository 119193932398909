import {
  GatewayAvancement,
  GatewayControleQuinquennal,
  GatewayEntreprise,
} from "@conformite/gateway";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayAvancementApi } from "@src/api/avancement.api";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { GatewayEntrepriseApi } from "@src/api/entreprise2.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { useOnce } from "@src/hooks/useOnce";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

type TState =
  | {
      status: "init" | "loading";
      controleQuinquennal?: undefined;
      entreprise?: undefined;
      avancement?: undefined;
      axes: [];
    }
  | {
      status: "loaded";
      controleQuinquennal: GatewayControleQuinquennal.ControleQuinquennalWithAxes;
      entreprise: GatewayEntreprise.EntreprisePrincipaleResponseType;
      axes: GatewayControleQuinquennal.ControleQuinquennalAxeWithFiles[];
      avancement: GatewayAvancement.AvancementResponseType;
    };

export const useControleQuinquennalAdmin = () => {
  const { idEntreprise } = useParams<{ idEntreprise: string }>();
  const [state, setState] = useState<TState>({
    status: "init",
    axes: [],
  });

  const retrieveData = useCallback(async () => {
    if (!idEntreprise) return;
    setState({ status: "loading", axes: [] });

    try {
      const controleQuinquennalResponse =
        await ControleQuinquennalApi.getControleQuinquennalAxeByEntrepriseId(
          idEntreprise
        );

      const { millesime } = controleQuinquennalResponse;

      const entrepriseResponse =
        await GatewayEntrepriseApi.getByIdAndMillesimeForAdmin(idEntreprise);

      const avancementResponse =
        await GatewayAvancementApi.getAvancementForAdmin(
          entrepriseResponse.siren,
          millesime
        );

      const controleQuinquennalAxes =
        await ControleQuinquennalApi.listControleQuinquennalAxes(
          controleQuinquennalResponse.id
        );

      setState({
        controleQuinquennal: controleQuinquennalResponse,
        entreprise: entrepriseResponse,
        avancement: avancementResponse,
        axes: controleQuinquennalAxes,
        status: "loaded",
      });
    } catch (err) {
      handleGatewayError({
        onResponse: (e) => {
          displayErrorToast(e.response.data.message);
        },
        onUnhandled: () => {
          displayErrorToast("Une erreur inconnue est survenue");
        },
      })(err);
    }
  }, [idEntreprise]);

  useOnce(() => {
    retrieveData().catch(console.error);
  });

  return {
    ...state,
    idEntreprise,
    resetControleQuinquennalData: retrieveData,
    addFileToAxe: (
      idAxe: string,
      file: GatewayControleQuinquennal.ControleQuinquennalAxeFile
    ) => {
      if (state.status !== "loaded") return;

      setState((prev) => {
        if (prev.status !== "loaded") return prev; // should never happen but TS doesn't know that
        return {
          ...prev,
          axes: prev.axes.map((axe) => {
            if (axe.idAxe !== idAxe) return axe;
            return {
              ...axe,
              controleQuinquennalAxeFiles: [
                ...axe.controleQuinquennalAxeFiles,
                file,
              ],
            };
          }),
        };
      });
    },
  };
};
