import { GatewayAdresse } from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputSelect,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";

export const typeVoieRecord: Record<GatewayAdresse.TypeVoie, string> = {
  [GatewayAdresse.TypeVoie.ABBAYE]: "Abbaye",
  [GatewayAdresse.TypeVoie.ANCIEN_CHEMIN]: "Ancien chemin",
  [GatewayAdresse.TypeVoie.AERODROME_AEROPORT]: "Aérodrome, Aéroport",
  [GatewayAdresse.TypeVoie.AIRE]: "Aire",
  [GatewayAdresse.TypeVoie.ALLEE]: "Allée",
  [GatewayAdresse.TypeVoie.ANSE]: "Anse",
  [GatewayAdresse.TypeVoie.ANCIENNE_RUE]: "Ancienne Rue",
  [GatewayAdresse.TypeVoie.ARCADE]: "Arcade",
  [GatewayAdresse.TypeVoie.ANCIENNE_ROUTE]: "Ancienne route",
  [GatewayAdresse.TypeVoie.AUTOROUTE]: "Autoroute",
  [GatewayAdresse.TypeVoie.AVENUE]: "Avenue",
  [GatewayAdresse.TypeVoie.BALCON]: "Balcon",
  [GatewayAdresse.TypeVoie.BASTION]: "Bastion",
  [GatewayAdresse.TypeVoie.BASE]: "Base",
  [GatewayAdresse.TypeVoie.BATIMENT]: "Bâtiment",
  [GatewayAdresse.TypeVoie.BAS_CHEMIN]: "Bas Chemin",
  [GatewayAdresse.TypeVoie.BOUCLE]: "Boucle",
  [GatewayAdresse.TypeVoie.BOULEVARD]: "Boulevard",
  [GatewayAdresse.TypeVoie.BERGE]: "Berge",
  [GatewayAdresse.TypeVoie.BOIS]: "Bois",
  [GatewayAdresse.TypeVoie.BRECHE]: "Brèche",
  [GatewayAdresse.TypeVoie.BARRIERE]: "Barrière",
  [GatewayAdresse.TypeVoie.BOURG]: "Bourg",
  [GatewayAdresse.TypeVoie.BRETTELLE]: "Bretelle",
  [GatewayAdresse.TypeVoie.BASTIDE]: "Bastide",
  [GatewayAdresse.TypeVoie.BUTTE]: "Butte",
  [GatewayAdresse.TypeVoie.CALE]: "Cale",
  [GatewayAdresse.TypeVoie.CAMP]: "Camp",
  [GatewayAdresse.TypeVoie.CANAL]: "Canal",
  [GatewayAdresse.TypeVoie.CARREFOUR]: "Carrefour",
  [GatewayAdresse.TypeVoie.CARRIERE]: "Carrière",
  [GatewayAdresse.TypeVoie.CARREAU]: "Carreau",
  [GatewayAdresse.TypeVoie.CHEMIN_COMMUNAL]: "Chemin communal",
  [GatewayAdresse.TypeVoie.CENTRE_COMMERCIAL]: "Centre Commercial",
  [GatewayAdresse.TypeVoie.CHEMIN_DEPARTEMENTAL]: "Chemin départemental",
  [GatewayAdresse.TypeVoie.CEINTURE]: "Ceinture",
  [GatewayAdresse.TypeVoie.CAMPAGNE]: "Campagne",
  [GatewayAdresse.TypeVoie.CHEMIN]: "Chemin",
  [GatewayAdresse.TypeVoie.CHEMINEMENT]: "Cheminement",
  [GatewayAdresse.TypeVoie.CHEZ]: "Chez",
  [GatewayAdresse.TypeVoie.CHARMILLE]: "Charmille",
  [GatewayAdresse.TypeVoie.CHALET]: "Chalet",
  [GatewayAdresse.TypeVoie.CHAPELLE]: "Chapelle",
  [GatewayAdresse.TypeVoie.CHAUSSEE]: "Chaussée",
  [GatewayAdresse.TypeVoie.CHATEAU]: "Château",
  [GatewayAdresse.TypeVoie.CITE]: "Cité",
  [GatewayAdresse.TypeVoie.CLOS]: "Clos",
  [GatewayAdresse.TypeVoie.COL]: "Col",
  [GatewayAdresse.TypeVoie.COLLINE]: "Colline",
  [GatewayAdresse.TypeVoie.CORNICHE]: "Corniche",
  [GatewayAdresse.TypeVoie.CORON]: "Coron",
  [GatewayAdresse.TypeVoie.COTE]: "Côte",
  [GatewayAdresse.TypeVoie.COTEAU]: "Coteau",
  [GatewayAdresse.TypeVoie.COTTAGE]: "Cottage",
  [GatewayAdresse.TypeVoie.COUR]: "Cour",
  [GatewayAdresse.TypeVoie.CAMPING]: "Camping",
  [GatewayAdresse.TypeVoie.CHEMIN_RURAL]: "Chemin rural",
  [GatewayAdresse.TypeVoie.COURS]: "Cours",
  [GatewayAdresse.TypeVoie.CASTEL]: "Castel",
  [GatewayAdresse.TypeVoie.CONTOUR]: "Contour",
  [GatewayAdresse.TypeVoie.CENTRE]: "Centre",
  [GatewayAdresse.TypeVoie.COURSIVE]: "Coursive",
  [GatewayAdresse.TypeVoie.CHEMIN_VICINAL]: "Chemin Vicinal",
  [GatewayAdresse.TypeVoie.DEPARTEMENTALE]: "Départementale",
  [GatewayAdresse.TypeVoie.DIGUE]: "Digue",
  [GatewayAdresse.TypeVoie.DOMAINE]: "Domaine",
  [GatewayAdresse.TypeVoie.DESCENTE]: "Descente",
  [GatewayAdresse.TypeVoie.ECART]: "Écart",
  [GatewayAdresse.TypeVoie.ECLUSE]: "Écluse",
  [GatewayAdresse.TypeVoie.EGLISE]: "Église",
  [GatewayAdresse.TypeVoie.ENCEINTE]: "Enceinte",
  [GatewayAdresse.TypeVoie.ENCLOS]: "Enclos",
  [GatewayAdresse.TypeVoie.ENCLAVE]: "Enclave",
  [GatewayAdresse.TypeVoie.ESCALIER]: "Escalier",
  [GatewayAdresse.TypeVoie.ESPLANADE]: "Esplanade",
  [GatewayAdresse.TypeVoie.ESPACE]: "Espace",
  [GatewayAdresse.TypeVoie.FAUBOURG]: "Faubourg",
  [GatewayAdresse.TypeVoie.FONTAINE]: "Fontaine",
  [GatewayAdresse.TypeVoie.FORUM]: "Forum",
  [GatewayAdresse.TypeVoie.FORT]: "Fort",
  [GatewayAdresse.TypeVoie.FOSSE]: "Fosse",
  [GatewayAdresse.TypeVoie.FERME]: "Ferme",
  [GatewayAdresse.TypeVoie.GALERIE]: "Galerie",
  [GatewayAdresse.TypeVoie.GARE]: "Gare",
  [GatewayAdresse.TypeVoie.GRAND_BOULEVARD]: "Grand boulevard",
  [GatewayAdresse.TypeVoie.GROUPE]: "Groupe",
  [GatewayAdresse.TypeVoie.GRANDE_PLACE]: "Grande Place",
  [GatewayAdresse.TypeVoie.GROUPEMENT]: "Groupement",
  [GatewayAdresse.TypeVoie.GRANDE_RUE]: "Grande rue",
  [GatewayAdresse.TypeVoie.GRILLE]: "Grille",
  [GatewayAdresse.TypeVoie.HAMEAU]: "Hameau",
  [GatewayAdresse.TypeVoie.HAUT_CHEMIN]: "HatypeVoieOptionsut Chemin",
  [GatewayAdresse.TypeVoie.HIPPODROME]: "Hippodrome",
  [GatewayAdresse.TypeVoie.HALLE]: "Halle",
  [GatewayAdresse.TypeVoie.HALAGE]: "Halage",
  [GatewayAdresse.TypeVoie.HLM]: "HLM",
  [GatewayAdresse.TypeVoie.ILE]: "Île",
  [GatewayAdresse.TypeVoie.ILOT]: "Îlot",
  [GatewayAdresse.TypeVoie.IMMEUBLE]: "Immeuble",
  [GatewayAdresse.TypeVoie.IMPASSE]: "Impasse",
  [GatewayAdresse.TypeVoie.JARDIN]: "Jardin",
  [GatewayAdresse.TypeVoie.JETEE]: "Jetée",
  [GatewayAdresse.TypeVoie.LIEU_DIT]: "Lieu-dit",
  [GatewayAdresse.TypeVoie.LEVEE]: "Levée",
  [GatewayAdresse.TypeVoie.LOTISSEMENT]: "Lotissement",
  [GatewayAdresse.TypeVoie.MAIL]: "Mail",
  [GatewayAdresse.TypeVoie.MAISON]: "Maison",
  [GatewayAdresse.TypeVoie.MARCHE]: "Marché",
  [GatewayAdresse.TypeVoie.MAS]: "Mas",
  [GatewayAdresse.TypeVoie.MOULIN]: "Moulin",
  [GatewayAdresse.TypeVoie.MONTEE]: "Montée",
  [GatewayAdresse.TypeVoie.MUSEE]: "Musée",
  [GatewayAdresse.TypeVoie.NOUVELLE_ROUTE]: "Nouvelle Route",
  [GatewayAdresse.TypeVoie.PETITE_AVENUE]: "Petite Avenue",
  [GatewayAdresse.TypeVoie.PALAIS]: "Palais",
  [GatewayAdresse.TypeVoie.PARC]: "Parc",
  [GatewayAdresse.TypeVoie.PASSAGE]: "Passage",
  [GatewayAdresse.TypeVoie.PASSE]: "Passe",
  [GatewayAdresse.TypeVoie.PATIO]: "Patio",
  [GatewayAdresse.TypeVoie.PAVILLON]: "Pavillon",
  [GatewayAdresse.TypeVoie.PETIT_CHEMIN]: "Petit Chemin",
  [GatewayAdresse.TypeVoie.PERIPHERIQUE]: "Périphérique",
  [GatewayAdresse.TypeVoie.PETITE_IMPASSE]: "Petite Impasse",
  [GatewayAdresse.TypeVoie.PISTE]: "Piste",
  [GatewayAdresse.TypeVoie.PARKING]: "Parking",
  [GatewayAdresse.TypeVoie.PLACE]: "Place",
  [GatewayAdresse.TypeVoie.PLAN]: "Plan",
  [GatewayAdresse.TypeVoie.PLACIS]: "Placis",
  [GatewayAdresse.TypeVoie.PASSERELLE]: "Passerelle",
  [GatewayAdresse.TypeVoie.PLAINE]: "Plaine",
  [GatewayAdresse.TypeVoie.PLATEAU]: "Plateau",
  [GatewayAdresse.TypeVoie.PASSAGE_A_NIVEAU]: "Passage à Niveau",
  [GatewayAdresse.TypeVoie.POINTE]: "Pointe",
  [GatewayAdresse.TypeVoie.PONT]: "Pont",
  [GatewayAdresse.TypeVoie.PORT]: "Port",
  [GatewayAdresse.TypeVoie.POTERNE]: "Poterne",
  [GatewayAdresse.TypeVoie.PETITE_RUE]: "Petite Rue",
  [GatewayAdresse.TypeVoie.PRE]: "Pré",
  [GatewayAdresse.TypeVoie.PROMENADE]: "Promenade",
  [GatewayAdresse.TypeVoie.PRESQU_ILE]: "Presqu_île",
  [GatewayAdresse.TypeVoie.PETITE_ROUTE]: "Petite Route",
  [GatewayAdresse.TypeVoie.PARVIS]: "Parvis",
  [GatewayAdresse.TypeVoie.PETITE_ALLEE]: "Petite Allée",
  [GatewayAdresse.TypeVoie.PORTE]: "Porte",
  [GatewayAdresse.TypeVoie.PLACETTE]: "Placette",
  [GatewayAdresse.TypeVoie.QUAI]: "Quai",
  [GatewayAdresse.TypeVoie.QUARTIER]: "Quartier",
  [GatewayAdresse.TypeVoie.RUE]: "Rue",
  [GatewayAdresse.TypeVoie.RACCOURCI]: "Raccourci",
  [GatewayAdresse.TypeVoie.RAIDILLON]: "Raidillon",
  [GatewayAdresse.TypeVoie.RONDE]: "Ronde",
  [GatewayAdresse.TypeVoie.REMPART]: "Rempart",
  [GatewayAdresse.TypeVoie.RESIDENCE]: "Résidence",
  [GatewayAdresse.TypeVoie.RUELLE]: "Ruelle",
  [GatewayAdresse.TypeVoie.ROUTE_NATIONALE]: "Route Nationale",
  [GatewayAdresse.TypeVoie.RANGE]: "Rangée",
  [GatewayAdresse.TypeVoie.ROCADE]: "Rocade",
  [GatewayAdresse.TypeVoie.RAMPE]: "Rampe",
  [GatewayAdresse.TypeVoie.ROND_POINT]: "Rond Point",
  [GatewayAdresse.TypeVoie.ROTONDE]: "Rotonde",
  [GatewayAdresse.TypeVoie.ROUTE]: "Route",
  [GatewayAdresse.TypeVoie.RUELLETTE]: "Ruellette",
  [GatewayAdresse.TypeVoie.RUETTE]: "Ruette",
  [GatewayAdresse.TypeVoie.SENTIER]: "Sentier",
  [GatewayAdresse.TypeVoie.SENTE]: "Sente",
  [GatewayAdresse.TypeVoie.SQUARE]: "Square",
  [GatewayAdresse.TypeVoie.STATION]: "Station",
  [GatewayAdresse.TypeVoie.STADE]: "Stade",
  [GatewayAdresse.TypeVoie.TOUR]: "Tour",
  [GatewayAdresse.TypeVoie.TERRE_PLEIN]: "Terre-Plein",
  [GatewayAdresse.TypeVoie.TRAVERSE]: "Traverse",
  [GatewayAdresse.TypeVoie.TERRAIN]: "Terrain",
  [GatewayAdresse.TypeVoie.TERTRE]: "Tertre",
  [GatewayAdresse.TypeVoie.TERRASSE]: "Terrasse",
  [GatewayAdresse.TypeVoie.VALLEE]: "Vallée",
  [GatewayAdresse.TypeVoie.VOIE_COMMUNALE]: "Voie Communale",
  [GatewayAdresse.TypeVoie.VIEUX_CHEMIN]: "Vieux Chemin",
  [GatewayAdresse.TypeVoie.VENELLE]: "Venelle",
  [GatewayAdresse.TypeVoie.VIA]: "Via",
  [GatewayAdresse.TypeVoie.VILLA]: "Villa",
  [GatewayAdresse.TypeVoie.VILLAGE]: "Village",
  [GatewayAdresse.TypeVoie.VOIE]: "Voie",
  [GatewayAdresse.TypeVoie.VIEILLE_RUE]: "Vieille Rue",
  [GatewayAdresse.TypeVoie.VIEILLE_ROUTE]: "Vieille Route",
  [GatewayAdresse.TypeVoie.ZONE_ARTISANALE]: "Zone Artisanale",
  [GatewayAdresse.TypeVoie.ZONE_AMENAGEMENT_CONCERTE]:
    "Zone Aménagement Concerté",
  [GatewayAdresse.TypeVoie.ZONE_AMENAGEMENT_DIFFERE]:
    "Zone Aménagement Différé",
  [GatewayAdresse.TypeVoie.ZONE_INDUSTRIELLE]: "Zone Industrielle",
  [GatewayAdresse.TypeVoie.ZONE]: "Zone",
  [GatewayAdresse.TypeVoie.ZONE_A_URBANISER_EN_PRIORITE]:
    "Zone à Urbaniser en Priorité",
};

export const paysRecord: Record<GatewayAdresse.Pays, string> = {
  ALLEMAGNE: "Allemagne",
  AUTRICHE: "Autriche",
  BELGIQUE: "Belgique",
  CROATIE: "Croatie",
  DANEMARK: "Danemark",
  ESPAGNE: "Espagne",
  ESTONIE: "Estonie",
  FINLANDE: "Finlande",
  FRANCE: "France",
  GRECE: "Grece",
  HONGRIE: "Hongrie",
  ISLANDE: "Islande",
  ITALIE: "Italie",
  LETTONIE: "Lettonie",
  LIECHTENSTEIN: "Liechtenstein",
  LITUANIE: "Lituanie",
  LUXEMBOURG: "Luxembourg",
  MALTE: "Malte",
  NORVEGE: "Norvege",
  PAYS_BAS: "Pays_bas",
  POLOGNE: "Pologne",
  PORTUGAL: "Portugal",
  REPUBLIQUE_TCHEQUE: "Republique_tcheque",
  SLOVAQUIE: "Slovaquie",
  SLOVENIE: "Slovenie",
  SUEDE: "Suede",
  SUISSE: "Suisse",
};

export const typeVoieOptions = recordToSelectOptions(typeVoieRecord);

export const paysOptions = recordToSelectOptions(paysRecord);

export const entrepriseAdresseSchema = zod.object({
  typeVoie: zod.nativeEnum(GatewayAdresse.TypeVoie),
  libelleVoie: zod.string().min(1),
  numeroVoie: zod.string().optional(),
  complementAdresse: zod.string().optional(),
  codePostal: zod
    .string({ errorMap: () => ({ message: "Requis" }) })
    .min(1, { message: "Requis" })
    .refine((v) => /^\d{5}$/.test(v), "Code postal invalide"),
  ville: zod.string().min(1),
  pays: zod.nativeEnum(GatewayAdresse.Pays),
});

export type EntrepriseAdresseFormValues = zod.infer<
  typeof entrepriseAdresseSchema
>;
type EntrepriseAdresseFormProps = {
  defaultValues: Partial<EntrepriseAdresseFormValues>;
  onSubmit: (response: EntrepriseAdresseFormValues) => void;
  formId?: string;
};

export function EntrepriseAdresseForm({
  defaultValues,
  onSubmit,
  formId,
}: EntrepriseAdresseFormProps) {
  const formConfig = useFormConfig<
    typeof entrepriseAdresseSchema,
    EntrepriseAdresseFormValues
  >({
    schema: entrepriseAdresseSchema,
    defaultValues,
  });

  return (
    <Form formConfig={formConfig} onSubmit={onSubmit} id={formId}>
      <FormRow>
        <FormField>
          <InputSelect<EntrepriseAdresseFormValues, GatewayAdresse.TypeVoie>
            isSearchable
            name="typeVoie"
            label="Type de voie"
            placeholder="Sélectionner"
            options={typeVoieOptions}
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputText<EntrepriseAdresseFormValues>
            label="Libellé de la voie"
            name="libelleVoie"
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputText<EntrepriseAdresseFormValues>
            label="Numéro de rue"
            name="numeroVoie"
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputText<EntrepriseAdresseFormValues>
            label="Complément d'adresse"
            name="complementAdresse"
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField size="quarter">
          <InputText<EntrepriseAdresseFormValues>
            label="Code postal"
            name="codePostal"
            required
          />
        </FormField>
        <FormField size="three-quarter">
          <InputText<EntrepriseAdresseFormValues>
            label="Ville"
            name="ville"
            required
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputSelect<EntrepriseAdresseFormValues, GatewayAdresse.Pays>
            name="pays"
            label="Pays"
            placeholder="Sélectionner"
            options={paysOptions}
            required
          />
        </FormField>
      </FormRow>
    </Form>
  );
}
