import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import { ParcoursAdhesionSaveAndQuitAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionSaveAndQuitAction";
import {
  AdhesionStepEnum,
  ID_FORM_ADHESION,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  EntrepriseSousCategoriesActiviteForm,
  EntrepriseSousCategoriesActiviteFormHandle,
} from "@src/societes/form/EntrepriseSousCategoriesActiviteForm";
import { useRef } from "react";

export function AdhesionSousCategoriesActivitePage({
  data,
  addData,
  changeStep,
  handleQuit,
}: ParcoursAdhesionStepComponentProps) {
  const needCOBSP =
    data.informationsActivite.categories?.includes(
      GatewayInfoSocieteOrias.Categorie.COBSP
    ) ?? false;
  const needMIOBSP =
    data.informationsActivite.categories?.includes(
      GatewayInfoSocieteOrias.Categorie.MIOBSP
    ) ?? false;
  const formRef = useRef<EntrepriseSousCategoriesActiviteFormHandle>(null);

  return (
    <ParcoursFullPageTemplate
      title="Informations sur l'activité"
      actions={
        <>
          <ParcoursAdhesionSaveAndQuitAction
            addData={addData}
            formConfig={() => formRef?.current?.getFormConfig()}
            handleQuit={handleQuit}
            transformValues={(formValues) => ({
              informationsActivite: {
                ...data.informationsActivite,
                sousCategoriesCOBSP: formValues.sousCategoriesCOBSP,
                sousCategoriesMIOBSP: formValues.sousCategoriesMIOBSP,
              },
            })}
          />
          <ParcoursAdhesionNextAction type="submit">
            Suivant
          </ParcoursAdhesionNextAction>
        </>
      }
    >
      <EntrepriseSousCategoriesActiviteForm
        ref={formRef}
        onSubmit={({ sousCategoriesCOBSP, sousCategoriesMIOBSP }) => {
          addData({
            informationsActivite: {
              ...data.informationsActivite,
              sousCategoriesCOBSP,
              sousCategoriesMIOBSP,
            },
          });
          changeStep(AdhesionStepEnum.ORGANISATION_PROFESSIONNELLE);
        }}
        defaultValues={{
          needCOBSP,
          needMIOBSP,
          sousCategoriesCOBSP: needCOBSP
            ? data.informationsActivite.sousCategoriesCOBSP
            : [],
          sousCategoriesMIOBSP: needMIOBSP
            ? data.informationsActivite.sousCategoriesMIOBSP
            : [],
        }}
        formId={ID_FORM_ADHESION}
      />
    </ParcoursFullPageTemplate>
  );
}
