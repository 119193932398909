import {
  GatewayConformiteRcPro,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { FormGrid } from "@lya-protect/lya-protect-form-library/dist/UI";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { isIas } from "@src/helper/entreprise.helper";
import styles from "../ConformiteRcPro.module.scss";
import { ConformiteRcProEditFiles } from "./ConformiteRcProEditFiles";
import { ConformiteRcProEditProperties } from "./ConformiteRcProEditProperties";
import { useConformiteRcProEdit } from "./useConformiteRcProEdit";

type ConformiteRcProFormProps = {
  formConfig: ReturnType<typeof useConformiteRcProEdit>["formConfig"];
  onSubmit?: ReturnType<typeof useConformiteRcProEdit>["onSubmit"];
  properties: GatewayConformiteRcPro.ConformiteRcProProperty | null;
  hasRcpIndividuelleSouscritSetToNo: boolean;
  categorie: GatewayInfoSocieteOrias.Categorie;
  millesime: number;
};

export function ConformiteRcProForm({
  formConfig,
  onSubmit = () => Promise.resolve(),
  hasRcpIndividuelleSouscritSetToNo,
  properties,
  categorie,
  millesime,
}: ConformiteRcProFormProps) {
  return (
    <Form formConfig={formConfig} onSubmit={onSubmit}>
      <ConformiteRcProEditFiles
        form={formConfig}
        hasRcpIndividuelleSouscritSetToNo={hasRcpIndividuelleSouscritSetToNo}
      />
      <h4 className={styles.subtitle}>Questionnaire</h4>
      {millesime >= 2025 && isIas([categorie]) && (
        <InfoCard className={styles.infoCardReglement}>
          <a
            target="_blank"
            href="https://webgate.ec.europa.eu/regdel/#/delegatedActs/2279"
            rel="noreferrer"
          >
            Un règlement délégué
          </a>{" "}
          qui modifie la{" "}
          <a
            href="https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=celex%3A32016L0097"
            target="_blank"
            rel="noreferrer"
          >
            Directive sur la Distribution d&apos;Assurance
          </a>{" "}
          - DDA a modifié à effet du 9 octobre 2024 les montants minimum de
          couverture
        </InfoCard>
      )}
      <FormGrid disableHorizontalGutter>
        <ConformiteRcProEditProperties
          categorie={categorie}
          formConfig={formConfig}
          properties={properties}
          hasRcpIndividuelleSouscritSetToNo={hasRcpIndividuelleSouscritSetToNo}
        />
      </FormGrid>
    </Form>
  );
}
