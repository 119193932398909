import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as IconX } from "@src/assets/icons/x-lg.svg";
import classNames from "classnames";
import { ReactNode } from "react";
import style from "./infoCard.module.scss";

type InfoCardProps = {
  children: ReactNode;
  className?: string;
  type?: "info" | "warning" | "error" | "success" | "neutral";
} & (CancelableCardProps | NonCancelableCardProps);

type CancelableCardProps = {
  onCancel: () => void;
  cancelButtonClassName?: string;
};

type NonCancelableCardProps = {
  onCancel?: never;
  cancelButtonClassName?: never;
};

export function InfoCard({
  children,
  className,
  type = "info",
  onCancel,
  cancelButtonClassName,
}: InfoCardProps) {
  return (
    <Card
      variant="outline"
      className={classNames(style.infoCard, style[type], className)}
    >
      <div className={style.content}>{children}</div>
      {onCancel && (
        <Button
          onClick={onCancel}
          variant="text"
          icon={<IconX />}
          className={classNames(style.cancelButton, cancelButtonClassName)}
        />
      )}
    </Card>
  );
}
