import {
  Button,
  Card,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as DownloadIcon } from "@src/assets/icons/download.svg";
import { ReactNode } from "react";
import styles from "./AssujettiImportCorrection.module.scss";

type Step1Props = {
  onExport: () => void;
};

function Step1({ onExport }: Step1Props) {
  return (
    <div>
      <Button icon={<DownloadIcon />} variant="text" onClick={onExport}>
        Télécharger le fichier à corriger
      </Button>
      <p>Les erreurs sont indiquées en rouge dans le fichier.</p>
    </div>
  );
}

function Step2() {
  return <p>Faire les corrections dans le fichier.</p>;
}

type Step3Props = {
  importButton: ReactNode;
};

function Step3({ importButton }: Step3Props) {
  return importButton;
}

type EnterpriseImportCorrectionProps = Step1Props & Step3Props;

export function AssujettiImportCorrection({
  onExport,
  importButton,
}: EnterpriseImportCorrectionProps) {
  return (
    <Card>
      <FormGrid disableHorizontalGutter>
        <FormRow>
          <FormField>
            <h2 className={styles.titleProcedure}>Comment procéder ?</h2>
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <div className={styles.container}>
              <span className={styles.step}>1</span>
              <Step1 onExport={onExport} />
            </div>
          </FormField>
        </FormRow>

        <FormRow>
          <FormField>
            <div className={styles.container}>
              <span className={styles.step}>2</span>
              <Step2 />
            </div>
          </FormField>
        </FormRow>

        <FormRow>
          <FormField>
            <div className={styles.container}>
              <span className={styles.step}>3</span>
              <Step3 importButton={importButton} />
            </div>
          </FormField>
        </FormRow>
      </FormGrid>
    </Card>
  );
}
