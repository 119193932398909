import {
  GatewayAdresse,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
  GatewayPaiement,
} from "@conformite/gateway";

export type AdhesionDataEntreprisesItem = {
  siren?: string;
  numeroOrias?: string;
  statutOrias?: GatewayInfoSocieteOrias.StatutSociete;
  formeJuridique?: GatewayEntreprise.FormeJuridique;
  raisonSociale?: string;
  typeVoie?: GatewayAdresse.TypeVoie;
  libelleVoie?: string;
  numeroVoie?: string;
  complementAdresse?: string;
  codePostal?: string;
  ville?: string;
  pays?: GatewayAdresse.Pays;
  trancheEffectif?: GatewayEntreprise.TrancheEffectif;
  chiffreAffaire?: number;
  categoriesOrias?: GatewayInfoSocieteOrias.Categorie[];
  coaAccessoire?: boolean;
  miaAccessoire?: boolean;
  cobspAccessoire?: boolean;
  miobspAccessoire?: boolean;
  sousCategorieCobsp?: GatewayInfoSocieteOrias.ActiviteBancaire[];
  sousCategorieMiobsp?: GatewayInfoSocieteOrias.ActiviteBancaire[];
  organisationPro?: GatewayInfoSocieteOrias.OrganisationProfessionnelle[];
  categorieRepresentation?: GatewayInfoSocieteOrias.CategorieRepresentation;
  typeSouscripteur?: GatewayEntreprise.TypeSouscripteur;
  paymentInfos?: GatewayPaiement.InvoiceInfosResponseType;
  lastOriasFetch?: string;
  coupon?: string;
  index?: number;
  consentementEDI?: boolean;
};

export type AdhesionDataEntreprisesItemDoublons =
  AdhesionDataEntreprisesItem & {
    description: string;
    index: number;
  };

export type AdhesionDataEntreprisesItemError = AdhesionDataEntreprisesItem & {
  errors: { description: string; key: string }[];
  index: number;
};

export const AdhesionDataEntreprisesItemInitialData: AdhesionDataEntreprisesItem =
  {
    siren: "",
    numeroOrias: "",
    raisonSociale: "",
    libelleVoie: "",
    complementAdresse: "",
    codePostal: "",
    ville: "",
    categoriesOrias: [],
    coaAccessoire: false,
    miaAccessoire: false,
    cobspAccessoire: false,
    miobspAccessoire: false,
    sousCategorieCobsp: [],
    sousCategorieMiobsp: [],
    organisationPro: [],
    consentementEDI: false,
  };
