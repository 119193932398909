import {
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import {
  ImportSocietesError,
  ImportSocietesItem,
} from "@src/adhesion/Steps/AdhesionModeImport/useImportSocietes";
import { activitesFromEntreprise } from "@src/helper/entreprise.helper";
import {
  excelLabelByCategorieRepresentation,
  excelLabelByFormeJuridique,
  excelLabelByOrganisationProfessionnelle,
  excelLabelByPays,
  excelLabelBySousCategorie,
  excelLabelByTrancheEffectif,
} from "./EntrepriseExport.definition";

export const transformEntrepriseApiRowsToRows = (
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
) => ({
  siren: entreprise.siren,
  numeroOrias: entreprise.numeroOrias,
  formeJuridique: entreprise.formeJuridique
    ? excelLabelByFormeJuridique[entreprise.formeJuridique]
    : undefined,
  raisonSociale: entreprise.raisonSociale,
  typeVoie: entreprise.adresse?.typeVoie,
  numeroVoie: entreprise.adresse?.numeroVoie,
  adresse: entreprise.adresse?.libelleVoie,
  complementAdresse: entreprise.adresse?.complementAdresse,
  codePostal: entreprise.adresse?.codePostal,
  ville: entreprise.adresse?.ville,
  pays: entreprise.adresse?.pays
    ? excelLabelByPays[entreprise.adresse.pays]
    : undefined,
  trancheEffectif: entreprise.trancheEffectif
    ? excelLabelByTrancheEffectif[entreprise.trancheEffectif]
    : undefined,
  chiffreAffaire: entreprise.chiffreAffaire,
  categorieOriasCOA: activitesFromEntreprise(entreprise).includes(
    GatewayInfoSocieteOrias.Categorie.COA
  ),
  coaAccessoire: entreprise.categoriesActivitesAccessoires?.includes(
    GatewayInfoSocieteOrias.Categorie.COA
  ),
  categorieOriasMIA: activitesFromEntreprise(entreprise).includes(
    GatewayInfoSocieteOrias.Categorie.MIA
  ),
  miaAccessoire: entreprise.categoriesActivitesAccessoires?.includes(
    GatewayInfoSocieteOrias.Categorie.MIA
  ),
  categorieOriasCOBSP: activitesFromEntreprise(entreprise).includes(
    GatewayInfoSocieteOrias.Categorie.COBSP
  ),
  cobspAccessoire: entreprise.categoriesActivitesAccessoires?.includes(
    GatewayInfoSocieteOrias.Categorie.COBSP
  ),
  categorieOriasMIOBSP: activitesFromEntreprise(entreprise).includes(
    GatewayInfoSocieteOrias.Categorie.MIOBSP
  ),
  miobspAccessoire: entreprise.categoriesActivitesAccessoires?.includes(
    GatewayInfoSocieteOrias.Categorie.MIOBSP
  ),
  sousCategorieCobsp1: entreprise.sousCategorieCOBSP?.[0]
    ? excelLabelBySousCategorie[entreprise.sousCategorieCOBSP[0]]
    : undefined,
  sousCategorieCobsp2: entreprise.sousCategorieCOBSP?.[1]
    ? excelLabelBySousCategorie[entreprise.sousCategorieCOBSP[1]]
    : undefined,
  sousCategorieCobsp3: entreprise.sousCategorieCOBSP?.[2]
    ? excelLabelBySousCategorie[entreprise.sousCategorieCOBSP[2]]
    : undefined,
  sousCategorieCobsp4: entreprise.sousCategorieCOBSP?.[3]
    ? excelLabelBySousCategorie[entreprise.sousCategorieCOBSP[3]]
    : undefined,
  sousCategorieCobsp5: entreprise.sousCategorieCOBSP?.[4]
    ? excelLabelBySousCategorie[entreprise.sousCategorieCOBSP[4]]
    : undefined,
  sousCategorieCobsp6: entreprise.sousCategorieCOBSP?.[5]
    ? excelLabelBySousCategorie[entreprise.sousCategorieCOBSP[5]]
    : undefined,
  sousCategorieMiobsp1: entreprise.sousCategoriesIOBSP?.[0]
    ? excelLabelBySousCategorie[entreprise.sousCategoriesIOBSP[0]]
    : undefined,
  sousCategorieMiobsp2: entreprise.sousCategoriesIOBSP?.[1]
    ? excelLabelBySousCategorie[entreprise.sousCategoriesIOBSP[1]]
    : undefined,
  sousCategorieMiobsp3: entreprise.sousCategoriesIOBSP?.[2]
    ? excelLabelBySousCategorie[entreprise.sousCategoriesIOBSP[2]]
    : undefined,
  sousCategorieMiobsp4: entreprise.sousCategoriesIOBSP?.[3]
    ? excelLabelBySousCategorie[entreprise.sousCategoriesIOBSP[3]]
    : undefined,
  sousCategorieMiobsp5: entreprise.sousCategoriesIOBSP?.[4]
    ? excelLabelBySousCategorie[entreprise.sousCategoriesIOBSP[4]]
    : undefined,
  sousCategorieMiobsp6: entreprise.sousCategoriesIOBSP?.[5]
    ? excelLabelBySousCategorie[entreprise.sousCategoriesIOBSP[5]]
    : undefined,
  organisationPro1: entreprise.organisationProfessionnelle?.[0]
    ? excelLabelByOrganisationProfessionnelle[
        entreprise.organisationProfessionnelle[0]
      ]
    : undefined,
  organisationPro2: entreprise.organisationProfessionnelle?.[1]
    ? excelLabelByOrganisationProfessionnelle[
        entreprise.organisationProfessionnelle[1]
      ]
    : undefined,
  organisationPro3: entreprise.organisationProfessionnelle?.[2]
    ? excelLabelByOrganisationProfessionnelle[
        entreprise.organisationProfessionnelle[2]
      ]
    : undefined,
  categorieRepresentation: entreprise.categorieRepresentation
    ? excelLabelByCategorieRepresentation[entreprise.categorieRepresentation]
    : undefined,
});

export const transformEntrepriseInErrorApiRowsToRows = (
  entreprise: ImportSocietesError | ImportSocietesItem
) => {
  const {
    siren,
    numeroOrias,
    formeJuridique,
    raisonSociale,
    typeVoie,
    numeroVoie,
    libelleVoie,
    categorieRepresentation,
    categoriesOrias,
    chiffreAffaire,
    coaAccessoire,
    cobspAccessoire,
    codePostal,
    complementAdresse,
    miaAccessoire,
    miobspAccessoire,
    organisationPro,
    pays,
    sousCategorieCobsp,
    sousCategorieMiobsp,
    trancheEffectif,
    ville,
  } = entreprise.societe;
  return {
    siren,
    numeroOrias,
    formeJuridique: formeJuridique
      ? excelLabelByFormeJuridique[formeJuridique]
      : undefined,
    raisonSociale,
    typeVoie,
    numeroVoie,
    adresse: libelleVoie,
    complementAdresse,
    codePostal,
    ville,
    pays: pays ? excelLabelByPays[pays] : undefined,
    trancheEffectif: trancheEffectif
      ? excelLabelByTrancheEffectif[trancheEffectif]
      : undefined,
    chiffreAffaire,
    categorieOriasCOA: categoriesOrias?.includes(
      GatewayInfoSocieteOrias.Categorie.COA
    ),
    coaAccessoire,
    categorieOriasMIA: categoriesOrias?.includes(
      GatewayInfoSocieteOrias.Categorie.MIA
    ),
    miaAccessoire,
    categorieOriasCOBSP: categoriesOrias?.includes(
      GatewayInfoSocieteOrias.Categorie.COBSP
    ),
    cobspAccessoire,
    categorieOriasMIOBSP: categoriesOrias?.includes(
      GatewayInfoSocieteOrias.Categorie.MIOBSP
    ),
    miobspAccessoire,
    sousCategorieCobsp1: sousCategorieCobsp?.[0]
      ? excelLabelBySousCategorie[sousCategorieCobsp[0]]
      : undefined,
    sousCategorieCobsp2: sousCategorieCobsp?.[1]
      ? excelLabelBySousCategorie[sousCategorieCobsp[1]]
      : undefined,
    sousCategorieCobsp3: sousCategorieCobsp?.[2]
      ? excelLabelBySousCategorie[sousCategorieCobsp[2]]
      : undefined,
    sousCategorieCobsp4: sousCategorieCobsp?.[3]
      ? excelLabelBySousCategorie[sousCategorieCobsp[3]]
      : undefined,
    sousCategorieCobsp5: sousCategorieCobsp?.[4]
      ? excelLabelBySousCategorie[sousCategorieCobsp[4]]
      : undefined,
    sousCategorieCobsp6: sousCategorieCobsp?.[5]
      ? excelLabelBySousCategorie[sousCategorieCobsp[5]]
      : undefined,
    sousCategorieMiobsp1: sousCategorieMiobsp?.[0]
      ? excelLabelBySousCategorie[sousCategorieMiobsp[0]]
      : undefined,
    sousCategorieMiosp2: sousCategorieMiobsp?.[1]
      ? excelLabelBySousCategorie[sousCategorieMiobsp[1]]
      : undefined,
    sousCategorieMiosp3: sousCategorieMiobsp?.[2]
      ? excelLabelBySousCategorie[sousCategorieMiobsp[2]]
      : undefined,
    sousCategorieMiosp4: sousCategorieMiobsp?.[3]
      ? excelLabelBySousCategorie[sousCategorieMiobsp[3]]
      : undefined,
    sousCategorieMiosp5: sousCategorieMiobsp?.[4]
      ? excelLabelBySousCategorie[sousCategorieMiobsp[4]]
      : undefined,
    sousCategorieMiosp6: sousCategorieMiobsp?.[5]
      ? excelLabelBySousCategorie[sousCategorieMiobsp[5]]
      : undefined,
    organisationPro1: organisationPro?.[0]
      ? excelLabelByOrganisationProfessionnelle[organisationPro[0]]
      : undefined,
    organisationPro2: organisationPro?.[1]
      ? excelLabelByOrganisationProfessionnelle[organisationPro[1]]
      : undefined,
    organisationPro3: organisationPro?.[2]
      ? excelLabelByOrganisationProfessionnelle[organisationPro[2]]
      : undefined,
    categorieRepresentation: categorieRepresentation
      ? excelLabelByCategorieRepresentation[categorieRepresentation]
      : undefined,
    errors: (entreprise as ImportSocietesError).errors
      ? (entreprise as ImportSocietesError).errors
          .map((e) => e.description)
          .join(", ")
      : "",
  };
};
