import {
  Button,
  Card,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { AdhesionDataEntreprisesItemError } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { Table } from "@src/components/Table/Table";
import { ReactComponent as ChevronDown } from "@src/assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "@src/assets/icons/chevron-up.svg";
import { ReactComponent as Pencil } from "@src/assets/icons/pencil.svg";
import { ReactComponent as XCircleFill } from "@src/assets/icons/x-circle-fill.svg";
import { ReactComponent as CheckCircleFill } from "@src/assets/icons/check-circle-fill.svg";
import { useState } from "react";
import styles from "./EntrepriseImportErrors.module.scss";

export type EntrepriseImportErrorsData = {
  raisonSociale?: string;
  siren?: string;
  errors: { description: string; key: string }[];
  index: number;
};

type EntrepriseImportErrorsProps = {
  errors: EntrepriseImportErrorsData[];
  onUpdate: (entreprise: EntrepriseImportErrorsData) => void;
};

const getMessageErreur = (importInError: AdhesionDataEntreprisesItemError) => {
  if (importInError.errors.length === 0) return "";
  if (importInError.errors.length === 1)
    return importInError.errors[0].description;
  return "Plusieurs erreurs identifiées";
};

export function EntrepriseImportErrors({
  onUpdate,
  errors,
}: EntrepriseImportErrorsProps) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Card>
      <FormGrid disableHorizontalGutter>
        <FormRow className={styles.cardHeader}>
          <FormField>
            <h2>
              <span className={styles.countErrors}>{errors.length} </span>
              ligne
              {errors.length > 1 ? "s" : ""} en erreur
            </h2>
          </FormField>
          <FormField className={styles.collapseButton}>
            <Button
              variant="text"
              onClick={() => setIsOpen((prev) => !prev)}
              icon={isOpen ? <ChevronUp /> : <ChevronDown />}
            />
          </FormField>
        </FormRow>
        {isOpen && (
          <FormRow>
            <FormField>
              <Table
                className={styles.tableWithoutShadow}
                data={errors}
                columns={[
                  {
                    key: "index",
                    title: "Ligne",
                    size: 1,
                    render: ({ index }) => <p>{index}</p>,
                  },

                  {
                    key: "raisonSociale",
                    title: "Raison sociale",
                    size: 2,
                    render: (importInError) => (
                      <p className={styles.raisonSociale}>
                        {importInError.errors.length > 0 ? (
                          <XCircleFill className={styles.error} />
                        ) : (
                          <CheckCircleFill className={styles.success} />
                        )}{" "}
                        {importInError.raisonSociale}
                      </p>
                    ),
                  },
                  {
                    key: "siren",
                    title: "SIREN",
                    size: 2,
                    render: (importInError) => <p>{importInError.siren}</p>,
                  },
                  {
                    key: "error",
                    title: "Erreur",
                    size: 3,
                    render: (importInError) => (
                      <p>{getMessageErreur(importInError)}</p>
                    ),
                  },
                  {
                    key: "actions",
                    size: "auto",
                    render: (importInError) =>
                      importInError.errors.length > 0 ? (
                        <Button
                          icon={<Pencil />}
                          variant="text"
                          onClick={() => onUpdate(importInError)}
                        >
                          Corriger
                        </Button>
                      ) : (
                        <div />
                      ),
                  },
                ]}
              />
            </FormField>
          </FormRow>
        )}
      </FormGrid>
    </Card>
  );
}
