import {
  GatewayAvancement,
  GatewayDeclarationActivite,
} from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";

export class GatewayDeclarationActiviteApi {
  public static async getIAS(
    siren: string,
    millesime: number
  ): Promise<GatewayDeclarationActivite.DeclarationActiviteIas | null> {
    const response =
      await userSession.axiosInstance.get<GatewayDeclarationActivite.DeclarationActiviteIas | null>(
        GatewayDeclarationActivite.declarationActiviteUrl(
          siren,
          millesime,
          "ias"
        )
      );
    return response.data;
  }

  public static async getIobsp(
    siren: string,
    millesime: number
  ): Promise<GatewayDeclarationActivite.DeclarationActiviteIobsp | null> {
    const response =
      await userSession.axiosInstance.get<GatewayDeclarationActivite.DeclarationActiviteIobsp | null>(
        GatewayDeclarationActivite.declarationActiviteUrl(
          siren,
          millesime,
          "iobsp"
        )
      );
    return response.data;
  }

  public static async saveIAS(
    declarationActivite: GatewayDeclarationActivite.DeclarationActiviteIas
  ): Promise<GatewayAvancement.AvancementSociete> {
    const response =
      await userSession.axiosInstance.post<GatewayAvancement.AvancementSociete>(
        GatewayDeclarationActivite.declarationActiviteUrl(
          declarationActivite.siren,
          declarationActivite.millesime,
          "ias"
        ),
        declarationActivite
      );
    return response.data;
  }

  public static async saveIobsp(
    declarationActivite: GatewayDeclarationActivite.DeclarationActiviteIobsp
  ): Promise<GatewayAvancement.AvancementSociete> {
    const response =
      await userSession.axiosInstance.post<GatewayAvancement.AvancementSociete>(
        GatewayDeclarationActivite.declarationActiviteUrl(
          declarationActivite.siren,
          declarationActivite.millesime,
          "iobsp"
        ),
        declarationActivite
      );
    return response.data;
  }

  public static async copyIobsp(
    declarationActivite: GatewayDeclarationActivite.DeclarationActiviteIobsp
  ): Promise<GatewayAvancement.AvancementSociete[]> {
    const response = await userSession.axiosInstance.post<
      GatewayAvancement.AvancementSociete[]
    >(
      GatewayDeclarationActivite.copyActiviteUrl(
        declarationActivite.siren,
        declarationActivite.millesime,
        "iobsp"
      ),
      declarationActivite
    );
    return response.data;
  }

  public static async copyIas(
    declarationActivite: GatewayDeclarationActivite.DeclarationActiviteIas
  ): Promise<GatewayAvancement.AvancementSociete[]> {
    const response = await userSession.axiosInstance.post<
      GatewayAvancement.AvancementSociete[]
    >(
      GatewayDeclarationActivite.copyActiviteUrl(
        declarationActivite.siren,
        declarationActivite.millesime,
        "ias"
      ),
      declarationActivite
    );
    return response.data;
  }
}
