import {
  GatewayConformiteRcPro,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import {
  InputButtonGroup,
  InputText,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useMillesime } from "@src/store/store.millesime";
import {
  PropertyKeyWithoutFiles,
  getBlacklistedProperties,
  getPropertiesLabel,
} from "../conformiteRcPro.helper";
import { useConformiteRcProEdit } from "./useConformiteRcProEdit";

const getPropertyOption = (key: PropertyKeyWithoutFiles, millesime: number) => [
  {
    label:
      millesime >= 2025 &&
      [
        "declarationActivitesIntraComnmunautaire",
        "travailleAvecMIADeclareAssureur",
        "collaborateursDistributionAssurance",
        "declareCollaborateurIOBSP",
      ].includes(key)
        ? "Non concerné"
        : "À verifier",
    value: GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
  },
  {
    label: "Oui",
    value: GatewayConformiteRcPro.ConformiteRcProPropertyType.OUI,
  },
  {
    label: "Non",
    value: GatewayConformiteRcPro.ConformiteRcProPropertyType.NON,
  },
];
type ConformiteRcProEditPropertiesProps = {
  properties: GatewayConformiteRcPro.ConformiteRcProProperty | null;
  hasRcpIndividuelleSouscritSetToNo: boolean;
  formConfig: ReturnType<typeof useConformiteRcProEdit>["formConfig"];
  categorie: GatewayInfoSocieteOrias.Categorie;
};
export function ConformiteRcProEditProperties({
  properties,
  hasRcpIndividuelleSouscritSetToNo,
  formConfig,
  categorie,
}: ConformiteRcProEditPropertiesProps) {
  const { selected: millesime } = useMillesime();

  if (!millesime) return null;
  const travailleAvecMIOBSP = formConfig.watch(
    "travailleAvecMIOBSP"
  ) as GatewayConformiteRcPro.ConformiteRcProPropertyType;

  const travailleAvecMIA = formConfig.watch(
    "travailleAvecMIA"
  ) as GatewayConformiteRcPro.ConformiteRcProPropertyType;

  const propertiesToShow = Object.keys(properties ?? {}).filter((key) => {
    if (getBlacklistedProperties(categorie, millesime).includes(key))
      return false;
    if (key === "travailleAvecMIADeclareAssureur" && travailleAvecMIA !== "OUI")
      return false;
    if (
      key === "travailleAvecMIOBSPDeclareAssureur" &&
      travailleAvecMIOBSP !== "OUI"
    ) {
      return false;
    }
    if (key !== "courvertRcMandant") return true;
    return hasRcpIndividuelleSouscritSetToNo;
  });

  const fieldIsNumber = ["nombreMIOBSP", "nombreMIA"];

  return propertiesToShow.map((key) => (
    <FormRow key={key}>
      <FormField>
        {!fieldIsNumber.includes(key) && (
          <InputButtonGroup<
            GatewayConformiteRcPro.ConformiteRcProProperty,
            true,
            GatewayConformiteRcPro.ConformiteRcProPropertyType
          >
            label={
              getPropertiesLabel(millesime)[key as PropertyKeyWithoutFiles]
            }
            options={getPropertyOption(
              key as PropertyKeyWithoutFiles,
              millesime
            )}
            name={key as PropertyKeyWithoutFiles}
            variant="outline-pop"
            size="medium"
          />
        )}

        {[
          key === "nombreMIOBSP" && travailleAvecMIOBSP === "OUI",
          key === "nombreMIA" && travailleAvecMIA === "OUI",
        ].includes(true) && (
          <FormRow>
            <FormField size="quarter">
              <InputText<GatewayConformiteRcPro.ConformiteRcProProperty>
                label={
                  getPropertiesLabel(millesime)[key as PropertyKeyWithoutFiles]
                }
                name={key as PropertyKeyWithoutFiles}
              />
            </FormField>
          </FormRow>
        )}
      </FormField>
    </FormRow>
  ));
}
