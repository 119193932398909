import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { useFormConfig } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayAuthApi } from "@src/api/auth.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import {
  LocalStorageKeys,
  localStorageHelper,
} from "@src/helper/localStorage.helper";
import { useSession } from "@src/session/UserSessionContext";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

const useDoubleAuth = () => {
  const [error, setError] = useState<string | null>(null);
  const session = useSession();
  const navigate = useNavigate();
  const schema = zod.object({
    code1: zod.number().nullable(),
    code2: zod.number().nullable(),
    code3: zod.number().nullable(),
    code4: zod.number().nullable(),
    code5: zod.number().nullable(),
    code6: zod.number().nullable(),
  });

  type DoubleAuthType = zod.infer<typeof schema>;

  const formConfig = useFormConfig<typeof schema, DoubleAuthType>({
    schema,
    defaultValues: {
      code1: null,
      code2: null,
      code3: null,
      code4: null,
      code5: null,
      code6: null,
    },
  });

  const handleSubmit = useCallback(
    async ({ code1, code2, code3, code4, code5, code6 }: DoubleAuthType) => {
      try {
        const { idToken } = session;
        if (
          [code1, code2, code3, code4, code5, code6].includes(null) ||
          !idToken
        )
          return null;
        const response = await GatewayAuthApi.sendDoubleAuthCode({
          accessToken: idToken,
          code: [code1, code2, code3, code4, code5, code6].join(""),
        });
        if (response.value === false) {
          displayErrorToast("Le code est incorrect");
          if (response.remainingAttempts === 0) {
            try {
              await session.logout();
            } catch (e) {
              console.error("Error while logging out", e);
            }
            navigate("/login");
          }
          return setError(`Il reste ${response.remainingAttempts} tentatives`);
        }

        if (response.value === true) {
          localStorageHelper.removeItem(LocalStorageKeys.HAS_DOUBLE_AUTH);
          window.location.reload();
        }
      } catch (e) {
        handleGatewayError({
          onResponse: (err) => {
            displayErrorToast(err.response.data.message);
          },
          onUnhandled: (err) => {
            console.error(err);
            displayErrorToast("Une erreur inconnue est survenue");
          },
        })(e);
      }

      return null;
    },
    [navigate, session]
  );

  return { formConfig, handleSubmit, error };
};

export default useDoubleAuth;
