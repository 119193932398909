import { FunctionComponent, ReactElement } from "react";
import { ReactComponent as XCircle } from "@src/assets/icons/x-circle-fill.svg";
import { ReactComponent as CheckCircle } from "@src/assets/icons/check-circle-fill.svg";
import { ReactComponent as InfoCircle } from "@src/assets/icons/question-circle-fill.svg";
import { Tag, TagVariant } from "./Tag";
import styles from "./Tag.module.scss";

const variantToIcon: Record<TagVariant, FunctionComponent> = {
  error: XCircle,
  success: CheckCircle,
  info: InfoCircle,
  warning: CheckCircle,
};
type TagProps = {
  children: string | ReactElement;
  variant?: TagVariant;
  className?: string;
  overrideIcon?: FunctionComponent;
};
export function TagWithIcon({
  children,
  variant = "success",
  className,
  overrideIcon,
}: TagProps) {
  const Icon = overrideIcon ?? variantToIcon[variant];
  return (
    <Tag variant={variant} className={className}>
      <div className={styles.tagContent}>
        <Icon />
        {children}
      </div>
    </Tag>
  );
}
