const HUBSPOT_CONVERSATION_SCRIPT_ID = "hs-script-loader";

export const addHubspotConversationScript = () => {
  const script = document.createElement("script");

  script.type = "text/javascript";
  script.id = HUBSPOT_CONVERSATION_SCRIPT_ID;
  script.async = true;
  script.defer = true;
  script.src = "//js-eu1.hs-scripts.com/139679007.js";

  document.head.appendChild(script);

  if (window.HubSpotConversations) window.HubSpotConversations?.widget?.load();
  else
    window.hsConversationsOnReady = [
      () => window.HubSpotConversations?.widget?.load(),
    ];
};

export const removeHubspotConversationScript = () => {
  window.HubSpotConversations?.widget?.remove();
  document
    .querySelectorAll("[data-loader='hs-scriptloader']")
    .forEach((e) => e.remove());
  [HUBSPOT_CONVERSATION_SCRIPT_ID, "hs-analytics"].forEach((id) =>
    document.getElementById(id)?.remove()
  );
};
