import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import {
  AdhesionDataEntreprisesItem,
  AdhesionDataEntreprisesItemError,
} from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import {
  AdhesionStepEnum,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { SidePane } from "@src/components/SidePane/SidePane";
import {
  addSocieteDataValidationToSheet,
  colorErrorsSocietesInSheet,
  downloadSheetFromWorkbook,
  importWorkBookFromFile,
  insertDataInSheet,
} from "@src/helper/excel/excel.export.helper";
import { SaveAndQuitButton } from "@src/parcours/NavigationButtons/SaveAndQuitButton";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  entrepriseExcelExportTemplate,
  entrepriseExcelTemplate,
} from "@src/societes/export/EntrepriseExcelTemplate";
import { EntrepriseImportDashboard } from "@src/societes/import/EntrepriseImportDashboard";
import { format } from "date-fns";
import { useState } from "react";
import { EntrepriseImportEdit } from "../../../societes/import/EntrepriseImportEdit";
import { ImportEntreprisesButton } from "../AdhesionModeImport/ImportEntreprisesButton";
import { ImportSocietesError } from "../AdhesionModeImport/useImportSocietes";
import { transformEntrepriseInErrorApiRowsToRows } from "./adhesionImportDashboard.mapping";

type PaneState = {
  open: boolean;
  entrepriseToEdit?: AdhesionDataEntreprisesItemError;
};

const sortByIndex = (
  a: AdhesionDataEntreprisesItem,
  b: AdhesionDataEntreprisesItem
) => {
  return (a.index ?? 0) - (b.index ?? 0);
};

export function AdhesionImportDashboard({
  data,
  changeStep,
  handleQuit,
  addData,
}: ParcoursAdhesionStepComponentProps) {
  const [paneState, setPaneState] = useState<PaneState>({
    open: false,
  });

  const handleExport = async () => {
    const societesExport = [
      ...data.societesDoublons,
      ...data.societesRattachees,
      ...data.importInError,
    ].sort(sortByIndex);

    const workbook = await importWorkBookFromFile("modele_societe_error.xlsx");
    const sheet = workbook.worksheets[0];
    if (!societesExport) return;
    insertDataInSheet(
      societesExport.map(transformEntrepriseInErrorApiRowsToRows),
      sheet,
      entrepriseExcelTemplate,
      entrepriseExcelExportTemplate
    );

    colorErrorsSocietesInSheet(sheet, societesExport as ImportSocietesError[]);

    addSocieteDataValidationToSheet(sheet, societesExport.length);
    await downloadSheetFromWorkbook(
      `export_entreprise_error_${format(new Date(), "dd-MM-yyyy_HH:mm")}.xlsx`,
      workbook
    );
  };

  return (
    <ParcoursFullPageTemplate
      title="Import en masse"
      size="large"
      actions={
        <>
          <SaveAndQuitButton onClick={handleQuit} />
          <ParcoursAdhesionNextAction
            onClick={() => changeStep(AdhesionStepEnum.SOCIETES_RATTACHEES)}
          >
            Suivant
          </ParcoursAdhesionNextAction>
        </>
      }
    >
      <EntrepriseImportDashboard
        doublons={data.societesDoublons}
        errors={data.importInError}
        success={data.societesRattachees}
        onExport={() => {
          handleExport().catch(
            handleGatewayError({
              onUnhandled: () => displayErrorToast("Une erreur est survenue"),
            })
          );
        }}
        onUpdateError={(error) =>
          setPaneState({
            open: true,
            entrepriseToEdit: data.importInError.find(
              (e) => e.index === error.index
            ),
          })
        }
        importButton={
          <ImportEntreprisesButton addData={addData} changeStep={changeStep}>
            Importer le fichier corrigé
          </ImportEntreprisesButton>
        }
      />
      <SidePane
        isOpen={paneState.open}
        setIsOpen={(nextIsOpen) => {
          setPaneState((prevState) => ({ ...prevState, open: nextIsOpen }));
        }}
      >
        {(closePane) => (
          <EntrepriseImportEdit
            key={`${JSON.stringify(paneState.entrepriseToEdit)}`}
            entrepriseToEdit={paneState.entrepriseToEdit}
            onSubmit={(editedEntreprise: AdhesionDataEntreprisesItem) => {
              if (!editedEntreprise.siren) return;
              addData({
                importInError: data.importInError.filter(
                  (e) => e.siren !== editedEntreprise.siren
                ),
                societesRattachees: [
                  ...data.societesRattachees,

                  editedEntreprise,
                ],
              });
              setPaneState({ open: false });
              closePane(true);
            }}
          />
        )}
      </SidePane>
    </ParcoursFullPageTemplate>
  );
}
