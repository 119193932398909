import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputCheckbox,
  InputSelect,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormGrid,
  FormRow,
  FormTitle,
  Toaster,
  displayErrorToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { partition } from "lodash";

import {
  GatewayAdhesion,
  GatewayAdresse,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { GatewayEntrepriseApi } from "@src/api/entreprise2.api";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { PageWithSidebar } from "@src/components/PageWithSidebar/PageWithSidebar";
import { retrieveEntrepriseUpdatedRequest } from "@src/payment/utils/createEntreprises";
import { isCategorieAccessoire } from "@src/payment/utils/createIntentionPaiement";
import {
  entrepriseAdresseSchema,
  paysOptions,
  typeVoieOptions,
} from "@src/societes/form/EntrepriseAdresseForm";
import {
  entrepriseIdentificationSchema,
  formeJuridiqueOptions,
  trancheEffectifOptions,
} from "@src/societes/form/EntrepriseIdentificationForm";
import { useEntreprises } from "@src/store/store.entreprise";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { SocietePaiement } from "../paiementCategories/SocietePaiement";
import styles from "./SocieteInformation.module.scss";
import { SocieteInformationActions } from "./SocieteInformationActions";
import { SocieteInformationSidebar } from "./SocieteInformationSidebar";

export const ID_FORM_SOCIETE_INFORMATION = "id-form-societe-information";

const schema = entrepriseIdentificationSchema
  .omit({ typeSouscripteur: true })
  .merge(entrepriseAdresseSchema)
  .merge(
    zod
      .object({
        id: zod.string(),
        categories: zod
          .array(zod.nativeEnum(GatewayInfoSocieteOrias.Categorie))
          .optional(),
        coaAccessoire: zod.boolean().optional(),
        cobspAccessoire: zod.boolean().optional(),
        miaAccessoire: zod.boolean().optional(),
        miobspAccessoire: zod.boolean().optional(),
        statutOrias: zod
          .nativeEnum(GatewayInfoSocieteOrias.StatutSociete)
          .optional(),
      })
      .merge(
        zod.object({
          consentementEDI: zod.boolean().optional(),
        })
      )
  );

type SocieteInformationFormValues = zod.infer<typeof schema>;
const formValueToEntreprise = (
  values: SocieteInformationFormValues,
  societe: GatewayEntreprise.EntrepriseAControllerResponseType
): GatewayEntreprise.UpdateEntrepriseAControllerRequestType => {
  const [categorieAccessoire, categorieNonAccessoire] = partition(
    values.categories,
    (categorie) =>
      isCategorieAccessoire(categorie, {
        coaAccessoire: !!values.coaAccessoire,
        miaAccessoire: !!values.miaAccessoire,
        cobspAccessoire: !!values.cobspAccessoire,
        miobspAccessoire: !!values.miobspAccessoire,
      })
  );
  return {
    hubspotId: values.id,
    raisonSociale: values.raisonSociale,
    trancheEffectif: values.trancheEffectif,
    formeJuridique: values.formeJuridique,
    adresse: {
      typeVoie: values.typeVoie,
      libelleVoie: values.libelleVoie,
      numeroVoie: values.numeroVoie as string,
      complementAdresse: values.complementAdresse,
      codePostal: values.codePostal,
      ville: values.ville,
      pays: values.pays,
    },
    categorieRepresentation: societe.categorieRepresentation,
    categoriesActivites: categorieNonAccessoire,
    categoriesActivitesAccessoires: categorieAccessoire,
    chiffreAffaire: societe.chiffreAffaire,
    organisationProfessionnelle: societe.organisationProfessionnelle,
    sousCategorieCOBSP: societe.sousCategorieCOBSP,
    sousCategoriesIOBSP: societe.sousCategoriesIOBSP,
    numeroOrias: societe.numeroOrias,
    consentementEDI: values.consentementEDI,
  };
};

type SocieteInformationProps = {
  societe: GatewayEntreprise.EntrepriseAControllerResponseType;
  setEntreprise: (
    entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
  ) => void;
};
export function SocieteInformation({
  societe,
  setEntreprise,
}: SocieteInformationProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [paiementIntention] =
    useState<GatewayAdhesion.CreateIntentionPaimentResponseType | null>(null);
  const [newEntreprise] =
    useState<GatewayEntreprise.UpdateEntrepriseAControllerRequestType | null>(
      null
    );
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const paiement = query.get("paiement");
  const hasReachedLimit = query.get("hasReachedLimit");
  const { search } = useLocation();
  const {
    hubspotId,
    raisonSociale,
    adresse,
    siren,
    formeJuridique,
    trancheEffectif,
    categoriesActivites,
    categoriesActivitesAccessoires,
    consentementEDI,
  } = societe;
  const formConfig = useFormConfig<typeof schema, SocieteInformationFormValues>(
    {
      schema,
      defaultValues: {
        id: hubspotId,
        raisonSociale,
        pays: adresse?.pays,
        ville: adresse?.ville,
        siren,
        typeVoie: adresse?.typeVoie,
        codePostal: adresse?.codePostal,
        numeroVoie: adresse?.numeroVoie,
        libelleVoie: adresse?.libelleVoie,
        formeJuridique,
        trancheEffectif,
        complementAdresse: adresse?.complementAdresse || "",
        categories: [...categoriesActivites, ...categoriesActivitesAccessoires],
        coaAccessoire: categoriesActivitesAccessoires.includes(
          GatewayInfoSocieteOrias.Categorie.COA
        ),
        cobspAccessoire: categoriesActivitesAccessoires.includes(
          GatewayInfoSocieteOrias.Categorie.COBSP
        ),
        miaAccessoire: categoriesActivitesAccessoires.includes(
          GatewayInfoSocieteOrias.Categorie.MIA
        ),
        miobspAccessoire: categoriesActivitesAccessoires.includes(
          GatewayInfoSocieteOrias.Categorie.MIOBSP
        ),
        consentementEDI,
      },
    }
  );

  useEffect(() => {
    if (paiement === "success") {
      const { updatedEntrepriseParams } = retrieveEntrepriseUpdatedRequest();
      setIsLoading(true);
      GatewayEntrepriseApi.updateEntreprise(updatedEntrepriseParams)
        .then((updatedEntreprise) => {
          displaySucessToast("La société a bien été mise à jour");
          setEntreprise(updatedEntreprise);
          navigate(`/societes/${updatedEntreprise.siren}${search}`);
        })
        .catch(() => {
          displayErrorToast("Une erreur est survenue lors de la mise à jour", {
            canal: "societe-information",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });

      setIsLoading(true);

      GatewayEntrepriseApi.setHasReachedLimit(
        hasReachedLimit as GatewayEntreprise.AAtteintLeTarif
      ).finally(() => {
        setIsLoading(false);
      });
    }
  }, [paiement]);

  const handleSubmit = (values: SocieteInformationFormValues) => {
    setIsLoading(true);
    GatewayEntrepriseApi.updateEntreprise(
      formValueToEntreprise(values, societe)
    )
      .then((updatedEntreprise) => {
        navigate(`/societes/${values.siren}${search}`);
        displaySucessToast("La société a bien été mise à jour");
        setEntreprise(updatedEntreprise);
      })
      .catch(() => {
        displayErrorToast("Une erreur est survenue lors de la mise à jour", {
          canal: "societe-information",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    // setIsLoading(true);
    // const typeAdhesion = me?.entreprisePrincipale
    //   .typeAdhesion as GatewayEntreprise.TypeAdhesionActive;

    // if (!me) return;

    // const paiementIntent = await callCreateIntentionPaiementAdhesionEntreprise(
    //   typeAdhesion,
    //   formValueToEntreprisePaiement(
    //     values,
    //     societe,
    //     me.entreprisePrincipale.typeSouscripteur
    //   )
    // );

    // if (!paiementIntent.paiement || paiementIntent.paiement.montant === 0) {
    //   GatewayEntrepriseApi.updateEntreprise(
    //     formValueToEntreprise(values, societe)
    //   )
    //     .then((updatedEntreprise) => {
    //       displaySucessToast("La société a bien été mise à jour");
    //       setEntreprise(updatedEntreprise);
    //       navigate(`/societes/${values.siren}${search}`);
    //     })
    //     .catch(() => {
    //       displayErrorToast("Une erreur est survenue lors de la mise à jour", {
    //         canal: "societe-information",
    //       });
    //     })
    //     .finally(() => {
    //       setIsLoading(false);
    //     });
    // } else {
    //   setPaiementIntention(paiementIntent);
    //   setNewEntreprise(formValueToEntreprise(values, societe));
    // }
  };
  // TODO: A remettre quand on aura les organisations professionnelles dans hubspot
  // const handleChange = useCallback(
  //   (nextValue: GatewayInfoSocieteOrias.OrganisationProfessionnelle[]) => {
  //     const doesPreviousContainAucun = formConfig
  //       .getValues("organisationsProfessionnelles")
  //       .includes(GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUN);
  //     const doesNextContainAucun = nextValue.includes(
  //       GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUN
  //     );
  //
  //     const options = { shouldValidate: true };
  //     if (doesNextContainAucun && !doesPreviousContainAucun) {
  //       formConfig.setValue(
  //         "organisationsProfessionnelles",
  //         [GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUN],
  //         options
  //       );
  //     } else if (doesPreviousContainAucun) {
  //       formConfig.setValue(
  //         "organisationsProfessionnelles",
  //         nextValue.filter(
  //           (organisation) =>
  //             organisation !==
  //             GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUN
  //         ),
  //         options
  //       );
  //     } else {
  //       formConfig.setValue(
  //         "organisationsProfessionnelles",
  //         nextValue,
  //         options
  //       );
  //     }
  //   },
  //   [formConfig]
  // );

  // const categoriesWatched = formConfig.watch("categories");

  // const categoriesOptions: {
  //   value: GatewayInfoSocieteOrias.Categorie;
  //   label: string;
  //   description?: ReactElement;
  // }[] = useMemo(
  //   () => [
  //     {
  //       value: GatewayInfoSocieteOrias.Categorie.COA,
  //       label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.COA],
  //       description: categoriesWatched?.includes(
  //         GatewayInfoSocieteOrias.Categorie.COA
  //       ) ? (
  //         <InputTitreAccessoire name="coaAccessoire" />
  //       ) : undefined,
  //     },
  //     {
  //       value: GatewayInfoSocieteOrias.Categorie.MIA,
  //       label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.MIA],
  //       description: categoriesWatched?.includes(
  //         GatewayInfoSocieteOrias.Categorie.MIA
  //       ) ? (
  //         <InputTitreAccessoire name="miaAccessoire" />
  //       ) : undefined,
  //     },
  //     {
  //       value: GatewayInfoSocieteOrias.Categorie.COBSP,
  //       label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.COBSP],
  //       description: categoriesWatched?.includes(
  //         GatewayInfoSocieteOrias.Categorie.COBSP
  //       ) ? (
  //         <InputTitreAccessoire name="cobspAccessoire" />
  //       ) : undefined,
  //     },
  //     {
  //       value: GatewayInfoSocieteOrias.Categorie.MIOBSP,
  //       label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.MIOBSP],
  //       description: categoriesWatched?.includes(
  //         GatewayInfoSocieteOrias.Categorie.MIOBSP
  //       ) ? (
  //         <InputTitreAccessoire name="miobspAccessoire" />
  //       ) : undefined,
  //     },
  //   ],
  //   [categoriesWatched]
  // );

  if (paiementIntention && newEntreprise)
    return (
      <SocietePaiement
        siren={societe.siren}
        millesime={societe.millesime}
        paiementIntention={paiementIntention}
        entreprise={newEntreprise}
      />
    );

  return (
    <FormGrid>
      <Form
        formConfig={formConfig}
        onSubmit={handleSubmit}
        id={ID_FORM_SOCIETE_INFORMATION}
      >
        <FormTitle>
          <h1>Informations générales</h1>
        </FormTitle>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Siren"
              name="siren"
              required
              disabled
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Raison sociale"
              name="raisonSociale"
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputSelect<
              SocieteInformationFormValues,
              GatewayEntreprise.TrancheEffectif
            >
              name="trancheEffectif"
              label="Tranche effectif"
              placeholder="Sélectionner"
              options={trancheEffectifOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputSelect<
              SocieteInformationFormValues,
              GatewayEntreprise.FormeJuridique
            >
              name="formeJuridique"
              label="Forme juridique"
              placeholder="Sélectionner"
              options={formeJuridiqueOptions}
              required
            />
          </FormField>
        </FormRow>
        {/* <FormRow className={styles.activites}>
          <FormField>
            {showActivities === "true" && (
              <InputButtonGroup<
                EntrepriseInfoActiviteFormValues,
                true,
                GatewayInfoSocieteOrias.Categorie
              >
                label={
                  <b className={styles.label}>Vos catégories d’adhésion</b>
                }
                options={categoriesOptions}
                name="categories"
                variant="outline-pop"
                size="large"
                isMultiple
              />
            )}
            {showActivities === "warned" && (
              <div>
                <b className={styles.label}>Vos catégories d’adhésion</b>
                <div className={styles.activitesList}>
                  {societe.categoriesActivites.map((cat) => (
                    <p key={cat}>
                      {
                        categorieActiviteLabel[
                          GatewayInfoSocieteOrias.Categorie[cat]
                        ]
                      }
                    </p>
                  ))}
                </div>
                <p>
                  L’ajout de catégorie(s) peut entrainer le règlement d’un
                  complément de cotisation. Pour continuer, cliquez sur
                  <Button
                    variant="text"
                    onClick={() => setShowActivities("true")}
                    className={styles.warningButton}
                  >
                    “Confirmer”.
                  </Button>
                </p>
              </div>
            )}
            {showActivities === "no" && (
              <div>
                <b className={styles.label}>Vos catégories d’adhésion</b>
                <div className={styles.activitesList}>
                  {societe.categoriesActivites.map((cat) => (
                    <p key={cat}>
                      {
                        categorieActiviteLabel[
                          GatewayInfoSocieteOrias.Categorie[cat]
                        ]
                      }
                    </p>
                  ))}
                </div>
                <Button onClick={() => setShowActivities("warned")}>
                  Modifier
                </Button>
              </div>
            )}
          </FormField>
        </FormRow> */}

        <h2 className={styles.subtitle}>Adresse</h2>
        <FormRow>
          <FormField>
            <InputSelect<SocieteInformationFormValues, GatewayAdresse.TypeVoie>
              isSearchable
              name="typeVoie"
              label="Type de voie"
              placeholder="Sélectionner"
              options={typeVoieOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Libellé de la voie"
              name="libelleVoie"
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Numéro de rue"
              name="numeroVoie"
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputText<SocieteInformationFormValues>
              label="Complément d'adresse"
              name="complementAdresse"
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <InputSelect<SocieteInformationFormValues, GatewayAdresse.Pays>
              name="pays"
              label="Pays"
              placeholder="Sélectionner"
              options={paysOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField size="quarter">
            <InputText<SocieteInformationFormValues>
              label="Code postal"
              name="codePostal"
              required
            />
          </FormField>
          <FormField size="three-quarter">
            <InputText<SocieteInformationFormValues>
              label="Ville"
              name="ville"
              required
            />
          </FormField>
        </FormRow>
        {[...categoriesActivites, ...categoriesActivitesAccessoires].includes(
          GatewayInfoSocieteOrias.Categorie.COA
        ) && (
          <FormRow>
            <FormField>
              <InputCheckbox<SocieteInformationFormValues>
                name="consentementEDI"
                label="Je souhaite partager mes informations avec la plateforme EDI Conformité"
              />
            </FormField>
          </FormRow>
        )}
        {/*
        // TODO: A remettre quand on aura les organisations professionnelles dans hubspot
        <h2 className={styles.subtitle}>Organisation professionnelle</h2>
        <h4 className={styles.subtitle}>
          Êtes-vous adhérent d’une ou plusieurs organisations professionnelles ?
        </h4>
        <FormRow>
          <FormField>
            <InputButtonGroup<
              SocieteInformationFormValues,
              true,
              GatewayInfoSocieteOrias.OrganisationProfessionnelle
            >
              label="Êtes-vous adhérent d'une ou plusieurs organisations professionnelles ?"
              name="organisationsProfessionnelles"
              size="large"
              variant="outline-pop"
              options={[
                {
                  value: GatewayInfoSocieteOrias.OrganisationProfessionnelle.APIC,
                  label: "APIC",
                },
                {
                  value: GatewayInfoSocieteOrias.OrganisationProfessionnelle.GCAB,
                  label: "GCAB",
                },
                {
                  value: GatewayInfoSocieteOrias.OrganisationProfessionnelle.PLANETE,
                  label: "Planete CSCA",
                },
                {
                  value: GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUTRE,
                  label: "Autre",
                },
                {
                  value: GatewayInfoSocieteOrias.OrganisationProfessionnelle.AUCUN,
                  label: "Aucune",
                },
              ]}
              required
              isMultiple
              onChange={handleChange}
            />
          </FormField>
        </FormRow>
        */}
        <SocieteInformationActions
          idSociete={societe.siren || ""}
          isLoading={isLoading}
        />
        <Toaster
          canal={ID_FORM_SOCIETE_INFORMATION}
          position={{
            vertical: "bottom",
            horizontal: "center",
          }}
          className={styles.toaster}
        />
      </Form>
    </FormGrid>
  );
}

export function SocieteInformationContainer() {
  const { entreprisesBySiren, setEntreprise } = useEntreprises();
  const { id } = useParams<{ id: string }>();
  const societe = id && entreprisesBySiren ? entreprisesBySiren[id] : undefined;

  return (
    <PageWithSidebar sidebar={<SocieteInformationSidebar />}>
      <MaxWidthContent className={styles.page}>
        {societe && (
          <SocieteInformation societe={societe} setEntreprise={setEntreprise} />
        )}
      </MaxWidthContent>
    </PageWithSidebar>
  );
}
