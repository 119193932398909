import {
  Card,
  FormField,
  FormGrid,
  displayErrorToast,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useCallback, useMemo } from "react";
import { useMe } from "@src/store/store.me";
import {
  Form,
  InputButtonGroup,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { GatewayEntrepriseApi } from "@src/api/entreprise2.api";

interface SecuriteFormProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  formId: string;
}

const schema = zod.object({
  isDoubleAuthEnabled: zod.boolean(),
});
export type UpdateSecuriteFormValues = zod.infer<typeof schema>;

export function SecuriteForm({ setIsLoading, formId }: SecuriteFormProps) {
  const { me } = useMe();

  const defaultValues = useMemo<UpdateSecuriteFormValues>(
    () => ({
      isDoubleAuthEnabled:
        (me?.entreprisePrincipale.isDoubleAuth as boolean) ?? false,
    }),
    [me?.entreprisePrincipale.isDoubleAuth]
  );

  const formConfig = useFormConfig<typeof schema, UpdateSecuriteFormValues>({
    schema,
    defaultValues,
  });

  const handleSubmit = useCallback(
    (values: UpdateSecuriteFormValues) => {
      setIsLoading(true);
      GatewayEntrepriseApi.setIsDouleAuthEnabled({
        ...values,
      })
        .catch(
          handleGatewayError({
            onUnhandled: (error) => {
              displayErrorToast(
                "Une erreur est survenue lors de la modification de vos informations"
              );
            },
          })
        )
        .then(() => {
          displaySucessToast("Modification effectuée avec succès");
        })
        .finally(() => setIsLoading(false));
    },
    [setIsLoading]
  );

  return (
    <Card>
      <FormGrid disableHorizontalGutter>
        <Form formConfig={formConfig} onSubmit={handleSubmit} id={formId}>
          <FormField>
            <InputButtonGroup<UpdateSecuriteFormValues, false, boolean>
              label="Activer la double authentification"
              name="isDoubleAuthEnabled"
              variant="outline-pop"
              size="medium"
              options={[
                {
                  label: "Oui",
                  value: true,
                },
                {
                  label: "Non",
                  value: false,
                },
              ]}
              required
            />
          </FormField>
        </Form>
      </FormGrid>
    </Card>
  );
}
