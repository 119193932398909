import { removeHubspotConversationScript } from "@src/loadHubspotConversation";
import { ConnectedAxios } from "./ConnectedAxios";
import {
  retrieveSessionFromLocalStorage,
  storeSessionInLocalStorage,
} from "./UserSession.helpers";
import { GatewaySessionApi } from "./session.api";

export type UserSessionData =
  | ConnectedUserSessionData
  | DisconnectedUserSessionData;

export type ConnectedUserSessionData = {
  isConnected: true;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  hasDoubleAuth: boolean | null;
};

export type DisconnectedUserSessionData = {
  isConnected: false;
  accessToken: null;
  refreshToken: null;
  idToken: null;
  hasDoubleAuth: boolean | null;
};

export function isConnectedSessionData(
  sessionData: UserSessionData
): sessionData is ConnectedUserSessionData {
  return sessionData.isConnected;
}

export class UserSession {
  private _onSetSessionData?: (session: UserSessionData) => void;

  private _connectedAxios: ConnectedAxios;

  private _sessionData: UserSessionData = {
    accessToken: null,
    refreshToken: null,
    idToken: null,
    isConnected: false,
    hasDoubleAuth: false,
  };

  constructor() {
    this._connectedAxios = new ConnectedAxios(this);
    const storedSession = retrieveSessionFromLocalStorage();

    if (
      storedSession.accessToken &&
      storedSession.refreshToken &&
      storedSession.idToken
    ) {
      this._sessionData = {
        accessToken: storedSession.accessToken,
        refreshToken: storedSession.refreshToken,
        idToken: storedSession.idToken,
        isConnected: true,
        hasDoubleAuth: storedSession.hasDoubleAuth,
      };
      document.body.setAttribute("data-connected", "true");
    }
  }

  set onSetSessionData(
    onSetSessionData: ((session: UserSessionData) => void) | undefined
  ) {
    this._onSetSessionData = onSetSessionData;
  }

  get onSetSessionData() {
    return this._onSetSessionData;
  }

  get sessionData() {
    return this._sessionData;
  }

  set sessionData(sessionData: UserSessionData) {
    this._sessionData = sessionData;
    storeSessionInLocalStorage(sessionData);
    if (this.onSetSessionData) this.onSetSessionData(sessionData);
    document.body.setAttribute(
      "data-connected",
      String(sessionData.isConnected)
    );
  }

  get axiosInstance() {
    return this._connectedAxios.axiosApiInstance;
  }

  async login(email: string, password: string) {
    const response = await GatewaySessionApi.signin({ email, password });
    this.sessionData = {
      accessToken: response.accessToken,
      refreshToken: response.refreshToken,
      idToken: response.idToken,
      isConnected: true,
      hasDoubleAuth: response.hasDoubleAuth ?? false,
    };
  }

  async logout() {
    removeHubspotConversationScript();
    if (
      !this.sessionData.accessToken ||
      !this.sessionData.refreshToken ||
      !this.sessionData.idToken
    )
      return;

    try {
      await GatewaySessionApi.logout({
        refreshToken: this.sessionData.refreshToken,
      });
    } finally {
      this.sessionData = {
        accessToken: null,
        refreshToken: null,
        idToken: null,
        isConnected: false,
        hasDoubleAuth: null,
      };
    }
  }

  async refresh() {
    if (!this.sessionData.accessToken || !this.sessionData.refreshToken)
      throw Error("no session to refresh");
    try {
      const response = await GatewaySessionApi.refresh(
        this.sessionData.idToken,
        this.sessionData.refreshToken
      );

      this.sessionData = {
        isConnected: true,
        accessToken: response.accessToken,
        refreshToken: this.sessionData.refreshToken,
        idToken: response.idToken,
        hasDoubleAuth: this.sessionData.hasDoubleAuth,
      };
    } catch (err) {
      await this.logout();
    }
  }
}

export const userSession = new UserSession();
