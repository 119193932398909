import {
  Form,
  InputNumber,
  InputSelect,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormGrid,
  FormRow,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import {
  GatewayAvancement,
  GatewayDeclarationActivite,
} from "@conformite/gateway";
import { useEffect, useMemo } from "react";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { GatewayDeclarationActiviteApi } from "@src/api/conformite/declarationActivite.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { OrganisationData, Organisations } from "./Organistions/Orgnanisations";
import {
  ActivitesData,
  ActivitesIobsp,
  activiteIobspToLabel,
} from "./Activites/ActivitesIobsp";
import {
  FournisseurRegafiData,
  FournisseurRegafi,
} from "./Fournisseur/FournisseurRegafi";
import styles from "../ConformiteDeclarationActivite.module.scss";

const schema = zod.object({
  fournisseur: zod.string(),
  fournisseurs: zod
    .array(zod.object({ value: zod.string() }))
    .min(1, { message: "Doit contenir au moins un élément" }),
  siren: zod.string(),
  millesime: zod.number(),
  organisation: zod.string(),
  organisations: zod
    .array(
      zod.object({
        type: zod.nativeEnum(GatewayDeclarationActivite.OrganisationType),
        repartitionClientele: zod.number(),
      })
    )
    .min(1, {
      message: "Doit contenir au moins un élément",
    })
    .superRefine((data, ctx) => {
      if (
        data.reduce(
          (acc, organisation) => acc + organisation.repartitionClientele,
          0
        ) !== 100
      )
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          path: ["0.repartitionClientele"],
          message: "Attention, la répartition ne peut pas dépasser 100%",
        });
    }),
  pourcentage: zod
    .object({
      pourcentageCaClientsParticuliers: zod.number(),
      pourcentageCaClientsProfessionnels: zod.number(),
      pourcentageTnsTpe: zod.number(),
    })
    .refine(
      (data) =>
        data.pourcentageCaClientsParticuliers +
          data.pourcentageCaClientsProfessionnels +
          data.pourcentageTnsTpe ===
        100,
      {
        path: ["pourcentageCaClientsParticuliers"],
        message: "La somme de Pourcentage des CA ne peut pas dépasser 100%",
      }
    ),
  effectifGlobalEntreprise: zod
    .number()
    .min(0, { message: "Doit être supérieur ou égal à 0" }),
  activitePrincipale: zod.nativeEnum(GatewayDeclarationActivite.ActiviteIobsp),
  activite: zod.string(),
  activites: zod
    .array(
      zod.object({
        value: zod.nativeEnum(GatewayDeclarationActivite.ActiviteIobsp),
      })
    )
    .min(1, { message: "Doit contenir au moins un élément" }),
});
export type DeclarationActiviteFormValues = zod.infer<typeof schema>;

export const useDeclarationActiviteIobspForm = (
  siren: string,
  millesime: number,
  declarationActivite: GatewayDeclarationActivite.DeclarationActiviteIobsp | null,
  stopEditing: (
    payload: GatewayDeclarationActivite.DeclarationActiviteIobsp,
    avancement: GatewayAvancement.AvancementSociete
  ) => void
) => {
  const defaultDeclarationActivite = useMemo(
    () =>
      ({
        siren,
        millesime,
        organisations: [],
        pourcentage: {
          pourcentageCaClientsParticuliers: null,
          pourcentageCaClientsProfessionnels: null,
          pourcentageTnsTpe: null,
        },
        effectifGlobalEntreprise: null,
        activitePrincipale: null,
        activites: [],
        fournisseurs: [],
      } as unknown as DeclarationActiviteFormValues),
    [siren, millesime]
  );
  const defaultValues: DeclarationActiviteFormValues = useMemo(
    () =>
      declarationActivite
        ? ({
            ...declarationActivite,
            pourcentage: {
              pourcentageCaClientsParticuliers:
                declarationActivite?.pourcentageCaClientsParticuliers,
              pourcentageCaClientsProfessionnels:
                declarationActivite?.pourcentageCaClientsProfessionnels,
              pourcentageTnsTpe: declarationActivite?.pourcentageTnsTpe,
            },
            siren,
            millesime,
            fournisseur: "",
            fournisseurs:
              declarationActivite?.fournisseurs.map((fournisseur) => ({
                value: fournisseur,
              })) ?? [],
            activitePrincipale: declarationActivite?.activitePrincipale,
            activite: "",
            activites:
              declarationActivite?.activites10Pourcent.map((activite) => ({
                value: activite,
              })) ?? [],
            organisation: "",
            organisations: declarationActivite?.organisations ?? [],
          } as DeclarationActiviteFormValues)
        : defaultDeclarationActivite,
    [declarationActivite, defaultDeclarationActivite, millesime, siren]
  );

  const form = useFormConfig({
    schema,
    defaultValues,
  });

  const onSubmit = async ({
    fournisseur,
    activite,
    organisation,
    pourcentage,
    ...data
  }: DeclarationActiviteFormValues) => {
    const payload: GatewayDeclarationActivite.DeclarationActiviteIobsp = {
      ...data,
      pourcentageCaClientsParticuliers:
        pourcentage.pourcentageCaClientsParticuliers,
      pourcentageCaClientsProfessionnels:
        pourcentage.pourcentageCaClientsProfessionnels,
      pourcentageTnsTpe: pourcentage.pourcentageTnsTpe,
      fournisseurs: data.fournisseurs.map((f) => f.value),
      activitePrincipale: data.activitePrincipale ?? null,
      activites10Pourcent: data.activites.map((a) => a.value),
    };
    try {
      const avancement = await GatewayDeclarationActiviteApi.saveIobsp(payload);
      stopEditing(payload, avancement);
    } catch (err) {
      handleGatewayError({
        onUnhandled: () => {
          displayErrorToast(
            "Une erreur est survenue lors de la récupération du formulaire"
          );
        },
      })(err);
    }
  };

  return { form, onSubmit };
};

type ConformiteDeclarationActiviteIobspFormProps = {
  form: ReturnType<typeof useDeclarationActiviteIobspForm>["form"];
  onSubmit?: ReturnType<typeof useDeclarationActiviteIobspForm>["onSubmit"];
};
export function ConformiteDeclarationActiviteIobspForm({
  form,
  onSubmit = () => Promise.resolve(),
}: ConformiteDeclarationActiviteIobspFormProps) {
  const [activitePrincipale, activites] = form.watch([
    "activitePrincipale",
    "activites",
  ]);

  const pourcentagesCA = form.watch([
    "pourcentage.pourcentageCaClientsParticuliers",
    "pourcentage.pourcentageCaClientsProfessionnels",
    "pourcentage.pourcentageTnsTpe",
  ]);

  useEffect(() => {
    form
      .trigger("pourcentage.pourcentageCaClientsParticuliers")
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pourcentagesCA, form.trigger]);

  return (
    <Form formConfig={form} onSubmit={onSubmit}>
      <FormGrid disableHorizontalGutter>
        <h4 className={styles.subtitle}>Organisation de la distribution</h4>
        <FormRow>
          <FormField>
            <Organisations
              form={form as unknown as UseFormReturn<OrganisationData>}
            />
          </FormField>
        </FormRow>
        <h4 className={styles.subtitle}>
          Répartition du chiffre d&apos;affaire
        </h4>
        <FormRow>
          <FormField size="one-third">
            <InputNumber<DeclarationActiviteFormValues>
              name="pourcentage.pourcentageCaClientsParticuliers"
              label="Pourcentage du CA clients particuliers"
            />
          </FormField>
          <FormField size="one-third">
            <InputNumber<DeclarationActiviteFormValues>
              name="pourcentage.pourcentageCaClientsProfessionnels"
              label="Pourcentage du CA clients professionnels"
            />
          </FormField>
          <FormField size="one-third">
            <InputNumber<DeclarationActiviteFormValues>
              name="pourcentage.pourcentageTnsTpe"
              label="Pourcentage TNS/TPE"
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField size="half">
            <InputNumber<DeclarationActiviteFormValues>
              name="effectifGlobalEntreprise"
              label="Effectif global de l'entreprise"
            />
          </FormField>
        </FormRow>
        <h4 className={styles.subtitle}>Diversification de l’activité</h4>
        <FormRow>
          <FormField>
            <InputSelect<
              DeclarationActiviteFormValues,
              GatewayDeclarationActivite.ActiviteIobsp
            >
              name="activitePrincipale"
              label="Activité principale"
              options={Object.values(GatewayDeclarationActivite.ActiviteIobsp)
                .filter(
                  (activite) =>
                    activites.every((a) => a.value !== activite) &&
                    activite !== "AUCUNE_AUTRE_ACTIVITE"
                )
                .map((value) => ({
                  value,
                  label: activiteIobspToLabel[value],
                }))}
              placeholder="Sélectionner l'activité principale"
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField>
            <ActivitesIobsp
              form={form as unknown as UseFormReturn<ActivitesData>}
              activitePrincipale={activitePrincipale}
            />
          </FormField>
        </FormRow>
        <h4 className={styles.subtitle}>Fournisseurs de produits</h4>
        <FournisseurRegafi
          form={form as unknown as UseFormReturn<FournisseurRegafiData>}
        />
      </FormGrid>
    </Form>
  );
}
