import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  InputButtonGroup,
  InputDate,
  InputSelect,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  FormField,
  FormGrid,
  FormRow,
  FormSubTitle,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayCapacitePro } from "@conformite/gateway";
import { useMemo } from "react";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { GatewayCapaciteProApi } from "@src/api/conformite/capacitePro.api";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { useAssujetti } from "@src/store/store.assujetti";
import { UseFormReturn } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { SelectOption } from "@lya-protect/lya-protect-form-library/dist/UI/Input/UIInputSelect";
import classNames from "classnames";
import { AssujettiConformiteData } from "../../../AssujettiParcoursConformite";
import styles from "./ConformiteFormCapaciteProIOBSP.module.scss";
import {
  labelsByGradeDiplome,
  labelsByStatusProfessionel,
} from "../formsCapacitePro.definition";
import {
  capaciteProIOBSPSchema,
  getTypeJustificatifOptionsIOBSP,
  labelsByCategorieIOBSP,
  labelsByFormCapaciteProNiveauIOBSP,
} from "./formCapaciteProIOBSP.definition";

export type CapaciteProIOBSPFormValues = zod.infer<
  typeof capaciteProIOBSPSchema
>;

function getDefaultValues(
  formData: GatewayCapacitePro.CapaciteProfessionnelleIOBSP | null,
  isMonoActivite: boolean
): CapaciteProIOBSPFormValues {
  return {
    datePriseFonction: formData?.datePriseFonction ?? null,
    capacite: {
      conditionCapaciteProfessionnelle: isMonoActivite
        ? true
        : formData?.conditionCapaciteProfessionnelle,
      niveau: formData?.niveau,
      statutProfessionel: formData?.statusProfessionel,
      categorie: formData?.categorie,
      gradeDiplome: formData?.gradeDiplome,
      nomDiplome: formData?.nomDiplome,
      typeJustificatif: formData?.typeJustificatif,
    },
  } as CapaciteProIOBSPFormValues;
}

function formatFormValuesToApiPayload(
  values: CapaciteProIOBSPFormValues
): GatewayCapacitePro.CapaciteProfessionnelleIOBSP {
  return {
    datePriseFonction: values.datePriseFonction ?? null,
    conditionCapaciteProfessionnelle:
      values.capacite.conditionCapaciteProfessionnelle ?? null,
    niveau: values.capacite.niveau ?? null,
    statusProfessionel: values.capacite.statutProfessionel ?? null,
    categorie: values.capacite.categorie ?? null,
    gradeDiplome: values.capacite.gradeDiplome ?? null,
    nomDiplome: values.capacite.nomDiplome ?? null,
    typeJustificatif: values.capacite.typeJustificatif ?? null,
  };
}

export const useCapaciteProIobspForm = (
  formData: GatewayCapacitePro.CapaciteProfessionnelleIOBSP | null,
  isMonoActivite: boolean
) => {
  const formConfig = useFormConfig<
    typeof capaciteProIOBSPSchema,
    CapaciteProIOBSPFormValues
  >({
    schema: capaciteProIOBSPSchema,
    defaultValues: getDefaultValues(formData, isMonoActivite),
  });

  const conditionCapaciteProfessionnelleValue = formConfig.watch(
    "capacite.conditionCapaciteProfessionnelle"
  );
  const niveauValue = formConfig.watch("capacite.niveau");
  const typeJustificatifOptions = useMemo(
    () => getTypeJustificatifOptionsIOBSP(niveauValue),
    [niveauValue]
  );
  return {
    formConfig,
    conditionCapaciteProfessionnelleValue,
    typeJustificatifOptions,
  };
};
export const useCapaciteProIOBSP = (
  data: AssujettiConformiteData,
  formData: GatewayCapacitePro.CapaciteProfessionnelleIOBSP | null,
  isMonoActivite: boolean,
  setFormData: (data: GatewayCapacitePro.CapaciteProfessionnelleIOBSP) => void
) => {
  const {
    formConfig,
    conditionCapaciteProfessionnelleValue,
    typeJustificatifOptions,
  } = useCapaciteProIobspForm(formData, isMonoActivite);
  const { updateAvancement } = useAssujetti();

  const onSubmit = async (values: CapaciteProIOBSPFormValues) => {
    const payload = formatFormValuesToApiPayload(values);

    const avancement = await GatewayCapaciteProApi.saveIOBSP(
      data.assujetti.id,
      data.assujetti.millesime,
      payload
    );
    updateAvancement(data.assujetti.millesime, data.assujetti.id, avancement);
    setFormData(payload);
  };
  return {
    formConfig,
    conditionCapaciteProfessionnelleValue,
    typeJustificatifOptions,
    onSubmit,
  };
};
type ConformiteFormCapaciteProIOBSPContentProps = {
  hideTitle?: boolean;
  formConfig: UseFormReturn<CapaciteProIOBSPFormValues>;
  data: AssujettiConformiteData;
  conditionCapaciteProfessionnelleValue: boolean;
  typeJustificatifOptions: SelectOption<GatewayCapacitePro.TypeJustificatifIOBSP>[];
  isMonoActivite: boolean;
};
export function ConformiteFormCapaciteProIOBSPContent({
  hideTitle,
  formConfig,
  data,
  conditionCapaciteProfessionnelleValue,
  typeJustificatifOptions,
  isMonoActivite,
}: ConformiteFormCapaciteProIOBSPContentProps) {
  return (
    <FormGrid disableHorizontalGutter>
      {!hideTitle && (
        <FormTitle className={styles.header}>
          <h2 className={styles.title}>
            <b>Capacité Professionnelle IOBSP</b> - {data.assujetti.surname}{" "}
            {data.assujetti.name}
          </h2>
          <IconStatus
            isValid={
              data.assujetti.avancement.capaciteProfessionnelleIobsp === 100
            }
          />
        </FormTitle>
      )}
      <FormRow>
        <FormField>
          <InputDate<CapaciteProIOBSPFormValues>
            label="Date de prise de fonction"
            name="datePriseFonction"
          />
        </FormField>
      </FormRow>
      <FormRow className={classNames({ [styles.hidden]: isMonoActivite })}>
        <FormField>
          <InputButtonGroup<CapaciteProIOBSPFormValues, false, boolean>
            size="medium"
            label="La personne est-elle soumise à la condition de capacité professionnelle ?"
            name="capacite.conditionCapaciteProfessionnelle"
            variant="outline-pop"
            onChange={(value) => {
              if (value === false) {
                formConfig.setValue(
                  "capacite.conditionCapaciteProfessionnelle",
                  false,
                  {
                    shouldValidate: true,
                  }
                );
                (
                  [
                    "capacite.categorie",
                    "capacite.gradeDiplome",
                    "capacite.niveau",
                    "capacite.nomDiplome",
                    "capacite.statutProfessionel",
                    "capacite.typeJustificatif",
                  ] as const
                ).forEach((name) => {
                  formConfig.setValue(name, null, {
                    shouldValidate: true,
                  });
                });
              } else {
                formConfig.setValue(
                  "capacite.conditionCapaciteProfessionnelle",
                  true,
                  {
                    shouldValidate: true,
                  }
                );
              }
            }}
            options={[
              { label: "Oui", value: true },
              { label: "Non", value: false },
            ]}
          />
        </FormField>
      </FormRow>
      {conditionCapaciteProfessionnelleValue && (
        <>
          <FormRow>
            <FormField>
              <InputButtonGroup<
                CapaciteProIOBSPFormValues,
                false,
                GatewayCapacitePro.CapaciteProNiveauIOBSP
              >
                size="medium"
                label="Niveau de capacité professionnelle"
                name="capacite.niveau"
                variant="outline-pop"
                options={recordToSelectOptions<GatewayCapacitePro.CapaciteProNiveauIOBSP>(
                  labelsByFormCapaciteProNiveauIOBSP
                )}
                onChange={(value) => {
                  formConfig.setValue("capacite.niveau", value, {
                    shouldValidate: true,
                  });
                  formConfig.setValue("capacite.typeJustificatif", null, {
                    shouldValidate: true,
                  });
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InputButtonGroup<
                CapaciteProIOBSPFormValues,
                false,
                GatewayCapacitePro.StatusProfessionel
              >
                size="medium"
                label="Statut professionnel"
                name="capacite.statutProfessionel"
                variant="outline-pop"
                options={recordToSelectOptions<GatewayCapacitePro.StatusProfessionel>(
                  labelsByStatusProfessionel
                )}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InputSelect<
                CapaciteProIOBSPFormValues,
                GatewayCapacitePro.CategorieIOBSP
              >
                label="Catégorie"
                name="capacite.categorie"
                options={recordToSelectOptions<GatewayCapacitePro.CategorieIOBSP>(
                  labelsByCategorieIOBSP
                )}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InputSelect<
                CapaciteProIOBSPFormValues,
                GatewayCapacitePro.GradeDiplome
              >
                label="Grade de diplôme obtenu"
                name="capacite.gradeDiplome"
                options={recordToSelectOptions<GatewayCapacitePro.GradeDiplome>(
                  labelsByGradeDiplome
                )}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InputText<CapaciteProIOBSPFormValues>
                label="Nom de diplôme obtenu"
                name="capacite.nomDiplome"
              />
            </FormField>
          </FormRow>
          <FormSubTitle>
            <h2>Pièces justificatives</h2>
          </FormSubTitle>
          <FormRow>
            <FormField>
              <InputSelect<CapaciteProIOBSPFormValues, string>
                label="Type de justificatif"
                name="capacite.typeJustificatif"
                options={typeJustificatifOptions}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InfoCard type="info">
                Seuls les justificatifs listés ci-dessus sont recevables au vu
                du niveau de l&apos;expérience de la personne.
              </InfoCard>
            </FormField>
          </FormRow>
        </>
      )}
    </FormGrid>
  );
}
