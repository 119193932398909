import { GatewayConformiteRcPro } from "@conformite/gateway";
import { ReactComponent as Check } from "@src/assets/icons/check.svg";
import { ReactComponent as XLg } from "@src/assets/icons/x-lg.svg";
import classNames from "classnames";
import styles from "../ConformiteRcPro.module.scss";
import { PropertyKeyWithoutFiles } from "../conformiteRcPro.helper";

const getPropertyStatusText = (
  value: GatewayConformiteRcPro.ConformiteRcProPropertyType,
  propertyKey: PropertyKeyWithoutFiles,
  millesime: number
) => {
  if (value === GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER) {
    if (
      millesime >= 2025 &&
      [
        "declarationActivitesIntraComnmunautaire",
        "travailleAvecMIADeclareAssureur",
        "collaborateursDistributionAssurance",
        "declareCollaborateurIOBSP",
      ].includes(propertyKey)
    ) {
      return "Non concerné";
    }
    return "À vérifier";
  }
  if (value === GatewayConformiteRcPro.ConformiteRcProPropertyType.OUI)
    return "Oui";
  return "Non";
};
const getPropertyStatusIcon = (
  value: GatewayConformiteRcPro.ConformiteRcProPropertyType,
  propertyKey: PropertyKeyWithoutFiles,
  millesime: number
): JSX.Element | null => {
  if (value === GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER) {
    if (
      millesime >= 2025 &&
      [
        "declarationActivitesIntraComnmunautaire",
        "collaborateursDistributionAssurance",
        "declareCollaborateurIOBSP",
      ].includes(propertyKey)
    ) {
      return <Check />;
    }
    return null;
  }
  if (value === GatewayConformiteRcPro.ConformiteRcProPropertyType.OUI)
    return <Check />;
  return <XLg />;
};
type PropertyStatusProps = {
  value: GatewayConformiteRcPro.ConformiteRcProPropertyType;
  propertyKey: PropertyKeyWithoutFiles;
  millesime: number;
};
export function PropertyStatus({
  value,
  propertyKey,
  millesime,
}: PropertyStatusProps) {
  return (
    <div
      className={classNames(styles.propertyVisu, {
        [styles.success]:
          value === GatewayConformiteRcPro.ConformiteRcProPropertyType.OUI ||
          (value ===
            GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER &&
            millesime >= 2025 &&
            [
              "declarationActivitesIntraComnmunautaire",
              "collaborateursDistributionAssurance",
              "declareCollaborateurIOBSP",
            ].includes(propertyKey)),
        [styles.error]:
          value === GatewayConformiteRcPro.ConformiteRcProPropertyType.NON,
      })}
    >
      {getPropertyStatusText(value, propertyKey, millesime)}
      {getPropertyStatusIcon(value, propertyKey, millesime)}
    </div>
  );
}
