import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { GatewayInfoSocieteOriasApi } from "@src/api/info-societe-orias.api";
import { useOnce } from "@src/hooks/useOnce";
import { EntrepriseInfoActiviteForm } from "@src/societes/form/EntrepriseInfoActiviteForm";
import {
  ParcoursEntrepriseStepComponentProps,
  ParcoursEntrepriseStepEnum,
} from "@src/societes/parcours/ParcoursEntreprise";
import {
  ID_FORM_ENTREPRISE,
  ParcoursEntrepriseActions,
} from "@src/societes/parcours/ParcoursEntrepriseActions";
import { ParcoursEntrepriseTemplate } from "@src/societes/parcours/ParcoursEntrepriseTemplate";
import { useState } from "react";

export const CATEGORIE_WITH_SOUS_CATEGORIE = [
  GatewayInfoSocieteOrias.Categorie.COBSP,
  GatewayInfoSocieteOrias.Categorie.MIOBSP,
];

function useValuesInfoActivites(
  data: AdhesionDataEntreprisesItem,
  editMode: boolean,
  updateDataAfterFetch: (
    siren: string,
    infoOrias: GatewayInfoSocieteOrias.InfoSocieteOriasType
  ) => void
) {
  const needFetch = editMode ? false : data.lastOriasFetch !== data.siren;
  const [isLoading, setIsLoading] = useState<boolean>(needFetch);

  useOnce(() => {
    if (!needFetch) return;
    GatewayInfoSocieteOriasApi.getBySiren({
      sirens: [data.siren as string],
      oriasIds: [],
    })
      .then((response) => {
        updateDataAfterFetch(
          data.siren as string,
          response.bySiren[data.siren as string]
        );
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  });

  return isLoading;
}

export function EntrepriseInfoActiviteStep({
  addData,
  data,
  changeStep,
  isEdit,
}: ParcoursEntrepriseStepComponentProps) {
  const isLoading = useValuesInfoActivites(
    data,
    !!isEdit,
    (siren, infoOrias) => {
      addData({
        lastOriasFetch: siren,
        numeroOrias: infoOrias.numeroOrias,
        statutOrias: infoOrias.statut,
        categoriesOrias: infoOrias.categories || [],
        coaAccessoire: false,
        cobspAccessoire: false,
        miaAccessoire: false,
        miobspAccessoire: false,
        sousCategorieCobsp: [],
        sousCategorieMiobsp: [],
      });
    }
  );

  if (isLoading) return <div />;

  return (
    <ParcoursEntrepriseTemplate
      title="Informations sur l'activité"
      actions={
        <ParcoursEntrepriseActions
          onPrevious={() =>
            changeStep(ParcoursEntrepriseStepEnum.IDENTITE_ET_ADRESSE)
          }
        />
      }
    >
      <EntrepriseInfoActiviteForm
        onSubmit={(response) => {
          addData({ ...response, categoriesOrias: response.categories });
          const nextStep = response.categories.find((categorie) =>
            CATEGORIE_WITH_SOUS_CATEGORIE.includes(categorie)
          )
            ? ParcoursEntrepriseStepEnum.SOUS_CATEGORIES
            : ParcoursEntrepriseStepEnum.ORGANISTATION_PROFESSIONNELLE;

          changeStep(nextStep);
        }}
        defaultValues={{
          categories: data.categoriesOrias || [],
          coaAccessoire: data.coaAccessoire || false,
          cobspAccessoire: data.cobspAccessoire || false,
          miaAccessoire: data.miaAccessoire || false,
          miobspAccessoire: data.miobspAccessoire || false,
          statutOrias:
            data.statutOrias ??
            GatewayInfoSocieteOrias.StatutSociete.JAMAIS_INSCRIT,
          numeroOrias: data.numeroOrias,
        }}
        formId={ID_FORM_ENTREPRISE}
      />
    </ParcoursEntrepriseTemplate>
  );
}
