import { Navigate, useNavigate } from "react-router-dom";
import { Parcours } from "@src/parcours/Parcours";
import { UIParcoursFullPage } from "@src/parcours/UIParcoursFullPage";
import { useMe } from "@src/store/store.me";
import { useEffect } from "react";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayEntreprise } from "@conformite/gateway";
import { useMillesime } from "@src/store/store.millesime";
import { RenouvellementIntroductionPage } from "./steps/introduction/RenouvellementIntroductionPage";
import { RenouvellementResumeEntreprisePage } from "./steps/resumeEntreprise/RenouvellementResumeEntreprisePage";
import { RenouvellementData } from "./steps/RenouvellementData";
import { RenouvellementIdentificationPage } from "./steps/identification/RenouvellementIdentificationPage";
import { RenouvellementAdressePage } from "./steps/adresse/RenouvellementAdressePage";
import { RenouvellementInformationsPersonnellesPage } from "./steps/informationsPersonnelles/RenouvellementInformationsPersonnelles";
import { RenouvellementStepEnum } from "./ParcoursRenouvellement.definition";
import { RenouvellementInfoActivitePage } from "./steps/informationsActivite/RenouvellementInfoActivitePage";
import { RenouvellementSousCategoriesPage } from "./steps/sousCategorie/RenouvellementSousCategoriesPage";
import { RenouvellementOrganisationProfessionnellesPage } from "./steps/organisationsProfessionnelles/RenouvellementOrganisationsProfessionnellesPage";
import { RenouvellementInformationsComplementairesPage } from "./steps/informationsComplementaires/RenouvellementInformationsComplementairesPage";
import { RenouvellementChiffreAffairePage } from "./steps/chiffreAffaire/RenouvellementChiffreAffairePage";
import { RenouvellementPaiementPage } from "./paiement/RenouvellementPaiementPage";
import { RenouvellementMajSocietesList } from "./steps/majSocietes/list/RenouvellementMajSocietesList";
import { RenouvellementMajSocietesListImport } from "./steps/majSocietes/list/RenouvellementMajSocietesListImport";
import { RenouvellementMajSocietesIntro } from "./steps/majSocietes/introduction/RenouvellementMajSocietesIntro";
import { persistRenouvellementCacheOnAPI } from "./cacheRenouvellement.utils";
import { useParcoursRenouvellementDefaults } from "./useParcoursRenouvellementDefaults";
import { RenouvellementCouponPage } from "./paiement/RenouvellementCouponPage";
import { RenouvellementOffertIntroductionPage } from "./steps/introduction/RenouvellementOffertIntroductionPage";

function useShouldRedirectOnRenouvellementNotNeeded(): boolean {
  const { me } = useMe();

  if (!me) return false;
  if (
    me.entreprisePrincipale.etat !==
    GatewayEntreprise.EtatEntreprise.A_RENOUVELER
  ) {
    return true;
  }
  return false;
}

export function useSetMillesimeToLastYear() {
  const { selected, setSelected } = useMillesime();
  const { me } = useMe();

  const selectedMillesimeIsLastYear =
    !!me && selected === me?.entreprisePrincipale.millesimeActuel;

  useEffect(() => {
    if (me && !selectedMillesimeIsLastYear) {
      setSelected(me?.entreprisePrincipale.millesimeActuel);
    }
  }, [me, selectedMillesimeIsLastYear, setSelected]);

  return selectedMillesimeIsLastYear;
}

export function ParcoursRenouvellement() {
  const navigate = useNavigate();
  const selectedMillesimeIsLastYear = useSetMillesimeToLastYear();
  const { me } = useMe();

  const defaults = useParcoursRenouvellementDefaults();

  const shouldRedirectToHome = useShouldRedirectOnRenouvellementNotNeeded();

  if (shouldRedirectToHome) return <Navigate to="/" replace />;
  if (!defaults || !selectedMillesimeIsLastYear || !me) return null;

  return (
    <Parcours<RenouvellementStepEnum, RenouvellementData>
      UIParcoursComponent={UIParcoursFullPage}
      afterChangeStep={(nextData, history) => {
        persistRenouvellementCacheOnAPI(nextData, history).catch((err) => {
          console.error(err);
          displayErrorToast("Votre Parcours n'a pas pu être sauvegardé");
        });
      }}
      handleQuit={async (data, history) => {
        try {
          await persistRenouvellementCacheOnAPI(data, history);
          navigate("/");
        } catch (err) {
          console.error(err);
          displayErrorToast("Votre Parcours n'a pas pu être sauvegardé");
        }
      }}
      withRouter
      rootPath="/renouvellement"
      config={{
        [RenouvellementStepEnum.INTRODUCTION]: {
          progress: 5,
          component: me.entreprisePrincipale.renouvellementOffert
            ? RenouvellementOffertIntroductionPage
            : RenouvellementIntroductionPage,
          centeredContent: true,
        },
        [RenouvellementStepEnum.RESUME_ENTREPRISE]: {
          progress: 30,
          component: RenouvellementResumeEntreprisePage,
        },
        [RenouvellementStepEnum.IDENTIFICATION]: {
          progress: 30,
          component: RenouvellementIdentificationPage,
        },
        [RenouvellementStepEnum.ADRESSE]: {
          progress: 30,
          component: RenouvellementAdressePage,
        },
        [RenouvellementStepEnum.INFORMATIONS_ACTIVITE]: {
          progress: 30,
          component: RenouvellementInfoActivitePage,
        },
        [RenouvellementStepEnum.SOUS_CATEGORIES]: {
          progress: 30,
          component: RenouvellementSousCategoriesPage,
        },
        [RenouvellementStepEnum.ORGANISATIONS_PROFESSIONNELLES]: {
          progress: 30,
          component: RenouvellementOrganisationProfessionnellesPage,
        },
        [RenouvellementStepEnum.INFORMATIONS_COMPLEMENTAIRES]: {
          progress: 30,
          component: RenouvellementInformationsComplementairesPage,
        },
        [RenouvellementStepEnum.INFORMATIONS_PERSONNELLES]: {
          progress: 30,
          component: RenouvellementInformationsPersonnellesPage,
        },
        [RenouvellementStepEnum.MISE_A_JOUR_SOCIETES]: {
          progress: 50,
          component: RenouvellementMajSocietesIntro,
        },
        [RenouvellementStepEnum.LISTE_SOCIETES]: {
          progress: 60,
          component: RenouvellementMajSocietesList,
        },
        [RenouvellementStepEnum.LISTE_SOCIETES_IMPORT]: {
          progress: 60,
          component: RenouvellementMajSocietesListImport,
        },
        [RenouvellementStepEnum.CHIFFRE_AFFAIRE]: {
          progress: 50,
          component: RenouvellementChiffreAffairePage,
        },
        [RenouvellementStepEnum.PAIEMENT]: {
          progress: 80,
          component: RenouvellementPaiementPage,
        },
        [RenouvellementStepEnum.CODE_REDUCTION]: {
          progress: 80,
          component: RenouvellementCouponPage,
        },
      }}
      firstStep={RenouvellementStepEnum.INTRODUCTION}
      initialData={defaults.data}
      defaultHistory={defaults.history}
      errorHandlers={{
        notFound: () => navigate("/404", { replace: true }),
        notAuthorized: () => navigate("/login", { replace: true }),
      }}
    />
  );
}
