import { FormApiAvancement, FormApiConformiteRcPro } from 'form-api';
import { QueueSQSTypeOCR } from 'analyse';

export const rcProUrl = 'rcPro';

export const copyRcProUrl = (
  siren: string,
  millesime?: number,
  categorie?: CategorieRcPro,
) =>
  `/rcPro/${siren}/${millesime ?? ':millesime'}/${
    categorie ?? ':categorie'
  }/copy`;

export type ConformiteRcProFile = FormApiConformiteRcPro.ConformiteRcProFile;
export type ConformiteRcProPropertyCOA =
  FormApiConformiteRcPro.ConformiteRcProPropertyCOA;
export type ConformiteRcProPropertyCOAKey = keyof ConformiteRcProPropertyCOA;
export type ConformiteRcProPropertyMIA =
  FormApiConformiteRcPro.ConformiteRcProPropertyMIA;
export type ConformiteRcProPropertyMIAKey = keyof ConformiteRcProPropertyMIA;
export type ConformiteRcProPropertyCOBSP =
  FormApiConformiteRcPro.ConformiteRcProPropertyCOBSP;
export type ConformiteRcProPropertyCOBSPKey =
  keyof ConformiteRcProPropertyCOBSP;
export type ConformiteRcProPropertyMIOBSP =
  FormApiConformiteRcPro.ConformiteRcProPropertyMIOBSP;
export type ConformiteRcProPropertyMIOBSPKey =
  keyof ConformiteRcProPropertyMIOBSP;

export type ConformiteRcProProperty =
  FormApiConformiteRcPro.ConformiteRcProProperty;
export type ConformiteRcProPropertyKey =
  FormApiConformiteRcPro.ConformiteRcProPropertyKey;
export const ConformiteRcProPropertyType =
  FormApiConformiteRcPro.ConformiteRcProPropertyType;
export type ConformiteRcProPropertyType =
  FormApiConformiteRcPro.ConformiteRcProPropertyType;

export type RcProFilesAndAvancementSociete =
  FormApiAvancement.RcProFilesAndAvancementSociete;
export type OCRInputQueueDTO = QueueSQSTypeOCR.InputQueueOCRDTO;
export type OCROutputQueueDTO = QueueSQSTypeOCR.OCRDTO;

export type CategorieRcPro = FormApiConformiteRcPro.CategorieOcrRcPro;
export const CategorieRcPro = FormApiConformiteRcPro.CategorieOcrRcPro;
