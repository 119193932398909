import { GatewayAdhesion, GatewayEntreprise } from "@conformite/gateway";
import { AdhesionData } from "@src/adhesion/AdhesionData/adhesionData";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import {
  CreationEntrepriseEIParams,
  CreationEntrepriseGroupeParams,
  CreationEntrepriseParams,
  GatewayAdhesion2Api,
  RenouvellementEntrepriseEIParams,
  RenouvellementEntrepriseGroupeParams,
  RenouvellementEntrepriseParams,
} from "@src/api/adhesion2.api";
import {
  localStorageHelper,
  LocalStorageKeys,
} from "@src/helper/localStorage.helper";
import { RenouvellementData } from "@src/renouvellement/steps/RenouvellementData";
import {
  getEntrepriseAControllerAdhesionEntreprise,
  getEntrepriseAControllerEI,
  getEntrepriseAControllerEIRenouvellement,
  getEntreprisesAControllerGroupe,
  getEntreprisesAControllerGroupeRenouvellement,
} from "./createIntentionPaiement";

export function getCreateEntrepriseParamsEntreprise(
  paiementIntention: GatewayAdhesion.CreateIntentionPaimentResponseType,
  societeRattachee: AdhesionDataEntreprisesItem,
  typeAdhesion: GatewayEntreprise.TypeAdhesionActive
): CreationEntrepriseParams {
  return [
    paiementIntention.paiement?.invoiceId,
    paiementIntention.hash,
    paiementIntention.idsEntreprisesNonAdherentes,
    getEntrepriseAControllerAdhesionEntreprise(societeRattachee),
    typeAdhesion,
  ];
}

export function getCreateEntrepriseParams(
  paiementIntention: GatewayAdhesion.CreateIntentionPaimentResponseType,
  data: AdhesionData
): CreationEntrepriseParams {
  if (!data.typeEntreprise.type) throw new Error("No type entreprise");
  if (
    data.typeEntreprise.type ===
    GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE
  ) {
    return [
      paiementIntention.paiement?.invoiceId,
      paiementIntention.hash,
      paiementIntention.idsEntreprisesNonAdherentes,
      getEntrepriseAControllerEI(data),
    ];
  }
  return [
    paiementIntention.paiement?.invoiceId,
    paiementIntention.hash,
    paiementIntention.idsEntreprisesNonAdherentes,
    getEntreprisesAControllerGroupe(data),
    data.typeEntreprise.type,
  ];
}

export function getCreateEntrepriseParamsRenouvellement<T extends boolean>(
  paiementIntention: T extends true
    ? null
    : GatewayAdhesion.CreateIntentionPaimentResponseType,
  data: RenouvellementData,
  _renouvellementOffert: T
): RenouvellementEntrepriseParams {
  if (
    data.typeAdhesion === GatewayEntreprise.TypeAdhesion.SOCIETE_INDIVIDUELLE
  ) {
    return [
      paiementIntention?.paiement?.invoiceId,
      paiementIntention?.hash,
      paiementIntention?.idsEntreprisesNonAdherentes ?? [],
      getEntrepriseAControllerEIRenouvellement(data),
      data.resume.contact,
      data.resume.entreprisePrincipale,
    ];
  }
  return [
    paiementIntention?.paiement?.invoiceId,
    paiementIntention?.hash,
    paiementIntention?.idsEntreprisesNonAdherentes ?? [],
    getEntreprisesAControllerGroupeRenouvellement(data),
    data.typeAdhesion as GatewayEntreprise.TypeAdhesionActive,
    data.resume.contact,
    data.resume.entreprisePrincipale,
  ];
}

export function storeEntrepriseCreationRequest(
  typeAdhesion: GatewayEntreprise.TypeAdhesionActive,
  creationEntrepriseParams:
    | CreationEntrepriseParams
    | RenouvellementEntrepriseParams
) {
  localStorageHelper.setItem(
    LocalStorageKeys.CREATION_ENTREPRISES_TYPE,
    typeAdhesion
  );

  localStorageHelper.setItem(
    LocalStorageKeys.CREATION_ENTREPRISES_PARAMS,
    JSON.stringify(creationEntrepriseParams)
  );
}
export function storeEntrepriseUpdatedRequest(
  entreprise: GatewayEntreprise.UpdateEntrepriseAControllerRequestType
) {
  localStorageHelper.setItem(
    LocalStorageKeys.UPDATED_ENTREPRISES_PARAMS,
    JSON.stringify(entreprise)
  );
}

export function retrieveEntrepriseCreationRequest() {
  const typeAdhesion = localStorageHelper.getItem(
    LocalStorageKeys.CREATION_ENTREPRISES_TYPE
  ) as GatewayEntreprise.TypeAdhesionActive | null;

  const creationEntrepriseParams = localStorageHelper.getItem(
    LocalStorageKeys.CREATION_ENTREPRISES_PARAMS
  );

  const typePaiement = localStorageHelper.getItem(
    LocalStorageKeys.CREATION_ENTREPRISES_TYPE_PAIEMENT
  );

  if (!typeAdhesion || !creationEntrepriseParams) {
    throw new Error(
      "Erreur lors de la récupération des données de création entreprises"
    );
  }

  return {
    typeAdhesion,
    creationEntrepriseParams: JSON.parse(creationEntrepriseParams) as
      | CreationEntrepriseParams
      | RenouvellementEntrepriseParams,
    typePaiement,
  };
}

export function retrieveEntrepriseUpdatedRequest() {
  const updatedEntrepriseParams = localStorageHelper.getItem(
    LocalStorageKeys.UPDATED_ENTREPRISES_PARAMS
  );

  const typePaiement = localStorageHelper.getItem(
    LocalStorageKeys.UPDATED_ENTREPRISES_TYPE_PAIEMENT
  );

  if (!updatedEntrepriseParams) {
    throw new Error(
      "Erreur lors de la récupération des données de création entreprises"
    );
  }

  return {
    updatedEntrepriseParams: JSON.parse(
      updatedEntrepriseParams
    ) as GatewayEntreprise.UpdateEntrepriseAControllerRequestType,
    typePaiement,
  };
}

export async function createEntreprises(
  typeAdhesion: GatewayEntreprise.TypeAdhesionActive,
  params: CreationEntrepriseParams
) {
  if (
    typeAdhesion === GatewayEntreprise.TypeAdhesionActive.SOCIETE_INDIVIDUELLE
  ) {
    return GatewayAdhesion2Api.createEntreprisesAfterPaiementEI(
      ...(params as CreationEntrepriseEIParams)
    );
  }
  return GatewayAdhesion2Api.createEntreprisesAfterPaiementGroupe(
    ...(params as CreationEntrepriseGroupeParams)
  );
}

export async function renouvellerEntreprises(
  typeAdhesion: GatewayEntreprise.TypeAdhesionActive,
  params: RenouvellementEntrepriseParams
) {
  if (
    typeAdhesion === GatewayEntreprise.TypeAdhesionActive.SOCIETE_INDIVIDUELLE
  ) {
    return GatewayAdhesion2Api.renouvellerEntreprisesAfterPaiementEI(
      ...(params as RenouvellementEntrepriseEIParams)
    );
  }
  return GatewayAdhesion2Api.renouvellerEntreprisesAfterPaiementGroupe(
    ...(params as RenouvellementEntrepriseGroupeParams)
  );
}

export async function changeCategoriesEntreprise(
  siren: string,
  millesime: number,
  entreprise: GatewayEntreprise.UpdateEntrepriseAControllerRequestType,
  paiementIntention: GatewayAdhesion.CreateIntentionPaimentResponseType
) {
  const { hubspotId, ...entrepriseWithoutHubspotId } = entreprise;
  return GatewayAdhesion2Api.changeCategoriesOfEntreprise(
    {
      ...entrepriseWithoutHubspotId,
      siren,
      millesime,
    },
    paiementIntention.paiement?.invoiceId,
    paiementIntention.hash,
    hubspotId
  );
}
