import { GatewayContact, GatewayInfoSocieteOrias } from "@conformite/gateway";
import { SelectOption } from "@lya-protect/lya-protect-form-library/dist/UI/Input/UIInputSelect";
import { AdhesionDataEntreprise } from "./adhesionDataEntreprise";
import { AdhesionDataEntrepriseAdresse } from "./adhesionDataEntrepriseAdresse";
import {
  AdhesionDataEntreprisesItem,
  AdhesionDataEntreprisesItemDoublons,
  AdhesionDataEntreprisesItemError,
} from "./adhesionDataEntreprises";
import { AdhesionDataTypeEntreprise } from "./adhesionDataTypeEntreprise";

export type AdhesionDataIdentifiants = {
  password: string;
  email: string;
};
export type AdhesionData = {
  identifiants: AdhesionDataIdentifiants;
  entreprise: AdhesionDataEntreprise;
  entrepriseAdresse: AdhesionDataEntrepriseAdresse;
  informationsPersonnelles: {
    prenom: string;
    nom: string;
    telephone: string;
    fonction: GatewayContact.ContactFonction;
    civilite: GatewayContact.ContactCivilite;
  };
  typeEntreprise: AdhesionDataTypeEntreprise;
  informationsActivite: {
    statutOrias?: GatewayInfoSocieteOrias.StatutSociete;
    numeroOrias?: string;
    categories?: GatewayInfoSocieteOrias.Categorie[];
    coaAccessoire: boolean;
    miaAccessoire: boolean;
    cobspAccessoire: boolean;
    miobspAccessoire: boolean;
    sousCategoriesCOBSP: GatewayInfoSocieteOrias.ActiviteBancaire[];
    sousCategoriesMIOBSP: GatewayInfoSocieteOrias.ActiviteBancaire[];
  };
  organisationsProfessionnelles: GatewayInfoSocieteOrias.OrganisationProfessionnelle[];
  consents: {
    consentCGV?: true;
    consentNewsletter?: boolean;
  };
  informationsComplementaires: {
    categorieRepresentation?: GatewayInfoSocieteOrias.CategorieRepresentation;
    chiffreAffairesEnEurosNMoins1?: number;
    consentementEDI: boolean;
  };
  societesRattachees: AdhesionDataEntreprisesItem[];
  societesDoublons: AdhesionDataEntreprisesItemDoublons[];
  importInError: AdhesionDataEntreprisesItemError[];
  coupon?: string;
};

export const adhesionDataDefault: AdhesionData = {
  consents: {},
  entreprise: {},
  identifiants: { email: "", password: "" },
  entrepriseAdresse: {},
  informationsPersonnelles: {
    prenom: "",
    nom: "",
    telephone: "",
    civilite: "",
    fonction: "",
  } as unknown as AdhesionData["informationsPersonnelles"],
  typeEntreprise: {},
  informationsActivite: {
    categories: [],
    coaAccessoire: false,
    miaAccessoire: false,
    cobspAccessoire: false,
    miobspAccessoire: false,
    sousCategoriesCOBSP: [],
    sousCategoriesMIOBSP: [],
  },
  organisationsProfessionnelles: [],
  informationsComplementaires: {
    chiffreAffairesEnEurosNMoins1: undefined,
    consentementEDI: false,
  },
  societesRattachees: [],
  societesDoublons: [],
  importInError: [],
  coupon: undefined,
};

export const recordToSelectOptions = <T extends string>(
  record: Record<T, string>
) =>
  Object.entries(record).map(([value, label]) => ({
    value,
    label,
  })) as SelectOption<T>[];
