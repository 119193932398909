import {
  GatewayAssujetti,
  GatewayCapacitePro,
  GatewayEntreprise,
} from "@conformite/gateway";
import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  Card,
  FormTitle,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayCapaciteProApi } from "@src/api/conformite/capacitePro.api";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import {
  ConformiteFormCapaciteProIOBSPContent,
  useCapaciteProIOBSP,
} from "@src/assujettis/AssujettiPaneContent/pages/AssujettiParcoursConformite/Steps/FormsCapacitePro/ConformiteFormCapaciteProIOBSP/ConformiteFormCapaciteProIOBSPContent";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { Link } from "@src/components/Link/Link";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { isMono } from "@src/helper/entreprise.helper";
import { useOnce } from "@src/hooks/useOnce";
import { useAssujetti } from "@src/store/store.assujetti";
import { useEntreprises } from "@src/store/store.entreprise";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styles from "../assujettis/AssujettiForm.module.scss";

type ConformiteCapaciteProfessionnelleContentProps = {
  assujetti: GatewayAssujetti.AssujettiWithAvancement;
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType;
  fromAxe: string | null;
  capaciteProfessionnelle: GatewayCapacitePro.CapaciteProfessionnelleIOBSP | null;
};
function ConformiteCapaciteProfessionnelleContent({
  assujetti,
  fromAxe,
  entreprise,
  capaciteProfessionnelle,
}: ConformiteCapaciteProfessionnelleContentProps) {
  const isMonoActivite = isMono(entreprise.categoriesActivites);
  const {
    onSubmit,
    formConfig,
    typeJustificatifOptions,
    conditionCapaciteProfessionnelleValue,
  } = useCapaciteProIOBSP(
    { assujetti },
    capaciteProfessionnelle,
    isMonoActivite,
    () => {
      displaySucessToast("La conformité a bien été mise à jour");
    }
  );
  useOnce(() => {
    if (capaciteProfessionnelle !== null)
      formConfig.trigger().catch(console.error);
  });
  if (!entreprise || !assujetti) return null;
  return (
    <MainContentPageTemplate
      title={
        <FormTitle className={styles.header}>
          <h1 className={styles.title}>
            <b>Capacité Professionnelle IOBSP</b> - {assujetti.surname}{" "}
            {assujetti.name}
          </h1>
          <IconStatus
            isValid={assujetti.avancement.capaciteProfessionnelleIobsp === 100}
          />
        </FormTitle>
      }
      actionNavigation={
        <Link
          to={`/conformite/capacite-professionnelle/iobsp/${entreprise.siren}${
            fromAxe === null ? "" : "?fromAxe"
          }`}
        >
          <Button variant="text" icon={<ChevronLeft />}>
            Retour
          </Button>
        </Link>
      }
    >
      <Card>
        <Form formConfig={formConfig} onSubmit={onSubmit}>
          <ConformiteFormCapaciteProIOBSPContent
            hideTitle
            data={{ assujetti }}
            conditionCapaciteProfessionnelleValue={
              conditionCapaciteProfessionnelleValue
            }
            typeJustificatifOptions={typeJustificatifOptions}
            formConfig={formConfig}
            isMonoActivite={isMonoActivite}
          />
        </Form>
        <div className={styles.action}>
          <Button
            onClick={() => {
              onSubmit(formConfig.getValues()).catch((e) => {
                console.error(e);
              });
            }}
          >
            Sauvegarder
          </Button>
        </div>
      </Card>
    </MainContentPageTemplate>
  );
}

export function ConformiteCapaciteProfessionnelleIobsp() {
  const { entreprisesBySiren } = useEntreprises();
  const { assujettis } = useAssujetti();
  const { siren, id } = useParams();
  const entreprise = entreprisesBySiren?.[siren || ""];
  const assujetti = assujettis?.[id || ""];
  const [query] = useSearchParams();
  const fromAxe = query.get("fromAxe");
  const [capaciteProfessionnelle, setCapaciteProfessionnelle] =
    useState<GatewayCapacitePro.CapaciteProfessionnelleIOBSP | null>();
  useEffect(() => {
    if (!assujetti) return;
    GatewayCapaciteProApi.getIOBSP(assujetti.id, assujetti.millesime)
      .then((data) => {
        setCapaciteProfessionnelle(data);
      })
      .catch((e) => {
        console.error(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assujetti]);
  if (
    !entreprise ||
    !assujetti ||
    !siren ||
    capaciteProfessionnelle === undefined
  )
    return null;
  return (
    <ConformiteCapaciteProfessionnelleContent
      entreprise={entreprise}
      assujetti={assujetti}
      fromAxe={fromAxe}
      capaciteProfessionnelle={capaciteProfessionnelle}
    />
  );
}
