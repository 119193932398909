import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import { ParcoursAdhesionSaveAndQuitAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionSaveAndQuitAction";
import {
  AdhesionStepEnum,
  ID_FORM_ADHESION,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import {
  EntrepriseInformationsComplementairesForm,
  EntrepriseInformationsComplementairesFormHandle,
} from "@src/societes/form/EntrepriseInformationsComplementairesForm";
import { useRef } from "react";

export function AdhesionInformationsComplementairesPage({
  data,
  addData,
  changeStep,
  handleQuit,
}: ParcoursAdhesionStepComponentProps) {
  const formRef = useRef<EntrepriseInformationsComplementairesFormHandle>(null);

  return (
    <ParcoursFullPageTemplate
      title="Informations complémentaires"
      actions={
        <>
          <ParcoursAdhesionSaveAndQuitAction
            addData={addData}
            formConfig={() => formRef?.current?.getFormConfig()}
            handleQuit={handleQuit}
            transformValues={(formValues) => ({
              informationsComplementaires: formValues,
            })}
          />
          <ParcoursAdhesionNextAction type="submit">
            Suivant
          </ParcoursAdhesionNextAction>
        </>
      }
    >
      <EntrepriseInformationsComplementairesForm
        ref={formRef}
        defaultValues={data.informationsComplementaires}
        onSubmit={(informationsComplementaires) => {
          addData({ informationsComplementaires });
          changeStep(AdhesionStepEnum.PAIEMENT_ADHESION);
        }}
        formId={ID_FORM_ADHESION}
        isCOA={
          data.informationsActivite.coaAccessoire ||
          !!data.informationsActivite.categories?.includes(
            GatewayInfoSocieteOrias.Categorie.COA
          )
        }
      />
    </ParcoursFullPageTemplate>
  );
}
