import { GatewayGarantieFinanciere } from "@conformite/gateway";
import {
  ConformiteGarantieFinanciereEditFormIas,
  useConformiteGarantieFinanciereIasEdit,
} from "@src/conformite/garantieFinanciere/edit/ConformiteGarantieFinanciereEditFormIas";
import { useEffect, useState } from "react";
import { GatewayGarantieFinanciereApi } from "@src/api/conformite/garantieFinanciere.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ControleQuinquennalAxeProps } from "./ControleQuinquennalAxe.type";

type ControleQuinquennalAxeGarantieFinanciereIasContentProps =
  ControleQuinquennalAxeProps & {
    garantieFinanciere: GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas | null;
  };
function ControleQuinquennalAxeGarantieFinanciereIasContent({
  controleQuinquennal,
  entreprise,
  garantieFinanciere,
}: ControleQuinquennalAxeGarantieFinanciereIasContentProps) {
  const { formConfig } = useConformiteGarantieFinanciereIasEdit(
    entreprise.siren,
    controleQuinquennal.millesime,
    garantieFinanciere,
    () => null
  );
  return (
    <ConformiteGarantieFinanciereEditFormIas
      millesime={controleQuinquennal.millesime}
      siren={entreprise.siren}
      garantieFinanciere={garantieFinanciere}
      formConfig={formConfig}
      disabled
    />
  );
}
export function ControleQuinquennalAxeGarantieFinanciereIas({
  controleQuinquennal,
  entreprise,
  axe,
  setIsValid,
}: ControleQuinquennalAxeProps) {
  const [garantieFinanciere, setGarantieFinanciere] =
    useState<GatewayGarantieFinanciere.ConformiteGarantieFinanciereIas | null>();
  useEffect(() => {
    GatewayGarantieFinanciereApi.getGarantieFinanciereIas(
      entreprise.siren,
      controleQuinquennal.millesime,
      true
    )
      .then((data) => {
        setGarantieFinanciere(data);
      })
      .catch((error) => {
        handleGatewayError({
          onUnhandled: () => {
            displayErrorToast(
              "Une erreur est survenue lors de la récupération du formulaire"
            );
          },
        })(error);
      });
  }, [controleQuinquennal.millesime, entreprise.siren]);
  if (garantieFinanciere === undefined) return null;
  return (
    <ControleQuinquennalAxeGarantieFinanciereIasContent
      controleQuinquennal={controleQuinquennal}
      entreprise={entreprise}
      axe={axe}
      garantieFinanciere={garantieFinanciere}
      setIsValid={setIsValid}
    />
  );
}
