import {
  GatewayAvancement,
  GatewayConformiteRcPro,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { useFormConfig } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayRcProApi } from "@src/api/conformite/rcPro.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { millesimeHasEveryDay } from "@src/conformite/conformite.helper";
import { useOnce } from "@src/hooks/useOnce";
import { chain, merge } from "lodash";
import { useCallback, useMemo } from "react";
import {
  RcProPropertyDefault,
  getBlacklistedProperties,
} from "../conformiteRcPro.helper";

export const useConformiteRcProEdit = (
  siren: string,
  millesime: number,
  categorie: GatewayInfoSocieteOrias.Categorie,
  rcPro: GatewayConformiteRcPro.ConformiteRcProProperty | null,
  stopEditing: (
    value: GatewayConformiteRcPro.ConformiteRcProProperty | undefined,
    avancement: GatewayAvancement.AvancementSociete
  ) => void
) => {
  const properties = useMemo(() => {
    const defaultProperties = RcProPropertyDefault[categorie];
    return merge({ ...defaultProperties }, rcPro);
  }, [rcPro, categorie]);

  const schema = useMemo(() => {
    return zod
      .object({
        ...Object.keys(properties ?? {}).reduce(
          (acc, key) => {
            if (key === "nombreMIOBSP") {
              return {
                ...acc,
                nombreMIOBSP: zod.preprocess(
                  (v) => parseInt(zod.string().parse(v), 10),
                  zod
                    .number({
                      errorMap: () => ({
                        message: "Veuillez renseigner un nombre",
                      }),
                    })
                    .min(0, "Veuillez renseigner un nombre positif")
                    .optional()
                ),
              };
            }
            if (key === "nombreMIA") {
              return {
                ...acc,
                nombreMIA: zod.preprocess(
                  (v) => parseInt(zod.string().parse(v), 10),
                  zod
                    .number({
                      errorMap: () => ({
                        message: "Veuillez renseigner un nombre",
                      }),
                    })
                    .min(0, "Veuillez renseigner un nombre positif")
                    .optional()
                ),
              };
            }
            return getBlacklistedProperties(categorie, millesime).includes(key)
              ? acc
              : {
                  ...acc,
                  [key]: zod
                    .nativeEnum(
                      GatewayConformiteRcPro.ConformiteRcProPropertyType
                    )
                    .default(
                      GatewayConformiteRcPro.ConformiteRcProPropertyType
                        .A_VERIFIER
                    )
                    .refine((value) => value !== "A_VERIFIER", {
                      message: "Champs obligatoire",
                    }),
                };
          },
          {} as {
            [key in GatewayConformiteRcPro.ConformiteRcProPropertyKey]: zod.ZodTypeAny;
          }
        ),
        files: zod
          .array(
            zod.union([
              zod.undefined(),
              zod.object({
                filename: zod.string().optional(),
                pathname: zod.string().optional(),
                startDate: zod.date().optional(),
                endDate: zod.date().optional(),
                orias: zod.string().optional(),
                nomAssureur: zod.string().optional(),
                file: zod.instanceof(File).optional(),
                isValid: zod.boolean(),
              }),
            ])
          )
          .min(1),
      })
      .superRefine((data, ctx) => {
        if (
          millesime >= 2025 &&
          data.travailleAvecMIOBSP === "OUI" &&
          !data.nombreMIOBSP
        ) {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: "Champs obligatoire",
            path: [`nombreMIOBSP`],
          });
        }
        if (
          millesime >= 2025 &&
          data.travailleAvecMIA === "OUI" &&
          !data.nombreMIA
        ) {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: "Champs obligatoire",
            path: [`nombreMIA`],
          });
        }
        if (data.rcpIndividuelleSouscrit === "NON") {
          data.files.forEach((file, index) => {
            if (!file?.orias) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                message: "Champs obligatoire",
                path: [`files.${index}.orias`],
              });
            }
          });
        } else {
          data.files.forEach((file, index) => {
            if (!file?.startDate) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                message: "Champs obligatoire",
                path: [`files.${index}.startDate`],
              });
            }
            if (!file?.endDate) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                message: "Champs obligatoire",
                path: [`files.${index}.endDate`],
              });
            }

            if (!file?.nomAssureur && millesime >= 2025) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                message: "Champs obligatoire",
                path: [`files.${index}.nomAssureur`],
              });
            }
          });
          if (!millesimeHasEveryDay(millesime, data.files)) {
            ctx.addIssue({
              code: zod.ZodIssueCode.custom,
              message: "Vos attestations doivent couvrir l'année entière",
              path: ["files.0.startDate"],
            });
          }
        }
      });
  }, [millesime, properties, categorie]);
  type ConformiteRcProFormValues = zod.infer<typeof schema>;
  const formConfig = useFormConfig({
    schema,
    defaultValues: {
      ...properties,
      files: properties?.files?.map((file) => ({
        ...file,
        startDate: file?.startDate
          ? new Date(file.startDate as unknown as string)
          : undefined,
        endDate: file?.endDate
          ? new Date(file.endDate as unknown as string)
          : undefined,
      })),
    } as ConformiteRcProFormValues,
  });
  const onSubmit = useCallback(
    async ({ files, ...data }: ConformiteRcProFormValues) => {
      const filesToSend = chain(files)
        .filter((file) => !!file?.file)
        .map((file) => file?.file)
        .value() as File[];
      const filesWithoutBlob = files.map((fileData) => {
        if (!fileData?.file) return fileData;
        const { file, ...rest } = fileData;
        return rest;
      });
      const payload = {
        ...data,
        files: filesWithoutBlob,
      } as GatewayConformiteRcPro.ConformiteRcProProperty;
      try {
        const avancement = await GatewayRcProApi.saveRcPro(
          siren,
          millesime,
          categorie,
          payload,
          filesToSend
        );
        stopEditing(payload, avancement);
      } catch (err) {
        handleGatewayError({
          onUnhandled: () => {
            displayErrorToast(
              "Une erreur est survenue lors de la récupération du formulaire"
            );
          },
        })(err);
      }
    },
    [categorie, millesime, siren, stopEditing]
  );
  const formWatched = formConfig.watch();
  const rcpIndividuelleSouscrit = formWatched.rcpIndividuelleSouscrit as
    | GatewayConformiteRcPro.ConformiteRcProPropertyType
    | undefined;
  const hasRcpIndividuelleSouscritSetToNo =
    rcpIndividuelleSouscrit ===
    GatewayConformiteRcPro.ConformiteRcProPropertyType.NON;
  useOnce(() => {
    if (rcPro !== null) {
      formConfig.trigger().catch(console.error);
    }
  });

  return useMemo(
    () => ({
      formConfig,
      properties,
      onSubmit,
      hasRcpIndividuelleSouscritSetToNo,
    }),
    [formConfig, properties, onSubmit, hasRcpIndividuelleSouscritSetToNo]
  );
};
