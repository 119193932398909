import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputPassword,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormAction,
  FormBloc,
  FormField,
  FormGrid,
  FormRow,
  FormTitle,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { PasswordCriterias } from "@src/components/PasswordCriterias/PasswordCriterias";
import { Header } from "@src/components/Header/Header";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { userSession } from "@src/session/UserSession";
import { GatewayAuthApi } from "@src/api/auth.api";
import { passwordWithConfirmationSchema } from "../Signup/password.definition";
import styles from "./Login.module.scss";

const schema = passwordWithConfirmationSchema;
export type UpdatePasswordFormValues = zod.infer<typeof schema>;
export function NewPassword() {
  const [query] = useSearchParams();
  const urlRedirect = query.get("url");
  const session = query.get("session");
  const { userId } = useParams<{ userId: string }>();
  const formConfig = useFormConfig<typeof schema, UpdatePasswordFormValues>({
    schema,
  });
  const navigate = useNavigate();
  const onSubmit = async (submittedData: UpdatePasswordFormValues) => {
    const response = await GatewayAuthApi.confirmPassword({
      userId: userId ?? "",
      session: session ?? "",
      password: submittedData.password,
    });
    userSession.sessionData = {
      accessToken: response.accessToken,
      refreshToken: response.refreshToken,
      idToken: response.idToken,
      isConnected: true,
      hasDoubleAuth: response.hasDoubleAuth ?? false,
    };

    navigate(urlRedirect ?? "/", { replace: true });
  };

  const passwordValue = formConfig.watch("password");

  return (
    <div>
      <Header />
      <MaxWidthContent>
        <Form formConfig={formConfig} onSubmit={onSubmit}>
          <FormGrid disableHorizontalGutter>
            <FormTitle>
              <h1>Définissez votre nouveau mot de passe</h1>
            </FormTitle>
            <FormRow>
              <FormField>
                <InputPassword<UpdatePasswordFormValues>
                  name="password"
                  label="Nouveau mot de passe"
                  required
                />
              </FormField>
            </FormRow>

            <FormBloc>
              <PasswordCriterias password={passwordValue} />
            </FormBloc>

            <FormRow>
              <FormField>
                <InputPassword<UpdatePasswordFormValues>
                  name="confirm"
                  label="Confirmer le nouveau mot de passe"
                  required
                />
              </FormField>
            </FormRow>
            <FormAction className={styles.buttons}>
              <Button type="submit">Changer de mot de passe</Button>
            </FormAction>
          </FormGrid>
        </Form>
      </MaxWidthContent>
    </div>
  );
}
