export function ControleQuinquennalExplainAxeRcProMiobsp() {
  return (
    <div>
      <p>
        Les documents sollicités ci-dessous permettront de vérifier la
        couverture de l&apos;activité de MIOBSP par une garantie RC
        Professionnelle conforme aux exigences légales (activité couverte,
        territoire et période de couverture, montants de garantie et de
        franchise).
      </p>
      <br />
      <p>
        Pièces à fournir mentionnant la franchise et la territorialité de la
        garantie RC Professionnelle souscrite :
      </p>
      <ul>
        <li>
          <b>
            Si vous disposez de votre propre garantie RC professionnelle MIOBSP
            :
          </b>
        </li>
        <ul>
          <li>Conditions particulières</li>
          <li>Et Conditions générales</li>
          <li>
            Et/ou tout autre document tel que le tableau de garantie ou le
            certificat d&apos;adhésion
          </li>
        </ul>
        <li>
          <b>
            Si vous bénéficiez de la couverture RC professionnelle du ou des
            mandants :
          </b>
        </li>
        <ul>
          <li>
            L&apos;ensemble de vos mandats de mandataire d&apos;intermédiaire en
            opérations de banque et en services de paiement en cours de validité
          </li>
          <li>Pour chacun de ces mandats :</li>
          <ul>
            <li>
              Les conditions particulières et conditions générales de la
              garantie RC Professionnelle de votre mandant mentionnant la
              franchise et votre couverture en qualité de mandataire
            </li>
            <li>
              La liste nominative des mandataires couverts par la garantie RC
              Professionnelle de votre mandant
            </li>
          </ul>
        </ul>
      </ul>
    </div>
  );
}
