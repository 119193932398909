import { TagVariant } from "./Tag";
import { TagWithIcon } from "./TagWithIcon";

type TagConformiteProps = {
  isConforme: boolean;
  year: number;
  isEditing?: boolean;
  isOptional?: boolean;
};
const getInfo = (
  isConforme: boolean,
  isEditing?: boolean,
  isOptional?: boolean
): {
  status: TagVariant;
  text: string;
} => {
  if (isEditing)
    return {
      text: "En cours de modification",
      status: "info",
    };
  if (isConforme)
    return {
      text: "Conforme",
      status: "success",
    };
  if (isOptional)
    return {
      text: "Non obligatoire",
      status: "warning",
    };
  return {
    text: "Non Conforme",
    status: "error",
  };
};
export function TagConformite({
  isConforme,
  year,
  isEditing,
  isOptional,
}: TagConformiteProps) {
  const { status, text: textStatus } = getInfo(
    isConforme,
    isEditing,
    isOptional
  );
  const text = `${year} - ${textStatus}`;
  return <TagWithIcon variant={status}>{text}</TagWithIcon>;
}
