import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayAssujettiApi } from "@src/api/assujetti.api";
import { AssujettiDataItemError } from "@src/assujettis/AssujettiData";
import { AssujettiImportDashboard } from "@src/assujettis/ImportExport/Import/AssujettiImportDashboard";
import { AssujettiImportEdit } from "@src/assujettis/ImportExport/Import/AssujettiImportEdit";
import {
  ImportAssujettiItem,
  useImportAssujetti,
} from "@src/assujettis/ImportExport/Import/useImportAssujetti";
import { SidePane } from "@src/components/SidePane/SidePane";
import { TitleWithNumber } from "@src/components/TitleWithNumber/TitleWithNumber";
import { useAssujetti } from "@src/store/store.assujetti";
import { useMillesime } from "@src/store/store.millesime";
import { useState } from "react";
import {
  ParcoursConformiteStepComponentProps,
  ParcoursConformiteStepEnum,
} from "../../ParcoursConformite";
import { ParcoursConformiteTemplate } from "../../ParcoursConformiteTemplate";

type PaneState = {
  open: boolean;
  assujettiToEdit?: AssujettiDataItemError;
  indexToEdit?: number;
};

export function ConformiteAssujettisDashboardStep({
  changeStep,
  currentStep,
  data,
  addData,
}: ParcoursConformiteStepComponentProps) {
  const { generateErrorsFile, importAssujettis } = useImportAssujetti();
  const { setEntries } = useAssujetti();
  const { selected: currentMillesime } = useMillesime();
  const [paneState, setPaneState] = useState<PaneState>({
    open: false,
  });

  if (!currentMillesime) return <div />;

  return (
    <ParcoursConformiteTemplate
      currentStep={currentStep}
      changeStep={changeStep}
      isConforme={
        data.assujettis.errors.length === 0 &&
        data.assujettis.success.length > 0
      }
      title={<TitleWithNumber title="Personnes assujetties" />}
      size="large"
      onSubmit={async () => {
        try {
          await importAssujettis({
            assujettis: data.assujettis.success.map(({ data: d }) => d),
            clean: false,
            callback: () => {
              GatewayAssujettiApi.list(currentMillesime)
                .then((list) => {
                  setEntries(list);
                  changeStep(ParcoursConformiteStepEnum.IMPORT_ASSUJETTIS);
                })
                .catch((e) => {
                  console.error(e);
                  displayErrorToast(
                    "Une erreur est survenue lors de la mise à jour des assujettis"
                  );
                });
            },
          });
        } catch (e) {
          console.error(e);
          displayErrorToast(
            "Une erreur est survenue lors de l'import des assujettis"
          );
        }
      }}
    >
      <AssujettiImportDashboard
        errors={data.assujettis.errors}
        success={data.assujettis.success}
        importButton={null}
        onExport={() => {
          generateErrorsFile(data.assujettis.errors).catch((e) => {
            console.error(e);
            displayErrorToast(
              "Une erreur est survenue lors de l'export des assujettis en erreur"
            );
          });
        }}
        onUpdateError={(error) =>
          setPaneState({
            open: true,
            assujettiToEdit: data.assujettis.errors.find(
              (e) => e.index === error.index
            ),
            indexToEdit: data.assujettis.errors.findIndex(
              (e) => e.index === error.index
            ),
          })
        }
      />
      <SidePane
        isOpen={paneState.open}
        setIsOpen={(nextIsOpen) => {
          setPaneState((prevState) => ({ ...prevState, open: nextIsOpen }));
        }}
      >
        {(closePane) => {
          if (!paneState.assujettiToEdit) return null;
          return (
            <AssujettiImportEdit
              key={`${JSON.stringify(paneState.assujettiToEdit)}`}
              rowToEdit={paneState.assujettiToEdit}
              onSubmit={(editedAssujetti: ImportAssujettiItem) => {
                addData({
                  assujettis: {
                    success: [...data.assujettis.success, editedAssujetti],
                    errors: data.assujettis.errors.filter(
                      (e) => e.index !== editedAssujetti.index
                    ),
                  },
                });
                setPaneState({ open: false });
                closePane(true);
              }}
            />
          );
        }}
      </SidePane>
    </ParcoursConformiteTemplate>
  );
}
