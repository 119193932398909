import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { EntrepriseInformationsComplementairesForm } from "@src/societes/form/EntrepriseInformationsComplementairesForm";
import {
  ParcoursEntrepriseStepComponentProps,
  ParcoursEntrepriseStepEnum,
} from "@src/societes/parcours/ParcoursEntreprise";
import {
  ID_FORM_ENTREPRISE,
  ParcoursEntrepriseActions,
} from "@src/societes/parcours/ParcoursEntrepriseActions";
import { ParcoursEntrepriseTemplate } from "@src/societes/parcours/ParcoursEntrepriseTemplate";

export function EntrepriseInformationsComplementairesStep({
  data,
  changeStep,
  onSubmit,
  addData,
  withPayment,
}: ParcoursEntrepriseStepComponentProps) {
  return (
    <ParcoursEntrepriseTemplate
      title="Informations complémentaires"
      actions={
        <ParcoursEntrepriseActions
          onPrevious={() =>
            changeStep(ParcoursEntrepriseStepEnum.ORGANISTATION_PROFESSIONNELLE)
          }
        />
      }
    >
      <EntrepriseInformationsComplementairesForm
        isCOA={
          !!data.categoriesOrias?.includes(
            GatewayInfoSocieteOrias.Categorie.COA
          )
        }
        defaultValues={{
          categorieRepresentation: data.categorieRepresentation,
          chiffreAffairesEnEurosNMoins1: data.chiffreAffaire,
          consentementEDI: data.consentementEDI,
        }}
        onSubmit={({
          categorieRepresentation,
          chiffreAffairesEnEurosNMoins1,
          consentementEDI,
        }) => {
          const additionalData = {
            categorieRepresentation,
            chiffreAffaire: chiffreAffairesEnEurosNMoins1,
            consentementEDI,
          };
          addData(additionalData);

          if (withPayment) {
            changeStep(ParcoursEntrepriseStepEnum.PAIEMENT);
            return;
          }
          onSubmit({ ...data, ...additionalData });
        }}
        formId={ID_FORM_ENTREPRISE}
      />
    </ParcoursEntrepriseTemplate>
  );
}
