import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import {
  AdhesionDataEntreprisesItem,
  AdhesionDataEntreprisesItemDoublons,
  AdhesionDataEntreprisesItemError,
} from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import {
  excelLabelByCategorieRepresentation,
  excelLabelByFormeJuridique,
  excelLabelByOrganisationProfessionnelle,
  excelLabelByPays,
  excelLabelBySousCategorie,
  excelLabelByTrancheEffectif,
} from "@src/societes/export/EntrepriseExport.definition";

export const transformEntrepriseInErrorApiRowsToRows = (
  entreprise:
    | AdhesionDataEntreprisesItem
    | AdhesionDataEntreprisesItemError
    | AdhesionDataEntreprisesItemDoublons
) => {
  const {
    siren,
    numeroOrias,
    formeJuridique,
    raisonSociale,
    typeVoie,
    numeroVoie,
    libelleVoie,
    categorieRepresentation,
    categoriesOrias,
    chiffreAffaire,
    coaAccessoire,
    cobspAccessoire,
    codePostal,
    complementAdresse,
    miaAccessoire,
    miobspAccessoire,
    organisationPro,
    pays,
    sousCategorieCobsp,
    sousCategorieMiobsp,
    trancheEffectif,
    ville,
  } = entreprise;
  return {
    siren,
    numeroOrias,
    formeJuridique: formeJuridique
      ? excelLabelByFormeJuridique[formeJuridique]
      : undefined,
    raisonSociale,
    typeVoie,
    numeroVoie,
    adresse: libelleVoie,
    complementAdresse,
    codePostal,
    ville,
    pays: pays ? excelLabelByPays[pays] : undefined,
    trancheEffectif: trancheEffectif
      ? excelLabelByTrancheEffectif[trancheEffectif]
      : undefined,
    chiffreAffaire,
    categorieOriasCOA: categoriesOrias?.includes(
      GatewayInfoSocieteOrias.Categorie.COA
    ),
    coaAccessoire,
    categorieOriasMIA: categoriesOrias?.includes(
      GatewayInfoSocieteOrias.Categorie.MIA
    ),
    miaAccessoire,
    categorieOriasCOBSP: categoriesOrias?.includes(
      GatewayInfoSocieteOrias.Categorie.COBSP
    ),
    cobspAccessoire,
    categorieOriasMIOBSP: categoriesOrias?.includes(
      GatewayInfoSocieteOrias.Categorie.MIOBSP
    ),
    miobspAccessoire,
    sousCategorieCobsp1: sousCategorieCobsp?.[0]
      ? excelLabelBySousCategorie[sousCategorieCobsp[0]]
      : undefined,
    sousCategorieCobsp2: sousCategorieCobsp?.[1]
      ? excelLabelBySousCategorie[sousCategorieCobsp[1]]
      : undefined,
    sousCategorieCobsp3: sousCategorieCobsp?.[2]
      ? excelLabelBySousCategorie[sousCategorieCobsp[2]]
      : undefined,
    sousCategorieCobsp4: sousCategorieCobsp?.[3]
      ? excelLabelBySousCategorie[sousCategorieCobsp[3]]
      : undefined,
    sousCategorieCobsp5: sousCategorieCobsp?.[4]
      ? excelLabelBySousCategorie[sousCategorieCobsp[4]]
      : undefined,
    sousCategorieCobsp6: sousCategorieCobsp?.[5]
      ? excelLabelBySousCategorie[sousCategorieCobsp[5]]
      : undefined,
    sousCategorieMiobsp1: sousCategorieMiobsp?.[0]
      ? excelLabelBySousCategorie[sousCategorieMiobsp[0]]
      : undefined,
    sousCategorieMiobsp2: sousCategorieMiobsp?.[1]
      ? excelLabelBySousCategorie[sousCategorieMiobsp[1]]
      : undefined,
    sousCategorieMiobsp3: sousCategorieMiobsp?.[2]
      ? excelLabelBySousCategorie[sousCategorieMiobsp[2]]
      : undefined,
    sousCategorieMiobsp4: sousCategorieMiobsp?.[3]
      ? excelLabelBySousCategorie[sousCategorieMiobsp[3]]
      : undefined,
    sousCategorieMiobsp5: sousCategorieMiobsp?.[4]
      ? excelLabelBySousCategorie[sousCategorieMiobsp[4]]
      : undefined,
    sousCategorieMiobsp6: sousCategorieMiobsp?.[5]
      ? excelLabelBySousCategorie[sousCategorieMiobsp[5]]
      : undefined,
    organisationPro1: organisationPro?.[0]
      ? excelLabelByOrganisationProfessionnelle[organisationPro[0]]
      : undefined,
    organisationPro2: organisationPro?.[1]
      ? excelLabelByOrganisationProfessionnelle[organisationPro[1]]
      : undefined,
    organisationPro3: organisationPro?.[2]
      ? excelLabelByOrganisationProfessionnelle[organisationPro[2]]
      : undefined,
    categorieRepresentation: categorieRepresentation
      ? excelLabelByCategorieRepresentation[categorieRepresentation]
      : undefined,
    errors: (entreprise as AdhesionDataEntreprisesItemError).errors
      ? (entreprise as AdhesionDataEntreprisesItemError).errors[0].description
      : "",
  };
};
