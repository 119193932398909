import {
  ExcelPropertyType,
  ExcelTemplate,
  MappedExcelTemplate,
} from "@src/helper/excel/excel.definition";
import { getExportTemplateFromTemplate } from "@src/helper/excel/excel.export.helper";

export const assujettiExcelTemplate = {
  firstRowIndex: 3,
  categories: {
    assujetti: {
      columns: {
        sirenCompany: {
          col: "A",
          type: ExcelPropertyType.string,
        },
        name: {
          col: "B",
          type: ExcelPropertyType.string,
        },
        surname: {
          col: "C",
          type: ExcelPropertyType.string,
        },
        role: {
          col: "D",
          type: ExcelPropertyType.string,
        },
        mail: {
          col: "E",
          type: ExcelPropertyType.string,
        },
      },
    },
    honorabilite: {
      columns: {
        dateVerif: {
          col: "F",
          type: ExcelPropertyType.date,
        },
        honorable: {
          col: "G",
          type: ExcelPropertyType.boolean,
        },
      },
    },
    formationContinueIAS: {
      columns: {
        soumisFormation: {
          col: "H",
          type: ExcelPropertyType.boolean,
        },
        dateSaisie: {
          col: "I",
          type: ExcelPropertyType.date,
        },
        formation0Theme: {
          col: "J",
          type: ExcelPropertyType.string,
        },
        formation0Heures: {
          col: "K",
          type: ExcelPropertyType.number,
        },
        formation1Theme: {
          col: "L",
          type: ExcelPropertyType.string,
        },
        formation1Heures: {
          col: "M",
          type: ExcelPropertyType.number,
        },
      },
    },
    capaciteProIAS: {
      columns: {
        datePriseFonction: {
          col: "N",
          type: ExcelPropertyType.date,
        },
        soumisALaCondition: {
          col: "O",
          type: ExcelPropertyType.boolean,
        },
        niveau: {
          col: "P",
          type: ExcelPropertyType.string,
        },
        statutPro: {
          col: "Q",
          type: ExcelPropertyType.string,
        },
        categorie: {
          col: "R",
          type: ExcelPropertyType.string,
        },
        nomDiplome: {
          col: "S",
          type: ExcelPropertyType.string,
        },
        gradeDiplome: {
          col: "T",
          type: ExcelPropertyType.string,
        },
        justificatif: {
          col: "U",
          type: ExcelPropertyType.string,
        },
      },
    },
    formationContinueIOBSP: {
      columns: {
        soumisFormation: {
          col: "V",
          type: ExcelPropertyType.boolean,
        },
        dateSaisie: {
          col: "W",
          type: ExcelPropertyType.date,
        },
        heuresFormationCreditConsommation: {
          col: "X",
          type: ExcelPropertyType.number,
        },
        heuresFormationCreditImmobilier: {
          col: "Y",
          type: ExcelPropertyType.number,
        },
        heuresFormationCreditPretViagerHypotecaire: {
          col: "Z",
          type: ExcelPropertyType.number,
        },
        heuresFormationCreditRegroupementCredits: {
          col: "AA",
          type: ExcelPropertyType.number,
        },
        heuresFormationCreditServicePaiement: {
          col: "AB",
          type: ExcelPropertyType.number,
        },
        formation0Theme: {
          col: "AC",
          type: ExcelPropertyType.string,
        },
        formation0Heures: {
          col: "AD",
          type: ExcelPropertyType.number,
        },
        formation1Theme: {
          col: "AE",
          type: ExcelPropertyType.string,
        },
        formation1Heures: {
          col: "AF",
          type: ExcelPropertyType.number,
        },
      },
    },
    capaciteProIOBSP: {
      columns: {
        datePriseFonction: {
          col: "AG",
          type: ExcelPropertyType.date,
        },
        soumisALaCondition: {
          col: "AH",
          type: ExcelPropertyType.boolean,
        },
        niveau: {
          col: "AI",
          type: ExcelPropertyType.string,
        },
        statutPro: {
          col: "AJ",
          type: ExcelPropertyType.string,
        },
        categorie: {
          col: "AK",
          type: ExcelPropertyType.string,
        },
        nomDiplome: {
          col: "AL",
          type: ExcelPropertyType.string,
        },
        gradeDiplome: {
          col: "AM",
          type: ExcelPropertyType.string,
        },
        justificatif: {
          col: "AN",
          type: ExcelPropertyType.string,
        },
      },
    },
  },
} as const satisfies ExcelTemplate;

export const assujettiExcelExportTemplate = getExportTemplateFromTemplate(
  assujettiExcelTemplate
);

export type AssujettiExcelTemplate = typeof assujettiExcelTemplate;
export type AssujettiExcelMappedTemplate =
  MappedExcelTemplate<AssujettiExcelTemplate>;
