import { GatewayPaiement } from "@conformite/gateway";
import {
  Button,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { Link } from "@src/components/Link/Link";
import { ReactNode } from "react";
import { EntreprisePaymentPublicity } from "./EntreprisePaymentPublicity";
import style from "./PaymentRemerciements.module.scss";

type PaymentRemerciementsProps = {
  paymentInfos?: GatewayPaiement.InvoiceInfosResponseType | undefined;
  informations: string;
  title: string;
  action: ReactNode;
};

export function PaymentRemerciements({
  paymentInfos,
  informations,
  title,
  action,
}: PaymentRemerciementsProps) {
  return (
    <FormGrid>
      <FormRow className={style.adhesionRemerciements__thanks}>
        <FormField>
          <span>Merci !</span>
        </FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <h1>{title}</h1>
        </FormField>
      </FormRow>

      {paymentInfos?.pdfUrl && (
        <FormRow>
          <FormField>
            <Link to={paymentInfos.pdfUrl} target="_blank">
              <Button icon={<Download />} variant="text">
                Votre facture
              </Button>
            </Link>
          </FormField>
        </FormRow>
      )}

      <FormRow>
        <FormField>
          <InfoCard type="neutral">
            <b className={style.adhesionConformite__cardContent}>
              {informations}
            </b>
          </InfoCard>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>{action}</FormField>
      </FormRow>

      <FormRow>
        <FormField>
          <EntreprisePaymentPublicity />
        </FormField>
      </FormRow>
    </FormGrid>
  );
}
