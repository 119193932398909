import {
  GatewayAuthentication,
  GatewayContact,
  GatewayEntreprise,
} from "@conformite/gateway";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import {
  LocalStorageKeys,
  localStorageHelper,
} from "@src/helper/localStorage.helper";
import { UserSessionData } from "@src/session/UserSession";
import { useSession } from "@src/session/UserSessionContext";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { ReactElement, useEffect } from "react";
import {
  refreshControleQuinquennal,
  refreshMe,
  refreshMeDependencies,
  refreshMillesimeDependencies,
  resetStore,
} from "./StoreRefresher.utils";
import { StoreContextValue } from "./store";
import { useStore } from "./store.hooks";

function useRefreshControleQuinquennal(
  session: UserSessionData,
  storeValue: StoreContextValue
) {
  const { hasAllowedCognitoRole, hasAllowedRole } = useIsAllowed();
  useEffect(() => {
    const isLoggedAsAdminWithPriseDeControle =
      hasAllowedCognitoRole(GatewayAuthentication.Role.ENDYA) &&
      localStorageHelper.getItem(LocalStorageKeys.ADMIN_CONTACT_ID) !== null;
    const isUserAllowed = hasAllowedRole(
      GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
      GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE
    );
    if (
      session.isConnected &&
      (isLoggedAsAdminWithPriseDeControle || isUserAllowed)
    ) {
      refreshControleQuinquennal(storeValue.controleQuinquennal).catch(
        handleGatewayError({
          onUnhandled: () =>
            displayErrorToast(
              "Une erreur est survenue lors de la récupération des controles quinquennaux."
            ),
        })
      );
    } else if (
      !hasAllowedRole(GatewayContact.ContactRoleUtilisateur.UTILISATEUR_SIMPLE)
    ) {
      resetStore(storeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.isConnected, storeValue.refresh.refreshCount, hasAllowedRole]);
}
function useRefreshMe(session: UserSessionData, storeValue: StoreContextValue) {
  const { hasAllowedCognitoRole } = useIsAllowed();
  useEffect(() => {
    if (
      session.isConnected &&
      !session.hasDoubleAuth &&
      (!hasAllowedCognitoRole(GatewayAuthentication.Role.ENDYA) ||
        localStorageHelper.getItem(LocalStorageKeys.ADMIN_CONTACT_ID) !== null)
    ) {
      refreshMe(storeValue.me).catch(
        handleGatewayError({
          onUnhandled: () =>
            displayErrorToast(
              "Une erreur est survenue lors de la récupération des données utilisateur."
            ),
        })
      );
    } else {
      resetStore(storeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.isConnected, storeValue.refresh.refreshCount]);
}
function useRefreshMeDependencies(storeValue: StoreContextValue) {
  useEffect(() => {
    if (!storeValue.me.data) return;
    if (
      storeValue.me.data?.entreprisePrincipale?.etat ===
      GatewayEntreprise.EtatEntreprise.ADHESION_EN_COURS
    )
      return;
    refreshMeDependencies(storeValue, storeValue.me.data).catch(
      handleGatewayError({
        onUnhandled: () =>
          displayErrorToast(
            "Une erreur est survenue lors de la récupération des données."
          ),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeValue.me.data]);
}
function useRefreshMillesimeDependencies(storeValue: StoreContextValue) {
  useEffect(() => {
    if (!storeValue.millesime.selected) return;
    refreshMillesimeDependencies(
      storeValue,
      storeValue.millesime.selected
    ).catch(
      handleGatewayError({
        onUnhandled: () =>
          displayErrorToast(
            "Une erreur est survenue lors de la récupération des données."
          ),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeValue.millesime.selected, storeValue.millesime.refreshCount]);
}

function useRefreshStore() {
  const session = useSession();
  const storeValue = useStore();

  useRefreshMe(session, storeValue);
  useRefreshControleQuinquennal(session, storeValue);
  useRefreshMeDependencies(storeValue);
  useRefreshMillesimeDependencies(storeValue);
}

export const StoreRefresher = ({ children }: { children: ReactElement }) => {
  useRefreshStore();
  return children;
};
