import { GatewayAssujetti } from "@conformite/gateway";
import {
  Form,
  InputSelect,
  InputText,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { RoleAssujettiLabels } from "@src/assujettis/AssujettiConsts";
import { AssujettiDataItemError } from "@src/assujettis/AssujettiData";
import {
  AssujettiInfoFormValues,
  useMyCompaniesOptions,
} from "@src/assujettis/AssujettiPaneContent/pages/AssujettiInfoForm/AssujettiInfoForm";
import classNames from "classnames";
import styles from "./AssujettiImportEdit.module.scss";
import { useAssujettiImportEdit } from "./hook/useAssujettiImportEdit";
import { ImportAssujettiItem } from "./useImportAssujetti";

interface NestedErrors {
  [key: string]: string | NestedErrors;
}

interface AssujettiImportEditProps {
  rowToEdit: AssujettiDataItemError;
  onSubmit: (data: ImportAssujettiItem) => void;
}

interface AssujettiFormValues {
  assujetti: AssujettiInfoFormValues;
}

export function AssujettiImportEdit({
  rowToEdit,
  onSubmit,
}: AssujettiImportEditProps) {
  const { formConfig, handleSubmit, initialErrors } = useAssujettiImportEdit({
    rowToEdit,
    onSubmit,
  });
  const companiesOption = useMyCompaniesOptions();
  if (!initialErrors) return null;

  const getErrorKeys = (errors: NestedErrors): string[] => {
    return Object.keys(errors).flatMap((key) => {
      if (typeof errors[key] === "object" && errors[key] !== null) {
        return Object.keys(errors[key] as NestedErrors).map(
          (subKey) => `${key}.${subKey}`
        );
      }
      return key;
    });
  };

  const errorsKeys = Array.from(
    new Set(getErrorKeys(initialErrors as NestedErrors))
  );

  return (
    <div>
      <h2 className={styles.title}>
        Correction de {rowToEdit?.data?.assujetti?.name}{" "}
        {rowToEdit?.data?.assujetti?.surname}
      </h2>
      <Form
        className={styles.form}
        formConfig={formConfig}
        onSubmit={(e) => {
          handleSubmit({ data: e, index: rowToEdit.index });
        }}
      >
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("assujetti.sirenCompany"),
          })}
        >
          <FormField>
            <InputSelect<AssujettiFormValues, string>
              label="Société"
              name="assujetti.sirenCompany"
              options={companiesOption}
              required
            />
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("assujetti.name"),
          })}
        >
          <FormField>
            <InputText<AssujettiFormValues>
              label="Nom"
              name="assujetti.name"
              required
            />
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("assujetti.surname"),
          })}
        >
          <FormField>
            <InputText<AssujettiFormValues>
              label="Prénom"
              name="assujetti.surname"
              required
            />
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("assujetti.role"),
          })}
        >
          <FormField>
            <InputSelect<AssujettiFormValues, GatewayAssujetti.RoleAssujetti>
              label="Fonction"
              name="assujetti.role"
              options={recordToSelectOptions(RoleAssujettiLabels)}
              required
            />
          </FormField>
        </FormRow>

        <FormRow className={styles.button}>
          <Button type="submit">Valider</Button>
        </FormRow>
      </Form>
    </div>
  );
}
