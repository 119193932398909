import {
  GatewayAdresse,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputButtonGroup,
  InputNumber,
  InputSelect,
  InputText,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Button,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { organisationProfessionnelleLabel } from "@src/adhesion/Steps/AdhesionOrganisationProfessionnelle/AdhesionOrganisationProfessionnellePage";
import {
  RenouvellementEntrepriseInformationsComplementairesFormValues,
  categorieRepresentationOptions,
} from "@src/renouvellement/steps/informationsComplementaires/RenouvellementInformationsComplementairesForm";
import {
  paysOptions,
  typeVoieOptions,
} from "@src/societes/form/EntrepriseAdresseForm";
import {
  formeJuridiqueOptions,
  trancheEffectifOptions,
} from "@src/societes/form/EntrepriseIdentificationForm";
import {
  EntrepriseInfoActiviteFormValues,
  InputTitreAccessoire,
  categorieActiviteLabel,
} from "@src/societes/form/EntrepriseInfoActiviteForm";
import { EntrepriseOrganisationProfessionnelleFormValues } from "@src/societes/form/EntrepriseOrganisationProfessionnelleForm";
import classNames from "classnames";
import { ReactElement, useMemo } from "react";
import styles from "./EntrepriseImportEdit.module.scss";
import { useEntrepriseImportEdit } from "./hook/useEntrepriseImportEdit";
import { sousCategoriesOptions } from "../form/EntrepriseSousCategoriesActiviteForm";

interface EntrepriseImportEditProps {
  entrepriseToEdit: AdhesionDataEntreprisesItem | undefined;
  onSubmit: (data: AdhesionDataEntreprisesItem) => void;
}

export function EntrepriseImportEdit({
  entrepriseToEdit,
  onSubmit,
}: EntrepriseImportEditProps) {
  const { formConfig, handleSubmit, initialErrors, schema } =
    useEntrepriseImportEdit({
      entreprise: entrepriseToEdit,
      onSubmit,
    });

  type EditEntrepriseSchema = zod.infer<typeof schema>;

  const categoriesWatched = formConfig.watch("categories");

  const categoriesOptions: {
    value: GatewayInfoSocieteOrias.Categorie;
    label: string;
    description?: ReactElement;
  }[] = useMemo(
    () => [
      {
        value: GatewayInfoSocieteOrias.Categorie.COA,
        label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.COA],
        description: categoriesWatched?.includes(
          GatewayInfoSocieteOrias.Categorie.COA
        ) ? (
          <InputTitreAccessoire name="coaAccessoire" />
        ) : undefined,
      },
      {
        value: GatewayInfoSocieteOrias.Categorie.MIA,
        label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.MIA],
        description: categoriesWatched?.includes(
          GatewayInfoSocieteOrias.Categorie.MIA
        ) ? (
          <InputTitreAccessoire name="miaAccessoire" />
        ) : undefined,
      },
      {
        value: GatewayInfoSocieteOrias.Categorie.COBSP,
        label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.COBSP],
        description: categoriesWatched?.includes(
          GatewayInfoSocieteOrias.Categorie.COBSP
        ) ? (
          <InputTitreAccessoire name="cobspAccessoire" />
        ) : undefined,
      },
      {
        value: GatewayInfoSocieteOrias.Categorie.MIOBSP,
        label: categorieActiviteLabel[GatewayInfoSocieteOrias.Categorie.MIOBSP],
        description: categoriesWatched?.includes(
          GatewayInfoSocieteOrias.Categorie.MIOBSP
        ) ? (
          <InputTitreAccessoire name="miobspAccessoire" />
        ) : undefined,
      },
    ],
    [categoriesWatched]
  );
  const organisationOptions = recordToSelectOptions(
    organisationProfessionnelleLabel
  );
  const needCOBSP = categoriesWatched?.includes(
    GatewayInfoSocieteOrias.Categorie.COBSP
  );
  const needMIOBSP = categoriesWatched?.includes(
    GatewayInfoSocieteOrias.Categorie.MIOBSP
  );

  if (!initialErrors) return null;

  const errorsKeys = Object.keys(initialErrors);
  return (
    <div>
      <h2 className={styles.title}>
        Correction de {entrepriseToEdit?.raisonSociale}
      </h2>
      <Form
        className={styles.form}
        formConfig={formConfig}
        onSubmit={handleSubmit}
      >
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("siren"),
          })}
        >
          <FormField>
            <InputText<EditEntrepriseSchema>
              label="Siren"
              name="siren"
              required
              disabled
            />
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("raisonSociale"),
          })}
        >
          <FormField>
            <InputText<EditEntrepriseSchema>
              label="Raison sociale"
              name="raisonSociale"
              required
            />
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("trancheEffectif"),
          })}
        >
          <FormField>
            <InputSelect<
              EditEntrepriseSchema,
              GatewayEntreprise.TrancheEffectif
            >
              name="trancheEffectif"
              label="Tranche effectif"
              placeholder="Sélectionner"
              options={trancheEffectifOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("formeJuridique"),
          })}
        >
          <FormField>
            <InputSelect<EditEntrepriseSchema, GatewayEntreprise.FormeJuridique>
              name="formeJuridique"
              label="Forme juridique"
              placeholder="Sélectionner"
              options={formeJuridiqueOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: ![
              "typeVoie",
              "libelleVoie",
              "pays",
              "codePostal",
              "ville",
            ].some((key) => errorsKeys.includes(key)),
          })}
        >
          <FormField>
            <h2>Adresse</h2>
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("typeVoie"),
          })}
        >
          <FormField>
            <InputSelect<EditEntrepriseSchema, GatewayAdresse.TypeVoie>
              isSearchable
              name="typeVoie"
              label="Type de voie"
              placeholder="Sélectionner"
              options={typeVoieOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("libelleVoie"),
          })}
        >
          <FormField>
            <InputText<EditEntrepriseSchema>
              label="Libellé de la voie"
              name="libelleVoie"
              required
            />
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("pays"),
          })}
        >
          <FormField>
            <InputSelect<EditEntrepriseSchema, GatewayAdresse.Pays>
              name="pays"
              label="Pays"
              placeholder="Sélectionner"
              options={paysOptions}
              required
            />
          </FormField>
        </FormRow>
        <FormRow className={styles.row}>
          <FormField
            size="quarter"
            className={classNames({
              [styles.hidden]: !errorsKeys.includes("codePostal"),
            })}
          >
            <InputText<EditEntrepriseSchema>
              label="Code postal"
              name="codePostal"
              required
            />
          </FormField>
          <FormField
            size="three-quarter"
            className={classNames({
              [styles.hidden]: !errorsKeys.includes("ville"),
            })}
          >
            <InputText<EditEntrepriseSchema>
              label="Ville"
              name="ville"
              required
            />
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("categories"),
          })}
        >
          <FormField>
            <InputButtonGroup<
              EntrepriseInfoActiviteFormValues,
              true,
              GatewayInfoSocieteOrias.Categorie
            >
              label={
                <b className={styles.label}>
                  Dans quelle(s) catégories(s) vous inscrivez-vous ?
                </b>
              }
              options={categoriesOptions}
              name="categories"
              variant="outline-pop"
              size="large"
              isMultiple
            />
          </FormField>
        </FormRow>
        {needCOBSP && (
          <FormRow
            className={classNames({
              [styles.hidden]: !errorsKeys.includes("sousCategorieCobsp"),
            })}
          >
            <FormField>
              <InputButtonGroup<
                EditEntrepriseSchema,
                true,
                GatewayInfoSocieteOrias.ActiviteBancaire
              >
                options={sousCategoriesOptions}
                name="sousCategorieCobsp"
                label={
                  <b className={styles.label}>
                    Sélectionnez au moins une sous-catégorie à votre activité
                    COBSP :
                  </b>
                }
                isMultiple
                variant="outline-pop"
                size="large"
              />
            </FormField>
          </FormRow>
        )}

        {needMIOBSP && (
          <FormRow
            className={classNames({
              [styles.hidden]: !errorsKeys.includes("sousCategorieMiobsp"),
            })}
          >
            <FormField>
              <InputButtonGroup<
                EditEntrepriseSchema,
                true,
                GatewayInfoSocieteOrias.ActiviteBancaire
              >
                options={sousCategoriesOptions}
                name="sousCategorieMiobsp"
                label={
                  <b className={styles.label}>
                    Sélectionnez au moins une sous-catégorie à votre activité
                    MIOBSP :
                  </b>
                }
                variant="outline-pop"
                size="large"
                isMultiple
              />
            </FormField>
          </FormRow>
        )}
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes(
              "organisationsProfessionnelles"
            ),
          })}
        >
          <FormField>
            <InputButtonGroup<
              EntrepriseOrganisationProfessionnelleFormValues,
              true,
              GatewayInfoSocieteOrias.OrganisationProfessionnelle
            >
              label="Êtes-vous adhérent d'une ou plusieurs organisations professionnelles ?"
              name="organisationsProfessionnelles"
              size="large"
              variant="outline-pop"
              options={organisationOptions}
              required
              isMultiple
            />
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("categorieRepresentation"),
          })}
        >
          <FormField>
            <InputSelect<
              RenouvellementEntrepriseInformationsComplementairesFormValues,
              GatewayInfoSocieteOrias.CategorieRepresentation
            >
              placeholder="Sélectionner..."
              options={categorieRepresentationOptions}
              name="categorieRepresentation"
              label="Catégorie de représentation"
              required
            />
          </FormField>
        </FormRow>
        <FormRow
          className={classNames({
            [styles.hidden]: !errorsKeys.includes("chiffreAffaire"),
          })}
        >
          <FormField>
            <InputNumber<EditEntrepriseSchema>
              name="chiffreAffaire"
              label="Chiffre d'affaires"
              required
            />
          </FormField>
        </FormRow>
        <FormRow className={styles.button}>
          <Button type="submit">Valider</Button>
        </FormRow>
      </Form>
    </div>
  );
}
