import { GatewayControleQuinquennal } from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { Button, Card } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ControleQuinquennalApi } from "@src/api/controleQuinquennal.api";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { ReactComponent as PlusCircle } from "@src/assets/icons/plus-circle.svg";
import { ReactComponent as TrashIcon } from "@src/assets/icons/trash.svg";
import { DownloadFile } from "@src/components/DownloadFile/DownloadFile";
import { FileInputButtonWithDrag } from "@src/components/FileInputButton/FileInputButtonWithDrag";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { Link } from "@src/components/Link/Link";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { axeTypeToLabel } from "@src/controleQuinquennal/controleQuinquennal.helper";
import { format } from "date-fns";
import { keyBy, partition } from "lodash";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useControlesQuinquennaux } from "../list/useControlesQuinquennaux";
import styles from "./ControleQuinquennalAxe.module.scss";
import { ControleQuinquennalAxeRemediations } from "./ControleQuinquennalAxeRemediation";
import { ControleQuinquennalExplainAxeAssujetti } from "./ControleQuinquennalExplainAxeAssujetti";
import { ControleQuinquennalExplainAxeGarantieFinanciereIas } from "./ControleQuinquennalExplainGarantieFinanciereIas";
import { ControleQuinquennalExplainAxeGarantieFinanciereIobsp } from "./ControleQuinquennalExplainGarantieFinanciereIobsp";
import { ControleQuinquennalExplainAxeRcProCoa } from "./ControleQuinquennalExplainRcproCoa";
import { ControleQuinquennalExplainAxeRcProCobsp } from "./ControleQuinquennalExplainRcproCobsp";
import { ControleQuinquennalExplainAxeRcProMia } from "./ControleQuinquennalExplainRcproMia";
import { ControleQuinquennalExplainAxeRcProMiobsp } from "./ControleQuinquennalExplainRcproMiobsp";
import { ControleQuinquennalPageActions } from "./ControleQuinquennalPageActions";
import { useControleQuinquennalAxe } from "./useControleQuinquennalAxe";

const axeTypeToComponentExplanation: Record<
  GatewayControleQuinquennal.ControleQuinquennalAxeType,
  () => ReactElement
> = {
  ASSUJETTI: ControleQuinquennalExplainAxeAssujetti,
  RCPRO_COA: ControleQuinquennalExplainAxeRcProCoa,
  RCPRO_MIA: ControleQuinquennalExplainAxeRcProMia,
  RCPRO_COBSP: ControleQuinquennalExplainAxeRcProCobsp,
  RCPRO_MIOBSP: ControleQuinquennalExplainAxeRcProMiobsp,
  GARANTIE_FINANCIERE_IAS: ControleQuinquennalExplainAxeGarantieFinanciereIas,
  GARANTIE_FINANCIERE_IOBSP:
    ControleQuinquennalExplainAxeGarantieFinanciereIobsp,
};

const schema = zod.object({
  files: zod.array(
    zod.object({
      idFile: zod.string().optional(),
      file: zod.instanceof(File).optional(),
      pathname: zod.string(),
      filename: zod.string(),
      readonly: zod.boolean().optional(),
    })
  ),
  complet: zod.boolean(),
});
export type AxeFormValues = zod.infer<typeof schema>;
type AxeFormValueFile = AxeFormValues["files"][0];

type ControleQuinquennalAxeContentProps = {
  axe: GatewayControleQuinquennal.ControleQuinquennalAxeWithFiles;
  remediations: GatewayControleQuinquennal.ControleQuinquennalRemediationWithFileResponseType[];
  axeBefore?: GatewayControleQuinquennal.ControleQuinquennalAxe;
  axeAfter?: GatewayControleQuinquennal.ControleQuinquennalAxe;
  isReadonly: boolean;
  millesime: number;
  siren: string;
  controleQuinquennal: GatewayControleQuinquennal.ControleQuinquennalWithCompletion;
};

export function ControleQuinquennalAxeContent({
  axe,
  remediations,
  axeBefore,
  axeAfter,
  isReadonly,
  millesime,
  siren,
  controleQuinquennal,
}: ControleQuinquennalAxeContentProps) {
  const shouldUpdateIsComplet = useRef(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const form = useFormConfig({
    schema,
    defaultValues: {
      files: axe.controleQuinquennalAxeFiles.map((file) => ({
        idFile: file.idFile,
        filename: file.filename,
        pathname: file.pathname,
        readonly: file.isReadonly,
      })),
      complet: axe.isComplet,
    } as AxeFormValues,
  });
  const axeType = axe.type;
  const Explanation = axeTypeToComponentExplanation[axeType];
  const files = form.watch("files");
  const handleAddFile = (file: File[]) => {
    if (file.length === 0) return;
    shouldUpdateIsComplet.current = true;
    form.setValue("files", [
      ...form.getValues().files,
      ...file.map((f) => ({
        file: f,
        filename: f.name,
        pathname: Math.random().toString(10),
        isReadonly: false,
      })),
    ]);
  };
  const handleRemoveFile = (file: AxeFormValueFile) => () => {
    shouldUpdateIsComplet.current = true;
    form.setValue(
      "files",
      form.getValues("files").filter((fileCheck) => file !== fileCheck)
    );
  };
  const handleSubmit = (linkToSwitch: string) => () => {
    form
      .handleSubmit((data) => {
        const [filesToAdd, filesExisting] = partition(
          data.files,
          (file) => file.file
        );
        setIsLoading(true);
        return ControleQuinquennalApi.saveControleQuinquennalAxe(
          axe.idControleQuinquennal,
          axe.idAxe,
          {
            isComplet: data.complet,
            idFiles: filesExisting.map((file) => file.idFile) as string[],
          },
          filesToAdd.map((file) => file.file) as File[]
        );
      })()
      .then(() => {
        navigate(linkToSwitch);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };
  const complet = form.watch("complet");
  const hasRemediation = remediations.length > 0;
  const hasEditRemediation = hasRemediation && !isReadonly;
  const getTitle = () => {
    if (hasRemediation || isReadonly)
      return controleQuinquennal.dateDepot
        ? `Pièces fournies le ${format(
            controleQuinquennal.dateDepot,
            "dd/MM/yyyy"
          )}`
        : "Pièces fournies";
    return "Pièces à fournir";
  };
  const [filesReadonly, fileEdit] = partition(files, (file) => file.readonly);
  useEffect(() => {
    if (shouldUpdateIsComplet.current) {
      shouldUpdateIsComplet.current = false;
      form.setValue("complet", false);
    }
  }, [form, files]);
  const isSwitchImportCompletDisabled = hasRemediation
    ? fileEdit.length > 0
    : files.length > 0;

  return (
    <Form formConfig={form} onSubmit={() => null} className={styles.container}>
      {hasRemediation && (
        <>
          <ControleQuinquennalAxeRemediations
            remediations={remediations}
            siren={siren}
            millesime={millesime}
          />
          {hasEditRemediation && (
            <Card>
              <h3>Pièces pour la remédiation</h3>
              <div className={styles.axe}>
                <p>Ajoutez les pièces afin de valider votre axe</p>
                <FileInputButtonWithDrag
                  variant="outline-accent"
                  onChange={handleAddFile}
                  className={styles.upload}
                  multiple
                >
                  <div className={styles.uploadContent}>
                    <PlusCircle className={styles.icon} />
                    <p className={styles.text}>Cliquer pour importer</p>
                    <p className={styles.desc}>ou glisser déposer</p>
                  </div>
                </FileInputButtonWithDrag>
                {fileEdit.map((file) => (
                  <div key={file.pathname} className={styles.file}>
                    <p>{file.filename}</p>
                    {!isReadonly && !file.readonly && (
                      <Button
                        variant="text"
                        icon={<TrashIcon />}
                        onClick={handleRemoveFile(file)}
                      />
                    )}
                  </div>
                ))}
              </div>
            </Card>
          )}
        </>
      )}
      <Card className={styles.card}>
        <div className={styles.titleContent}>
          <h3>{getTitle()}</h3>
        </div>
        <div className={styles.axe}>
          {!hasEditRemediation && files.length > 0 && !complet && (
            <InfoCard type="warning">
              Pensez à cocher la case &quot;Import complet&quot; lorsque vous
              avez fourni toutes les pièces demandées.
            </InfoCard>
          )}
          <div className={styles.explanation}>
            <Explanation />
            <p>
              Des modèles sont à votre disposition dans la Base documentaire
              accessible depuis votre espace adhérent (à droite sur la page
              d’accueil).
            </p>
          </div>
          {!hasEditRemediation && !isReadonly && (
            <FileInputButtonWithDrag
              variant="outline-accent"
              onChange={handleAddFile}
              className={styles.upload}
              multiple
            >
              <div className={styles.uploadContent}>
                <PlusCircle className={styles.icon} />
                <p className={styles.text}>Cliquer pour importer</p>
                <p className={styles.desc}>ou glisser déposer</p>
              </div>
            </FileInputButtonWithDrag>
          )}
          <div className={styles.files}>
            {(hasEditRemediation ? filesReadonly : files).map((file) => (
              <div key={file.pathname} className={styles.file}>
                <p>{file.filename}</p>
                {(isReadonly || file.readonly) && (
                  <div>
                    <DownloadFile
                      filename={file.filename}
                      pathname={file.pathname}
                      siren={siren}
                      millesime={millesime}
                    />
                  </div>
                )}
                {!isReadonly && !file.readonly && (
                  <Button
                    variant="text"
                    icon={<TrashIcon />}
                    onClick={handleRemoveFile(file)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </Card>
      {!isReadonly && (
        <ControleQuinquennalPageActions
          onPrevious={
            axeBefore
              ? handleSubmit(
                  `/controle-quinquennal/${axe.idControleQuinquennal}/${axeBefore.idAxe}`
                )
              : undefined
          }
          isValid={isSwitchImportCompletDisabled}
          onNext={handleSubmit(
            `/controle-quinquennal/${axe.idControleQuinquennal}/${
              axeAfter?.idAxe ?? ""
            }`
          )}
          isLast={axeAfter === undefined}
          isLoading={isLoading}
        />
      )}
    </Form>
  );
}
export function ControleQuinquennalAxe() {
  const {
    axe,
    remediations,
    axeAfter,
    axeBefore,
    isReadonly,
    millesime,
    siren,
    numberOfAssujettis,
    idControleQuinquennal,
  } = useControleQuinquennalAxe();
  const { controlesQuinquennaux } = useControlesQuinquennaux();
  const controlesQuinquennauxById = keyBy(controlesQuinquennaux, "id");
  const title = useMemo(() => {
    if (
      axe?.type ===
      GatewayControleQuinquennal.ControleQuinquennalAxeType.ASSUJETTI
    ) {
      return `${axeTypeToLabel[axe.type]} (${numberOfAssujettis})`;
    }
    return axe?.type ? axeTypeToLabel[axe.type] : "";
  }, [axe?.type, numberOfAssujettis]);
  const isLoading =
    !axe || controlesQuinquennaux.length === 0 || !idControleQuinquennal;

  return (
    <MainContentPageTemplate
      actionNavigation={
        <Link to={`/controle-quinquennal/${axe?.idControleQuinquennal ?? ""}`}>
          <Button variant="text" icon={<ChevronLeft />}>
            Retour
          </Button>
        </Link>
      }
      title={title}
    >
      <MaxWidthContent size="big">
        {isLoading && <SkeletonCard height={300} />}
        {!isLoading && (
          <ControleQuinquennalAxeContent
            key={axe.idAxe}
            axe={axe}
            remediations={remediations}
            siren={siren}
            axeAfter={axeAfter}
            axeBefore={axeBefore}
            isReadonly={isReadonly}
            millesime={millesime}
            controleQuinquennal={
              controlesQuinquennauxById[idControleQuinquennal]
            }
          />
        )}
      </MaxWidthContent>
    </MainContentPageTemplate>
  );
}
