import { Breadcrumb } from "@src/components/Breadcrumb/Breadcrumb";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { TagConformite } from "@src/components/Tag/TagConformite";
import {
  ParcoursFullPageTemplate,
  ParcoursFullPageTemplateProps,
} from "@src/parcours/Template/ParcoursFullPageTemplate";
import { useMillesime } from "@src/store/store.millesime";
import { isString } from "lodash";

import { GatewayConformite } from "@conformite/gateway";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { SaveAndQuitButton } from "@src/parcours/NavigationButtons/SaveAndQuitButton";
import { getAxesConformiteEntrepriseByActivites } from "@src/societes/societe.helper";
import { PropsWithChildren, ReactNode, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ParcoursConformiteStepEnum,
  useParcoursConformite,
} from "./ParcoursConformite";

export type ParcoursConformiteTemplateProps = {
  actions?: ReactNode;
  onSubmit?: () => Promise<void>;
  size: ParcoursFullPageTemplateProps["size"];
  currentStep: ParcoursConformiteStepEnum;
  changeStep: (step: ParcoursConformiteStepEnum) => void;
} & ParcoursConformiteTitleProps &
  PropsWithChildren;

type ParcoursConformiteTitleProps = {
  title: ReactNode;
  isConforme?: boolean;
  isDirty?: boolean;
};
export function ParcoursConformiteTitle({
  isConforme,
  title,
  isDirty,
}: ParcoursConformiteTitleProps) {
  const { selected: millesime } = useMillesime();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {isString(title) && <h1>{title}</h1>}
      {!isString(title) && title}
      {isConforme !== undefined && (
        <TagConformite
          isConforme={isConforme}
          isEditing={isDirty}
          year={millesime as number}
        />
      )}
    </div>
  );
}

function DefaultActions({
  onSubmit,
  changeStep,
  currentStep,
}: Pick<
  ParcoursConformiteTemplateProps,
  "changeStep" | "onSubmit" | "currentStep"
>) {
  const [isLoading, setIsLoading] = useState(false);
  const { getNextStep } = useParcoursConformite();
  const navigate = useNavigate();

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    await onSubmit?.();
    setIsLoading(false);
  }, [onSubmit, setIsLoading]);

  const handleNext = useCallback(async () => {
    await handleSubmit();
    changeStep(getNextStep(currentStep));
  }, [handleSubmit, changeStep, getNextStep, currentStep]);

  const handleSaveAndQuit = useCallback(async () => {
    await handleSubmit();
    navigate("/");
  }, [handleSubmit, navigate]);

  return (
    <>
      <SaveAndQuitButton onClick={handleSaveAndQuit} />
      <Button
        disabled={isLoading}
        icon={isLoading ? <Loader /> : undefined}
        onClick={() => {
          handleNext().catch(console.error);
        }}
      >
        Suivant
      </Button>
    </>
  );
}

export function ParcoursConformiteTemplate({
  actions,
  onSubmit,
  isConforme,
  title,
  children,
  size,
  isDirty,
  currentStep,
  changeStep,
}: ParcoursConformiteTemplateProps) {
  const { entreprise, parcoursStepByAxeConformite } = useParcoursConformite();

  if (!entreprise) return null;

  const labelByAxeEntreprise: Record<
    GatewayConformite.AxeConformiteEntreprise,
    string
  > = {
    [GatewayConformite.AxeConformiteEntreprise.RC_PRO_COA]: "RC COA",
    [GatewayConformite.AxeConformiteEntreprise.RC_PRO_MIA]: "RC MIA",
    [GatewayConformite.AxeConformiteEntreprise.RC_PRO_COBSP]: "RC COBSP",
    [GatewayConformite.AxeConformiteEntreprise.RC_PRO_MIOBSP]: "RC MIOBSP",
    [GatewayConformite.AxeConformiteEntreprise.DECLARATION_ACTIVITE_IAS]:
      "Déclaration d'activité IAS",
    [GatewayConformite.AxeConformiteEntreprise.DECLARATION_ACTIVITE_IOBSP]:
      "Déclaration d'activité IOBSP",
    [GatewayConformite.AxeConformiteEntreprise.GARANTIE_FINANCIERE_IAS]:
      "Garantie financière IAS",
    [GatewayConformite.AxeConformiteEntreprise.GARANTIE_FINANCIERE_IOBSP]:
      "Garantie financière IOBSP",
  };

  return (
    <>
      <MaxWidthContent size={size}>
        <Breadcrumb<ParcoursConformiteStepEnum>
          items={[
            {
              label: "Personnes",
              value: ParcoursConformiteStepEnum.IMPORT_ASSUJETTIS,
            },
            ...getAxesConformiteEntrepriseByActivites(
              entreprise.categoriesActivites
            ).map((axe) => ({
              label:
                labelByAxeEntreprise[
                  axe as GatewayConformite.AxeConformiteEntreprise
                ],
              value: parcoursStepByAxeConformite[axe],
            })),
            {
              label: "Récapitulatif",
              value: ParcoursConformiteStepEnum.RECAPITULATIF,
            },
          ]}
          selected={
            currentStep ===
            ParcoursConformiteStepEnum.IMPORT_ASSUJETTIS_DASHBOARD
              ? ParcoursConformiteStepEnum.IMPORT_ASSUJETTIS
              : currentStep
          }
          setSelected={(nextStep) => changeStep(nextStep)}
        />
      </MaxWidthContent>
      <ParcoursFullPageTemplate
        title={
          <ParcoursConformiteTitle
            title={title}
            isConforme={isConforme}
            isDirty={isDirty}
          />
        }
        actions={
          actions || (
            <DefaultActions
              onSubmit={onSubmit}
              changeStep={changeStep}
              currentStep={currentStep}
            />
          )
        }
        size={size}
      >
        {children}
      </ParcoursFullPageTemplate>
    </>
  );
}
