import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { displayErrorToast } from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayAdhesion2Api } from "@src/api/adhesion2.api";
import { GatewayInfoSocieteOriasApi } from "@src/api/info-societe-orias.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { withLastEntryNotDisabled } from "@src/helper/object.helper";
import { useEntreprises } from "@src/store/store.entreprise";
import { useMe } from "@src/store/store.me";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { RenouvellementStepEnum } from "./ParcoursRenouvellement.definition";
import {
  RenouvellementContactData,
  RenouvellementData,
  RenouvellementEntrepriseAControllerData,
  RenouvellementEntreprisePrincipaleData,
} from "./steps/RenouvellementData";

function useParcoursRenouvellemenCacheDefaults(): [
  boolean,
  RenouvellementData,
  RenouvellementStepEnum[] | null
] {
  const [readyState, setReadyState] = useState<boolean>(false);
  const [cacheData, setCacheData] = useState<RenouvellementData | null>(null);
  const [cacheHistory, setCacheHistory] = useState<
    RenouvellementStepEnum[] | null
  >(null);

  useEffect(() => {
    GatewayAdhesion2Api.getCache("RENOUVELLEMENT")
      .then((res) => {
        if (
          res !== null &&
          !!res.history &&
          !!res.data &&
          isArray(res.history)
        ) {
          setCacheData(res.data);
          setCacheHistory(res.history);
        }
      })
      .finally(() => {
        setReadyState(true);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [readyState, cacheData as RenouvellementData, cacheHistory];
}

function useInfoORIAS(sirens: string[] | undefined) {
  const [infoOrias, setInfoOrias] = useState<
    | GatewayInfoSocieteOrias.RetrieveInfoSocietesOriasResponseType["bySiren"]
    | null
  >(null);

  useEffect(() => {
    if (sirens === undefined) return;

    GatewayInfoSocieteOriasApi.getBySiren({
      sirens,
      oriasIds: [],
    })
      .then((response) => {
        setInfoOrias(response.bySiren);
      })
      .catch((error) => {
        handleGatewayError({
          onUnhandled: () => {
            console.error(error);
            displayErrorToast(
              "Erreur lors de la récupération des informations ORIAS"
            );
          },
        });
        setInfoOrias({});
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sirens !== undefined]);
  return infoOrias;
}
type UseParcoursRenouvellemenDefaultsReturn = {
  data: RenouvellementData;
  history: RenouvellementStepEnum[] | null;
} | null;

const stepsWithDisabledCache: RenouvellementStepEnum[] = [
  RenouvellementStepEnum.INFORMATIONS_PERSONNELLES,
  RenouvellementStepEnum.IDENTIFICATION,
  RenouvellementStepEnum.ADRESSE,
  RenouvellementStepEnum.PAIEMENT,
];

export function useParcoursRenouvellementDefaults(): UseParcoursRenouvellemenDefaultsReturn {
  const { me } = useMe();
  const { entreprisesBySiren } = useEntreprises(
    me?.entreprisePrincipale.millesimeActuel
  );
  const infoOrias = useInfoORIAS(
    entreprisesBySiren ? Object.keys(entreprisesBySiren) : undefined
  );
  const [cacheIsReady, cacheData, cacheHistory] =
    useParcoursRenouvellemenCacheDefaults();

  if (!cacheIsReady || !me || !entreprisesBySiren || !infoOrias) return null;

  if (cacheIsReady && cacheData)
    return {
      data: {
        ...cacheData,
        coupon: undefined,
      },
      history: withLastEntryNotDisabled(cacheHistory, stepsWithDisabledCache),
    };

  const entreprisePrincipaleData: RenouvellementEntreprisePrincipaleData = {
    adresse: {
      ...me.entreprisePrincipale.adresse,
      complementAdresse:
        me.entreprisePrincipale.adresse.complementAdresse ?? "",
    },
    formeJuridique: me.entreprisePrincipale.formeJuridique,
    raisonSociale: me.entreprisePrincipale.raisonSociale,
    siren: me.entreprisePrincipale.siren,
    trancheEffectif: me.entreprisePrincipale.trancheEffectif,
    typeSouscripteur: me.entreprisePrincipale.typeSouscripteur,
  };

  const contactData: RenouvellementContactData = {
    civilite: me.contact.civilite,
    fonction: me.contact.fonction,
    nom: me.contact.nom,
    prenom: me.contact.prenom,
    telephone: me.contact.telephone,
  };

  const entrepriseAControllerData: Record<
    string,
    RenouvellementEntrepriseAControllerData
  > = Object.values(entreprisesBySiren).reduce<
    Record<string, RenouvellementEntrepriseAControllerData>
  >((acc, entreprise) => {
    acc[entreprise.siren] = {
      consentementEDI: false,
      siren: entreprise.siren,
      raisonSociale: entreprise.raisonSociale,
      numeroOrias:
        infoOrias[entreprise.siren]?.numeroOrias ?? entreprise.numeroOrias,
      adresse: entreprise.adresse,
      formeJuridique: entreprise.formeJuridique,
      trancheEffectif: entreprise.trancheEffectif,
      chiffreAffaire: undefined,
      categoriesActivites: entreprise.categoriesActivites,
      categoriesActivitesAccessoires: entreprise.categoriesActivitesAccessoires,
      categorieRepresentation: entreprise.categorieRepresentation,
      organisationProfessionnelle: entreprise.organisationProfessionnelle,
      sousCategorieCOBSP: entreprise.sousCategorieCOBSP,
      sousCategoriesIOBSP: entreprise.sousCategoriesIOBSP,
    };
    return acc;
  }, {});

  return {
    data: {
      consentementEDI: false,
      shouldShowIntroMajSocietes: true,
      typeAdhesion: me.entreprisePrincipale.typeAdhesion,
      resume: {
        contact: contactData,
        entreprisePrincipale: entreprisePrincipaleData,
      },
      entreprisesAControler: entrepriseAControllerData,
      entreprisesUpdateCount: 0,
      coupon: undefined,
      societesDoublons: [],
      societesInError: [],
      societesToAdd: [],
    },
    history: withLastEntryNotDisabled(cacheHistory, stepsWithDisabledCache),
  };
}
