import { ReactElement } from "react";
import "@lya-protect/lya-protect-form-library/dist/style.css";
import { Toaster } from "@lya-protect/lya-protect-form-library/dist/UI";
import "./app.scss";
import "./lya-lib-override.scss";
import { Router } from "./Router";
import { ToastContent } from "./components/ToastContent/ToastContent";
import { UserSessionProvider } from "./session/UserSessionContext";
import { DialogProvider } from "./components/Dialog/DialogProvider";
import { StoreProvider } from "./store/store";
import { StoreRefresher } from "./store/StoreRefresher";

export function App(): ReactElement | null {
  return (
    <UserSessionProvider>
      <StoreProvider>
        <StoreRefresher>
          <DialogProvider>
            <Toaster
              defaultHideAfter={5000}
              position={{
                vertical: "bottom",
                horizontal: "center",
              }}
              ToastContentComponent={ToastContent}
            />
            <Router />
          </DialogProvider>
        </StoreRefresher>
      </StoreProvider>
    </UserSessionProvider>
  );
}

// dd
