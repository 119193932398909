import { FieldErrors } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { useFormConfig } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  AssujettiDataItemError,
  RowAssujettiDataItem,
} from "@src/assujettis/AssujettiData";
import { useEffect, useState } from "react";
import {
  ImportAssujettiItem,
  ImportRowAssujettisSchema,
  useImportAssujetti,
} from "../useImportAssujetti";

interface UseEditAssujettiProps {
  rowToEdit: AssujettiDataItemError | undefined;
  onSubmit: (data: ImportAssujettiItem) => void;
}

export const useAssujettiImportEdit = ({
  rowToEdit,
  onSubmit,
}: UseEditAssujettiProps) => {
  const { schema } = useImportAssujetti();

  const [initialErrors, setInitialErrors] =
    useState<FieldErrors<ImportRowAssujettisSchema> | null>(null);

  const formConfig = useFormConfig<typeof schema, ImportRowAssujettisSchema>({
    schema,
    defaultValues: {
      ...rowToEdit?.data,
    },
  });

  useEffect(() => {
    if (initialErrors === null) {
      setInitialErrors({ ...formConfig.formState.errors });
    }
  }, [formConfig.formState.errors, initialErrors]);

  useEffect(() => {
    formConfig.trigger().catch((err) => console.error(err));
  }, []);

  const handleSubmit = ({ data, index }: ImportAssujettiItem) => {
    const formatedData: RowAssujettiDataItem = {
      assujetti: data.assujetti,
      honorabilite: data.honorabilite,
      formationContinueIAS: data.formationContinueIAS,
      formationContinueIOBSP: data.formationContinueIOBSP,
      capaciteProIAS: {
        ...data.capaciteProIAS,
        categorie: data.capaciteProIAS?.categorie ?? undefined,
        gradeDiplome: data.capaciteProIAS?.gradeDiplome ?? undefined,
        niveau: data.capaciteProIAS?.niveau ?? undefined,
        nomDiplome: data.capaciteProIAS?.nomDiplome ?? undefined,
        statusProfessionel:
          data.capaciteProIAS?.statusProfessionel ?? undefined,
        typeJustificatif: data.capaciteProIAS?.typeJustificatif ?? undefined,
      },
      capaciteProIOBSP: {
        ...data.capaciteProIOBSP,
        categorie: data.capaciteProIOBSP?.categorie ?? undefined,
        gradeDiplome: data.capaciteProIOBSP?.gradeDiplome ?? undefined,
        niveau: data.capaciteProIOBSP?.niveau ?? undefined,
        nomDiplome: data.capaciteProIOBSP?.nomDiplome ?? undefined,
        statusProfessionel:
          data.capaciteProIOBSP?.statusProfessionel ?? undefined,
        typeJustificatif: data.capaciteProIOBSP?.typeJustificatif ?? undefined,
      },
    };
    onSubmit({ data: formatedData, index });
  };

  return {
    formConfig,
    handleSubmit,
    initialErrors,
  };
};
