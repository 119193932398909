import {
  Button,
  Card,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as CheckCircleFill } from "@src/assets/icons/check-circle-fill.svg";
import { ReactComponent as ChevronDown } from "@src/assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "@src/assets/icons/chevron-up.svg";
import { ReactComponent as Pencil } from "@src/assets/icons/pencil.svg";
import { ReactComponent as XCircleFill } from "@src/assets/icons/x-circle-fill.svg";
import {
  AssujettiDataItemError,
  RowAssujettiDataItem,
} from "@src/assujettis/AssujettiData";
import { useFilterPagination } from "@src/components/Filter/useFilterPagination";
import { Pagination } from "@src/components/Pagination/Pagination";
import { Table } from "@src/components/Table/Table";
import { useState } from "react";
import styles from "./AssujettiImportErrors.module.scss";

export type AssujettiImportErrorsData = {
  assujetti: RowAssujettiDataItem;
  errors: AssujettiDataItemError[];
  index: number;
};

type AssujettiImportErrorsProps = {
  errors: AssujettiDataItemError[];
  onUpdate: (entreprise: AssujettiDataItemError) => void;
};

const getMessageErreur = (importInError: AssujettiDataItemError) => {
  if (importInError.errors.length === 0) return "";
  if (importInError.errors.length === 1)
    return importInError.errors[0].description;
  return "Plusieurs erreurs identifiées";
};

export const ASSUJETTIS_PAGE_SIZE = 25;

export function AssujettiImportErrors({
  onUpdate,
  errors,
}: AssujettiImportErrorsProps) {
  const [isOpen, setIsOpen] = useState(true);
  const { content, page, setPage, pageSize } = useFilterPagination({
    content: errors,
    pagination: { pageSize: ASSUJETTIS_PAGE_SIZE },
  });

  return (
    <Card>
      <FormGrid disableHorizontalGutter>
        <FormRow className={styles.cardHeader}>
          <FormField>
            <h2>
              <span className={styles.countErrors}>{errors.length} </span>
              ligne
              {errors.length > 1 ? "s" : ""} en erreur
            </h2>
          </FormField>
          <FormField className={styles.collapseButton}>
            <Button
              variant="text"
              onClick={() => setIsOpen((prev) => !prev)}
              icon={isOpen ? <ChevronUp /> : <ChevronDown />}
            />
          </FormField>
        </FormRow>
        {isOpen && (
          <>
            <FormRow>
              <FormField>
                <Table
                  className={styles.tableWithoutShadow}
                  data={content}
                  columns={[
                    {
                      key: "index",
                      title: "Ligne",
                      size: 1,
                      render: ({ index }) => <p>{index}</p>,
                    },

                    {
                      key: "name",
                      title: "Nom prénom",
                      size: 2,
                      render: (importInError) => (
                        <p className={styles.raisonSociale}>
                          {importInError.errors.length > 0 ? (
                            <XCircleFill className={styles.error} />
                          ) : (
                            <CheckCircleFill className={styles.success} />
                          )}{" "}
                          {importInError.data?.assujetti?.name}{" "}
                          {importInError.data?.assujetti?.surname}
                        </p>
                      ),
                    },
                    {
                      key: "siren",
                      title: "SIREN",
                      size: 2,
                      render: (importInError) => (
                        <p>{importInError.data?.assujetti?.sirenCompany}</p>
                      ),
                    },
                    {
                      key: "error",
                      title: "Erreur",
                      size: 3,
                      render: (importInError) => (
                        <p>{getMessageErreur(importInError)}</p>
                      ),
                    },
                    {
                      key: "actions",
                      size: "auto",
                      render: (importInError) =>
                        importInError.errors.length > 0 ? (
                          <Button
                            icon={<Pencil />}
                            variant="text"
                            onClick={() => onUpdate(importInError)}
                          >
                            Corriger
                          </Button>
                        ) : (
                          <div />
                        ),
                    },
                  ]}
                />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField className={styles.paginationContainer}>
                <Pagination
                  page={page}
                  onChange={setPage}
                  pageSize={pageSize}
                  total={errors.length}
                />
              </FormField>
            </FormRow>
          </>
        )}
      </FormGrid>
    </Card>
  );
}
