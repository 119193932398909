import { userSession } from "@src/session/UserSession";
import { GatewayAvancement } from "@conformite/gateway";

export class GatewayAvancementApi {
  static async getAvancementForAdmin(siren: string, millesime: number) {
    const { data } =
      await userSession.axiosInstance.get<GatewayAvancement.AvancementResponseType>(
        `${GatewayAvancement.avancementControllerUrl}/${millesime}/${siren}`
      );
    return data;
  }
}
