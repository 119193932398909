import {
  GatewayConformiteRcPro,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useMemo } from "react";
import {
  PropertyKeyWithoutFiles,
  getBlacklistedProperties,
  getPropertiesLabel,
} from "../conformiteRcPro.helper";
import styles from "../ConformiteRcPro.module.scss";
import { PropertyStatus } from "./ConformiteRcProReadStatus";

const useConformiteRcProReadProperties = (
  properties: GatewayConformiteRcPro.ConformiteRcProProperty,
  categorie: GatewayInfoSocieteOrias.Categorie,
  millesime: number
) => {
  const hasRcpIndividuelleSouscritSetToNo =
    (
      properties as {
        rcpIndividuelleSouscrit?: GatewayConformiteRcPro.ConformiteRcProPropertyType;
      }
    ).rcpIndividuelleSouscrit ===
    GatewayConformiteRcPro.ConformiteRcProPropertyType.NON;
  return useMemo(
    () =>
      Object.entries(properties).filter(([key]) => {
        if (
          millesime &&
          getBlacklistedProperties(categorie, millesime).includes(key)
        )
          return false;
        if (
          key !== "courvertRcMandant" &&
          key !== "nombreMIOBSP" &&
          key !== "nombreMIA" &&
          key !== "travailleAvecMIADeclareAssureur" &&
          key !== "travailleAvecMIOBSPDeclareAssureur"
        )
          return true;
        return hasRcpIndividuelleSouscritSetToNo;
      }),
    [hasRcpIndividuelleSouscritSetToNo, properties, millesime, categorie]
  );
};

type ConformiteRcProReadPropertiesProps = {
  properties: GatewayConformiteRcPro.ConformiteRcProProperty;
  categorie: GatewayInfoSocieteOrias.Categorie;
  millesime: number;
};
export function ConformiteRcProReadProperties({
  properties,
  categorie,
  millesime,
}: ConformiteRcProReadPropertiesProps) {
  const propertiesToShow = useConformiteRcProReadProperties(
    properties,
    categorie,
    millesime
  );

  return (
    <>
      {propertiesToShow.map(([key, value]) => (
        <FormRow key={key}>
          <FormField>
            <div className={styles.property}>
              <p className={styles.propertyText}>
                {getPropertiesLabel(millesime)[key as PropertyKeyWithoutFiles]}
              </p>
              <PropertyStatus
                value={
                  value as GatewayConformiteRcPro.ConformiteRcProPropertyType
                }
                propertyKey={key as PropertyKeyWithoutFiles}
                millesime={millesime}
              />
            </div>
          </FormField>
        </FormRow>
      ))}
    </>
  );
}
