import {
  ExcelPropertyType,
  ExcelTemplate,
  ExportCellTemplate,
} from "@src/helper/excel/excel.definition";

export const entrepriseExcelTemplate: ExcelTemplate = {
  firstRowIndex: 2,
  categories: {
    main: {
      columns: {
        siren: { col: "A", type: ExcelPropertyType.string },
        numeroOrias: { col: "B", type: ExcelPropertyType.string },
        formeJuridique: { col: "C", type: ExcelPropertyType.string },
        raisonSociale: { col: "D", type: ExcelPropertyType.string },
        typeVoie: { col: "E", type: ExcelPropertyType.string },
        numeroVoie: { col: "F", type: ExcelPropertyType.string },
        adresse: { col: "G", type: ExcelPropertyType.string },
        complementAdresse: { col: "H", type: ExcelPropertyType.string },
        codePostal: { col: "I", type: ExcelPropertyType.string },
        ville: { col: "J", type: ExcelPropertyType.string },
        pays: { col: "K", type: ExcelPropertyType.string },
        trancheEffectif: { col: "L", type: ExcelPropertyType.string },
        chiffreAffaire: { col: "M", type: ExcelPropertyType.string },
        categorieOriasCOA: { col: "N", type: ExcelPropertyType.boolean },
        coaAccessoire: { col: "O", type: ExcelPropertyType.boolean },
        categorieOriasMIA: { col: "P", type: ExcelPropertyType.boolean },
        miaAccessoire: { col: "Q", type: ExcelPropertyType.boolean },
        categorieOriasCOBSP: { col: "R", type: ExcelPropertyType.boolean },
        cobspAccessoire: { col: "S", type: ExcelPropertyType.boolean },
        categorieOriasMIOBSP: {
          col: "T",
          type: ExcelPropertyType.boolean,
        },
        miobspAccessoire: { col: "U", type: ExcelPropertyType.boolean },
        sousCategorieCobsp1: { col: "V", type: ExcelPropertyType.string },
        sousCategorieCobsp2: { col: "W", type: ExcelPropertyType.string },
        sousCategorieCobsp3: { col: "X", type: ExcelPropertyType.string },
        sousCategorieCobsp4: { col: "Y", type: ExcelPropertyType.string },
        sousCategorieCobsp5: { col: "Z", type: ExcelPropertyType.string },
        sousCategorieCobsp6: { col: "AA", type: ExcelPropertyType.string },
        sousCategorieMiobsp1: { col: "AB", type: ExcelPropertyType.string },
        sousCategorieMiobsp2: { col: "AC", type: ExcelPropertyType.string },
        sousCategorieMiobsp3: { col: "AD", type: ExcelPropertyType.string },
        sousCategorieMiobsp4: { col: "AE", type: ExcelPropertyType.string },
        sousCategorieMiobsp5: { col: "AF", type: ExcelPropertyType.string },
        sousCategorieMiobsp6: { col: "AG", type: ExcelPropertyType.string },
        organisationPro1: { col: "AH", type: ExcelPropertyType.string },
        organisationPro2: { col: "AI", type: ExcelPropertyType.string },
        organisationPro3: { col: "AJ", type: ExcelPropertyType.string },
        categorieRepresentation: { col: "AK", type: ExcelPropertyType.string },
        errors: { col: "AL", type: ExcelPropertyType.string },
      },
    },
  },
};

const getEntrepriseExcelTemplateExport = (
  input: typeof entrepriseExcelTemplate
): ExportCellTemplate[] => {
  return Object.entries(input.categories.main.columns).reduce<
    ExportCellTemplate[]
  >((acc, [key, value]) => [...acc, { path: key, type: value.type }], []);
};

export const entrepriseExcelExportTemplate = getEntrepriseExcelTemplateExport(
  entrepriseExcelTemplate
);
