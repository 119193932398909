export function ControleQuinquennalExplainAxeRcProCobsp() {
  return (
    <div>
      <p>
        Les documents sollicités ci-dessous permettront de vérifier la
        couverture de l&apos;activité de COBSP par une garantie RC
        Professionnelle conforme aux exigences légales (activité couverte,
        territoire et période de couverture, montants de garantie et de
        franchise).
      </p>
      <br />
      <ul>
        <li>
          Pièce(s) à fournir mentionnant la franchise et la territorialité de la
          garantie RC Professionnelle souscrite :
        </li>
        <ul>
          <li>Conditions particulières</li>
          <li>Et Conditions générales</li>
          <li>
            Et/ou tout autre document tel que le tableau de garantie ou le
            certificat d&apos;adhésion
          </li>
        </ul>

        <li>
          En cas de couverture de mandataires par votre garantie RC
          Professionnelle :
        </li>
        <ul>
          <li>La liste nominative de vos mandataires actuels</li>
        </ul>
      </ul>
    </div>
  );
}
