import { GatewayAdhesion, GatewayEntreprise } from "@conformite/gateway";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { storeEntrepriseUpdatedRequest } from "../../../payment/utils/createEntreprises";

export const useSocieteStripePaiement = (
  siren: string,
  entreprise: GatewayEntreprise.UpdateEntrepriseAControllerRequestType,
  paiementIntention: GatewayAdhesion.CreateIntentionPaimentResponseType
) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent?.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      })
      .catch(console.error);
  }, [stripe]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      throw new Error("Une erreur est survenue");
    }

    setIsLoading(true);

    storeEntrepriseUpdatedRequest(entreprise);

    const returnUrl = `${
      import.meta.env.VITE_URL
    }/societes/${siren}/informations?paiement=success&hasReachedLimit=${
      (paiementIntention.hasReachedLimit ||
        GatewayEntreprise.AAtteintLeTarif.NON) as string
    }`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: returnUrl,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return { onSubmit: handleSubmit, isLoading, message };
};
