import {
  Button,
  Card,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { Table } from "@src/components/Table/Table";
import { ReactComponent as ChevronDown } from "@src/assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "@src/assets/icons/chevron-up.svg";
import { useState } from "react";
import styles from "./EntrepriseImportDoublon.module.scss";

export type EntrepriseImportDoublonData = {
  index: number;
  description: string;
  siren?: string;
  raisonSociale?: string;
};

type EntrepriseImportDoublonProps = {
  doublons: EntrepriseImportDoublonData[];
};

export function EntrepriseImportDoublon({
  doublons,
}: EntrepriseImportDoublonProps) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Card>
      <FormGrid disableHorizontalGutter>
        <FormRow className={styles.cardHeader}>
          <FormField>
            <h2>
              <span className={styles.countErrors}>{doublons.length}</span>{" "}
              doublons de Siren détectés
            </h2>
          </FormField>
          <FormField className={styles.collapseButton}>
            <Button
              variant="text"
              onClick={() => setIsOpen((prev) => !prev)}
              icon={isOpen ? <ChevronUp /> : <ChevronDown />}
            />
          </FormField>
        </FormRow>
        {isOpen && (
          <FormRow>
            <FormField>
              <Table
                className={styles.tableWithoutShadow}
                data={doublons}
                columns={[
                  {
                    key: "index",
                    title: "Ligne",
                    size: 1,
                    render: ({ index }) => <p>{index}</p>,
                  },
                  {
                    key: "raisonSociale",
                    title: "Raison sociale",
                    size: 2,
                    render: (entreprise) => (
                      <p className={styles.raisonSociale}>
                        {entreprise.raisonSociale}
                      </p>
                    ),
                  },
                  {
                    key: "siren",
                    title: "SIREN",
                    size: 2,
                    render: (entreprise) => <p>{entreprise.siren}</p>,
                  },
                  {
                    key: "comment",
                    title: "Commentaire",
                    size: 3,
                    render: ({ description }) => <p>{description}</p>,
                  },
                ]}
              />
            </FormField>
          </FormRow>
        )}
      </FormGrid>
    </Card>
  );
}
