import {
  Card,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { AssujettiDataItemError } from "@src/assujettis/AssujettiData";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { ReactNode } from "react";
import { AssujettiImportCorrection } from "./AssujettiImportCorrection";
import styles from "./AssujettiImportDashboard.module.scss";
import { AssujettiImportErrors } from "./AssujettiImportErrors";
import { ImportAssujettiItem } from "./useImportAssujetti";

interface AssujettiImportDashboardProps {
  success: ImportAssujettiItem[];
  errors: AssujettiDataItemError[];
  onUpdateError: (error: AssujettiDataItemError) => void;
  onExport: () => void;
  importButton: ReactNode;
}

export function AssujettiImportDashboard({
  success,
  errors,
  onUpdateError,
  onExport,
  importButton,
}: AssujettiImportDashboardProps) {
  return (
    <FormGrid disableHorizontalGutter>
      {errors.length > 0 && (
        <FormRow>
          <FormField>
            <InfoCard type="warning" className={styles.infoCard}>
              {success.length === 0
                ? "Votre fichier semble ne pas correspondre au modèle attendu. Nous vous remercions de respecter le format et la structure du fichier modèle à télécharger ici"
                : "Votre fichier comporte des lignes erronées."}
            </InfoCard>
          </FormField>
        </FormRow>
      )}
      <FormRow>
        <FormField>
          {errors.length > 0 && (
            <h2 className={styles.detailTitle}>Détails des erreurs</h2>
          )}
        </FormField>
      </FormRow>
      {errors.length > 0 && (
        <FormRow>
          <FormField>
            <AssujettiImportErrors onUpdate={onUpdateError} errors={errors} />
          </FormField>
        </FormRow>
      )}
      {success.length > 0 && (
        <FormRow>
          <FormField>
            <Card>
              <h2>
                <span className={styles.countLignesCorrectes}>
                  {success.length}
                </span>
                {success.length > 1 ? " lignes correctes" : " ligne correcte"}
              </h2>
            </Card>
          </FormField>
        </FormRow>
      )}
      {errors.length > 0 && (
        <FormRow className={styles.correctionBloc}>
          <FormField>
            <AssujettiImportCorrection
              onExport={onExport}
              importButton={importButton}
            />
          </FormField>
        </FormRow>
      )}
    </FormGrid>
  );
}
