import { CreationEntreprisePrincipaleType } from '../../creation-entreprise/service/creation-entreprise-principale.definition';
import { CreationContactType } from '../../creation-contact/service/creation-contact.definition';
import { TypeAdhesionActive } from '../../entreprise/service/entreprise.definition';
import { PaymentWithHash } from '../../paiement/service/paiement.definition';
import {
  ActiviteBancaire,
  Categorie,
  CategorieRepresentation,
  OrganisationProfessionnelle,
} from '../../info-societe-orias/service/info-societe-orias.definition';
import { CreationEntrepriseAControllerType as CreationEntrepriseAControllerTypeService } from '../../creation-entreprise/service/creation-entreprise-a-controller.definition';
import {
  FormeJuridique,
  TrancheEffectif,
  TypeSouscripteur,
} from '../../entreprise/controller/entreprise.definition';
import { AdresseType } from '../../common/entreprise/adresse.definition';
import {
  ContactCivilite,
  ContactFonction,
} from '../../contact/service/contact.definition';
export const adhesionControllerUrl = 'adhesion';

export const adhesionInitUrl = `${adhesionControllerUrl}/init`;

export type InitRequestType = {
  contact: Omit<
    CreationContactType,
    'entreprisePrincipaleId' | 'role' | 'axesConformite' | 'etat'
  >;
  entreprisePrincipale: Omit<
    CreationEntreprisePrincipaleType,
    'typeAdhesion' | 'millesimeActuel'
  >;
};

export type CreationEntrepriseAControllerType =
  CreationEntrepriseAControllerTypeService;

export const createIntentionPaiementGroupeUrl = `${adhesionControllerUrl}/createIntentionPaiementGroupe`;
export const createIntentionPaiementEIUrl = `${adhesionControllerUrl}/createIntentionPaiementEI`;
export const createEntreprisesAfterPaiementGroupeUrl = `${adhesionControllerUrl}/createEntreprisesAfterPaiementGroupe`;
export const createEntreprisesAfterPaiementEIUrl = `${adhesionControllerUrl}/createEntreprisesAfterPaiementEI`;
export const createIntentionPaiementRenouvellementGroupeUrl = `${adhesionControllerUrl}/createIntentionPaiementRenouvellementGroupe`;
export const createIntentionPaiementRenouvellementEIUrl = `${adhesionControllerUrl}/createIntentionPaiementRenouvellementEI`;
export const renouvellerGroupeAfterPaiement = `${adhesionControllerUrl}/renouvellerGroupeAfterPaiement`;
export const changerCategoriesOfEntreprise = `${adhesionControllerUrl}/changerCategoriesOfEntreprise`;
export const renouvellerEIAfterPaiement = `${adhesionControllerUrl}/renouvellerEIAfterPaiement`;
export const adhesionCacheUrl = `${adhesionControllerUrl}/cache`;

export type AdhesionCreationEntrepriseAControllerRequestType = Omit<
  CreationEntrepriseAControllerType,
  'idFacture' | 'dateFacture' | 'millesime'
>;

export type UpdateCategoriesEntrepriseAContrololerRequestType =
  AdhesionCreationEntrepriseAControllerRequestType & {
    millesime: number;
  };

export type CreateIntentionPaimentGroupeRequestType = {
  typeAdhesion: TypeAdhesionActive;
  entreprisesAController: AdhesionCreationEntrepriseAControllerRequestType[];
  coupon?: string;
};

export type CreateIntentionPaimentResponseType = PaymentWithHash;
export type CreateIntentionPaimentEIResponseType = PaymentWithHash;

export type CreateIntentionPaimentEIRequestType = {
  numeroOrias?: string;
  chiffreAffaire: number;
  categoriesActivites: Categorie[];
  categoriesActivitesAccessoires: Categorie[];
  categorieRepresentation: CategorieRepresentation;
  organisationProfessionnelle: OrganisationProfessionnelle[];
  sousCategorieCOBSP: ActiviteBancaire[];
  sousCategoriesIOBSP: ActiviteBancaire[];
  coupon?: string;
  consentementEDI: boolean;
};

export type RenouvellementContactRequestType = {
  civilite: ContactCivilite;
  nom: string;
  prenom: string;
  telephone?: string;
  fonction: ContactFonction;
};
export type RenouvellementEntreprisePrincipaleRequestType = {
  raisonSociale: string;
  trancheEffectif: TrancheEffectif;
  formeJuridique: FormeJuridique;
  typeSouscripteur: TypeSouscripteur;
  adresse: AdresseType;
};
export type EntrepriseIdAndSiren = {
  id: string;
  siren: string;
};
export type CreateEntreprisesAfterPaiementEIRequestType = {
  invoiceId: string;
  hash: string;
  creationRequest: CreateIntentionPaimentEIRequestType;
  idsEntreprisesNonAdherentes: EntrepriseIdAndSiren[];
};

export type CreateEntreprisesAfterPaiementGroupeRequestType = {
  invoiceId?: string;
  hash?: string;
  creationRequest: CreateIntentionPaimentGroupeRequestType;
  idsEntreprisesNonAdherentes: EntrepriseIdAndSiren[];
};

export type UpdateEntreprisesAfterPaiementEIRequestType = {
  invoiceId?: string;
  hash?: string;
  creationRequest: CreateIntentionPaimentEIRequestType;
  contact: RenouvellementContactRequestType;
  entreprisePrincipale: RenouvellementEntreprisePrincipaleRequestType;
  idsEntreprisesNonAdherentes: EntrepriseIdAndSiren[];
};

export type UpdateEntreprisesAfterPaiementGroupeRequestType = {
  invoiceId?: string;
  hash?: string;
  creationRequest: CreateIntentionPaimentGroupeRequestType;
  contact: RenouvellementContactRequestType;
  entreprisePrincipale: RenouvellementEntreprisePrincipaleRequestType;
  idsEntreprisesNonAdherentes: EntrepriseIdAndSiren[];
};

export type ChangerCategoriesOfEntrepriseRequestType = {
  invoiceId: string;
  hash: string;
  entreprise: CreationEntrepriseAControllerType;
  hubspotId: string;
};

export type CreationEntrepriseAControllerRequestType = {
  adresse: AdresseType;
  categorieRepresentation: CategorieRepresentation;
  categoriesActivites: Categorie[];
  categoriesActivitesAccessoires: Categorie[];
  chiffreAffaire: number;
  formeJuridique: FormeJuridique;
  millesime: number;
  numeroOrias?: string;
  organisationProfessionnelle: OrganisationProfessionnelle[];
  raisonSociale: string;
  siren: string;
  sousCategorieCOBSP: ActiviteBancaire[];
  sousCategoriesIOBSP: ActiviteBancaire[];
  trancheEffectif: TrancheEffectif;
};

export type CacheAdhesionType = 'ADHESION' | 'RENOUVELLEMENT';

export type CacheAdhesionRequestType = {
  cached: string | null;
};
