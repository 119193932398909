import { GatewayEntreprise } from "@conformite/gateway";
import {
  activitesFromEntreprise,
  isIas,
  isIobsp,
} from "@src/helper/entreprise.helper";
import { Parcours } from "@src/parcours/Parcours";
import { StepComponentProps } from "@src/parcours/Parcours.types";
import { Assujetti } from "@src/store/store.definition";
import { useEntreprises } from "@src/store/store.entreprise";
import { intersection } from "lodash";
import { useMemo } from "react";
import { ConformiteFormHonorabilite } from "./Steps/ConformiteFormHonorabilite/ConformiteFormHonorabilite";
import { ConformiteFormCapaciteProIAS } from "./Steps/FormsCapacitePro/ConformiteFormCapaciteProIAS/ConformiteFormCapaciteProIAS";
import { ConformiteFormCapaciteProIOBSP } from "./Steps/FormsCapacitePro/ConformiteFormCapaciteProIOBSP/ConformiteFormCapaciteProIOBSP";
import { ConformiteFormContinueIAS } from "./Steps/FormsFormation/ConformiteFormFormationIAS/ConformiteFormFormationIAS";
import { ConformiteFormContinueIOBSP } from "./Steps/FormsFormation/ConformiteFormFormationIOBSP/ConformiteFormFormationIOBSP";
import { UIAssujettiParcoursConformite } from "./UIAssujettiParcoursConformite";

export enum AssujettiParcoursConformiteEnum {
  HONORABILITE = "honorabilite",
  FORMATION_CONTINUE_IAS = "formation-continue-ias",
  FORMATION_CONTINUE_IOBSP = "formation-continue-iobsp",
  CAPACITE_PRO_IOBSP = "capacite-pro-iobsp",
  CAPACITE_PRO_IAS = "capacite-pro-ias",
}

export type AssujettiConformiteData = {
  assujetti: Assujetti;
};

type AssujettiConformiteStepProperties = {
  closePane: (unmount?: boolean) => void;
  entreprise?: GatewayEntreprise.EntrepriseAControllerResponseType;
  readonly?: boolean;
};

type AssujettiParcoursConformiteProps = {
  closePane: () => void;
  assujetti: Assujetti;
  firstStep: AssujettiParcoursConformiteEnum;
  readonly?: boolean;
};

export type ConformiteStepComponentProps = StepComponentProps<
  AssujettiParcoursConformiteEnum,
  AssujettiConformiteData,
  AssujettiConformiteStepProperties
>;

export function AssujettiParcoursConformite({
  assujetti,
  closePane,
  firstStep,
  readonly,
}: AssujettiParcoursConformiteProps) {
  const { entreprisesBySiren } = useEntreprises();
  const entreprise = entreprisesBySiren
    ? entreprisesBySiren[assujetti.sirenCompany]
    : undefined;

  return (
    <div>
      <Parcours<
        AssujettiParcoursConformiteEnum,
        AssujettiConformiteData,
        AssujettiConformiteStepProperties
      >
        withRouter={false}
        initialData={{ assujetti }}
        UIParcoursComponent={UIAssujettiParcoursConformite}
        config={{
          [AssujettiParcoursConformiteEnum.HONORABILITE]: {
            component: ConformiteFormHonorabilite,
          },
          [AssujettiParcoursConformiteEnum.FORMATION_CONTINUE_IAS]: {
            component: ConformiteFormContinueIAS,
          },
          [AssujettiParcoursConformiteEnum.FORMATION_CONTINUE_IOBSP]: {
            component: ConformiteFormContinueIOBSP,
          },
          [AssujettiParcoursConformiteEnum.CAPACITE_PRO_IOBSP]: {
            component: ConformiteFormCapaciteProIOBSP,
          },
          [AssujettiParcoursConformiteEnum.CAPACITE_PRO_IAS]: {
            component: ConformiteFormCapaciteProIAS,
          },
        }}
        firstStep={firstStep}
        errorHandlers={{
          notAuthorized: () => undefined,
          notFound: () => undefined,
        }}
        stepProperties={{
          closePane,
          entreprise,
          readonly,
        }}
      />
    </div>
  );
}

export function hasIntersections(a1: unknown[], a2: unknown[]) {
  return intersection(a1, a2).length > 0;
}

export function getAvailableStepsConformite(
  entreprise?: GatewayEntreprise.EntrepriseAControllerResponseType
): AssujettiParcoursConformiteEnum[] {
  const available = [AssujettiParcoursConformiteEnum.HONORABILITE];
  if (!entreprise) return available;
  const isEntrepriseIas = isIas(activitesFromEntreprise(entreprise));
  const isEntrepriseIobsp = isIobsp(activitesFromEntreprise(entreprise));

  if (isEntrepriseIas)
    available.push(AssujettiParcoursConformiteEnum.CAPACITE_PRO_IAS);
  if (isEntrepriseIobsp)
    available.push(AssujettiParcoursConformiteEnum.CAPACITE_PRO_IOBSP);
  if (isEntrepriseIas)
    available.push(AssujettiParcoursConformiteEnum.FORMATION_CONTINUE_IAS);
  if (isEntrepriseIobsp)
    available.push(AssujettiParcoursConformiteEnum.FORMATION_CONTINUE_IOBSP);

  return available;
}

export function useChangeStepConformite(
  activeStep: AssujettiParcoursConformiteEnum,
  changeStep: (step: AssujettiParcoursConformiteEnum) => void,
  closePane: (unmount?: boolean) => void,
  entreprise?: GatewayEntreprise.EntrepriseAControllerResponseType
) {
  const res = useMemo(() => {
    const steps = getAvailableStepsConformite(entreprise);
    const currentIndex = steps.indexOf(activeStep);
    const hasError = currentIndex === -1;

    return {
      goPrev: () => {
        if (hasError || currentIndex === 0) return;
        changeStep(steps[currentIndex - 1]);
      },
      goNext: () => {
        if (hasError) return;
        if (currentIndex === steps.length - 1) closePane(true);
        changeStep(steps[currentIndex + 1]);
      },
    };
  }, [activeStep, changeStep, closePane, entreprise]);

  return res;
}
