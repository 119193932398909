import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as XLg } from "@src/assets/icons/x-lg.svg";
import { Link } from "@src/components/Link/Link";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import styles from "./SocieteInformationActions.module.scss";
import { ID_FORM_SOCIETE_INFORMATION } from "./SocieteInformation";

type SocieteInformationActionsProps = {
  idSociete: string;
  isLoading: boolean;
};
export function SocieteInformationActions({
  idSociete,
  isLoading,
}: SocieteInformationActionsProps) {
  return (
    <div className={styles.actions}>
      <Link to={`/societes/${idSociete}`}>
        <Button variant="text" icon={<XLg />}>
          Annuler les modifications
        </Button>
      </Link>
      <Button
        type="submit"
        form={ID_FORM_SOCIETE_INFORMATION}
        icon={isLoading ? <Loader /> : undefined}
      >
        Enregistrer
      </Button>
    </div>
  );
}
