import {
  Button,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { Dialog, DialogComponentProps } from "@src/components/Dialog/Dialog";
import { ReactComponent as XLg } from "@src/assets/icons/x-lg.svg";
import classNames from "classnames";
import { useState } from "react";
import { ReactComponent as Info } from "@src/assets/icons/info-circle-fill.svg";
import { ReactComponent as Warning } from "@src/assets/icons/exclamation-circle-fill.svg";
import style from "./RenouvellementMajSocietesDialog.module.scss";
import { SelectedModeImport } from "../useImportRenouvellement";

export type RenouvellementMajSocietesDialogProps = {
  onClose?: (value: string) => void;
  setSelectedMode: (nextMode: SelectedModeImport | null) => void;
} & DialogComponentProps;

export function RenouvellementMajSocietesDialog({
  onClose,
  dialogRef,
  setSelectedMode,
}: RenouvellementMajSocietesDialogProps) {
  const [selected, setSelected] = useState<SelectedModeImport | null>(null);

  const handleSubmit = () => {
    if (!selected || !onClose) return;

    setSelectedMode(selected);
    onClose("true");
  };

  return (
    <Dialog dialogRef={dialogRef}>
      <FormGrid>
        <FormRow>
          <FormField>
            <h3>Je souhaite que cet import :</h3>
          </FormField>
        </FormRow>
        <FormRow className={style.completeButton}>
          <button
            className={style.button}
            onClick={() => setSelected("complete")}
          >
            <div
              className={classNames(style.card, {
                [style.selected]: selected === "complete",
              })}
            >
              <p>Complète la liste existante des sociétés à contrôler</p>
              <div className={classNames(style.blue, style.text)}>
                <Info />
                <p>
                  Les sociétés dans cet import s’ajouteront aux sociétés déjà
                  renseignées.
                </p>
              </div>
            </div>
          </button>
        </FormRow>
        <FormRow className={style.replaceButton}>
          <button
            className={style.button}
            onClick={() => setSelected("replace")}
          >
            <div
              className={classNames(style.card, {
                [style.selected]: selected === "replace",
              })}
            >
              <p>Remplace la liste existante des sociétés à contrôler</p>
              <div className={classNames(style.orange, style.text)}>
                <Warning />
                <p>Les données actuelles seront écrasées</p>
              </div>
            </div>
          </button>
        </FormRow>
        <FormRow>
          <FormField className={style.actions}>
            <Button
              variant="text"
              onClick={() => {
                if (!onClose) return;
                onClose("false");
              }}
              icon={<XLg />}
            >
              Annuler
            </Button>

            <Button disabled={!selected} onClick={handleSubmit}>
              Suivant
            </Button>
          </FormField>
        </FormRow>
      </FormGrid>
    </Dialog>
  );
}
