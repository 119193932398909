import {
  GatewayConformiteRcPro,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";

export type PropertyKeyWithoutFiles = Exclude<
  GatewayConformiteRcPro.ConformiteRcProPropertyKey,
  "files"
>;

export const getBlacklistedProperties = (
  categorie: GatewayInfoSocieteOrias.Categorie,
  millesime: number
) => {
  const blacklistedProperties = ["files", "avancement", "millesime"];
  if (millesime < 2025) {
    blacklistedProperties.push(
      "travailleAvecMIA",
      "travailleAvecMIADeclareAssureur",
      "nombreMIOBSP",
      "travailleAvecMIOBSPDeclareAssureur",
      "travailleAvecMIOBSP",
      "declareCollaborateurIOBSP",
      "collaborateursDistributionAssurance"
    );
  }
  if (
    millesime >= 2025 &&
    categorie === GatewayInfoSocieteOrias.Categorie.MIA
  ) {
    blacklistedProperties.push(
      "declarationApporteurCommercialisation",
      "declarationNombreSalarie"
    );
  }
  if (
    millesime >= 2025 &&
    categorie === GatewayInfoSocieteOrias.Categorie.COA
  ) {
    blacklistedProperties.push(
      "declarationApporteurCommercialisation",
      "declarationNombreSalarie"
    );
  }
  if (categorie === GatewayInfoSocieteOrias.Categorie.COBSP) {
    if (millesime >= 2025)
      blacklistedProperties.push(
        "activiteTerritoieFrancais",
        "declarationNombreSalarie",
        "declarationApporteurCommercialisation"
      );
    else blacklistedProperties.push("declarationActivitesIntraComnmunautaire");
  }
  if (
    millesime >= 2025 &&
    categorie === GatewayInfoSocieteOrias.Categorie.MIOBSP
  ) {
    blacklistedProperties.push(
      "activiteTerritoieFrancais",
      "declarationNombreSalarie",
      "declarationApporteurCommercialisation"
    );
  }
  return blacklistedProperties;
};
export const RcProPropertyCoaDefault: GatewayConformiteRcPro.ConformiteRcProPropertyCOA =
  {
    niveauMinimumGarantie:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    franchiseInferieur:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationActivitesIntraComnmunautaire:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationApporteurCommercialisation:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationNombreSalarie:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    travailleAvecMIA:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    nombreMIA: 0,
    travailleAvecMIADeclareAssureur:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    collaborateursDistributionAssurance:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
  };
export const RcProPropertyMiaDefault: GatewayConformiteRcPro.ConformiteRcProPropertyMIA =
  {
    rcpIndividuelleSouscrit:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    courvertRcMandant:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    niveauMinimumGarantie:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    franchiseInferieur:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationActivitesIntraComnmunautaire:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationApporteurCommercialisation:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationNombreSalarie:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    collaborateursDistributionAssurance:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
  };
export const RcProPropertyCobspDefault: GatewayConformiteRcPro.ConformiteRcProPropertyCOBSP =
  {
    niveauMinimumGarantieBSP:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    franchiseInferieur:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    activiteTerritoieFrancais:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationApporteurCommercialisation:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationNombreSalarie:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationActivitesIntraComnmunautaire:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    travailleAvecMIOBSP:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    nombreMIOBSP: 0,
    travailleAvecMIOBSPDeclareAssureur:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declareCollaborateurIOBSP:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
  };
export const RcProPropertyCobspMiobspDefault: GatewayConformiteRcPro.ConformiteRcProPropertyMIOBSP =
  {
    rcpIndividuelleSouscrit:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    courvertRcMandant:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    niveauMinimumGarantieBSP:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    franchiseInferieur:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    activiteTerritoieFrancais:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationApporteurCommercialisation:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declarationNombreSalarie:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
    declareCollaborateurIOBSP:
      GatewayConformiteRcPro.ConformiteRcProPropertyType.A_VERIFIER,
  };

export const RcProPropertyDefault: Record<
  GatewayInfoSocieteOrias.Categorie,
  GatewayConformiteRcPro.ConformiteRcProProperty
> = {
  COA: RcProPropertyCoaDefault,
  MIA: RcProPropertyMiaDefault,
  COBSP: RcProPropertyCobspDefault,
  MIOBSP: RcProPropertyCobspMiobspDefault,
};

export const getPropertiesLabel = (
  millesime: number
): Record<PropertyKeyWithoutFiles, string> => ({
  niveauMinimumGarantie:
    millesime >= 2025
      ? "Le niveau minimum de garantie de mon contrat est-il bien supérieur ou égal à 1.564.610 € par sinistre et 2.315.610 € par année ?"
      : "Le niveau minimum de garantie de mon contrat est-il bien fixé à 1.500.000 € par sinistre et 2.000.000 € par année ?",
  franchiseInferieur:
    millesime >= 2025
      ? "La franchise visée au contrat est-elle bien inférieure ou égale à 20% du montant de l'indemnisation ?"
      : "La franchise contractuelle est-elle bien inférieure ou égale à 20% du montant de l'indemnisation ?",
  declarationActivitesIntraComnmunautaire:
    millesime >= 2025
      ? "Avez-vous déclaré à votre assureur des activités intra-communautaires (passeport européen en libre prestation de services ou libre établissement ?"
      : "Ai-je bien déclaré à mon assureur mes activités intra-communautaires ?",
  declarationApporteurCommercialisation:
    "Ai-je bien déclaré l'existence d'éventuels sous-apporteurs auxquels je fais appel pour la commercialisation des contrats d'assurance ?",
  declarationNombreSalarie:
    "Ai-je bien indiqué le nombre de salariés travaillant au sein de mon cabinet ?",
  rcpIndividuelleSouscrit: "Avez-vous souscrit une RCP individuelle ?",
  courvertRcMandant:
    "Pour chaque activité exercée, êtes-vous couvert par la RC de votre mandant ?",
  niveauMinimumGarantieBSP:
    "Le niveau minimum de garantie de mon contrat est-il bien fixé à 500.000 € par sinistre et 800.000 € par année ?",
  activiteTerritoieFrancais:
    "Le territoire de mes activités déclarées est-il bien couvert par le contrat, à savoir le territoire français ?",
  travailleAvecMIOBSP:
    "Travaillez-vous avec des mandataires d'intermédiaires en opérations de banque et en services de paiement (MIOBSP) ?",
  nombreMIOBSP: "Si oui, combien ?",
  travailleAvecMIOBSPDeclareAssureur:
    "Si oui, cette information a-t-elle été déclaré à votre assureur ?",
  travailleAvecMIA:
    "Travaillez-vous avec des mandataires d'intermédiaires en assurance (MIA)",
  nombreMIA: "Si oui, combien ?",
  travailleAvecMIADeclareAssureur:
    "Si oui, cette information a-t-elle été déclaré à votre assureur ?",
  collaborateursDistributionAssurance:
    "Avez-vous déclaré les collaborateurs exerçant une activité de distribution d'assurance à votre assureur ?",
  declareCollaborateurIOBSP:
    "Avez-vous déclaré les collaborateurs/salariés exerçant une activité d'IOBSP à votre assureur ?",
});

export const isCategorie = (
  categorie: string
): categorie is GatewayInfoSocieteOrias.Categorie => {
  return Object.values(GatewayInfoSocieteOrias.Categorie).includes(
    categorie as GatewayInfoSocieteOrias.Categorie
  );
};
