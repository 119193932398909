/* eslint-disable react-hooks/exhaustive-deps */
import {
  GatewayAdresse,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import {
  Button,
  UIInputNumber,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { ImportSocietesItem } from "@src/adhesion/Steps/AdhesionModeImport/useImportSocietes";
import { ReactComponent as Pencil } from "@src/assets/icons/pencil.svg";
import { ReactComponent as Trash } from "@src/assets/icons/trash.svg";
import { useDialog } from "@src/components/Dialog/useDialog";
import { Dropdown } from "@src/components/DropDown/Dropdown";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import { FilterSearch } from "@src/components/FilterSearch/FilterSearch";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { InputSearch } from "@src/components/InputSearch/InputSearch";
import { Link } from "@src/components/Link/Link";
import { ListTemplate } from "@src/components/ListTemplate/ListTemplate";
import { Pagination } from "@src/components/Pagination/Pagination";
import { SidePane } from "@src/components/SidePane/SidePane";
import { Table } from "@src/components/Table/Table";
import { TitleWithNumber } from "@src/components/TitleWithNumber/TitleWithNumber";
import { ParcoursAction } from "@src/parcours/NavigationButtons/ParcoursAction";
import { SaveAndQuitButton } from "@src/parcours/NavigationButtons/SaveAndQuitButton";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { isCategorieAccessoire } from "@src/payment/utils/createIntentionPaiement";
import {
  ParcoursRenouvellementStepComponentProps,
  RenouvellementStepEnum,
} from "@src/renouvellement/ParcoursRenouvellement.definition";
import { ParcoursEntreprise } from "@src/societes/parcours/ParcoursEntreprise";
import { cloneDeep, omit, partition } from "lodash";
import { ChangeEvent, useMemo, useState } from "react";
import { RenouvellementEntrepriseAControllerData } from "../../RenouvellementData";
import { useImportRenouvellement } from "../introduction/useImportRenouvellement";
import styles from "./RenouvellementMajSocietesList.module.scss";
import {
  SOCIETES_PAGE_SIZE,
  useRenouvellementSocieteFiltered,
} from "./useRenouvellementSocieteFilter";

type UncontrolledCAInputNumberProps = {
  defaultValue: number | null;
  name: string;
  showError: boolean;
  onChange: (nextValue: number | null) => void;
  className?: string;
};
function UncontrolledCAInputNumber({
  defaultValue,
  name,
  showError,
  onChange,
  className,
}: UncontrolledCAInputNumberProps) {
  const [value, setValue] = useState(defaultValue);

  const valueHasChanged = defaultValue !== value;
  const valueIsValid = value !== null && value >= 0;
  return (
    <UIInputNumber
      value={value}
      className={className}
      name={name}
      hasError={(showError || valueHasChanged) && !valueIsValid}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const nextValue = e.target.value === "" ? null : Number(e.target.value);
        setValue(nextValue);
        onChange(nextValue);
      }}
    />
  );
}

export function transformAdhesionDataEntreprisesItemToRenouvellementEntrepriseAController(
  societe: AdhesionDataEntreprisesItem
) {
  const [categorieAccessoire, categorieNonAccessoire] = partition(
    societe.categoriesOrias || [],
    (categorie) =>
      isCategorieAccessoire(categorie, {
        coaAccessoire: !!societe.coaAccessoire,
        miaAccessoire: !!societe.miaAccessoire,
        cobspAccessoire: !!societe.cobspAccessoire,
        miobspAccessoire: !!societe.miobspAccessoire,
      })
  );
  return {
    siren: societe.siren as string,
    raisonSociale: societe.raisonSociale as string,
    adresse: {
      codePostal: societe.codePostal as string,
      libelleVoie: societe.libelleVoie as string,
      numeroVoie: societe.numeroVoie as string,
      pays: societe.pays as GatewayAdresse.Pays,
      typeVoie: societe.typeVoie as GatewayAdresse.TypeVoie,
      ville: societe.ville as string,
      complementAdresse: societe.complementAdresse,
    },
    categorieRepresentation:
      societe.categorieRepresentation as GatewayInfoSocieteOrias.CategorieRepresentation,
    categoriesActivites: categorieNonAccessoire,
    categoriesActivitesAccessoires: categorieAccessoire,
    formeJuridique: societe.formeJuridique as GatewayEntreprise.FormeJuridique,
    numeroOrias: societe.numeroOrias,
    organisationProfessionnelle:
      societe.organisationPro as GatewayInfoSocieteOrias.OrganisationProfessionnelle[],
    sousCategorieCOBSP:
      societe.sousCategorieCobsp as GatewayInfoSocieteOrias.ActiviteBancaire[],
    sousCategoriesIOBSP:
      societe.sousCategorieMiobsp as GatewayInfoSocieteOrias.ActiviteBancaire[],
    trancheEffectif:
      societe.trancheEffectif as GatewayEntreprise.TrancheEffectif,
    chiffreAffaire: societe.chiffreAffaire,
    consentementEDI: societe.consentementEDI ?? false,
  };
}

export function transformRenouvellementEntrepriseAControllerToAdhesionDataEntreprisesItem(
  entrepriseAControllerData?: RenouvellementEntrepriseAControllerData
): AdhesionDataEntreprisesItem | undefined {
  if (!entrepriseAControllerData) return undefined;
  return {
    categorieRepresentation: entrepriseAControllerData.categorieRepresentation,
    categoriesOrias: [
      ...entrepriseAControllerData.categoriesActivites,
      ...entrepriseAControllerData.categoriesActivitesAccessoires,
    ],
    coaAccessoire:
      entrepriseAControllerData.categoriesActivitesAccessoires.includes(
        GatewayInfoSocieteOrias.Categorie.COA
      ),
    miaAccessoire:
      entrepriseAControllerData.categoriesActivitesAccessoires.includes(
        GatewayInfoSocieteOrias.Categorie.MIA
      ),
    cobspAccessoire:
      entrepriseAControllerData.categoriesActivitesAccessoires.includes(
        GatewayInfoSocieteOrias.Categorie.COBSP
      ),
    miobspAccessoire:
      entrepriseAControllerData.categoriesActivitesAccessoires.includes(
        GatewayInfoSocieteOrias.Categorie.MIOBSP
      ),
    chiffreAffaire: entrepriseAControllerData.chiffreAffaire,
    codePostal: entrepriseAControllerData.adresse.codePostal,
    complementAdresse: entrepriseAControllerData.adresse.complementAdresse,
    libelleVoie: entrepriseAControllerData.adresse.libelleVoie,
    numeroVoie: entrepriseAControllerData.adresse.numeroVoie,
    pays: entrepriseAControllerData.adresse.pays,
    typeVoie: entrepriseAControllerData.adresse.typeVoie,
    ville: entrepriseAControllerData.adresse.ville,
    formeJuridique: entrepriseAControllerData.formeJuridique,
    numeroOrias: entrepriseAControllerData.numeroOrias,
    organisationPro: entrepriseAControllerData.organisationProfessionnelle,
    raisonSociale: entrepriseAControllerData.raisonSociale,
    siren: entrepriseAControllerData.siren,
    sousCategorieCobsp: entrepriseAControllerData.sousCategorieCOBSP,
    sousCategorieMiobsp: entrepriseAControllerData.sousCategoriesIOBSP,
    trancheEffectif: entrepriseAControllerData.trancheEffectif,
    consentementEDI: entrepriseAControllerData.consentementEDI,
  };
}

export function transformImportSocietesDataToRenouvellementData(
  entreprise: ImportSocietesItem[]
): Record<string, RenouvellementEntrepriseAControllerData> {
  return entreprise.reduce((acc, { societe }) => {
    acc[societe.siren as string] =
      transformAdhesionDataEntreprisesItemToRenouvellementEntrepriseAController(
        societe
      );
    return acc;
  }, {} as Record<string, RenouvellementEntrepriseAControllerData>);
}

type PaneState = {
  open: boolean;
  entrepriseToEdit?: RenouvellementEntrepriseAControllerData;
};

export function RenouvellementMajSocietesList({
  data,
  setData,
  addData,
  changeStep,
  handleQuit,
}: ParcoursRenouvellementStepComponentProps) {
  const [unmountPaneAfterDeleteCount, setUnmountPaneAfterDeleteCount] =
    useState(0);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [paneState, setPaneState] = useState<PaneState>({
    open: false,
  });
  const entreprisesArray = useMemo(
    () => Object.values(data.entreprisesAControler),
    [data.entreprisesAControler]
  );
  const {
    societes,
    societesPaginated,
    numberOfSocietes,
    page,
    search,
    setPage,
    setSearch,
  } = useRenouvellementSocieteFiltered(entreprisesArray);

  const memoizedSocietesPaginated = useMemo(() => {
    return cloneDeep(societesPaginated);
  }, [data.entreprisesUpdateCount, page, search]);

  const societesAreValid = societesPaginated.every(
    (s) => s.chiffreAffaire !== undefined && s.chiffreAffaire >= 0
  );

  const { handleImportSocietes } = useImportRenouvellement(
    data,
    addData,
    changeStep
  );
  const { openConfirmDialog } = useDialog();

  return (
    <>
      <ParcoursFullPageTemplate
        title={
          <TitleWithNumber
            title="Sociétés à contrôler"
            number={numberOfSocietes}
          />
        }
        actions={
          <>
            <SaveAndQuitButton onClick={handleQuit} />
            <ParcoursAction
              onClick={() => {
                setHasSubmitted(true);
                if (societesAreValid) {
                  changeStep(RenouvellementStepEnum.PAIEMENT);
                }
              }}
            >
              Suivant
            </ParcoursAction>
          </>
        }
        size="large"
      >
        {hasSubmitted && !societesAreValid && (
          <InfoCard type="error" className={styles.importError}>
            Certains Chiffres d&apos;affaires sont manquants ou invalides
          </InfoCard>
        )}
        <ListTemplate
          header={
            <FilterSearch
              search={
                <InputSearch
                  placeholder="Raison sociale"
                  name="search"
                  value={search}
                  onChange={(nextValue) => {
                    setSearch(nextValue.target.value);
                    setPage(0);
                  }}
                />
              }
              action={
                <div className={styles.actions}>
                  <Button
                    variant="outline"
                    onClick={() =>
                      setPaneState({
                        open: true,
                      })
                    }
                  >
                    Ajouter un entreprise
                  </Button>
                  <Dropdown content="Actions" placement="bottom-end">
                    {({ close: closeDropDown }) => (
                      <>
                        <Link to="/modele_societe.xlsx" target="_blank">
                          <Button
                            variant="text"
                            className={styles.download}
                            onClick={() => closeDropDown()}
                          >
                            Télécharger le fichier modèle
                          </Button>
                        </Link>
                        <FileInputButton
                          variant="text"
                          onChange={(e) => {
                            closeDropDown();
                            handleImportSocietes(e);
                          }}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        >
                          Importer la liste des sociétés à jour
                        </FileInputButton>
                      </>
                    )}
                  </Dropdown>
                </div>
              }
            />
          }
          table={
            <Table
              data={memoizedSocietesPaginated}
              columns={[
                {
                  key: "raisonSociale",
                  title: "Raison sociale",
                  size: 2,
                  render: (entreprise) => <p>{entreprise.raisonSociale}</p>,
                },
                {
                  key: "siren",
                  title: "SIREN",
                  size: 1,
                  render: (entreprise) => <p>{entreprise.siren}</p>,
                },
                {
                  key: "numeroOrias",
                  title: "N° Orias",
                  size: 1,
                  render: (entreprise) => <p>{entreprise.numeroOrias}</p>,
                },
                {
                  key: "chiffreAffaire",
                  title: "Chiffre d'affaires",
                  size: 1,
                  render: (entreprise) => (
                    <UncontrolledCAInputNumber
                      className={styles.caInput}
                      name={`ca-${entreprise.siren}`}
                      showError={hasSubmitted}
                      defaultValue={entreprise.chiffreAffaire ?? null}
                      onChange={(nextValue) => {
                        setData({
                          ...data,
                          entreprisesAControler: {
                            ...data.entreprisesAControler,
                            [entreprise.siren]: {
                              ...entreprise,
                              chiffreAffaire: nextValue ?? undefined,
                            },
                          },
                        });
                      }}
                    />
                  ),
                },
                {
                  key: "actions",
                  size: "auto",
                  render: (entreprise) => (
                    <div className={styles.actions}>
                      <Button
                        className={styles.editButton}
                        icon={<Pencil />}
                        onClick={() => {
                          setPaneState({
                            open: true,
                            entrepriseToEdit: entreprise,
                          });
                        }}
                        variant="text"
                      />
                      <Button
                        className={styles.deleteButton}
                        icon={<Trash />}
                        variant="text"
                        onClick={() => {
                          openConfirmDialog({
                            title: "Supprimer l'entreprise",
                            message:
                              "Êtes-vous sur(e) de vouloir supprimer cette société ?",
                            confirmText: "Confirmer",
                            cancelText: "Annuler",
                          })
                            .then((shouldContinue) => {
                              if (shouldContinue) {
                                addData({
                                  entreprisesUpdateCount:
                                    data.entreprisesUpdateCount + 1,
                                  entreprisesAControler: omit(
                                    data.entreprisesAControler,
                                    entreprise.siren
                                  ),
                                });
                                if (
                                  paneState.entrepriseToEdit?.siren ===
                                  entreprise.siren
                                )
                                  setUnmountPaneAfterDeleteCount(
                                    unmountPaneAfterDeleteCount + 1
                                  );
                                setPaneState({
                                  open: false,
                                });
                              }
                            })
                            .catch(console.error);
                        }}
                      />
                    </div>
                  ),
                },
              ]}
            />
          }
          footer={
            <Pagination
              page={page}
              pageSize={SOCIETES_PAGE_SIZE}
              total={societes.length}
              onChange={setPage}
            />
          }
        />
      </ParcoursFullPageTemplate>
      <SidePane
        key={unmountPaneAfterDeleteCount}
        isOpen={paneState.open}
        setIsOpen={(nextIsOpen) => {
          setPaneState((prevState) => ({ ...prevState, open: nextIsOpen }));
        }}
      >
        {(closePane) => (
          <ParcoursEntreprise
            key={`${JSON.stringify(paneState.entrepriseToEdit)}${
              data.entreprisesUpdateCount
            }`}
            entrepriseToEdit={transformRenouvellementEntrepriseAControllerToAdhesionDataEntreprisesItem(
              paneState.entrepriseToEdit
            )}
            withPayment={false}
            onSubmit={(adhesion: AdhesionDataEntreprisesItem) => {
              addData({
                entreprisesUpdateCount: data.entreprisesUpdateCount + 1,
                entreprisesAControler: {
                  ...data.entreprisesAControler,
                  [adhesion.siren as string]:
                    transformAdhesionDataEntreprisesItemToRenouvellementEntrepriseAController(
                      adhesion
                    ),
                },
              });
              setPaneState({ open: false });
              closePane(true);
            }}
          />
        )}
      </SidePane>
    </>
  );
}
