import {
  Button,
  Card,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useState } from "react";
import { ReactComponent as PlusCircle } from "@src/assets/icons/plus-circle.svg";
import { ReactComponent as Download } from "@src/assets/icons/download.svg";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { Link } from "@src/components/Link/Link";
import {
  AdhesionStepEnum,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { SidePane } from "@src/components/SidePane/SidePane";
import { ParcoursEntreprise } from "@src/societes/parcours/ParcoursEntreprise";
import { SaveAndQuitButton } from "@src/parcours/NavigationButtons/SaveAndQuitButton";
import styles from "./AdhesionModeImport.module.scss";
import { ImportEntreprisesButton } from "./ImportEntreprisesButton";

export function AdhesionModeImport({
  addData,
  changeStep,
  data,
  handleQuit,
}: ParcoursAdhesionStepComponentProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ParcoursFullPageTemplate
        title="Sociétés à contrôler"
        size="large"
        actions={
          <>
            <SaveAndQuitButton onClick={handleQuit} />
            <Button
              disabled={data.societesRattachees.length === 0}
              onClick={() => changeStep(AdhesionStepEnum.SOCIETES_RATTACHEES)}
            >
              Suivant
            </Button>
          </>
        }
      >
        <FormRow>
          <FormField>
            <InfoCard type="info">
              N&apos;oubliez pas d&apos;inclure la société qui administre le
              groupe si elle est également soumise au contrôle
            </InfoCard>
          </FormField>
        </FormRow>
        <FormRow>
          <FormField className={styles.cards}>
            <Card className={styles.card}>
              <h3>Importer les sociétés en masse</h3>
              <p>
                Renseignez toutes les informations des sociétés dans un fichier
                excel à partir d&apos;un modèle téléchargeable
              </p>
              <Link to="/modele_societe.xlsx" target="_blank">
                <Button
                  variant="text"
                  icon={<Download />}
                  className={styles.download}
                >
                  Télécharger le fichier modèle
                </Button>
              </Link>
              <ImportEntreprisesButton
                addData={addData}
                changeStep={changeStep}
              />
            </Card>
            <Card className={styles.card}>
              <h3>Ou renseigner les sociétés individuellement</h3>
              <p>
                Saisissez directement dans l&apos;outil les sociétés une par
                une.
              </p>
              <Button
                variant="outline"
                icon={<PlusCircle />}
                onClick={() => setIsOpen(true)}
              >
                Ajouter une société
              </Button>
            </Card>
          </FormField>
        </FormRow>
      </ParcoursFullPageTemplate>

      <SidePane isOpen={isOpen} setIsOpen={setIsOpen}>
        {() => (
          <ParcoursEntreprise
            fromParcoursAdhesion
            onSubmit={(adhesion: AdhesionDataEntreprisesItem) => {
              addData({
                societesRattachees: [...data.societesRattachees, adhesion],
              });
              changeStep(AdhesionStepEnum.SOCIETES_RATTACHEES);
            }}
          />
        )}
      </SidePane>
    </>
  );
}
