import { GatewayImportExportAssujetti } from "@conformite/gateway";
import { AssujettiDataItemError } from "@src/assujettis/AssujettiData";
import { format } from "date-fns";
import { AssujettiExcelMappedTemplate } from "../AssujettiExcelTemplate";
import { ImportAssujettiItem } from "../Import/useImportAssujetti";
import {
  excelLabelByAssujettiRole,
  excelLabelByCategorieIAS,
  excelLabelByCategorieIOBSP,
  excelLabelByGradeDiplome,
  excelLabelByNiveauIas,
  excelLabelByNiveauIobsp,
  excelLabelByStatutPro,
  excelLabelByTypeJustificatifIAS,
  excelLabelByTypeJustificatifIOBSP,
} from "../ImportExport.definition";

function transformAssujetti(
  assujetti: GatewayImportExportAssujetti.AssujettiRowImportAssujetti
): AssujettiExcelMappedTemplate["assujetti"] {
  return {
    mail: assujetti.email ?? null,
    name: assujetti.name,
    role: excelLabelByAssujettiRole[assujetti.role],
    sirenCompany: assujetti.sirenCompany,
    surname: assujetti.surname,
  };
}

function transformHonorabilite(
  honorabilite: GatewayImportExportAssujetti.AssujettiRowImportHonorabilite
): AssujettiExcelMappedTemplate["honorabilite"] {
  return {
    dateVerif: honorabilite.lastVerifiedAt ?? null,
    honorable: honorabilite.isHonourable ?? null,
  };
}

function transformFormationContinueIAS(
  formationContinue: GatewayImportExportAssujetti.AssujettiRowImportFormationContinueIAS
): AssujettiExcelMappedTemplate["formationContinueIAS"] {
  return {
    soumisFormation: formationContinue.soumisFormationContinue,
    dateSaisie: formationContinue.dateDernierVerification,
    formation0Heures: formationContinue.formations.at(0)?.nombreHeures ?? null,
    formation0Theme: formationContinue.formations.at(0)?.theme ?? null,
    formation1Heures: formationContinue.formations.at(1)?.nombreHeures ?? null,
    formation1Theme: formationContinue.formations.at(1)?.theme ?? null,
  };
}

function transformFormationContinueIOBSP(
  formationContinue: GatewayImportExportAssujetti.AssujettiRowImportFormationContinueIOBSP
): AssujettiExcelMappedTemplate["formationContinueIOBSP"] {
  return {
    soumisFormation: formationContinue.soumisFormationContinue ?? null,
    formation0Heures: formationContinue.formations.at(0)?.nombreHeures ?? null,
    formation0Theme: formationContinue.formations.at(0)?.theme ?? null,
    formation1Heures: formationContinue.formations.at(1)?.nombreHeures ?? null,
    formation1Theme: formationContinue.formations.at(1)?.theme ?? null,
    dateSaisie: formationContinue.dateDernierVerification,
    heuresFormationCreditConsommation:
      formationContinue.heuresFormationCreditConsommation ?? null,
    heuresFormationCreditImmobilier:
      formationContinue.heuresFormationCreditImmobilier ?? null,
    heuresFormationCreditPretViagerHypotecaire:
      formationContinue.heuresFormationCreditPretViagerHypotecaire ?? null,
    heuresFormationCreditRegroupementCredits:
      formationContinue.heuresFormationCreditRegroupementCredits ?? null,
    heuresFormationCreditServicePaiement:
      formationContinue.heuresFormationCreditServicePaiement ?? null,
  };
}

function transformCapaciteProIAS(
  capacitePro: GatewayImportExportAssujetti.AssujettiRowImportCapaciteProIAS
): AssujettiExcelMappedTemplate["capaciteProIAS"] {
  return {
    categorie: capacitePro.categorie
      ? excelLabelByCategorieIAS[capacitePro.categorie]
      : null,
    datePriseFonction: capacitePro.datePriseFonction ?? null,
    gradeDiplome: capacitePro.gradeDiplome
      ? excelLabelByGradeDiplome[capacitePro.gradeDiplome]
      : null,
    justificatif: capacitePro.typeJustificatif
      ? excelLabelByTypeJustificatifIAS[capacitePro.typeJustificatif]
      : null,
    niveau: capacitePro.niveau
      ? excelLabelByNiveauIas[capacitePro.niveau]
      : null,
    nomDiplome: capacitePro.nomDiplome ?? null,
    soumisALaCondition: capacitePro.conditionCapaciteProfessionnelle ?? null,
    statutPro: capacitePro.statusProfessionel
      ? excelLabelByStatutPro[capacitePro.statusProfessionel]
      : null,
  };
}

function transformCapaciteProIOBSP(
  capacitePro: GatewayImportExportAssujetti.AssujettiRowImportCapaciteProIOBSP
): AssujettiExcelMappedTemplate["capaciteProIOBSP"] {
  return {
    categorie: capacitePro.categorie
      ? excelLabelByCategorieIOBSP[capacitePro.categorie]
      : null,
    datePriseFonction: capacitePro.datePriseFonction ?? null,
    gradeDiplome: capacitePro.gradeDiplome
      ? excelLabelByGradeDiplome[capacitePro.gradeDiplome]
      : null,
    justificatif: capacitePro.typeJustificatif
      ? excelLabelByTypeJustificatifIOBSP[capacitePro.typeJustificatif]
      : null,
    niveau: capacitePro.niveau
      ? excelLabelByNiveauIobsp[capacitePro.niveau]
      : null,
    nomDiplome: capacitePro.nomDiplome ?? null,
    soumisALaCondition: capacitePro.conditionCapaciteProfessionnelle ?? null,
    statutPro: capacitePro.statusProfessionel
      ? excelLabelByStatutPro[capacitePro.statusProfessionel]
      : null,
  };
}

export function transformAPIRowsForToRows(
  mapping: GatewayImportExportAssujetti.AssujettiRowImport
): AssujettiExcelMappedTemplate {
  return {
    assujetti: transformAssujetti(mapping.assujetti),
    honorabilite: transformHonorabilite(mapping.honorabilite),
    formationContinueIAS: transformFormationContinueIAS(
      mapping.formationContinueIAS
    ),
    formationContinueIOBSP: transformFormationContinueIOBSP(
      mapping.formationContinueIOBSP
    ),
    capaciteProIAS: transformCapaciteProIAS(mapping.capaciteProIAS),
    capaciteProIOBSP: transformCapaciteProIOBSP(mapping.capaciteProIOBSP),
  };
}

export const transformAssujettiInErrorApiRowsToRows = (
  assujettiItem: AssujettiDataItemError | ImportAssujettiItem
) => {
  const { data: assujettiImport } = assujettiItem;
  return {
    assujetti: {
      mail: assujettiImport.assujetti?.email ?? undefined,
      name: assujettiImport.assujetti?.name ?? undefined,
      role: assujettiImport.assujetti?.role
        ? excelLabelByAssujettiRole[assujettiImport.assujetti?.role]
        : undefined,
      sirenCompany: assujettiImport.assujetti?.sirenCompany ?? undefined,
      surname: assujettiImport.assujetti?.surname ?? undefined,
    },
    honorabilite: {
      dateVerif: assujettiImport.honorabilite?.lastVerifiedAt
        ? format(
            new Date(assujettiImport.honorabilite.lastVerifiedAt),
            "dd/MM/yyyy"
          )
        : undefined,
      honorable: assujettiImport.honorabilite?.isHonourable ?? undefined,
    },
    formationContinueIAS: {
      dateSaisie: assujettiImport.formationContinueIAS?.dateDernierVerification
        ? format(
            new Date(
              assujettiImport.formationContinueIAS.dateDernierVerification
            ),
            "dd/MM/yyyy"
          )
        : undefined,
      soumisFormation:
        assujettiImport.formationContinueIAS?.soumisFormation ?? undefined,
      formations: assujettiImport.formationContinueIAS?.formations?.map(
        (formation) => ({
          nombreHeures: formation.nombreHeures ?? undefined,
          theme: formation.theme ?? undefined,
        })
      ),
    },
    formationContinueIOBSP: {
      soumisFormation:
        assujettiImport.formationContinueIOBSP?.soumisFormation ?? undefined,
      dateSaisie: assujettiImport.formationContinueIOBSP
        ?.dateDernierVerification
        ? format(
            new Date(
              assujettiImport.formationContinueIOBSP.dateDernierVerification
            ),
            "dd/MM/yyyy"
          )
        : undefined,
      formations: assujettiImport.formationContinueIOBSP?.formations?.map(
        (formation) => ({
          nombreHeures: formation.nombreHeures ?? undefined,
          theme: formation.theme ?? undefined,
        })
      ),
      heuresFormationCreditConsommation:
        assujettiImport.formationContinueIOBSP
          ?.heuresFormationCreditConsommation ?? undefined,
      heuresFormationCreditImmobilier:
        assujettiImport.formationContinueIOBSP
          ?.heuresFormationCreditImmobilier ?? undefined,
      heuresFormationCreditPretViagerHypotecaire:
        assujettiImport.formationContinueIOBSP
          ?.heuresFormationCreditPretViagerHypotecaire ?? undefined,
      heuresFormationCreditRegroupementCredits:
        assujettiImport.formationContinueIOBSP
          ?.heuresFormationCreditRegroupementCredits ?? undefined,
      heuresFormationCreditServicePaiement:
        assujettiImport.formationContinueIOBSP
          ?.heuresFormationCreditServicePaiement ?? undefined,
    },
    capaciteProIAS: {
      categorie: assujettiImport.capaciteProIAS?.categorie
        ? excelLabelByCategorieIAS[assujettiImport.capaciteProIAS.categorie]
        : undefined,
      datePriseFonction: assujettiImport.capaciteProIAS?.datePriseFonction
        ? format(
            new Date(assujettiImport.capaciteProIAS.datePriseFonction),
            "dd/MM/yyyy"
          )
        : undefined,
      gradeDiplome: assujettiImport.capaciteProIAS?.gradeDiplome
        ? excelLabelByGradeDiplome[assujettiImport.capaciteProIAS.gradeDiplome]
        : undefined,
      justificatif: assujettiImport.capaciteProIAS?.typeJustificatif
        ? excelLabelByTypeJustificatifIAS[
            assujettiImport.capaciteProIAS.typeJustificatif
          ]
        : undefined,
      niveau: assujettiImport.capaciteProIAS?.niveau
        ? excelLabelByNiveauIas[assujettiImport.capaciteProIAS.niveau]
        : undefined,
      nomDiplome: assujettiImport.capaciteProIAS?.nomDiplome ?? undefined,
      soumisALaCondition:
        assujettiImport.capaciteProIAS?.conditionCapaciteProfessionnelle ??
        undefined,
      statutPro: assujettiImport.capaciteProIAS?.statusProfessionel
        ? excelLabelByStatutPro[
            assujettiImport.capaciteProIAS.statusProfessionel
          ]
        : undefined,
    },
    capaciteProIOBSP: {
      categorie: assujettiImport.capaciteProIOBSP?.categorie
        ? excelLabelByCategorieIOBSP[assujettiImport.capaciteProIOBSP.categorie]
        : undefined,
      datePriseFonction: assujettiImport.capaciteProIOBSP?.datePriseFonction
        ? format(
            new Date(assujettiImport.capaciteProIOBSP.datePriseFonction),
            "dd/MM/yyyy"
          )
        : undefined,
      gradeDiplome: assujettiImport.capaciteProIOBSP?.gradeDiplome
        ? excelLabelByGradeDiplome[
            assujettiImport.capaciteProIOBSP.gradeDiplome
          ]
        : undefined,
      justificatif: assujettiImport.capaciteProIOBSP?.typeJustificatif
        ? excelLabelByTypeJustificatifIOBSP[
            assujettiImport.capaciteProIOBSP.typeJustificatif
          ]
        : undefined,
      niveau: assujettiImport.capaciteProIOBSP?.niveau
        ? excelLabelByNiveauIobsp[assujettiImport.capaciteProIOBSP.niveau]
        : undefined,
      nomDiplome: assujettiImport.capaciteProIOBSP?.nomDiplome ?? undefined,
      soumisALaCondition:
        assujettiImport.capaciteProIOBSP?.conditionCapaciteProfessionnelle ??
        undefined,
      statutPro: assujettiImport.capaciteProIOBSP?.statusProfessionel
        ? excelLabelByStatutPro[
            assujettiImport.capaciteProIOBSP.statusProfessionel
          ]
        : undefined,
    },
    errors: (assujettiItem as AssujettiDataItemError).errors
      ? (assujettiItem as AssujettiDataItemError).errors
          .map((e) => e.description)
          .join(", ")
      : "",
  };
};
