import {
  Button,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as DownloadIcon } from "@src/assets/icons/download.svg";
import { ReactComponent as Warning } from "@src/assets/icons/exclamation-circle-fill.svg";
import { ReactComponent as Info } from "@src/assets/icons/info-circle-fill.svg";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { Dialog } from "@src/components/Dialog/Dialog";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import { useMe } from "@src/store/store.me";
import classNames from "classnames";
import { useEffect, useState } from "react";
import styles from "./ImportAssujettiDialog.module.scss";

type WarningContentProps = {
  onCancel: () => void;
  onConfirm: (cleanOnImport: boolean) => void;
  defaultValue: "complete" | "replace";
  setClean: (clean: boolean) => void;
};

const WARNING_CONTENT_FORM_ID = "warning-content-form-id";
function WarningContent({
  onCancel,
  onConfirm,
  defaultValue,
  setClean,
}: WarningContentProps) {
  const [selected, setSelected] = useState<"complete" | "replace">(
    defaultValue
  );

  useEffect(() => {
    setClean(selected === "replace");
  }, [selected, setClean]);
  return (
    <FormGrid>
      <FormRow>
        <FormField>
          <h3 className={styles.title}>Je souhaite que cet import :</h3>
        </FormField>
      </FormRow>

      <FormRow className={styles.completeButton}>
        <button
          className={styles.button}
          onClick={() => setSelected("complete")}
        >
          <div
            className={classNames(styles.card, {
              [styles.selected]: selected === "complete",
            })}
          >
            <p>Complète la liste existante des assujettis à contrôler</p>
            <div className={classNames(styles.blue, styles.text)}>
              <Info />
              <p>
                Les assujettis dans cet import s’ajouteront aux assujettis déjà
                renseignées.
              </p>
            </div>
          </div>
        </button>
      </FormRow>
      <FormRow className={styles.replaceButton}>
        <button
          className={styles.button}
          onClick={() => setSelected("replace")}
        >
          <div
            className={classNames(styles.card, {
              [styles.selected]: selected === "replace",
            })}
          >
            <p>Remplace la liste existante des sociétés à contrôler</p>
            <div className={classNames(styles.orange, styles.text)}>
              <Warning />
              <p>Les données actuelles seront écrasées</p>
            </div>
          </div>
        </button>
      </FormRow>

      <FormRow>
        <FormField className={styles.actions}>
          <Button variant="text" onClick={onCancel}>
            Annuler
          </Button>
          <Button
            form={WARNING_CONTENT_FORM_ID}
            onClick={() => onConfirm(selected === "replace")}
            variant="filled"
          >
            Confirmer
          </Button>
        </FormField>
      </FormRow>
    </FormGrid>
  );
}

type ImportContentProps = {
  onCancel: () => void;
  onFileChange: (file: File | null | undefined) => void;
  onClose?: (value: string) => void;
};

function ImportContent({
  onCancel,
  onFileChange,
  onClose,
}: ImportContentProps) {
  const { me } = useMe();

  const [isImporting, setIsImporting] = useState(false);
  if (!me) return <div />;
  return (
    <FormGrid>
      <FormRow>
        <FormField>
          <h3>Importer les assujettis en masse</h3>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <p>
            Renseignez toutes les informations des personnes dans un fichier
            excel à partir d&apos;un modèle téléchargeable
          </p>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <a
            href="/modele_personnes_assujetties.xlsx"
            target="_blank"
            className={styles.downloadLink}
          >
            <Button
              variant="text"
              icon={<DownloadIcon />}
              className={styles.download}
            >
              Télécharger le modèle
            </Button>
          </a>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField className={styles.actions}>
          <Button variant="text" onClick={onCancel}>
            Précédent
          </Button>
          <FileInputButton
            disabled={isImporting}
            icon={isImporting ? <Loader /> : undefined}
            onChange={(e) => {
              setIsImporting(true);
              onFileChange(e.target.files?.item(0));
              setIsImporting(false);
              onClose?.("true");
            }}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
            Importer le fichier complété
          </FileInputButton>
        </FormField>
      </FormRow>
    </FormGrid>
  );
}

export type ImportAssujettiDialogProps = {
  onClose?: (value: string) => void;
  dialogRef: React.MutableRefObject<HTMLDialogElement | null>;
  onFileChange: (file: File | null | undefined) => void;
  setClean: (clean: boolean) => void;
};
export function ImportAssujettiDialog({
  onClose,
  dialogRef,
  onFileChange,
  setClean,
}: ImportAssujettiDialogProps) {
  const [state, setState] = useState<{
    step: "warning" | "import";
    cleanOnImport: boolean;
  }>({ step: "warning", cleanOnImport: false });
  return (
    <Dialog dialogRef={dialogRef}>
      {state.step === "warning" && (
        <WarningContent
          onCancel={() => onClose?.("false")}
          onConfirm={(shouldClean) =>
            setState({
              step: "import",
              cleanOnImport: shouldClean,
            })
          }
          defaultValue={state.cleanOnImport ? "replace" : "complete"}
          setClean={setClean}
        />
      )}
      {state.step === "import" && (
        <ImportContent
          onCancel={() => {
            setState((prev) => ({ ...prev, step: "warning" }));
          }}
          onFileChange={onFileChange}
          onClose={onClose}
        />
      )}
    </Dialog>
  );
}
