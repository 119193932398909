import { useCallback, useState } from "react";
import { SkeletonCard } from "@src/components/Skeletons/card/SkeletonCard";
import { useMe } from "@src/store/store.me";
import { ParametrePageTemplate } from "../ParametrePageTemplate";
import { ParametrePageActions } from "../ParametrePageActions";
import { SecuriteForm } from "./SecuriteForm";

const FORM_ID = "security-form-id";

export function Securite() {
  const { me } = useMe();
  const [isLoading, setIsLoading] = useState(false);
  const [formKey, setFormKey] = useState(0);

  const handleResetForm = useCallback(() => {
    setFormKey((prev) => prev + 1);
  }, []);

  return (
    <ParametrePageTemplate
      title="Sécurité"
      actions={
        <ParametrePageActions
          onCancel={handleResetForm}
          formId={FORM_ID}
          isLoading={isLoading}
        />
      }
    >
      {!me && <SkeletonCard height={500} />}
      {me && (
        <SecuriteForm
          key={formKey}
          setIsLoading={setIsLoading}
          formId={FORM_ID}
        />
      )}
    </ParametrePageTemplate>
  );
}
