import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { FileInputButton } from "@src/components/FileInputButton/FileInputButton";
import {
  AdhesionStepEnum,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { useImportSocietes } from "./useImportSocietes";

export function ImportEntreprisesButton({
  addData,
  changeStep,
  children = "Importer le fichier complété",
}: Pick<ParcoursAdhesionStepComponentProps, "addData" | "changeStep"> & {
  children?: string;
}) {
  const { handleImportSocietes, status } = useImportSocietes(
    ({ societesToAdd, societesInError, societesDoublons }) => {
      addData({
        societesRattachees: societesToAdd.map(({ societe, index }) => ({
          ...societe,
          index,
        })),
        societesDoublons: societesDoublons.map(
          ({ societe, description, index }) => ({
            ...societe,
            description,
            index,
          })
        ),
        importInError: societesInError.map(({ societe, errors, index }) => ({
          ...societe,
          errors,
          index,
        })),
      });
    },
    () => changeStep(AdhesionStepEnum.IMPORT_DASHBOARD)
  );
  return (
    <FileInputButton
      disabled={status === "loading"}
      icon={status === "loading" ? <Loader /> : undefined}
      onChange={(e) => handleImportSocietes(e)}
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    >
      {children}
    </FileInputButton>
  );
}
