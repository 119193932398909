import { isNumber } from "lodash";
import classNames from "classnames";
import styles from "./Table.module.scss";
import { ColumnDefinition } from "./Table.definition";
import { TableBody } from "./Components/TableBody";
import { TableHeader } from "./Components/TableHeader";
import { TableSeparator } from "./Components/TableSeparator";
import { useTableSelection } from "./Components/useTableSelection";

type TablePropsSelectionOptions<T> = {
  renderActions?: (selectedRows: T[]) => React.ReactElement;
};

type TableProps<T> = {
  data: T[];
  columns: ColumnDefinition<T>[];
  onRowClick?: (row: T, index: number) => void;
  textNoData?: string;
  selectionOptions?: TablePropsSelectionOptions<T>;
  hideHeader?: boolean;
  flatDesign?: boolean;
  noDarkenColor?: boolean;
  ctaNoData?: React.ReactNode;
  className?: string;
};

export function Table<T>({
  data,
  columns,
  onRowClick,
  textNoData = "Aucune données",
  selectionOptions,
  hideHeader = false,
  flatDesign = false,
  noDarkenColor = false,
  ctaNoData = null,
  className,
}: TableProps<T>) {
  const selectionState = useTableSelection(!!selectionOptions, data);
  function getTemplateColumns() {
    const template = columns
      .map((column) =>
        isNumber(column.size) ? `${column.size}fr` : column.size
      )
      .join(" ");
    if (selectionState !== null) return `64px ${template}`;
    return template;
  }

  return (
    <div
      className={classNames(
        styles.tablecontainer,
        {
          [styles.flat]: flatDesign,
        },
        className
      )}
    >
      <div
        className={styles.table}
        style={{ gridTemplateColumns: getTemplateColumns() }}
      >
        {!hideHeader && (
          <>
            <TableHeader<T>
              columns={columns}
              selectionState={selectionState}
              renderSelectionActions={selectionOptions?.renderActions}
            />
            <TableSeparator
              columns={columns}
              selectable={selectionState !== null}
            />
          </>
        )}
        <TableBody<T>
          columns={columns}
          data={data}
          onRowClick={onRowClick}
          textNoData={textNoData}
          selectionState={selectionState}
          noDarkenColor={noDarkenColor}
        />
      </div>
      {!data.length && ctaNoData && (
        <div className={styles.ctaNoData}>{ctaNoData}</div>
      )}
    </div>
  );
}
