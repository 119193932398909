import {
  GatewayConformiteRcPro,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import {
  Button,
  Card,
  FormGrid,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { MaxWidthContent } from "@src/components/MaxWidthContent/MaxWidthContent";
import { TagConformite } from "@src/components/Tag/TagConformite";
import { ConformiteReadFiles } from "@src/conformite/ConformiteReadFiles";
import styles from "../ConformiteRcPro.module.scss";
import { ConformiteRcProReadProperties } from "./ConformiteRcProReadProperties";
import { CopyRcProButton } from "./CopyRcProButton/CopyRcProButton";

type ConformiteRcProReadProps = {
  properties: GatewayConformiteRcPro.ConformiteRcProProperty;
  isConforme: boolean;
  setToEdit: () => void;
  millesime: number;
  siren: string;
  categorie: GatewayInfoSocieteOrias.Categorie;
};
export function ConformiteRcProReadContent({
  properties,
  millesime,
  siren,
  categorie,
}: Omit<ConformiteRcProReadProps, "setToEdit" | "isConforme" | "rcPro">) {
  const hasRcpIndividuelleSouscritSetToNo =
    (
      properties as {
        rcpIndividuelleSouscrit?: GatewayConformiteRcPro.ConformiteRcProPropertyType;
      }
    ).rcpIndividuelleSouscrit ===
    GatewayConformiteRcPro.ConformiteRcProPropertyType.NON;
  return (
    <>
      <h4 className={styles.subtitle}>Pièces justificatives</h4>
      <ConformiteReadFiles
        files={properties.files}
        millesime={millesime}
        siren={siren}
        isMandataire={hasRcpIndividuelleSouscritSetToNo}
      />
      <h4 className={styles.subtitle}>Questionnaire</h4>
      <FormGrid disableHorizontalGutter>
        <ConformiteRcProReadProperties
          millesime={millesime}
          properties={properties}
          categorie={categorie}
        />
      </FormGrid>
    </>
  );
}
export function ConformiteRcProRead({
  properties,
  isConforme,
  setToEdit,
  millesime,
  siren,
  categorie,
}: ConformiteRcProReadProps) {
  return (
    <MaxWidthContent size="large">
      <Card className={styles.content}>
        <div className={styles.cardHeader}>
          <div className={styles.cardHeaderLeft}>
            <h3>Formulaire</h3>
            <TagConformite isConforme={isConforme} year={millesime} />
          </div>
          <div className={styles.buttons}>
            <CopyRcProButton
              categorie={categorie}
              millesime={millesime}
              siren={siren}
              isConforme={isConforme}
            />
            <Button onClick={setToEdit}>Modifier</Button>
          </div>
        </div>
        <ConformiteRcProReadContent
          categorie={categorie}
          properties={properties}
          millesime={millesime}
          siren={siren}
        />
      </Card>
    </MaxWidthContent>
  );
}
