import {
  Card,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { Link } from "@src/components/Link/Link";
import { ReactNode } from "react";
import { EntrepriseImportCorrection } from "./EntrepriseImportCorrection";
import styles from "./EntrepriseImportDashboard.module.scss";
import {
  EntrepriseImportDoublon,
  EntrepriseImportDoublonData,
} from "./EntrepriseImportDoublon";
import {
  EntrepriseImportErrors,
  EntrepriseImportErrorsData,
} from "./EntrepriseImportErrors";

type EntrepriseImportDashboardProps<T> = {
  doublons: EntrepriseImportDoublonData[];
  success: T[];
  errors: EntrepriseImportErrorsData[];
  onUpdateError: (error: EntrepriseImportErrorsData) => void;
  onExport: () => void;
  importButton: ReactNode;
};

export function EntrepriseImportDashboard<T>({
  doublons,
  success,
  errors,
  onUpdateError,
  onExport,
  importButton,
}: EntrepriseImportDashboardProps<T>) {
  return (
    <FormGrid disableHorizontalGutter>
      {errors.length > 0 && (
        <FormRow>
          <FormField>
            <InfoCard type="warning" className={styles.infoCard}>
              {success.length === 0 ? (
                <>
                  Votre fichier semble ne pas correspondre au modèle attendu.
                  Nous vous remercions de respecter le format et la structure du
                  fichier modèle à télécharger{" "}
                  <Link to="/modele_societe.xlsx" target="_blank">
                    ici
                  </Link>
                  .
                </>
              ) : (
                "Votre fichier comporte des lignes erronées."
              )}
            </InfoCard>
          </FormField>
        </FormRow>
      )}
      <FormRow>
        <FormField>
          {errors.length + doublons.length > 0 && (
            <h2 className={styles.detailTitle}>Détails des erreurs</h2>
          )}
        </FormField>
      </FormRow>
      {errors.length > 0 && (
        <FormRow>
          <FormField>
            <EntrepriseImportErrors onUpdate={onUpdateError} errors={errors} />
          </FormField>
        </FormRow>
      )}
      {doublons.length > 0 && (
        <FormRow>
          <FormField>
            <EntrepriseImportDoublon doublons={doublons} />
          </FormField>
        </FormRow>
      )}
      {success.length > 0 && (
        <FormRow>
          <FormField>
            <Card>
              <h2>
                <span className={styles.countLignesCorrectes}>
                  {success.length}
                </span>
                {success.length > 1 ? " lignes correctes" : " ligne correcte"}
              </h2>
            </Card>
          </FormField>
        </FormRow>
      )}
      {errors.length > 0 && (
        <FormRow className={styles.correctionBloc}>
          <FormField>
            <EntrepriseImportCorrection
              onExport={onExport}
              importButton={importButton}
            />
          </FormField>
        </FormRow>
      )}
    </FormGrid>
  );
}
