export function ControleQuinquennalExplainAxeAssujetti() {
  return (
    <div>
      <p>
        Les documents sollicités ci-dessous permettront de vérifier le respect
        de l&apos;exigence d&apos;honorabilité (collaborateurs),
        l&apos;adéquation du niveau de capacité professionnelle initiale avec
        les missions exercées et le cas échéant le suivi d&apos;une formation
        continue annuelle adaptée aux produits distribués et aux fonctions
        occupées (dirigeants + collaborateurs).
      </p>
      <br />
      <p>Pièces à fournir :</p>
      <ul>
        <li>
          <b>
            Si des collaborateur(s) salariés participent à l&apos;activité
            d&apos;intermédiation : Honorabilité et capacité professionnelle :
          </b>
        </li>
        <ul>
          <li>
            Formulaire B3 de moins d&apos;1 an ou déclaration sur l&apos;honneur
            (modèle accessible dans la Base Documentaire).
          </li>
          <li>Organigramme</li>
          <li>Fiche(s) de poste des collaborateurs</li>
          <li>
            Justificatif(s) de capacité professionnelle initiale IAS et/ou IOBSP
            tel(s) que déclaré(s) lors de l&apos;adhésion (certificats de
            travail du précédent employeur ou copie du diplômes ou livret de
            stage / attestation de formations)
          </li>
        </ul>
      </ul>

      <ul>
        <li>
          <b>
            Dirigeant(s) et collaborateur(s) : Formation continue annuelle (N-1)
            :
          </b>
        </li>
        <ul>
          <li>
            Certificat(s) de chacune des formations suivies dans l&apos;année
            N-1 par le(s) dirigeant(s) et collaborateur(s) pour les activités
            d&apos;IAS et/ou d&apos;IOBSP
          </li>
        </ul>
      </ul>
      <p>
        Si lesdites attestations ne précisent pas explicitement le contenu, le
        programme de la formation suivie pourra être sollicité ultérieurement
        dans le cadre de la vérification.
      </p>
      <br />
      <p>
        Par ailleurs, si un registre de formation a été mis en place au sein de
        votre structure, nous vous invitons à nous le transmettre.
      </p>
      <ul>
        <li>
          <b>
            Si vous exercez l&apos;activité de distribution d&apos;assurance à
            titre accessoire au sens de l&apos;article L 511-1 III du code des
            assurances et afin de justifier l&apos;absence de formation :
          </b>
        </li>
        <ul>
          <li>
            Déclaration sur l&apos;honneur attestant de la pratique de
            l&apos;activité d&apos;IAS à titre accessoire accessible dans la
            Base Documentaire.
          </li>
        </ul>
      </ul>
    </div>
  );
}
