import { GatewayAdhesion, GatewayEntreprise } from "@conformite/gateway";
import { AdhesionData } from "@src/adhesion/AdhesionData/adhesionData";
import { AdhesionStepEnum } from "@src/adhesion/ParcoursAdhesion.definition";
import { RenouvellementStepEnum } from "@src/renouvellement/ParcoursRenouvellement.definition";
import { RenouvellementData } from "@src/renouvellement/steps/RenouvellementData";
import { userSession } from "@src/session/UserSession";
import { captchaAxiosInstance } from "./utils/captchaAxiosInstance";

export type CacheParcours<
  TData extends AdhesionData | RenouvellementData,
  TStep extends AdhesionStepEnum | RenouvellementStepEnum
> = {
  data: TData;
  history: TStep[];
};

export class GatewayAdhesion2Api {
  public static async getCache(
    typeCache: "ADHESION"
  ): Promise<CacheParcours<AdhesionData, AdhesionStepEnum> | null>;

  public static async getCache(
    typeCache: "RENOUVELLEMENT"
  ): Promise<CacheParcours<RenouvellementData, RenouvellementStepEnum> | null>;

  public static async getCache(
    typeCache: GatewayAdhesion.CacheAdhesionType
  ): Promise<
    | CacheParcours<RenouvellementData, RenouvellementStepEnum>
    | CacheParcours<AdhesionData, AdhesionStepEnum>
    | null
  > {
    const res = await userSession.axiosInstance.get<
      | CacheParcours<AdhesionData, AdhesionStepEnum>
      | CacheParcours<RenouvellementData, RenouvellementStepEnum>
      | null
    >(`${GatewayAdhesion.adhesionCacheUrl}/${typeCache}`);
    return res.data;
  }

  public static async saveCache(
    data: CacheParcours<AdhesionData, AdhesionStepEnum>,
    typeCache: "ADHESION"
  ): Promise<void>;

  public static async saveCache(
    data: CacheParcours<RenouvellementData, RenouvellementStepEnum>,
    typeCache: "RENOUVELLEMENT"
  ): Promise<void>;

  public static async saveCache(
    data:
      | CacheParcours<AdhesionData, AdhesionStepEnum>
      | CacheParcours<RenouvellementData, RenouvellementStepEnum>,
    typeCache: GatewayAdhesion.CacheAdhesionType
  ): Promise<void> {
    await userSession.axiosInstance.post(
      `${GatewayAdhesion.adhesionCacheUrl}/${typeCache}`,
      data
    );
  }

  public static async init(
    body: GatewayAdhesion.InitRequestType
  ): Promise<void> {
    await captchaAxiosInstance.post(`${GatewayAdhesion.adhesionInitUrl}`, body);
  }

  public static async createIntentionPaiementRenouvellementGroupe(
    entreprisesAController: GatewayAdhesion.AdhesionCreationEntrepriseAControllerRequestType[],
    typeAdhesion: GatewayEntreprise.TypeAdhesionActive,
    coupon?: string
  ): Promise<GatewayAdhesion.CreateIntentionPaimentResponseType> {
    const res =
      await userSession.axiosInstance.post<GatewayAdhesion.CreateIntentionPaimentResponseType>(
        `${GatewayAdhesion.createIntentionPaiementRenouvellementGroupeUrl}`,
        {
          entreprisesAController,
          typeAdhesion,
          coupon,
        }
      );
    return res.data;
  }

  public static async createIntentionPaiementRenouvellementEI(
    entrepriseAController: GatewayAdhesion.CreateIntentionPaimentEIRequestType
  ): Promise<GatewayAdhesion.CreateIntentionPaimentResponseType> {
    const res =
      await userSession.axiosInstance.post<GatewayAdhesion.CreateIntentionPaimentResponseType>(
        `${GatewayAdhesion.createIntentionPaiementRenouvellementEIUrl}`,
        entrepriseAController
      );
    return res.data;
  }

  public static async createIntentionPaiementGroupe(
    typeAdhesion: GatewayEntreprise.TypeAdhesionActive,
    entreprisesAController: GatewayAdhesion.AdhesionCreationEntrepriseAControllerRequestType[],
    coupon?: string
  ): Promise<GatewayAdhesion.CreateIntentionPaimentResponseType> {
    const res =
      await userSession.axiosInstance.post<GatewayAdhesion.CreateIntentionPaimentResponseType>(
        `${GatewayAdhesion.createIntentionPaiementGroupeUrl}`,
        {
          entreprisesAController,
          typeAdhesion,
          coupon,
        }
      );

    return res.data;
  }

  public static async createIntentionPaiementEI(
    entrepriseAController: GatewayAdhesion.CreateIntentionPaimentEIRequestType
  ): Promise<GatewayAdhesion.CreateIntentionPaimentResponseType> {
    const res =
      await userSession.axiosInstance.post<GatewayAdhesion.CreateIntentionPaimentResponseType>(
        `${GatewayAdhesion.createIntentionPaiementEIUrl}`,
        entrepriseAController
      );
    return res.data;
  }

  public static async createEntreprisesAfterPaiementGroupe(
    invoiceId: string | undefined,
    hash: string | undefined,
    idsEntreprisesNonAdherentes: GatewayAdhesion.EntrepriseIdAndSiren[],
    entreprisesAController: GatewayAdhesion.AdhesionCreationEntrepriseAControllerRequestType[],
    typeAdhesion: GatewayEntreprise.TypeAdhesionActive
  ) {
    const res = await userSession.axiosInstance.post<string[]>(
      `${GatewayAdhesion.createEntreprisesAfterPaiementGroupeUrl}`,
      {
        invoiceId,
        hash,
        idsEntreprisesNonAdherentes,
        creationRequest: {
          entreprisesAController,
          typeAdhesion,
        },
      }
    );
    return res.data;
  }

  public static async createEntreprisesAfterPaiementEI(
    invoiceId: string | undefined,
    hash: string | undefined,
    idsEntreprisesNonAdherentes: GatewayAdhesion.EntrepriseIdAndSiren[],
    entrepriseAController: GatewayAdhesion.CreateIntentionPaimentEIRequestType
  ) {
    const res = await userSession.axiosInstance.post<string[]>(
      `${GatewayAdhesion.createEntreprisesAfterPaiementEIUrl}`,
      {
        invoiceId,
        hash,
        idsEntreprisesNonAdherentes,
        creationRequest: entrepriseAController,
      }
    );
    return res.data;
  }

  public static async renouvellerEntreprisesAfterPaiementGroupe(
    invoiceId: string | undefined,
    hash: string | undefined,
    idsEntreprisesNonAdherentes: GatewayAdhesion.EntrepriseIdAndSiren[],
    entreprisesAController: GatewayAdhesion.AdhesionCreationEntrepriseAControllerRequestType[],
    typeAdhesion: GatewayEntreprise.TypeAdhesionActive,
    contact:
      | GatewayAdhesion.UpdateEntreprisesAfterPaiementEIRequestType["contact"],
    entreprisePrincipale:
      | GatewayAdhesion.UpdateEntreprisesAfterPaiementEIRequestType["entreprisePrincipale"]
  ) {
    const res = await userSession.axiosInstance.post<string[]>(
      `${GatewayAdhesion.renouvellerGroupeAfterPaiement}`,
      {
        invoiceId,
        idsEntreprisesNonAdherentes,
        hash,
        creationRequest: {
          entreprisesAController,
          typeAdhesion,
        },
        contact,
        entreprisePrincipale,
      }
    );
    return res.data;
  }

  public static async changeCategoriesOfEntreprise(
    entreprise: GatewayAdhesion.UpdateCategoriesEntrepriseAContrololerRequestType,
    invoiceId: string | undefined,
    hash: string | undefined,
    hubspotId: string
  ) {
    const res = await userSession.axiosInstance.post<string[]>(
      `${GatewayAdhesion.changerCategoriesOfEntreprise}`,
      {
        invoiceId,
        hash,
        entreprise,
        hubspotId,
      }
    );
    return res.data;
  }

  public static async renouvellerEntreprisesAfterPaiementEI(
    invoiceId: string | undefined,
    hash: string | undefined,
    idsEntreprisesNonAdherentes: GatewayAdhesion.EntrepriseIdAndSiren[],
    entrepriseAController: GatewayAdhesion.CreateIntentionPaimentEIRequestType,
    contact: GatewayAdhesion.UpdateEntreprisesAfterPaiementEIRequestType["contact"],
    entreprisePrincipale: GatewayAdhesion.UpdateEntreprisesAfterPaiementEIRequestType["entreprisePrincipale"]
  ) {
    const res = await userSession.axiosInstance.post<string[]>(
      `${GatewayAdhesion.renouvellerEIAfterPaiement}`,
      {
        invoiceId,
        hash,
        idsEntreprisesNonAdherentes,
        creationRequest: entrepriseAController,
        contact,
        entreprisePrincipale,
      }
    );
    return res.data;
  }
}

export type CreationEntrepriseEIParams = Parameters<
  typeof GatewayAdhesion2Api.createEntreprisesAfterPaiementEI
>;
export type CreationEntrepriseGroupeParams = Parameters<
  typeof GatewayAdhesion2Api.createEntreprisesAfterPaiementGroupe
>;

export type RenouvellementEntrepriseEIParams = Parameters<
  typeof GatewayAdhesion2Api.renouvellerEntreprisesAfterPaiementEI
>;
export type RenouvellementEntrepriseGroupeParams = Parameters<
  typeof GatewayAdhesion2Api.renouvellerEntreprisesAfterPaiementGroupe
>;

export type CreationEntrepriseParams =
  | CreationEntrepriseEIParams
  | CreationEntrepriseGroupeParams;

export type RenouvellementEntrepriseParams =
  | RenouvellementEntrepriseEIParams
  | RenouvellementEntrepriseGroupeParams;
