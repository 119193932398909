import { useCallback, useMemo } from "react";
import { GatewayAvancement, GatewayEntreprise } from "@conformite/gateway";
import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import { EntrepriseComponent, SocieteList } from "@src/societes/SocieteList";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { TitleWithNumber } from "@src/components/TitleWithNumber/TitleWithNumber";
import { Dropdown } from "@src/components/DropDown/Dropdown";
import { ReactComponent as ThreeDots } from "@src/assets/icons/three-dots.svg";
import { TagConformite } from "@src/components/Tag/TagConformite";
import { useMillesime } from "@src/store/store.millesime";
import { useEntreprises } from "@src/store/store.entreprise";
import {
  SocieteFilters,
  useSocieteFiltered,
} from "@src/societes/useSocieteFiltered";
import style from "./ConformiteSocieteList.module.scss";

type SocieteActionsProps = {
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType;
  onSocieteRedirect: (
    entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
  ) => void;
};
function SocieteActions({
  entreprise,
  onSocieteRedirect,
}: SocieteActionsProps) {
  return (
    <div aria-hidden onClick={(e) => e.stopPropagation()}>
      <Dropdown
        variant="text"
        icon={<ThreeDots className={style.editIcon} />}
        placement="bottom-end"
      >
        {({ close }) => (
          <div>
            <Button
              variant="text"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                close();
                onSocieteRedirect?.(entreprise);
              }}
            >
              Editer la conformité
            </Button>
          </div>
        )}
      </Dropdown>
    </div>
  );
}
type ConformiteSocieteListProps = {
  getFormAvancement: (
    entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
  ) => number;
  title: string;
  defaultFilters?: SocieteFilters | undefined;
  onSocieteRedirect: (
    entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
  ) => void;
  getIsOptional?: (
    entreprise: GatewayEntreprise.EntrepriseAControllerResponseType
  ) => boolean;
};
export function ConformiteSocieteList({
  getFormAvancement,
  title,
  defaultFilters,
  onSocieteRedirect,
  getIsOptional,
}: ConformiteSocieteListProps) {
  const { selected: millesime } = useMillesime();
  const { entreprisesBySiren, setEntreprises, status } = useEntreprises();
  const entreprisesArray = useMemo(
    () => Object.values(entreprisesBySiren ?? {}),
    [entreprisesBySiren]
  );
  const handleDeleteEntreprise = useCallback(
    (deletedIds: string[]) => {
      setEntreprises(
        entreprisesArray.filter(
          (entreprise) => !deletedIds.includes(entreprise.hubspotId ?? "")
        )
      );
    },
    [setEntreprises, entreprisesArray]
  );
  const ConformiteSocieteActions = useCallback<EntrepriseComponent>(
    ({ entreprise }) => {
      return (
        <SocieteActions
          entreprise={entreprise}
          onSocieteRedirect={onSocieteRedirect}
        />
      );
    },
    [onSocieteRedirect]
  );
  const ConformiteSocieteConforme = useCallback<EntrepriseComponent>(
    ({ entreprise }) => {
      if (!millesime) return <div />;
      return (
        <TagConformite
          isConforme={
            getFormAvancement(entreprise) ===
            GatewayAvancement.AvancementStatus.COMPLET
          }
          year={millesime}
          isOptional={getIsOptional?.(entreprise) ?? false}
        />
      );
    },
    [getFormAvancement, getIsOptional, millesime]
  );
  const props = useSocieteFiltered(entreprisesArray, defaultFilters);
  return (
    <MainContentPageTemplate
      title={
        <TitleWithNumber
          title={title}
          number={props.numberOfContentUnpaginated}
        />
      }
    >
      <SocieteList
        onDeleteEntreprises={handleDeleteEntreprise}
        isLoading={status !== "loaded"}
        actionOnClick={onSocieteRedirect}
        SocieteActions={ConformiteSocieteActions}
        SocieteConformite={ConformiteSocieteConforme}
        {...props}
      />
    </MainContentPageTemplate>
  );
}
